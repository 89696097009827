import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styles from './Dropdown.module.scss';
import classNames from 'classnames/bind';
import { images } from '@utils/constants';
const cx = classNames.bind(styles);

interface IInitialProps {
    children?: React.ReactNode;
    placeholder?: string;
    styles?: any;
    disabled?: boolean;
    defaultValue?: number[];
    allValues?: IAllValuesDropdownCompany[];
    isSort?: boolean;
    onChange?: (listValueSelected: number[]) => void;
}

export const DropdownMulti: React.FC<IInitialProps> = (props) => {
    const { placeholder, styles, disabled, defaultValue, allValues = [], isSort, onChange } = props;
    const [isDrop, setIsDrop] = useState(false);
    const [valueSelected, setValueSelected] = useState<IAllValuesDropdownCompany[]>([]);
    const divRef = useRef<HTMLDivElement | null>(null);

    const valueInput = useMemo(() => {
        return valueSelected.map((i) => i.name).join(', ');
    }, [valueSelected]);

    useEffect(() => {
        const values: IAllValuesDropdownCompany[] = [];
        defaultValue?.forEach((vl) => {
            const item = allValues.find((it) => it.value === vl);
            item && values.push(item);
        });
        values.sort((a, b) => a.value - b.value);
        setValueSelected(values);
    }, [defaultValue]);

    useEffect(() => {
        if (onChange) onChange(valueSelected.map((i) => i.value));
    }, [valueSelected]);

    useEffect(() => {
        function handleClickOutsideBox(event: any) {
            const target = event?.target;
            if (divRef.current && !divRef.current.contains(target)) {
                setIsDrop(false);
            }
        }
        document.addEventListener('click', handleClickOutsideBox);

        return () => {
            document.removeEventListener('click', handleClickOutsideBox);
        };
    }, []);

    const handleSortByItem = useCallback(
        (item: IAllValuesDropdownCompany) => {
            const idx = valueSelected.findIndex((value) => value.value === item.value);

            if (idx < 0) {
                valueSelected.push(item);
            } else {
                valueSelected.splice(idx, 1);
            }
            valueSelected.sort((a, b) => a.value - b.value);
            setValueSelected(JSON.parse(JSON.stringify(valueSelected)));
        },
        [valueSelected],
    );

    return (
        <div ref={divRef} className={cx('form-control d-flex justify-content-between p-2 mb-3', isDrop ? 'gl-border' : '')}>
            <div
                className={styles ? 'w-100 position-relative ' + styles : 'w-100 position-relative '}
                style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
                <div
                    className={cx('select_drop')}
                    onClick={(e) => {
                        setIsDrop(!isDrop);
                    }}>
                    <input type="text" className={cx('dropdown_input')} value={valueInput} placeholder={placeholder} readOnly />
                    <div>
                        <img src={images.IMAGES.ICON_DROPDOWN}></img>
                    </div>
                </div>
                {isDrop && (
                    <div className={cx('select_list')}>
                        {allValues?.map((item, index) => {
                            const isSelected = valueSelected.find((vl) => vl.value === item.value) != null;
                            return (
                                <div
                                    onClick={() => handleSortByItem(item)}
                                    className={'form-check my-3 ' + cx({ item_selected: isSelected })}
                                    key={index}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.value.toString()}
                                        name={item.value.toString()}
                                        checked={isSelected}
                                        onChange={() => console.log()}
                                    />
                                    <label className={cx('form-check-label')} htmlFor={item.value.toString()}>
                                        {item.name}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
