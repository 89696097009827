
import { ReduxStates } from '@redux/reducers';
import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';


function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);
  const { userInfo, checkAccountNotPermisstion } = useSelector((states: ReduxStates) => states);

  useEffect(() => {
    if (userInfo.user_id && !checkAccountNotPermisstion) {
      if (!when) {
        return;
      }

      const push = navigator.push;

      navigator.push = (...args: Parameters<typeof push>) => {
        const result = confirmExit();
        if (result !== false) {
          push(...args);
        }
      };

      window.history.pushState(null, "", window.location.href);
      window.onpopstate = () => {
        const result = confirmExit();
        if (result !== false) {
          window.onpopstate = null;
          history.go(-2);
        }
      };
      return () => {
        navigator.push = push;
      };
    }
  }, [navigator, confirmExit, when, userInfo.user_id, checkAccountNotPermisstion]);
}

export function usePrompt(message: string, when = true) {

  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}