import * as Types from '@redux/actions/type';

interface IAsset {
    id: string;
    register_time: number;
    logo: string;
    name: string;
    address: string;
    room_amount: number;
    box_amount: number;
    publication: number;
}

interface IListAsset {
    listAsset: Array<IAsset>;
}

const defaultState: IListAsset = {
    listAsset: [],
};

export default function AssetReducer(state = defaultState, action: any) {
    switch (action.type) {
        case Types.SET_ASSET:
            return {
                ...state,
                listAsset: action.payload,
            };
        default:
            return state;
    }
}
