import { createRef, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormDropdown, FormInput } from '@components/commons';
import { APPOINTMENT_STATUS, images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import stylesModal from '@components/modals/Modal.module.scss';
import styles from './AppointmentManagement.module.scss';
import { Formik, useFormik } from 'formik';
import { handleGetFullAddress } from '@utils/helpers/utilities';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import moment from 'moment/moment';
import { useSearchParams } from 'react-router-dom';
import DataTable from '@components/commons/Table/Table';
import commonStyles from '@assets/styles/base/Common.module.scss';
import { fetchGetListAppointment, fetchUpdateStatusAppointment } from '@utils/helpers/appointmentApi';
import { SUCCESS_STATUS_MESSAGE } from '@utils/constants/http';
import AppointmentDetailModal from '@components/modals/AppointmentDetailModal';
import ModalConfirm from '@components/modals/ModalConfirm';
import { getDetailAppointment } from '@utils/helpers/api';
import ModalInterface from '@components/commons/Modal';
import WarningModal from './WarningModal';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';
const cxModal = classNames.bind(stylesModal);
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

export enum APPOINTMENT_ERROR {
    NOT_SCHEDULE = 'APPOINTMENT_NOT_SCHEDULE',
    TIME_OUT = 'APPOINTMENT_TIME_OUT',
    DUPLICATE = 'APPOINTMENT_DUPLICATE',
}

const AppointmentManagement: IAppointmentPage<IAppointmentPageProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listAll } = useSelector((states: ReduxStates) => states);
    const tableRef = createRef<ITableComponentHandle>();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [data, setData] = useState<IDataAppointmentSearchRes[]>([]);
    const [dataDetail, setDataDetail] = useState<IDataAppointmentSearchRes | null>(null);
    const [searchParams, setSearchParams] = useSearchParams({
        application_from_time: '',
        application_to_time: '',
        viewing_datetime_from: '',
        viewing_datetime_to: '',
        broker_name: '',
        broker_company_name: '',
        store_name: '',
        status: '',
        limit: '30',
        offset: '0',
    });
    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        application_from_time: searchParams.get('application_from_time') ?? '',
        application_to_time: searchParams.get('application_to_time') ?? '',
        viewing_datetime_from: searchParams.get('viewing_datetime_from') ?? '',
        viewing_datetime_to: searchParams.get('viewing_datetime_to') ?? '',
        broker_name: searchParams?.get('broker_name') ?? '',
        broker_company_name: searchParams.get('broker_company_name') ?? '',
        store_name: searchParams.get('store_name') ?? '',
        status: searchParams.get('status') ?? '',
        limit: searchParams.get('limit') ?? '30',
        offset: searchParams.get('offset') ?? '0',
        asset_name: searchParams.get('asset_name') ?? '',
        room_number: searchParams.get('room_number') ?? '',
    });
    const [appoint, setAppoint] = useState<{
        isShowDetail: boolean;
        isShowWarning: boolean;
        isShowApprove: boolean | null;
        textError: any;
    }>({
        isShowDetail: false,
        isShowApprove: null,
        isShowWarning: false,
        textError: '',
    });
    const [reasonReject, setReasonReject] = useState<any>(t('appointment.warningReject'));

    useEffect(() => {
        if (searchParams.get('appointment_id')) {
            getDataDetail(Number(searchParams.get('appointment_id')));
        }
    }, []);

    useEffect(() => {
        if (appoint.isShowDetail) {
            window.addEventListener('popstate', () => {
                setAppoint({ ...appoint, isShowDetail: false });
            });
        }
    }, [appoint.isShowDetail]);


    const getDataDetail = (id: string | number) => {
        getDetailAppointment(id)
            .then(({ data }) => {
                const searchParamsObject = Object.fromEntries(searchParams.entries());
                setSearchParams({
                    ...searchParamsObject,
                    appointment_id: id.toString(),
                });
                setDataDetail(data.data);
                setAppoint({ ...appoint, isShowDetail: true });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSearch = (values: any) => {
        setSearchParams(values);
        fetchAppointmentList(values as any).then();
    }

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const searchObject = {
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        };
        setSearchParams(searchObject);
        fetchAppointmentList(searchObject as any).then();
    };

    const clear = () => {
        const newValue = {
            application_from_time: '',
            application_to_time: '',
            viewing_datetime_from: '',
            viewing_datetime_to: '',
            broker_name: '',
            broker_company_name: '',
            store_name: '',
            asset_name: '',
            room_number: '',
            status: '',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    };

    const getDataChangeStatus = (item: SetStateAction<IDataAppointmentSearchRes | null>, isApprove: boolean) => {
        setAppoint({ ...appoint, isShowApprove: isApprove });
        setDataDetail(item);
    };

    const updateStatusAppointment = async (status: number, reason = null) => {
        if (dataDetail?.id) {
            const res = await fetchUpdateStatusAppointment({
                id: dataDetail?.id,
                status,
                reason,
                currentStatus: dataDetail.request_status,
            });
            if (res.status === SUCCESS_STATUS_MESSAGE) {
                setAppoint({
                    ...appoint,
                    isShowApprove: null,
                });
                await fetchAppointmentList(searchParams as any).then();
            } else {
                const mess = res.data.message;
                setAppoint({
                    ...appoint,
                    isShowWarning: true,
                    textError:
                        mess == APPOINTMENT_ERROR.NOT_SCHEDULE ? (
                            t<string>('appointment.warning_schedule')
                        ) : mess == APPOINTMENT_ERROR.TIME_OUT ? (
                            t<string>('appointment.warning_time_out')
                        ) : mess == APPOINTMENT_ERROR.DUPLICATE ? (
                            <span>
                                {t('appointment.warning_duplicate_1')}
                                <br />
                                <span style={{ color: '#51A60E' }}>{t('appointment.warning_duplicate_2')}</span>
                                {t('appointment.warning_duplicate_3')}
                            </span>
                        ) : (
                            t<string>('appointment.warning_data_change')
                        ),
                });
            }
        }
    };
    useEffect(() => {
        const response = listAll.appointment;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.appointment]);
    const fetchAppointmentList = async (searchParams: IAppointmentSearchParams) => {
        const response = await fetchGetListAppointment(searchParams);
        dispatch(setListDataAll({...listAll, appointment: response}))
        // if (response.status === SUCCESS_STATUS_MESSAGE) {
        //     setData(response.data);
        //     setPageState({
        //         firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
        //         currentPage: response?.metadata?.offset + 1,
        //         totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
        //         pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
        //         totalRecords: response?.metadata?.total,
        //     });
        // }
    };

    useEffect(() => {
        fetchAppointmentList(searchParams as any).then();
    }, [searchParams]);

    const handleClosePopup = async () => {
        setAppoint({
            ...appoint,
            isShowApprove: null,
            isShowWarning: false,
        });
        if (appoint.isShowWarning) {
            await fetchAppointmentList(searchParams as any).then();
        }
    };

    const handleRoomName = (rooms: any) => {
        if (isEmpty(rooms)) return '-';
        return rooms.join('・');
    };

    const handleTableData = () => {
        return data.map((item) => {
            return {
                ...item,
                created_time: item?.created_at ?? '',
                start_date: moment.utc(item?.start_date).format('YYYY/MM/DD HH:mm'),
                end_date: moment.utc(item?.end_date).format('YYYY/MM/DD HH:mm'),
                asset_address: handleGetFullAddress({ ...item, postalCode: item.postal_code }),
                room_number: handleRoomName(item.rooms),
                action: (
                    <Fragment>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <Button
                                disabled={item.is_disable}
                                onClick={() => {
                                    if (item.request_status !== APPOINTMENT_STATUS.APPROVED) {
                                        getDataChangeStatus(item, true);
                                    }
                                }}
                                className={cx(
                                    'appointmentBtn',
                                    item.request_status === APPOINTMENT_STATUS.APPROVED
                                        ? 'appointmentBtn__approve'
                                        : 'appointmentBtn__submitted',
                                    item.request_status === APPOINTMENT_STATUS.SUBMITTED ? 'appoint_table_bold' : '',
                                )}
                                text={t<string>('appointment.approve')}></Button>
                            <Button
                                disabled={item.is_disable}
                                onClick={() => {
                                    if (item.request_status !== APPOINTMENT_STATUS.REJECTED) {
                                        getDataChangeStatus(item, false);
                                    }
                                }}
                                className={cx(
                                    'appointmentBtn',
                                    item.request_status === APPOINTMENT_STATUS.REJECTED
                                        ? 'appointmentBtn__reject'
                                        : 'appointmentBtn__submitted',
                                    item.request_status === APPOINTMENT_STATUS.SUBMITTED ? 'appoint_table_bold' : '',
                                )}
                                text={t<string>('appointment.reject')}></Button>
                            <Button
                                onClick={() => getDataDetail(item.id)}
                                className={cx('appointmentBtn', 'appointmentBtn__detail')}
                                text={t<string>('common.detail')}>
                                <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                            </Button>
                        </div>
                    </Fragment>
                ),
                _style: item.request_status === APPOINTMENT_STATUS.SUBMITTED ? { fontWeight: 700 } : {},
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('appointment.application_datetime'),
            },
            {
                title: t('appointment.user'),
            },
            {
                title: t('appointment.phone_number'),
            },
            {
                title: t('appointment.brokerage_company_name_update'),
            },
            {
                title: t('appointment.store_name'),
            },
            {
                title: t('appointment.asset_name'),
            },

            {
                title: t('appointment.asset_address'),
            },
            {
                title: t('appointment.room_number'),
            },
            {
                title: t('appointment.start_date'),
            },
            {
                title: t('appointment.end_date'),
            },
            {
                title: t('appointment.action'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'created_time',
                },
                {
                    field: 'broker_name',
                },
                {
                    field: 'phone_number',
                },
                {
                    field: 'broker_company_name',
                },
                {
                    field: 'broker_store_name',
                },
                {
                    field: 'asset_name',
                },
                {
                    field: 'asset_address',
                },
                {
                    field: 'room_number',
                },
                {
                    field: 'start_date',
                },
                {
                    field: 'end_date',
                },
                {
                    field: 'action',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableData(),
        },
    };

    return (
        <BodyContainer title="title.reservation">
            <Formik initialValues={initialValueSearch} enableReinitialize={true} onSubmit={(values) => handleSearch(values)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                handleReset();
                                clear();
                            }}
                            isDisableBtnDelete={
                                values.application_from_time == '' &&
                                values.application_to_time == '' &&
                                values.viewing_datetime_from == '' &&
                                values.viewing_datetime_to == '' &&
                                values.broker_name == '' &&
                                values.broker_company_name == '' &&
                                values.store_name == '' &&
                                values.status == '' &&
                                values.asset_name == '' &&
                                values.room_number == ''
                            }>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('appointment.application_date')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.application_to_time !== '' ? new Date(values.application_to_time) : null}
                                                value={
                                                    values.application_from_time !== '' ? new Date(values.application_from_time) : null
                                                }
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) =>
                                                    setFieldValue('application_from_time', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={
                                                    values.application_from_time !== '' ? new Date(values.application_from_time) : null
                                                }
                                                value={values.application_to_time !== '' ? new Date(values.application_to_time) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('application_to_time', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('appointment.user')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.broker_name || ''}
                                            name="user_name"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="appointment.user"
                                            onChangeText={(value) => setFieldValue('broker_name', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('appointment.brokerage_company_name_update')}</label>
                                        </div>
                                        <FormInput
                                            value={values.broker_company_name || ''}
                                            name="broker_name"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="appointment.brokerage_company_name_update"
                                            onChangeText={(value) => setFieldValue('broker_company_name', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('appointment.viewing_datetime')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.viewing_datetime_to !== '' ? new Date(values.viewing_datetime_to) : null}
                                                value={
                                                    values.viewing_datetime_from !== '' ? new Date(values.viewing_datetime_from) : null
                                                }
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) =>
                                                    setFieldValue('viewing_datetime_from', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={
                                                    values.viewing_datetime_from !== '' ? new Date(values.viewing_datetime_from) : null
                                                }
                                                value={values.viewing_datetime_to !== '' ? new Date(values.viewing_datetime_to) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('viewing_datetime_to', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('appointment.store_name')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.store_name || ''}
                                            name="user_name"
                                            maxLength={255}
                                            className="col-4"
                                            styleBlock
                                            placeholder="appointment.store_name"
                                            onChangeText={(value) => setFieldValue('store_name', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('appointment.asset_name')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.asset_name || ''}
                                            name="user_name"
                                            maxLength={255}
                                            className="col-4"
                                            styleBlock
                                            placeholder="appointment.asset_name"
                                            onChangeText={(value) => setFieldValue('asset_name', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('appointment.room_number')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.room_number || ''}
                                            name="user_name"
                                            maxLength={255}
                                            className="col-4"
                                            styleBlock
                                            placeholder="appointment.room_number"
                                            onChangeText={(value) => setFieldValue('room_number', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('appointment.action')}
                                            </label>
                                        </div>
                                        <div className="col-4">
                                            <FormDropdown
                                                hiddenLabel={true}
                                                options={[
                                                    { value: 0, label: 'すべて' },
                                                    { value: 1, label: '承認' },
                                                    { value: 2, label: '拒否' },
                                                ]}
                                                value={values.status}
                                                onChangeOption={(option) => setFieldValue('status', option.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={table.heads}
                    body={table.body}
                    pageData={pageState}
                    onChangePage={(page) => handleChangePage(page)}
                />
            </div>
            <AppointmentDetailModal
                hidePositiveButton={true}
                typeBroker="brokerRegistered"
                dataInit={dataDetail}
                isShow={appoint.isShowDetail}
                handleClose={() => {
                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                    delete searchParamsObject.appointment_id;
                    setSearchParams(searchParamsObject);
                    setAppoint({
                        ...appoint,
                        isShowDetail: false,
                    });
                }}
            />
            {appoint.isShowApprove !== null && (
                <ModalInterface
                    dialogClassname={cxModal('modal-delete-width', 'modal-fixed')}
                    modalHeader={t<string>('common.confirm')}
                    classNameBody={cxCommon('bases__height-max-percent--90', 'overflow-hidden')}
                    isShow={appoint.isShowApprove !== null}
                    handleOK={() => {
                        updateStatusAppointment(appoint.isShowApprove ? 1 : 2, reasonReject);
                        setReasonReject(t('appointment.warningReject'));
                    }}
                    handleClose={() => {
                        setReasonReject(t('appointment.warningReject'));
                        handleClosePopup();
                    }}
                    positiveButtonText="common.ok">
                    <div className="row">
                        <div className={'col text-center'}>
                            <span className={cxModal('text-content') + ' ' + cxCommon('label-color')}>
                                {t<string>(appoint.isShowApprove ? 'appointment.confirm_content' : 'appointment.decline_content')}
                            </span>
                        </div>
                    </div>
                    {!appoint.isShowApprove && (
                        <WarningModal
                            onChangeText={(value: any) => {
                                setReasonReject(value);
                            }}
                            reasonReject={reasonReject}
                            showModal={!appoint.isShowApprove}
                        />
                    )}
                    {appoint.isShowWarning && (
                        <>
                            <div className={cxModal('opacity-modal')} />
                            <ModalConfirm
                                isShow={appoint.isShowWarning}
                                handleOK={handleClosePopup}
                                handleClose={handleClosePopup}
                                hideNegativeButton
                                hideNegativeButtonClose
                                header={t<string>('common.error_title')}
                                content={appoint.textError}
                            />
                        </>
                    )}
                </ModalInterface>
            )}
        </BodyContainer>
    );
};

export default AppointmentManagement;
