import { Button, FormDropdown, FormInput } from '@components/commons';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import DataTable from '@components/commons/Table/Table';
import { images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { Formik, FormikHelpers, useFormik } from 'formik';
import moment from 'moment/moment';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';

import { SUCCESS_STATUS_MESSAGE } from '@utils/constants/http';

import { fetchGetListOneTimePassword } from '@utils/helpers/oneTimePass';

import commonStyles from '@assets/styles/base/Common.module.scss';
import ModalConfirm from '@components/modals/ModalConfirm';
import { default as Configs, default as configs } from '@utils/configs';
import { checkAssetIsPublic } from '@utils/helpers/api';
import { convertToHalfSize, removeSpecialCharacter } from '@utils/helpers/utilities';
import styles from './OneTimePassword.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);
export enum PreviewType {
    SEE_BY_YOURSELF = 0, // セルフ内覧
    WITH_STAFF = 1, // スタッフ同行
}
const OneTimePasswordManagement: IOneTimePassPage<IOneTimePassPageProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { listAll } = useSelector((states: ReduxStates) => states);

    const tableRef = createRef<ITableComponentHandle>();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [data, setData] = useState<IDataOneTimePassSearchRes[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        created_from: searchParams.get('created_from') ?? '',
        created_to: searchParams.get('created_to') ?? '',
        preview_time_from: searchParams.get('preview_time_from') ?? '',
        preview_time_to: searchParams.get('preview_time_to') ?? '',
        asset_name: searchParams?.get('asset_name') ?? '',
        user_info: searchParams.get('user_info') ?? '',
        phone_number: searchParams.get('phone_number') ?? '',
        preview_type: searchParams.get('preview_type') ?? '',
        limit: searchParams.get('limit') ?? '30',
        offset: searchParams.get('offset') ?? '0',
    });
    const [isShowWarningPopup, setIsShowWarningPopup] = useState(false);

    const handleSearch = (values: any, helpers: FormikHelpers<any>) => {
        const newPhoneNumber = convertToHalfSize(values?.phone_number || '');
        const newValues = {
            ...values,
            phone_number: removeSpecialCharacter(newPhoneNumber).trim(),
        };
        setSearchParams((params) => {
            return {
                ...params,
                ...newValues,
            };
        });
        helpers.setFieldValue('phone_number', newPhoneNumber);
        fetchOneTimePasswordtList(newValues as any).then();
    }

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const searchObject = {
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        };
        setSearchParams(searchObject);
        fetchOneTimePasswordtList(searchObject as any).then();
    };

    const clear = () => {
        const newValue = {
            created_from: '',
            created_to: '',
            preview_time_from: '',
            preview_time_to: '',
            asset_name: '',
            user_info: '',
            phone_number: '',
            preview_type: '',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    };
    useEffect(() => {
        const response = listAll.oneTime;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.oneTime]);

    const fetchOneTimePasswordtList = async (searchParams: IOneTimePassSearchParams) => {
        const response = await fetchGetListOneTimePassword(searchParams);
        dispatch(setListDataAll({...listAll, oneTime: response}))
        // if (response.status === SUCCESS_STATUS_MESSAGE) {
        //     setData(response.data);
        //     setPageState({
        //         firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
        //         currentPage: response?.metadata?.offset + 1,
        //         totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
        //         pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
        //         totalRecords: response?.metadata?.total,
        //     });
        // }
    };

    useEffect(() => {
        fetchOneTimePasswordtList(searchParams as any);
    }, [searchParams]);

    const handlePreviewType = (type: number) => {
        if (type === PreviewType.SEE_BY_YOURSELF) {
            return 'セルフ内覧';
        } else if (type === PreviewType.WITH_STAFF) {
            return 'スタッフ同行';
        } else {
            return '-';
        }
    };

    const handleClickAddOneTimePass = () => {
        checkAssetIsPublic().then((res) => {
            if (res.data.data) {
                navigate(configs.routes.oneTimePasswordResgister);
            } else {
                setIsShowWarningPopup(true);
            }
        }).catch((err) => console.log(err))
    }

    const handleTableData = () => {
        return data.map((item) => {
            return {
                ...item,
                updatedAt: moment.utc(item?.updatedAt).format('YYYY/MM/DD HH:mm'),
                startTime: moment.utc(item?.startTime).format('YYYY/MM/DD HH:mm'),
                endTime: moment.utc(item?.endTime).format('YYYY/MM/DD HH:mm'),
                previewType: handlePreviewType(item.previewType),
                // roomNumber: handleRoomName(item.roomNumber),
                action: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <NavLink to={`${Configs.routes.oneTimePassword}/${item.id}`} style={{ textDecoration: 'none' }}>
                            <Button
                                className={cx('button-detail')}
                                text={t<string>('common.detail')}>
                                <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                            </Button>
                        </NavLink>
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('oneTimePassword.issuing_date_and_time'),
            },
            {
                title: t('oneTimePassword.user_info'),
            },
            {
                title: t('oneTimePassword.user_phone_number'),
            },
            {
                title: t('oneTimePassword.store_name'),
            },
            {
                title: t('oneTimePassword.receptionist'),
            },
            {
                title: t('oneTimePassword.person_in_charge'),
            },

            {
                title: t('oneTimePassword.preview_support'),
            },
            {
                title: t('oneTimePassword.asset_name'),
            },
            {
                title: t('oneTimePassword.propertyaddress'),
            },
            {
                title: t('oneTimePassword.room_number'),
            },
            {
                title: t('oneTimePassword.privateViewingStartDatetime'),
            },
            {
                title: t('oneTimePassword.privateViewingEndDatetime'),
            },
            {
                title: t('common.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'updatedAt',
                },
                {
                    field: 'userInfo',
                },
                {
                    field: 'phoneNumber',
                },
                {
                    field: 'storeName',
                },
                {
                    field: 'receptionist',
                },
                {
                    field: 'personInCharge',
                },
                {
                    field: 'previewType',
                },
                {
                    field: 'assetName',
                },
                {
                    field: 'address',
                },
                {
                    field: 'roomNumber',
                },
                {
                    field: 'startTime',
                },
                {
                    field: 'endTime',
                },
                {
                    field: 'action',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableData(),
        },
    };

    return (
        <BodyContainer title="oneTimePassword.title">
            <Button onClick={handleClickAddOneTimePass} text={t<string>('oneTimePassword.add')} add className="bases__width--170 bases__margin--bottom20" />
            <Formik initialValues={initialValueSearch} enableReinitialize={true} onSubmit={(values, helpers) => handleSearch(values, helpers)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                clear();
                                handleReset();
                            }}
                            isDisableBtnDelete={
                                values.created_from == '' &&
                                values.created_to == '' &&
                                values.preview_time_from == '' &&
                                values.preview_time_to == '' &&
                                values.asset_name == '' &&
                                values.user_info == '' &&
                                values.phone_number == '' &&
                                values.preview_type == ''
                            }>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('oneTimePassword.dateOfIssue')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.created_to !== '' ? new Date(values.created_to) : null}
                                                value={values.created_from !== '' ? new Date(values.created_from) : null}
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) => setFieldValue('created_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.created_from !== '' ? new Date(values.created_from) : null}
                                                value={values.created_to !== '' ? new Date(values.created_to) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('created_to', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('oneTimePassword.user_info')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.user_info || ''}
                                            name="user_info"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="oneTimePassword.user_info"
                                            onChangeText={(value) => setFieldValue('user_info', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('oneTimePassword.user_phone_number')}</label>
                                        </div>
                                        <FormInput
                                            value={values.phone_number || ''}
                                            name="phone_number"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="oneTimePassword.user_phone_number"
                                            onChangeText={(value) => setFieldValue('phone_number', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('oneTimePassword.viewingDatetime')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.preview_time_to !== '' ? new Date(values.preview_time_to) : null}
                                                value={values.preview_time_from !== '' ? new Date(values.preview_time_from) : null}
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) =>
                                                    setFieldValue('preview_time_from', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.preview_time_from !== '' ? new Date(values.preview_time_from) : null}
                                                value={values.preview_time_to !== '' ? new Date(values.preview_time_to) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('preview_time_to', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('oneTimePassword.asset_name')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.asset_name || ''}
                                            name="asset_name"
                                            maxLength={255}
                                            className="col-4"
                                            styleBlock
                                            placeholder="oneTimePassword.asset_name"
                                            onChangeText={(value) => setFieldValue('asset_name', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('oneTimePassword.preview_support')}
                                            </label>
                                        </div>
                                        <div className="col-4">
                                            <FormDropdown
                                                hiddenLabel={true}
                                                options={[
                                                    { value: '', label: 'すべて' },
                                                    { value: '0', label: 'セルフ内覧' },
                                                    { value: '1', label: 'スタッフ同行' },
                                                ]}
                                                value={values.preview_type}
                                                onChangeOption={(option) => setFieldValue('preview_type', option.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={table.heads}
                    body={table.body}
                    pageData={pageState}
                    onChangePage={(page) => handleChangePage(page)}
                />
            </div>

            {isShowWarningPopup && <ModalConfirm
                hidePositiveButton
                dialogClassname={cx('modal')}
                isShow={isShowWarningPopup}
                negativeButtonText="common.close"
                handleClose={() => setIsShowWarningPopup(false)}
                header='oneTimePassword.warning_title'
                content={t('oneTimePassword.warning_content')}
            >
            </ModalConfirm>}
        </BodyContainer>
    );
};

export default OneTimePasswordManagement;
