import { setCheckAccountHasBeenDeleted, setLoader, setSuccessCallAPI, setShowDeleted, setShowNotFound, showAlert, setAccountNotPermission } from '~/redux/actions/CommonAction';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '~/redux/store';
import { setCheckError, setCheckAccessTokenExpired } from '~/redux/actions/CommonAction';
import Configs from '@utils/configs';
import { getRefreshToken } from '../helpers/api';
import { http } from '@utils/constants';
import { fetchUserInfo } from '@utils/helpers/userApi';
import { io } from 'socket.io-client';
import configs from '@utils/configs';

let isRefresh = false;

const axiosConfig = axios.create({
    baseURL: Configs.appConfig.API_URL,
    headers: {
        'Content-type': 'application/json',
    },
});


axiosConfig.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const accessToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken') || '{}') : null;
        store.dispatch(setLoader(true));
        config.data = {
            ...config.data,
        };
        if (config.headers && accessToken) {
            config.headers['Authorization'] = `${accessToken.token_type} ${accessToken.access_token}`;
        }

        return config;
    },
    (error: AxiosError) => {
        console.info('Request Error: ', error);
        store.dispatch(setLoader(false));
        return Promise.reject(error);
    },
);

axiosConfig.interceptors.response.use(
    (response: AxiosResponse) => {
        store.dispatch(setLoader(false));
        //
        try {
            const accessToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken') || '{}') : null;

            if(accessToken){
                const socket = io(configs.appConfig.SOCKET_URL, {
                    path: configs.appConfig.SOCKET_PATH,
                    transports: ['websocket'],
                    auth: {
                        Authorization: `Bearer ${accessToken.access_token}`,
                    },
                });
                socket.emit('dashboardSummary', {});
            }
        }catch (error){
            console.log(error)
        }
        //
        if (response && response.status === http.SUCCESS_CODE) {
            store.dispatch(setSuccessCallAPI(true));
            store.dispatch(setAccountNotPermission(false));
        }

        return response;
    },
    async (error: AxiosError<IBaseAPIRes>) => {
        store.dispatch(setLoader(false));
        const { response, config } = error;
        if (!response) {
            if (error.message === 'Network Error' || error.code === 'ERR_NETWORK') {
                store.dispatch(setCheckError(true));
            }
        } else {
            if (response.status === http.AUTHENTICATION_FAIL_CODE && response.data.message === 'ACCESS_TOKEN_EXPIRED' && !isRefresh) {
                isRefresh = true;
                const originalRequest = config as AxiosRequestConfig;
                const authorization = localStorage.getItem('accessToken');
                const token = authorization ? JSON.parse(authorization) : null;
                if (token && token.refresh_token) {
                    const data = {
                        refresh_token: token.refresh_token,
                    };

                    try {
                        const response = await getRefreshToken(data);
                        localStorage.setItem('accessToken', JSON.stringify(response.data.data));
                        isRefresh = false;
                        fetchUserInfo();
                        return Promise.resolve(axiosConfig(originalRequest));
                    } catch (err) {
                        isRefresh = false;
                        store.dispatch(setCheckAccessTokenExpired(true));
                        // TODO: Logout
                    }
                }
            }
            if (response.status === http.AUTHENTICATION_FAIL_CODE && response.data.message === 'USER_ACCOUNT_ALREADY_DELETE') {
                store.dispatch(setCheckAccountHasBeenDeleted(true));
            }
            if (response.status === http.NOT_FOUND_CODE) {
                store.dispatch(setShowNotFound(true));
            }
            if (response.status === http.NOT_ALLOW_CODE) {
                store.dispatch(setShowNotFound(true));
                store.dispatch(setAccountNotPermission(true));
            }
            if (response.status === http.ERROR_EXCEPTION_CODE) {
                store.dispatch(showAlert({
                    header: 'common.error_title',
                    content: 'common.network_errror',
                    hiddenCancel: true,
                }));
            }
            if (response.status === http.ERROR_GONE_CODE) {
                store.dispatch(showAlert({
                    header: 'common.confirm',
                    content: 'common.existed_popup_content',
                    hiddenCancel: true,
                    handleOK: () => {
                        store.dispatch(setShowDeleted(true));
                    },
                }));
            }
        }

        // if (response && response.status !== http.SUCCESS_CODE) {
        //     return Promise.reject(response);
        // }
        return Promise.reject(response);
    },
);

export default axiosConfig;
