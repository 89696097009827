import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormInput } from '@components/commons';
import { CompanyForm } from '@components/forms';
import ModalConfirm from '@components/modals/ModalConfirm';
import { ReduxStates } from '@redux/reducers';
import Configs from '@utils/configs';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { FormEvent, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { changePasswordAsync } from '~/redux/actions/ChangePasswordAction';
import { RULE } from '~/utils/constants/regex';
import { useAuth } from '~/utils/hook/useAuth';
import styles from './Company.module.scss';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const EditPassword: IChangePasswordForm<IChangePasswordComponentProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [duplicatePassword, setDuplicatePassword] = useState('');
    const dispatch = useDispatch();
    const { changePassword } = useSelector((states: ReduxStates) => states);

    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
    });
    const { showFirstModal } = modalState;
    const passwordInputRef = createRef<IValidatorPasswordSubmit>()

    const [isShowPopupLeave, setIsShowPopupLeave] = useState(false);

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave)
    useEffect(() => {
        if (location.pathname === Configs.routes.changePassword) {
            setIsShowPopupLeave(true)
        } else {
            setIsShowPopupLeave(false)
        }
    }, [location.pathname]);

    const showPopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: true,
        }));
        setIsShowPopupLeave(false)
    };

    const hidePopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: false,
        }));
        setIsShowPopupLeave(true)
    };

    const confirmModal = (
        <ModalConfirm
            isShow={showFirstModal}
            handleOK={() => handleSubmit()}
            handleClose={() => hidePopup('showFirstModal')}
            header={t<string>('common.confirm')}
            content={t<string>('change_password.modal.confirm-content')}></ModalConfirm>
    );

    const changePasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required(t<string>('message.required_current_password')),
        newPassword: Yup.string()
            .min(8, t<string>('message.regex_password'))
            .required(t<string>('message.required_new_password'))
            .matches(RULE.PASSWORD, t<string>('resetPassword.errorPasswordFormat')),
        passwordConfirm: Yup.string()
            .required(t<string>('message.required_confirm_password'))
            .oneOf([Yup.ref('newPassword'), null], t<string>('message.password_duplicate')),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            passwordConfirm: '',
        },
        onSubmit: (values) => {
            passwordInputRef?.current?.checkJapanese('');
            if (values.oldPassword === values.newPassword) {
                setDuplicatePassword(t<string>('message.password_duplicate'));
            } else {
                const reJP = new RegExp(RULE.IS_JP);
                const reEmoji = new RegExp(RULE.IS_EMOJI);
                if (!reJP.test(formik.getFieldProps('newPassword').value) && !reEmoji.test(formik.getFieldProps('newPassword').value)) {
                    showPopup('showFirstModal');
                } else {
                    passwordInputRef?.current?.checkJapanese(t('resetPassword.errorPasswordFormat'))
                }

            }
        },
        validationSchema: changePasswordSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const handleChange = (type: string, value: string) => {
        formik.setFieldValue(`${type}`, value);
    };
    const handleSubmit = async () => {
        setDuplicatePassword('');
        const data = {
            password: formik.getFieldProps('oldPassword').value,
            newPassword: formik.getFieldProps('newPassword').value,
        };
        dispatch(
            await changePasswordAsync(data, (result) => {
                if (result) {
                    if (result.status === 'SUCCESS') {
                        auth.signOut(() => {
                            navigate(Configs.routes.login);
                        });
                    } else {
                        hidePopup('showFirstModal');
                        if (result.status === 'FAILURE') {
                            formik.setFieldError(
                                'oldPassword',
                                t<string>('message.required', { 0: t<string>('change_password.current_password') }),
                            );
                        }
                    }
                }
            }),
        );
    };

    useEffect(() => {
        if (changePassword.data?.status === 'SUCCESS') {
            auth.signOut(() => {
                navigate(Configs.routes.login);
            });
            hidePopup('showFirstModal');
        } else if (changePassword.data?.status === 'FAILURE') {
            hidePopup('showFirstModal');
        }
    }, [changePassword]);

    const handlePreSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        formik.setErrors({});
        formik.validateForm();
        formik.handleSubmit();
    }

    return (
        <CompanyForm>
            <div className={'px-3 d-flex justify-content-center'}>
                <form onSubmit={(e) => handlePreSubmit(e)} style={{ width: '600px' }}>
                    <div className="row my-2">
                        <div className="col">
                            <p className={cx('text-label', 'mb-2')}>
                                {t('change_password.current_password')}
                                <span className={cxCommon('text-danger')}>*</span>
                                &nbsp;
                                {t('change_password.validate')}
                            </p>
                            <div className="position-relative">
                                <FormInput
                                    placeholder={t<string>('change_password.enter_current_password')}
                                    className="w-100"
                                    type="password"
                                    name="oldPassword"
                                    value={formik.values.oldPassword}
                                    onChangeText={(text) => {
                                        handleChange('oldPassword', text);
                                    }}
                                    maxLength={255}
                                    style={formik.errors.oldPassword && formik.touched.oldPassword && { border: '1px solid #FF2416' }}
                                />
                            </div>
                            {formik.errors.oldPassword && (
                                <div className={cxCommon('error-msg')}>
                                    <div className={cxCommon('text-overflow')}>{formik.errors.oldPassword}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row my-2">
                        <p className={cx('text-label', 'mb-2')}>
                            {t('change_password.new_password')}
                            <span className={cxCommon('text-danger')}>*</span>
                        </p>
                        <div className="col">
                            <div className="position-relative">
                                <FormInput
                                    placeholder={t<string>('change_password.enter_new_password')}
                                    className="w-100"
                                    type="password"
                                    name="newPassword"
                                    value={formik.values.newPassword}
                                    onChangeText={(text) => {
                                        handleChange('newPassword', text);
                                    }}
                                    inputRef={passwordInputRef}
                                    isPassword
                                    maxLength={255}
                                    style={formik.errors.newPassword && formik.touched.newPassword && { border: '1px solid #FF2416' }}
                                />
                            </div>
                            {duplicatePassword && (
                                <div className={cxCommon('error-msg')}>
                                    <div className={cxCommon('text-overflow')}>{duplicatePassword}</div>
                                </div>
                            )}
                            {formik.errors.newPassword && formik.touched.newPassword && (
                                <div className={cxCommon('error-msg')}>
                                    <div className={cxCommon('text-overflow')}>{formik.errors.newPassword}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col">
                            <p className={cx('text-label', 'mb-2')}>
                                {t('change_password.new_password_confirm')}
                                <span className={cxCommon('text-danger')}>*</span>
                            </p>
                            <div className="position-relative">
                                <FormInput
                                    placeholder={t<string>('change_password.enter_new_password_to_confirrm')}
                                    className="w-100"
                                    type="password"
                                    name="passwordConfirm"
                                    value={formik.values.passwordConfirm}
                                    onChangeText={(text) => {
                                        handleChange('passwordConfirm', text);
                                    }}
                                    maxLength={255}
                                    style={
                                        formik.errors.passwordConfirm && formik.touched.passwordConfirm && { border: '1px solid #FF2416' }
                                    }
                                />
                            </div>
                            {formik.errors.passwordConfirm && formik.touched.passwordConfirm && (
                                <div className={cxCommon('error-msg')}>
                                    <div className={cxCommon('text-overflow')}>{formik.errors.passwordConfirm}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center ">
                        <div className="col-3">
                            <Button
                                className={cxCommon('button-non-min-width')}
                                text={t<string>('change_password.change')}
                                search
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
            {confirmModal}
        </CompanyForm>
    );
};

export default EditPassword;
