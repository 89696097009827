import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import { images } from '@utils/constants';
import styles from './Form.module.scss';

const cx = classNames.bind(styles);

interface IInputTimeRangeProps {
    styleBlock?: boolean;
    className?: string;
    label?: string;
    startTime?: string;
    endTime?: string;
    defaultStartTime?: string;
    defaultEndTime?: string;
    disabled?: boolean;
    onChangeDate?: (startTime: string, endTime: string) => void;
}

const FormTimeRange: IBaseComp<IInputTimeRangeProps> = ({
    styleBlock,
    className = '',
    label = '',
    startTime,
    endTime,
    defaultStartTime = '',
    defaultEndTime = '',
    disabled,
    onChangeDate,
}) => {
    const handleDataChange = (text: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeDate) {
            if (text.toString() === '') {
                onChangeDate('', '');
            } else {
                onChangeDate(text.toString(), text.toString());
            }
        }
    };

    const labelClassName = useMemo(() => (styleBlock ? 'form-label' : 'col-md-3 col-form-label text-end pe-0'), []);

    return (
        <div className={className}>
            <div className={styleBlock ? '' : 'row align-items-start'}>
                <label className={labelClassName}>{t(label)}</label>
                <div className="col">
                    <div className="row">
                        <div className="col pe-0">
                            <div className={cx('form-control', 'input-group', 'input-group-gl', disabled ? 'input--disable' : '')}>
                                <input
                                    type="text"
                                    className={cx('w-100', 'gl-input')}
                                    value={startTime}
                                    defaultValue={defaultStartTime}
                                    placeholder="9:00"
                                    disabled={disabled}
                                    onChange={handleDataChange}
                                />
                                <img src={images.IMAGES.ICON_CLOCK} className={cx('input_image')} />
                            </div>
                        </div>
                        <label className="col-md-2 col-form-label text-center">~</label>
                        <div className="col ps-0">
                            <div className={cx('form-control', 'input-group', 'input-group-gl', disabled ? 'input--disable' : '')}>
                                <input
                                    type="text"
                                    className={cx('w-100', 'gl-input')}
                                    value={endTime}
                                    defaultValue={defaultEndTime}
                                    placeholder="18:00"
                                    disabled={disabled}
                                    onChange={handleDataChange}
                                />
                                <img src={images.IMAGES.ICON_CLOCK} className={cx('input_image')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormTimeRange;
