import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Configs from '~/utils/configs';
import { Loader, Toast } from '@components/layouts';
import * as Pages from '@components/pages';
import ModalNotification from './components/modals/ModalNotification';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import 'animate.css';
import { useEffect, useState } from 'react';
import ModalArlet from './components/commons/ModalNotification/ModalNotification';
import ModalTokenExpired from './components/modals/ModalTokenExpired';
import { setCheckAccountHasBeenDeleted, setShowPopupAccessTokenExpired, setActionPopupAccountDeleted } from '~/redux/actions/CommonAction';
import Alert from './components/modals/Alert';
import ConfirmDelete from '@components/modals/ConfirmDelete';
import useRole from '@utils/hook/useRole';
import { USER_ROLE_ENUM } from '@utils/constants';
import ModalAlertAccountDeleted from '@components/modals/ModalAlertAccountDeleted';

//Public routes
const publicRoutes = [
    { path: Configs.routes.notFound, component: Pages.NotFound },
    { path: Configs.routes.login, component: Pages.Login },
    { path: Configs.routes.resetPassword, component: Pages.ResetPassword },
];

//Private routes
const privateRoutes = [
    /************** Dashboard **************/
    { path: Configs.routes.dashboard, component: Pages.Dashboard },
    { path: Configs.routes.notificationDashboard, component: Pages.NotificationDashboard },
    /************** Dashboard **************/

    /************** Company Infomation ***************/
    { path: Configs.routes.companyInfo, component: Pages.CompanyInfo },
    { path: Configs.routes.companyInfoEdit, component: Pages.CompanyInfoEdit },
    { path: Configs.routes.changePassword, component: Pages.EditPassword },
    /************** Company Infomation ***************/

    /************** Entrance Management ***************/
    // Appointment Management
    { path: Configs.routes.appointment, component: Pages.AppointmentManagement },
    // One-time Password
    { path: Configs.routes.oneTimePassword, component: Pages.OneTimePassword },
    { path: Configs.routes.oneTimePasswordResgister, component: Pages.OneTimePasswordRegister },
    { path: Configs.routes.oneTimePasswordDetails, component: Pages.OneTimePasswordDetails },
    { path: Configs.routes.oneTimePassWordEdit, component: Pages.OneTimePasswordRegister },
    // Keybox Unlock History
    { path: Configs.routes.brokerHistory, component: Pages.BrokerHistory },
    { path: Configs.routes.employeeHistory, component: Pages.EmployeeHistory },
    { path: Configs.routes.workerHistory, component: Pages.WorkerHistory },
    { path: Configs.routes.oneTimeHistory, component: Pages.OneTimePasswordHistory },
    // Keybox Setting History
    { path: Configs.routes.keyboxHistory, component: Pages.SettingHistory },
    /************** Entrance Management ***************/

    /************** Asset Management ***************/
    { path: Configs.routes.asset, component: Pages.AssetManagement },
    { path: Configs.routes.assetRegister, component: Pages.AssetRegister },
    { path: Configs.routes.assetDetails, component: Pages.AssetDetails },
    /************** Asset Management ***************/

    /************** Keybox Management ***************/
    { path: Configs.routes.keybox, component: Pages.KeyboxManagement },
    /************** Keybox Management ***************/

    /************** User Infomation ***************/
    // Broker System
    { path: Configs.routes.brokerSystem, component: Pages.BrokerSystem },
    // Broker Registered
    { path: Configs.routes.broker, component: Pages.BrokerUser },
    { path: Configs.routes.brokerRegister, component: Pages.BrokerUserRegister },
    { path: Configs.routes.brokerDetails, component: Pages.BrokerUserDetails },
    /************** User Infomation ***************/

    /************** Account Management ***************/
    // Company Admin
    { path: Configs.routes.accountDetail, component: Pages.AccountManagement },
    // Employee・Woker Account
    { path: Configs.routes.employeeWorker, component: Pages.EmployeeWorkerAccount },
    { path: Configs.routes.employeeWorkerRegister, component: Pages.EmployeeWorkerRegister },
    { path: Configs.routes.employeeWorkerEdit, component: Pages.EmployeeWorkerRegister },
    { path: Configs.routes.employeeWorkerDetails, component: Pages.EmployeeWorkerDetails },
    // Admin Account
    /************** Account Management ***************/
    { path: Configs.routes.company, component: Pages.CompanyAccount },
    { path: Configs.routes.companyRegister, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyEdit, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyDetails, component: Pages.CompanyAccountDetails },

    /************** Manual ***************/
    { path: Configs.routes.manual, component: Pages.Manual },
    /************** Manual ***************/

    /************** Terms Of Service ***************/
    // { path: Configs.routes.terms, component: Pages.TermsOfService },
    /************** Terms Of Service ***************/

    /************** Payment ***************/
    { path: Configs.routes.payment, component: Pages.PaymentManagement },
    /************** Payment ***************/

    /************** Notification Management ***************/
    { path: Configs.routes.notification, component: Pages.NotificationList },
    { path: Configs.routes.notificationRegister, component: Pages.NotificationRegister },
    /************** Notification Management ***************/

    /**************  Management ***************/
    { path: Configs.routes.questionnaireManagement, component: Pages.QuestionnaireManagement },
    { path: Configs.routes.surveyQuestion, component: Pages.SurveyQuestion },
    { path: Configs.routes.newQuestion, component: Pages.NewQuestion },
    { path: Configs.routes.summaryQuestion, component: Pages.SummaryQuestion },
    /************** Management ***************/

    { path: Configs.routes.accountDetail, component: Pages.AccountManagement },

    { path: Configs.routes.company, component: Pages.CompanyAccount },
    { path: Configs.routes.companyRegister, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyEdit, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyDetails, component: Pages.CompanyAccountDetails },
    /************** Account Management ***************/
];

const superAdminRoutes = [
    /************** Dashboard **************/
    { path: Configs.routes.dashboard, component: Pages.Dashboard },
    /************** Dashboard **************/

    /************** Company Infomation ***************/
    { path: Configs.routes.changePassword, component: Pages.EditPassword },
    /************** Company Infomation ***************/

    // Broker System
    { path: Configs.routes.brokerSystem, component: Pages.BrokerSystem },

    /************** Keybox Management ***************/
    { path: Configs.routes.keybox, component: Pages.KeyboxManagement },
    /************** Keybox Management ***************/

    /************** Account Management ***************/
    // Company Admin
    { path: Configs.routes.accountDetail, component: Pages.AccountManagement },

    { path: Configs.routes.company, component: Pages.CompanyAccount },
    { path: Configs.routes.companyRegister, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyEdit, component: Pages.CompanyAccountRegister },
    { path: Configs.routes.companyDetails, component: Pages.CompanyAccountDetails },
    /************** Account Management ***************/

    /************** Manual ***************/
    { path: Configs.routes.manual, component: Pages.Manual },
    /************** Manual ***************/

    /************** Terms Of Service ***************/
    // { path: Configs.routes.terms, component: Pages.TermsOfService },
    /************** Terms Of Service ***************/

    /************** Payment ***************/
    { path: Configs.routes.payment, component: Pages.PaymentManagement },
    /************** Payment ***************/

    /**************  Management ***************/
    // { path: Configs.routes.questionnaireManagement, component: Pages.QuestionnaireManagement },
    // { path: Configs.routes.surveyQuestion, component: Pages.SurveyQuestion },
    // { path: Configs.routes.newQuestion, component: Pages.NewQuestion },
    // { path: Configs.routes.summaryQuestion, component: Pages.SummaryQuestion },
    // /************** Management ***************/
    /************** Notification Management ***************/
    { path: Configs.routes.notification, component: Pages.NotificationList },
    { path: Configs.routes.notificationRegister, component: Pages.NotificationRegister },
    /************** Notification Management ***************/
];

function App() {
    const [isShowError, setIsShowError] = useState(false);
    const { checkNotiToken, checkAccountHasBeenDeleted, userInfo, checkError } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);
    const dispatch = useDispatch();
    const handleClose = () => {
        setIsShowError(false);
    };

    const handleCloseModal = () => {
        dispatch(setShowPopupAccessTokenExpired(false));
    };

    const handleCloseModalAccount = () => {
        dispatch(setCheckAccountHasBeenDeleted(false));
        dispatch(setActionPopupAccountDeleted(true));
    };
    useEffect(() => {
        setIsShowError(!!checkError);
    }, [checkError]);

    return (
        <>
            <ModalArlet />
            <ModalNotification isShow={isShowError} handleOK={handleClose} />
            <ModalTokenExpired isShow={checkNotiToken} handleOK={() => handleCloseModal()} />
            <ModalAlertAccountDeleted isShow={checkAccountHasBeenDeleted} handleOK={() => handleCloseModalAccount()} />
            <Router>
                <Routes>
                    {publicRoutes.map((route, index) => {
                        const Page = route.component;
                        return <Route key={index} path={route.path} element={<Page />} />;
                    })}
                    <Route path={Configs.routes.home} element={<Pages.Home />}>
                        <Route index element={<Navigate to={Configs.routes.dashboard} />} />
                        {(isSuperAdminPermission ? superAdminRoutes : privateRoutes).map((route, index) => {
                            const Page = route.component;
                            return <Route key={index} path={route.path} element={<Page />} />;
                        })}
                    </Route>
                    <Route path="*" element={<Navigate to={Configs.routes.notFound} />} />
                </Routes>
            </Router>
            <Alert />
            <ConfirmDelete />
            <Loader />
            <Toast />
        </>
    );
}

export default App;
