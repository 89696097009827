import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/commons';

import classNames from 'classnames/bind';
import styles from './BrokerUserRegister.module.scss';
import { Common, Layout } from '@assets/styles';
import { BodyContainer } from '~/components/layouts';

const cx = classNames.bind(styles);
const cxLayout = classNames.bind(Layout);
const common = classNames.bind(Common);

const BrokerUserRegister: IStaffWorkerAddPage<IStaffWorkerAddPageProps> = (props) => {
    const { t } = useTranslation();

    const [state, setState] = useState<IStaffWorkerAddPageState>({
        name: '',
        position: '',
        role: '',
        assetLimit: '',
        assetsCharge: '',
        numberUses: '',
    });
    const { name, position, role, assetLimit, assetsCharge, numberUses } = state;

    const handleChangeData = (field: string, value: string | number | boolean) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    return (
        <BodyContainer title={t('title.add')}>
            <div className={cxLayout('container')}>
                <div className={cx('content-wrapper')}>
                    <section className={cx('content')}>
                        <div className="w-100 card border-0">
                            <div className="card-body py-0">
                                <p className="mb-2">{t('staffworker.add.name')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="name"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('name', e.target.value)}
                                        value={name}
                                        placeholder={t<string>('staffworker.add.name')}
                                    />
                                </div>

                                <p className="mb-2">{t('staffworker.add.position')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="position"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('position', e.target.value)}
                                        value={position}
                                        placeholder={t<string>('staffworker.add.inputPosition')}
                                    />
                                </div>

                                <p className="mb-2">{t('staffworker.add.role')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="role"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('role', e.target.value)}
                                        value={role}
                                        placeholder={t<string>('staffworker.add.inputRole')}
                                    />
                                </div>

                                <p className="mb-2">{t('staffworker.add.assetLimit')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="assetLimit"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('assetLimit', e.target.value)}
                                        value={assetLimit}
                                        placeholder={t<string>('staffworker.add.inputAssetLimit')}
                                    />
                                </div>

                                <p className="mb-2">{t('staffworker.add.assetsCharge')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="assetsCharge"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('assetsCharge', e.target.value)}
                                        value={assetsCharge}
                                        placeholder={t<string>('staffworker.add.inputAssetsCharge')}
                                    />
                                </div>

                                <p className="mb-2">{t('staffworker.add.numberUses')}</p>
                                <div className="form-control d-flex justify-content-between p-2 mb-3">
                                    <input
                                        className="w-100"
                                        type="text"
                                        id="numberUses"
                                        autoComplete="off"
                                        onChange={(e) => handleChangeData('numberUses', e.target.value)}
                                        value={numberUses}
                                        placeholder={t<string>('staffworker.add.inputNumberUses')}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="w-100 content border-0 mt-3">
                        <div className="col col d-flex justify-content-center">
                            <Button className={common('w-49pc')} create text={t<string>('common.create')} />
                        </div>
                    </section>
                </div>
            </div>
        </BodyContainer>
    );
};

export default BrokerUserRegister;
