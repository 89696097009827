export const RULE = {
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,})(?!.* )/,
    EMAIL: /^(?![-_.+])([a-zA-Z0-9-_.+](?![-_.+]{2,}))+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
    DATE: /^\d{4}-\d{2}-\d{2}/,
    TIME: /\d{2}:\d{2}:\d{2}/,
    SHORT_TIME: /^\d{2}:\d{2}$/,
    DATE_ONLY: /^\d{4}-\d{2}-\d{2}$/,
    TIME_ONLY: /^\d{2}:\d{2}:\d{2}$/,
    DATE_TIME: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/,
    HIRAGANA: /^[ぁ-ん]*$/,
    ZIP_CODE: /^(\d{7}|\d{3}-\d{4})$/,
    HALF_WIDTH: /^[A-Za-zぁ-んァ-ン一-龥々〆〤ヶー ]*$/,
    URl: /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/,
    FLOAT_NUMBER: /^([0-9０-９]+([.][0-9０-９]*)?|[.][0-9０-９]+)$/,
    NUMBER: /^[0-9０-９]*$/,
    NUMBERPHONE: /^(0\d{2}-?\d{4}-?\d{4})|(\d{10})$/,
    SIGNED_NUMBER: /^[0-9０-９-]*$/,
    SPECIAL_CHARACTER: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    IS_EMOJI: /(?=.*\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi,
    IS_JP: /(?=.*[ぁ-んァ-ン一-龥々〆〤ヶーａ-ｚＡ-Ｚ０-９ｧ-ﾝﾞﾟン一-龥一-龥ぁ-ん。])/,
};