import { getListKeybox, deleteKeybox, getDetailKeyBox, cancelContractKeyBox } from './api';

export const fetchListKeybox = async (params: IKeyboxSearchParams) => {
    try {
        const response = await getListKeybox(params);
        return response.data;
    } catch (error) {
        console.log(error);
        throw(error);
    }
};

export const fetchDeleteKeybox = async (params: IDeleteKeyboxParams) => {
    try {
        const response = await deleteKeybox(params);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchDetailKeybox = async (id: number) => {
    try {
        const response = await getDetailKeyBox(id);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchCancelContractKeybox = async (id: number) => {
    try {
        const response = await cancelContractKeyBox(id);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
