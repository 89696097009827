import { Button, FormInput, Pagination } from '@components/commons';
import DataTable from '@components/commons/Table/Table';
import { http, images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import Configs from '@utils/configs';
import styles from './CompanyAccount.module.scss';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import { Common } from '@assets/styles';
import { Formik, useFormik } from 'formik';
import { convertToHalfSize, handleGetFullAddress, removeSpecialCharacter, trimedValues } from '@utils/helpers/utilities';
import moment from 'moment';
import { getListAccountCompany } from '@redux/actions/companyAcction';
import { useDispatch } from 'react-redux';
import { formatDateUTC } from '@utils/helpers/date';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

const CompanyAccount: ICompanyAccountPage<ICompanyAccountPageProps> = () => {
    const { t } = useTranslation('translation', { useSuspense: true });
    const tableRef = createRef<ITableComponentHandle>();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [searchParams, setSearchParams] = useSearchParams({
        date_from: '',
        date_to: '',
        name: '',
        manager: '',
        phone: '',
        email: '',
        affiliation: '',
        limit: '30',
        offset: '0',
    });
    const [state, setState] = useState<ICompanyAccountState>({
        dataTable: undefined,
        metadata: undefined,
        isSearch: false,
    });
    const { dataTable, metadata, isSearch } = state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDisableBtnDelete, setIsDisableBtnDelete] = useState(true);

    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        date_from: searchParams.get('date_from') ?? '',
        date_to: searchParams.get('date_to') ?? '',
        name: searchParams.get('name') ?? '',
        manager: searchParams.get('manager') ?? '',
        phone: searchParams.get('phone') ?? '',
        email: searchParams.get('email') ?? '',
        affiliation: searchParams.get('affiliation') ?? '',
    });

    useEffect(() => {
        const limit = metadata?.limit;
        const offset = metadata?.offset;
        const count = metadata?.count;
        const total = metadata?.total;
        setPageState({
            firstRecords: offset * limit + 1,
            currentPage: offset + 1,
            totalPage: Math.ceil(total / limit),
            pageRecords: count + offset * limit,
            totalRecords: total,
        });
    }, [metadata, dataTable]);

    useEffect(() => {
        handleFetchListCompany();
    }, [searchParams, isSearch]);

    const clear = () => {
        const newValue = {
            date_from: '',
            date_to: '',
            name: '',
            manager: '',
            phone: '',
            email: '',
            affiliation: '',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    };
    useEffect(() => {
        const checkDefault =
            !searchParams.get('date_from') &&
            !searchParams.get('date_to') &&
            searchParams.get('name') === '' &&
            searchParams.get('phone') === '' &&
            searchParams.get('manager') === '' &&
            searchParams.get('email') === '' &&
            searchParams.get('affiliation') === '';
        setIsDisableBtnDelete(checkDefault);
    }, [searchParams]);

    const handleSearch = (values: any, helper: any) => {
        const newPhoneNumber = convertToHalfSize(removeSpecialCharacter(values?.phone).trim());
        const newSearchParams = { ...values, phone: newPhoneNumber };
        setSearchParams((params) => {
            return {
                ...params,
                ...values,
                ...newSearchParams,
            };
        });
        setState((prevState) => ({
            ...prevState,
            isSearch: true,
        }));

        helper.setSubmitting(false);
        return;
    };
    const handleFetchListCompany = async () => {
        setState((prevState) => ({
            ...prevState,
            isSearch: false,
        }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        dispatch(
            await getListAccountCompany(searchParamsObject, (result: IListCompanyRes | IErrorAPIRes | null) => {
                if (result?.status === 'SUCCESS') {
                    const data = result as IListCompanyRes;
                    setState((prevState) => ({
                        ...prevState,
                        dataTable: data.data ?? [],
                        metadata: data.metadata ?? [],
                    }));
                }
            }),
        );
    };

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        });
    };

    const handleTableDatas = () => {
        return dataTable?.map((item) => {
            const addressParams: IEnterAddressForm = {
                postalCode: item?.postalCode ?? '',
                prefecture: item?.prefecture ?? '',
                city: item?.city ?? '',
                town: item?.town ?? '',
                building: item?.building ?? '',
            };

            const address = handleGetFullAddress(addressParams);
            return {
                ...item,
                registered_date: item?.registerTime ? formatDateUTC(item?.created_at || '', 'YYYY/MM/DD HH:mm') : '',
                company_image: (
                    <div className="components__table-avatar">
                        {item?.images?.length ? (
                            <img className="bases__table--image" src={item.images[0]?.url} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                company_logo: (
                    <div className="components__table-avatar">
                        {item.company_logo ? (
                            <img className="bases__table--image" src={item.company_logo} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                company_name: item.company_name,
                manager: item.manager,
                affiliation: item.affiliation,
                company_address: address,
                phone: item.phone,
                company_email_address: item.email_admin,
                number_of_properties: item.amount_asset,
                status: (
                    <span
                        className={
                            item?.accountStatus === 0
                                ? cxCommon('text-blue')
                                : item?.accountStatus === 1
                                ? cxCommon('text-green')
                                : item?.accountStatus === 2
                                ? cxCommon('text-red')
                                : ''
                        }>
                        {item?.accountStatus === 0
                            ? '認証中'
                            : item?.accountStatus === 1
                            ? '使用中'
                            : item?.accountStatus === 2
                            ? '停止'
                            : ''}
                    </span>
                ),
                raw_status: (
                    <span>
                        {item?.accountStatus === 0
                            ? '認証中'
                            : item?.accountStatus === 1
                            ? '使用中'
                            : item?.accountStatus === 2
                            ? '停止'
                            : ''}
                    </span>
                ),
                memo: item.memo,
                detail: (
                    <Button
                        onClick={() => navigate(`${Configs.routes.company}/${item.company_id}/${item.id}`)}
                        className={cx('button-public')}
                        text={t<string>('common.detail')}>
                        <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                    </Button>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('management_company_account.registered_date'),
            },
            {
                title: t('management_company_account.company_image'),
            },
            {
                title: t('management_company_account.company_logo'),
            },
            {
                title: t('management_company_account.company_name'),
            },
            {
                title: t('management_company_account.manager'),
            },
            {
                title: t('management_company_account.affiliation'),
            },
            {
                title: t('management_company_account.company_address'),
            },
            {
                title: t('management_company_account.phoneNumber'),
            },
            {
                title: t('management_company_account.company_email_address'),
            },
            {
                title: t('management_company_account.number_of_properties'),
            },
            {
                title: t('management_company_account.status'),
            },
            {
                title: t('management_company_account.memo'),
                classNames: 'bases__width-max--500 bases__width-min--400',
            },
            {
                title: t('management_company_account.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'registered_date',
                },
                {
                    field: 'company_image',
                    isNotTooltip: true,
                },
                {
                    field: 'company_logo',
                    isNotTooltip: true,
                },
                {
                    field: 'company_name',
                    className: 'bases__width-max--150 bases__width-min--150',
                },
                {
                    field: 'manager',
                    className: 'bases__width-max--150 bases__width-min--150',
                },
                {
                    field: 'affiliation',
                    className: 'bases__width-max--100 bases__width-min--100',
                },
                {
                    field: 'company_address',
                },
                {
                    field: 'phone',
                },
                {
                    field: 'company_email_address',
                },
                {
                    field: 'number_of_properties',
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'memo',
                    className: 'bases__width-min--200',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };

    return (
        <>
            {dataTable !== undefined && (
                <BodyContainer title={t('management_company_account.title')}>
                    <NavLink style={{ textDecoration: 'none' }} to={Configs.routes.companyRegister}>
                        <Button className={cx('btn-add-one_time_password')}>{t('management_company_account.add')}</Button>
                    </NavLink>
                    <Formik
                        initialValues={initialValueSearch}
                        enableReinitialize={true}
                        onSubmit={(values, helper) => handleSearch(values, helper)}>
                        {(props) => {
                            const { handleSubmit, values, setFieldValue, handleReset } = props;
                            return (
                                <SearchForm
                                    onDelete={() => {
                                        clear();
                                        handleReset();
                                    }}
                                    isDisableBtnDelete={isDisableBtnDelete}
                                    btnDelete={true}
                                    onSubmit={handleSubmit}
                                    className="mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2 d-flex justify-content-end align-items-center px-0">
                                                    <label className={cxCommon('label-color')}>{t('common.date_time_picker')}</label>
                                                </div>
                                                <div className="col-md-4">
                                                    <Datepicker
                                                        maxDate={values.date_to !== '' ? new Date(values.date_to) : undefined}
                                                        value={values.date_from !== '' ? new Date(values.date_from) : undefined}
                                                        placeholder={t<string>('common.start')}
                                                        onChange={(value: Date) => {
                                                            return setFieldValue('date_from', moment(value).format('YYYY/MM/DD'));
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-center align-items-center">
                                                    <span style={{ color: '#333333' }}>~</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <Datepicker
                                                        minDate={values.date_from !== '' ? new Date(values.date_from) : undefined}
                                                        value={values.date_to !== '' ? new Date(values.date_to) : undefined}
                                                        placeholder={t<string>('common.end')}
                                                        onChange={(value: Date) => {
                                                            return setFieldValue('date_to', moment(value).format('YYYY/MM/DD'));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <FormInput
                                            className="col-md-3"
                                            label="management_company_account.company_name"
                                            placeholder="management_company_account.company_name"
                                            value={values.name}
                                            maxLength={255}
                                            onChangeText={(text) => {
                                                return setFieldValue('name', text);
                                            }}
                                        />
                                        <FormInput
                                            className="col-md-3"
                                            label="management_company_account.manager"
                                            placeholder="management_company_account.manager"
                                            value={values.manager}
                                            maxLength={255}
                                            onChangeText={(text) => {
                                                return setFieldValue('manager', text);
                                            }}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <FormInput
                                                    maxLength={255}
                                                    className="col-md-9"
                                                    label="management_company_account.phoneNumber"
                                                    placeholder="management_company_account.phoneNumber"
                                                    value={values.phone}
                                                    onChangeText={(text) => {
                                                        return setFieldValue('phone', text);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-5 ">
                                            <div className="row d-fels">
                                                <div className="col-7 bases__width-percent--60 d-flex flex-column justify-content-end align-items-end px-0">
                                                    <label className={cxCommon('label-color')}>会社</label>
                                                    <label className={cxCommon('label-color')}>メールアドレス</label>
                                                </div>
                                                <FormInput
                                                    maxLength={255}
                                                    className="col-5 bases__width-percent--40"
                                                    placeholder="management_company_account.company_email_address"
                                                    value={values.email}
                                                    onChangeText={(text) => {
                                                        return setFieldValue('email', text);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <FormInput
                                            className="col-md-3"
                                            label="management_company_account.affiliation"
                                            maxLength={255}
                                            placeholder="management_company_account.affiliation"
                                            value={values.affiliation}
                                            onChangeText={(text) => {
                                                return setFieldValue('affiliation', text);
                                            }}
                                        />
                                    </div>
                                </SearchForm>
                            );
                        }}
                    </Formik>

                    {dataTable !== undefined && pageState.totalRecords !== undefined && (
                        <div className="mt-4">
                            <DataTable
                                isStickyColumn
                                ref={tableRef}
                                heads={table.heads}
                                body={table.body}
                                pageData={pageState}
                                total={pageState.totalRecords}
                                onChangePage={(page) => handleChangePage(page)}
                            />
                        </div>
                    )}
                </BodyContainer>
            )}
        </>
    );
};

export default CompanyAccount;
