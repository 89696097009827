import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput } from '@components/commons';
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BodyContainer } from '~/components/layouts';
import styles from './OneTimePasswordRegister.module.scss';
import Configs from '@utils/configs';

import DateTimePicker from '@components/commons/Dropdown/DateTimePicker';
import { setToast, showAlert } from '@redux/actions';
import { ReduxStates } from '@redux/reducers';
import configs from '@utils/configs';
import { http } from '@utils/constants';
import { SUCCESS_STATUS_MESSAGE } from '@utils/constants/http';
import { addOneTimePass, editOneTimePass, getDetailOneTimePass } from '@utils/helpers/api';
import {
    compareDateBefore,
    compareDateSameOrAfter,
    compareDateSameOrBefore,
    compareSameDate,
    formatDate,
    formatDateUTC,
    isToday,
    isValid
} from '@utils/helpers/date';
import { fetchGetListAssetOTP, fetchGetListRoomNumber, fetchGetListSchedule } from '@utils/helpers/oneTimePass';
import { usePrompt } from '@utils/hook/usePormpt';
import { addOneTimePassFormInitialValues, addOneTimePassSchema } from '@utils/schema/oneTimePass.form';
import ja from 'date-fns/locale/ja';
import { Form, Formik, FormikHelpers } from 'formik';
import { has, isEmpty } from 'lodash';
import moment from 'moment/moment';
import { DateRange, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import './dateRange.scss';
registerLocale('ja', ja);
setDefaultLocale('ja');

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const OneTimePasswordRegister: IModalComponent<IModalComponentProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [dataAsset, setDataAsset] = useState<IListAssetName[]>([]);
    const [dataRoom, setDataRoom] = useState<IListRoomNumber[]>([]);
    const [dataInitOneTimePass, setDataInitOneTimePass] = useState<IAddOneTimePassForm>(addOneTimePassFormInitialValues);
    const [startTimeInit, setStartTimeInit] = useState('');
    const [roomSchedule, setRoomSchedule] = useState<any[]>([])
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);
    const [bookingList, setBookingList] = useState<IListScheduleRoom[]>([]);
    const [dateSelected, setDateSelected] = useState<any>({
        startDate: new Date().toDateString(),
        endDate: new Date().toDateString(),
    });
    const [dateRange, setDateRange] = useState<Range[]>([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        showDateDisplay: false,
        color: 'orange'
    }]);

    const isEdit = useMemo(() => id != null, [id]);
    const maxDateDefault = useMemo(() => {
        return moment().add(1, 'year').endOf('year').endOf('month').toDate();
    }, []);

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave);
    useEffect(() => {
        if (location.pathname === Configs.routes.oneTimePassWordEdit.replace(':id', `${id}`) || location.pathname === Configs.routes.oneTimePasswordResgister) {
            setIsShowPopupLeave(true)
        } else {
            setIsShowPopupLeave(false)
        }
    }, [location.pathname]);

    // get List Asset
    const getListAssetOTP = async () => {
        const response = await fetchGetListAssetOTP();
        if (response.status === SUCCESS_STATUS_MESSAGE) {
            const dataAsset = [{ value: '', label: '', address: '' }, ...response.data];
            setDataAsset(dataAsset);
        }
    };
    // get List Room Number
    const getListRoomOTP = async (id: number, type: 'edit' | '', setFieldValue?: (field: string, value: any) => void) => {
        const response = await fetchGetListRoomNumber({ assetId: id });
        if (response.status === SUCCESS_STATUS_MESSAGE) {
            const dataRoom = [{ value: '', label: '', address: '' }, ...response.data];
            setDataRoom(dataRoom);
        }
        if (type === '' && setFieldValue) {
            setFieldValue('room_id', '');
        }
    };

    const getScheduleByRoomId = async (body: IScheduleBody) => {
        const response = await fetchGetListSchedule(body);
        if (response.status === SUCCESS_STATUS_MESSAGE) {
            if (body?.isSchedule) {
                setBookingList(response.data);
            } else {
                const listSchedule: { startDate: Date; endDate: Date; key: string; showDateDisplay: boolean; autoFocus: boolean; disabled: boolean; }[] = [];
                response.data.forEach((res, index) => {
                    const data = {
                        startDate: new Date(res.startTime),
                        endDate: new Date(res.endTime),
                        key: 'selection' + (index + 1),
                        showDateDisplay: false,
                        autoFocus: false,
                        disabled: true,
                        color: '#5865F2'
                    }
                    listSchedule.push(data)
                })
                setRoomSchedule(listSchedule);
                setDateRange((prevDateRange) => {
                    const dateSelection = prevDateRange.filter((item) => item.key === 'selection');
                    return [...dateSelection, ...listSchedule];
                });
                setBookingList([]);
            }
        }

    }

    const fetchDetailOneTimePass = async (id: number) => {
        getDetailOneTimePass(id)
            .then(async (res) => {
                const data = res.data.data;
                await getListAssetOTP();
                await getListRoomOTP(data.asset_id, 'edit');
                const dataInitOneTimePass: IAddOneTimePassForm = {
                    ...data,
                    preview_type: data.preview_type !== null ? String(data.preview_type) : '',
                    start_time: formatDateUTC(data.start_time, 'YYYY-MM-DD HH:mm:ss'),
                    end_time: formatDateUTC(data.end_time, 'YYYY-MM-DD HH:mm:ss'),
                };
                setStartTimeInit(data.start_time);
                setDataInitOneTimePass(dataInitOneTimePass);
            })
            .catch((err) => console.log(err));
    };

    const handleCompareDate = (startTime: string): boolean => {
        const currentTime = moment().tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm:ss');

        const startTimeNew = moment(startTime).utc();

        const animusTime = moment(startTimeNew)
            .clone()
            .subtract(2, 'hours')
            .subtract(userInfo.company_reservation_entry_offset_enabled ? userInfo.company_reservation_entry_offset_start : 0, 'minutes')
            .format('YYYY/MM/DD HH:mm:ss');

        return !compareDateBefore(currentTime.toString(), animusTime.toString(), 'YYYY/MM/DD HH:mm:ss');
    };

    const handleGetScheduleRoom = async (body: IScheduleBody) => {
        await getScheduleByRoomId({roomId: body.roomId});
        await getScheduleByRoomId(body);
    }

    useEffect(() => {
        getListAssetOTP();
    }, []);

    useEffect(() => {
        if (id) {
            fetchDetailOneTimePass(Number(id));
        }
    }, [id]);

    useEffect(() => {
        if (dataInitOneTimePass.start_time && dataInitOneTimePass.room_id) {
            const dateRange: Range[] = [{
                startDate: new Date(dataInitOneTimePass.start_time),
                endDate: new Date(dataInitOneTimePass.start_time),
                key: 'selection',
                showDateDisplay: false,
                color: 'orange'
            }]
            const dateSelected = {
                startDate: dataInitOneTimePass.start_time,
                endDate: dataInitOneTimePass.start_time
            }
            setDateRange(dateRange);
            setDateSelected(dateSelected);

            const body: IScheduleBody = {
                roomId: Number(dataInitOneTimePass.room_id),
                isSchedule: true,
                startDate: formatDate(dateSelected.startDate, 'YYYY-MM-DD 00:00:00'),
                endDate: formatDate(dateSelected.endDate, 'YYYY-MM-DD 23:59:59')
            };
            handleGetScheduleRoom(body);
        }

    }, [dataInitOneTimePass.start_time]);

    const handleSubmit = (values: IAddOneTimePassForm, helpers: FormikHelpers<IAddOneTimePassForm>) => {
        const newValues: IAddOneTimePassForm = {
            ...values,
            start_time: formatDate(values.start_time, 'YYYY/MM/DD HH:mm'),
            end_time: formatDate(values.end_time, 'YYYY/MM/DD HH:mm'),
        };
        setIsShowPopupLeave(false);

        if (isEdit) {
            if (startTimeInit && handleCompareDate(startTimeInit)) {
                dispatch(
                    showAlert({
                        header: 'common.error_title',
                        content: 'appointment.warning_schedule',
                        hiddenCancel: true,
                        handleOK: () => navigate(`${configs.routes.oneTimePassword}/${id}`),
                    }),
                );
            } else {
                editOneTimePass(Number(id), newValues)
                    .then((res) => {
                        if (res.data.status === 'SUCCESS') {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.save_data')}`,
                                }),
                            );
                            setIsShowPopupLeave(true);
                            navigate(configs.routes.oneTimePassword);
                        }
                    })
                    .catch((err) => {
                        handleSetError(err.status, err.data.message, helpers);
                    });
            }
        } else {
            addOneTimePass(newValues)
                .then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.add_success')}`,
                            }),
                        );
                        setIsShowPopupLeave(true);
                        navigate(configs.routes.oneTimePassword);
                    }
                })
                .catch((err) => {
                    handleSetError(err.status, err.data.message, helpers);
                });
        }
    };

    const handleSetError = (status: number, message: string, helpers: FormikHelpers<IAddOneTimePassForm>) => {
        if (status === http.ERROR_OTP_DUPLICATE) {
            helpers.setFieldError('start_time', t<string>('message.duplicate_request_otp'));
            helpers.setFieldError('end_time', t<string>(''));
        }
        if (status === http.ERROR_LOCKED) {
            if (message === 'ASSET_ALREADY_DELETE') {
                helpers.setFieldError('asset_id', t<string>('message.changed_asset_data'));
            }
            if (message === 'ROOM_ALREADY_DELETE') {
                helpers.setFieldError('room_id', t<string>('message.changed_room_data'));
            }
        }
        if (status === http.VALIDATION_FAIL_CODE) {
            helpers.setFieldError('start_time', t<string>('message.invalid_end_date'));
            helpers.setFieldError('end_time', t<string>(''));
        }
    };

    const handleSelect = (ranges: any, values: IAddOneTimePassForm) => {

        const selection = ranges.selection;
        const schedule = roomSchedule;
        const dataDate = {
            startDate: selection.startDate,
            endDate: selection.endDate,
            key: 'selection',
            showDateDisplay: false,
            color: 'orange'
        };

        setDateRange([dataDate, ...schedule]);
        const body: IScheduleBody = {
            roomId: Number(values.room_id),
            isSchedule: true,
            startDate: formatDate(selection.startDate, 'YYYY-MM-DD 00:00:00'),
            endDate: formatDate(selection.endDate, 'YYYY-MM-DD 23:59:59')
        };
        if(values.room_id) getScheduleByRoomId(body);

        setDateSelected({
            startDate: selection.startDate,
            endDate: selection.endDate
        });
    };

    const handleChangeRoomId = (roomId: number) => {
        const body: IScheduleBody = {
            roomId: roomId,
            isSchedule: true,
            startDate: formatDate(dateSelected.startDate, 'YYYY-MM-DD 00:00:00'),
            endDate: formatDate(dateSelected.endDate, 'YYYY-MM-DD 23:59:59')
        };
        handleGetScheduleRoom(body);
    }

    return (
        <BodyContainer title={isEdit ? 'ワンタイムパス編集' : t('oneTimePassword.add')}>
            <Formik
                initialValues={dataInitOneTimePass}
                enableReinitialize={true}
                validationSchema={addOneTimePassSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values, helpers) => handleSubmit(values, helpers)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, touched, errors } = props;
                    return (
                        <Form>
                            <div className={cx('content-wrapper')}>
                                <section className={cx('content')}>
                                    <div className="w-100 card border-0" style={{ alignItems: 'center' }}>
                                        <div className="card-body py-0 col-xxl-10 col-12">
                                            <div className="row">
                                                <div className="py-0 col-8">
                                                    <FormInput
                                                        required
                                                        styleBlock
                                                        name="user_info"
                                                        maxLength={255}
                                                        className="w-100"
                                                        label="oneTimePassword.username"
                                                        placeholder="oneTimePassword.username"
                                                        value={values.user_info}
                                                        onChangeText={(text) => setFieldValue('user_info', text)}
                                                        error={touched.user_info && errors.user_info ? errors.user_info : undefined}
                                                    />
                                                    <FormInput
                                                        maxLength={11}
                                                        styleBlock
                                                        required
                                                        name="phone_number"
                                                        className="w-100 mt-2"
                                                        label="oneTimePassword.user_phone_number"
                                                        placeholder="oneTimePassword.user_phone_number"
                                                        value={values.phone_number}
                                                        onChangeText={(text) => setFieldValue('phone_number', text)}
                                                        error={
                                                            touched.phone_number && errors.phone_number ? errors.phone_number : undefined
                                                        }
                                                    />
                                                    <FormDropdown
                                                        styleBlock
                                                        required
                                                        className="w-100 mt-2"
                                                        label="oneTimePassword.asset_name"
                                                        placeholder="oneTimePassword.asset_name"
                                                        options={dataAsset}
                                                        value={values.asset_id}
                                                        onChangeOption={(option) => {
                                                            setFieldValue('asset_id', option.value);
                                                            setFieldValue('address', option.address);
                                                            getListRoomOTP(+option.value, '', setFieldValue).then();
                                                            setDateRange([{
                                                                startDate: new Date(),
                                                                endDate: new Date(),
                                                                key: 'selection',
                                                                showDateDisplay: false,
                                                                color: 'orange'
                                                            }])
                                                        }}
                                                        classNameOption="bases__height--26 menu-ellipsis"
                                                        error={touched.asset_id && errors.asset_id ? errors.asset_id : undefined}
                                                    />
                                                    <FormInput
                                                        tooltip
                                                        styleBlock
                                                        disabled
                                                        name="address"
                                                        className="w-100 mt-2"
                                                        value={values.address}
                                                        label="oneTimePassword.propertyaddress"
                                                        placeholder="oneTimePassword.propertyaddress"
                                                        error={touched.address && errors.address ? errors.address : undefined}
                                                    />
                                                    <FormDropdown
                                                        styleBlock
                                                        required
                                                        disabled={!values.asset_id}
                                                        className="w-100 mt-2"
                                                        classNameOption={cxCommon('bases__height--26')}
                                                        label="oneTimePassword.room_number"
                                                        placeholder="oneTimePassword.room_number"
                                                        options={dataRoom}
                                                        value={values.room_id}
                                                        onChangeOption={(option) => {
                                                            setFieldValue('room_id', option.value);
                                                            handleChangeRoomId(Number(option.value));
                                                        }}
                                                        error={touched.room_id && errors.room_id ? errors.room_id : undefined}
                                                    />
                                                    <div className="row mt-2">
                                                        <div
                                                            className={cxCommon(
                                                                'col d-flex align-items-center',
                                                                'label_asterisk',
                                                                'label-color',
                                                            )}>
                                                            {t('oneTimePassword.privateViewingStartDatetime')}
                                                        </div>
                                                        <div className="col-1"></div>
                                                        <div
                                                            className={cxCommon(
                                                                'col d-flex align-items-center',
                                                                'label_asterisk',
                                                                'label-color',
                                                            )}>
                                                            {t('oneTimePassword.privateViewingEndDatetime')}
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 d-flex align-items-center">
                                                        <div className="col">
                                                            <DateTimePicker
                                                                value={values.start_time}
                                                                onChange={(value) => {
                                                                    setFieldValue('start_time', value);
                                                                    setFieldValue('end_time', '');
                                                                }}
                                                                disableTime={!values.start_time}
                                                                placeholderDate={t<string>('oneTimePassword.start_date_placeholder')}
                                                                placeholderTime={t<string>('oneTimePassword.start_time_placeholder')}
                                                                minDate={new Date()}
                                                                maxDate={values.end_time ? new Date(values.end_time) : maxDateDefault}
                                                                minTime={isToday(moment(values.start_time).toDate()) ? new Date() : null}
                                                                maxTime={
                                                                    values.end_time &&
                                                                        compareDateSameOrBefore(
                                                                            values.end_time,
                                                                            values.start_time,
                                                                            'YYYY-MM-DD',
                                                                        ) &&
                                                                        isValid(values.end_time, 'YYYY-MM-DD HH:mm:ss')
                                                                        ? new Date(values.end_time)
                                                                        : null
                                                                }
                                                                error={
                                                                    touched.start_time && errors.start_time ? errors.start_time : undefined
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-1 text-center">
                                                            <span>~</span>
                                                        </div>
                                                        <div className="col">
                                                            <DateTimePicker
                                                                value={values.end_time}
                                                                onChange={(value) => setFieldValue('end_time', value)}
                                                                disableTime={!values.end_time}
                                                                placeholderDate={t<string>('oneTimePassword.end_date_placeholder')}
                                                                placeholderTime={t<string>('oneTimePassword.end_time_placeholder')}
                                                                maxDate={maxDateDefault}
                                                                minDate={values.start_time ? new Date(values.start_time) : new Date()}
                                                                minTime={
                                                                    values.start_time &&
                                                                        compareDateSameOrAfter(
                                                                            values.start_time,
                                                                            values.end_time,
                                                                            'YYYY-MM-DD',
                                                                        ) &&
                                                                        isValid(values.start_time, 'YYYY-MM-DD HH:mm:ss')
                                                                        ? new Date(values.start_time)
                                                                        : isToday(moment(values.end_time).toDate())
                                                                            ? new Date()
                                                                            : null
                                                                }
                                                                error={touched.end_time && errors.end_time ? errors.end_time : undefined}
                                                                isErrorNonMessage={has(errors, 'end_time')}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(errors.start_time || errors.end_time) && (
                                                        <div className="row mt-2">
                                                            <div className="col bases__text--red">{errors.start_time}</div>
                                                            <div className="col-1"></div>
                                                            <div className="col bases__text--red">{errors.end_time}</div>
                                                        </div>
                                                    )}
                                                    <FormInput
                                                        styleBlock
                                                        maxLength={255}
                                                        className="w-100 mt-2"
                                                        label="oneTimePassword.store_name"
                                                        placeholder="oneTimePassword.store_name"
                                                        value={values.store_name}
                                                        onChangeText={(text) => setFieldValue('store_name', text)}
                                                    />
                                                    <FormInput
                                                        styleBlock
                                                        maxLength={255}
                                                        className="w-100 mt-2"
                                                        label="oneTimePassword.receptionist"
                                                        placeholder="oneTimePassword.receptionist"
                                                        value={values.receptionist}
                                                        onChangeText={(text) => setFieldValue('receptionist', text)}
                                                    />
                                                    <FormInput
                                                        styleBlock
                                                        maxLength={255}
                                                        className="w-100 mt-2"
                                                        label="oneTimePassword.person_in_charge"
                                                        placeholder="oneTimePassword.person_in_charge"
                                                        value={values.person_in_charge}
                                                        onChangeText={(text) => setFieldValue('person_in_charge', text)}
                                                    />
                                                    <FormDropdown
                                                        styleBlock
                                                        className="w-100 mt-2"
                                                        classNameOption={cxCommon('bases__height--26')}
                                                        label="oneTimePassword.preview_support"
                                                        options={[
                                                            { value: '', label: '' },
                                                            { value: '0', label: 'oneTimePassword.self_preview' },
                                                            { value: '1', label: 'oneTimePassword.accompanied_by_staff' },
                                                        ]}
                                                        value={values.preview_type}
                                                        onChangeOption={(option) => {
                                                            setFieldValue('preview_type', option.value);
                                                        }}
                                                        error={
                                                            touched.preview_type && errors.preview_type ? errors.preview_type : undefined
                                                        }
                                                    />
                                                    <div className="row mt-3 d-flex justify-content-center">
                                                        <Button
                                                            create
                                                            className="bases__width--170"
                                                            text={isEdit ? t<string>('common.save') : t<string>('oneTimePassword.add')}
                                                            onClick={handleSubmit}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='py-0 col-4'>
                                                    <DateRange
                                                        locale={ja}
                                                        ranges={dateRange}
                                                        direction="vertical"
                                                        minDate={new Date()}
                                                        maxDate={maxDateDefault}
                                                        onChange={(ranges) => handleSelect(ranges, values)}
                                                        showMonthAndYearPickers={false}
                                                        moveRangeOnFirstSelection={false}
                                                        retainEndDateOnFirstSelection={false}
                                                        editableDateInputs={false}
                                                        className='w-100 calender'
                                                        classNames={
                                                            {
                                                                dayEndOfWeek: 'end_date',
                                                                dayStartOfWeek: 'end_date',
                                                                weekDays: 'week__days',
                                                                dayToday: 'today',
                                                                daySelected: 'day__selected'
                                                            }
                                                        }
                                                    />
                                                    <div className="my-2 text-center fw-bold bases__text--label">
                                                        {
                                                            compareSameDate(dateSelected.startDate, dateSelected.endDate, 'day')
                                                            ? `${formatDate(dateSelected.startDate, 'YYYY年MM月DD日')}`
                                                            : `${formatDate(dateSelected.startDate, 'YYYY年MM月DD日')} ~ ${formatDate(dateSelected.endDate, 'YYYY年MM月DD日')}`
                                                        }
                                                        内覧状況
                                                    </div>
                                                    <div className={'p-2 ' + cx('calendar-height', 'border-calendar')}>
                                                        {isEmpty(bookingList) ? (
                                                            <div className="py-4">{t('oneTimePassword.no_schedule')}</div>
                                                        ) : (
                                                            bookingList.map((item, index) => {
                                                                return (
                                                                    <div className={cx('schedule')} key={index}>
                                                                        {/*<div className={cx('schedule__title')}>*/}
                                                                        {/*    {t('oneTimePassword.title_schedule')}*/}
                                                                        {/*</div>*/}
                                                                        <div className={cx('schedule__start')}>{`${t(
                                                                            'oneTimePassword.start',
                                                                        )}: ${formatDate(item.startTime, 'MM月DD日　HH:mm')}`}</div>
                                                                        <div className={cx('schedule__end')}>{`${t(
                                                                            'oneTimePassword.end',
                                                                        )}: ${formatDate(item.endTime, 'MM月DD日　HH:mm')}`}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </BodyContainer>
    );
};

export default OneTimePasswordRegister;
