import classNames from 'classnames/bind';
import styles from '@components/pages/home/manager/QuestionnaireManagement.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import { FormCheckbox, FormInput, FormRadio } from '@components/commons';
import { useTranslation } from 'react-i18next';
import ModalInterface from '../commons/Modal';
import { FormItemType } from '@components/commons/Form/FormRadio';
import { FormItemType as FormItemCheckboxType} from '@components/commons/Form/FormCheckbox';
import { dateHelper } from '@utils/helpers';
import { SURVEY_TYPE } from '@utils/constants';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

const SurveyAnswerDetailModal: IModalComponent<IModalComponentProps> = (props) => {
    const { t } = useTranslation();
    const { isShow, handleClose, data } = props;

    const answer: IDataApiAnswerDetailRes = data;

    console.log(answer.answerDetail);

    const findSelected = (answerOptions: IAnswerOption[], options: IOptions[]) => {
        let id = 0;
        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            const obj = answerOptions.find(e => element.id == e.optionId);
            if (obj) {
                id = obj.optionId;
                break;
            }
        }
        return id
    }
    return (
        <ModalInterface
            classNameBody={cxCommon('bases__padding--top0')}
            classNameHeader={cxCommon('bases__padding20')}
            modalHeader={t('answers.detail.title')}
            isShow={isShow}
            hidePositiveButton
            handleClose={() => handleClose(false)}
            classNameFooter={`pt-1 ${cxCommon('bases__margin--bottom24')}`}
            negativeButtonText="common.close">
            <div className={`${cx('modal-detail')}`}>
                <div className={`${cx('modal-content')}`}>
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className={cxCommon('bases__width--159')}>{t('answers.answer_date')}:</div>
                            <span className={`fw-bold ${cx('f-1')}`}>{`${dateHelper.formatDateUTC(
                                answer.answerInfo.createdAt ?? '',
                                'YYYY/MM/DD HH:mm',
                            )}`}</span>
                        </div>
                        <div className={`d-flex ${cxCommon('bases__margin--top10')}`}>
                            <div className={cxCommon('bases__width--159')}>{t('answers.user')}:</div>
                            <span className={`fw-bold ${cx('f-1')}`}>
                                {answer.answerInfo.otpId ? answer.answerInfo.otpUserInfo : answer.answerInfo.appUserInfo}
                            </span>
                        </div>
                        <div className={`d-flex ${cxCommon('bases__margin--top10')}`}>
                            <div className={cxCommon('bases__width--159')}>{t('answers.asset_name')}:</div>
                            <span className={`fw-bold ${cx('f-1')}`}>{answer.answerInfo.assetName}</span>
                        </div>
                        <div className={`d-flex ${cxCommon('bases__margin--top10')}`}>
                            <div className={cxCommon('bases__width--159')}>{t('answers.room_number')}:</div>
                            <span className={`fw-bold ${cx('f-1')}`}>
                                {answer.answerInfo.otpId ? answer.answerInfo.otpRoomNumber : answer.answerInfo.appRoomNumber}
                            </span>
                        </div>
                    </div>
                </div>

                <div className={`${cx('modal-content')} ${cxCommon('bases__margin--top14')}`}>
                    <div className="d-flex flex-column">
                        {answer.answerDetail.map((e) => {
                            return (
                                <div key={e.id} className={cx('option__separation')}>
                                    <div className="d-flex justify-contnet-between">
                                        <span className="fw-bold">{e.question.content}</span>
                                    </div>
                                    {Number(e.question.type) == SURVEY_TYPE.RADIO ? (
                                        <FormRadio
                                            disabledOnlyIcon
                                            styleBlock
                                            classNameInput="size_radio--20"
                                            defaultCheckedIndex={findSelected(e.answerOptions, e.question.options)}
                                            items={
                                                e.question.options?.map((ele: { content: string; id: number }, index: number) => {
                                                    return {
                                                        value: ele.id,
                                                        label: ele.content,
                                                        key: `${index}`,
                                                    } as FormItemType;
                                                }) ?? []
                                            }
                                        />
                                    ) : (
                                        <FormCheckbox
                                            classNameContent="d-flex flex-column"
                                            classNameInput="size_checkbox--18"
                                            classNameGroupItem="d-flex align-items-center"
                                            label=""
                                            readOnly
                                            items={
                                                e.question.options?.map((ele: { content: string, id: number }) => {
                                                    return {
                                                        label: ele.content,
                                                        key: `${ele.id}`,
                                                        defaultChecked: e.answerOptions.find(item => item.optionId == ele.id) ? true : false,
                                                    } as FormItemCheckboxType;
                                                }) ?? []
                                            }
                                        />
                                    )}
                                </div>
                            );
                        })}
                        <div>
                            <div className="d-flex justify-contnet-between">
                                <span className="fw-bold">{t('answers.other_ratings')}</span>
                            </div>
                            <FormInput classNameTextArea={cxCommon('bases__height-min--105')} textArea readOnly rows={4} defaultValue={answer.answerInfo?.planText || '-'} />
                        </div>
                    </div>
                </div>
            </div>
        </ModalInterface>
    );
};

SurveyAnswerDetailModal.defaultProps = {
    isShow: false,
};

export default SurveyAnswerDetailModal;
