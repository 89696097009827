const routes = {
    notFound: '/404',

    login: '/login',
    resetPassword: '/reset-password',

    home: '/',
    dashboard: '/dashboard',
    notificationDashboard: '/dashboard/notification',
    companyInfo: '/company',
    companyInfoEdit: '/company/edit',
    changePassword: '/change-password',

    appointment: '/appointment',
    oneTimePassword: '/oneTime',
    oneTimePasswordResgister: '/oneTime/register',
    oneTimePasswordDetails: '/oneTime/:id',
    oneTimePassWordEdit: '/oneTime/edit/:id',
    brokerHistory: '/history/broker',
    employeeHistory: '/history/employee',
    workerHistory: '/history/worker',
    oneTimeHistory: '/history/otp',
    keyboxHistory: '/keybox-history',

    asset: '/asset',
    assetRegister: '/asset/register',
    assetDetails: '/asset/:id',
    keybox: '/keybox',

    brokerSystem: '/user/broker-system',
    broker: '/user/broker',
    brokerRegister: '/user/broker/register',
    brokerDetails: '/user/broker/:id',

    accountDetail: '/account/info',
    employeeWorker: '/account/employee-worker',
    employeeWorkerRegister: '/account/employee-worker/register',
    employeeWorkerDetails: '/account/employee-worker/:id',
    employeeWorkerEdit: '/account/employee-worker/:id/edit',
    company: '/account/company',
    companyRegister: '/account/company/register',
    companyDetails: '/account/company/:id/:adminId',
    companyEdit: '/account/company/:id/edit/:adminId',
    manual: '/manual',
    payment: '/payment',

    terms: '/terms',

    notification: '/notification',
    notificationRegister: '/notification/add',

    questionnaireManagement: '/questionnaireManagement',
    surveyQuestion: '/surveyQuestion',
    newQuestion: '/questionnaireManagement/newQuestion',
    summaryQuestion: '/SummaryQuestion',
};

export default routes;
