import { getAppointmentList, updateStatusAppointment } from '@utils/helpers/api';

export const fetchGetListAppointment = async (params: IAppointmentSearchParams): Promise<IBaseAPISearchRes<IDataAppointmentSearchRes>> => {
    try {
        const response = await getAppointmentList(params);
        return response.data;
    } catch (error: any) {
        return error;
    }
};

export const fetchUpdateStatusAppointment = async (body : IAppointmentUpdateStatus): Promise<IBaseAPIRes> =>{
    try {
        const response = await updateStatusAppointment(body);
        return response.data;
    }catch (error: any) {
        return error;
    }
}
