import { ReactComponent as ICON_SUCCESS } from '@assets/images/icon_success.svg';
import { ReactComponent as ICON_DASHBOARD } from '@assets/images/icon_dashboard.svg';
import { ReactComponent as ICON_IN_OUT } from '@assets/images/icon_in_out.svg';
import { ReactComponent as ICON_ASSETS } from '@assets/images/icon_assets.svg';
import { ReactComponent as ICON_KEYBOX_SVG } from '@assets/images/icon_keybox.svg';
import { ReactComponent as ICON_BROKER } from '@assets/images/icon_broker.svg';
import { ReactComponent as ICON_USER_INFO_SVG } from '@assets/images/icon_user_info.svg';
import { ReactComponent as ICON_MANAGER } from '@assets/images/icon_manager.svg';
import { ReactComponent as ICON_PAPER_SVG } from '@assets/images/icon_paper.svg';
import { ReactComponent as ICON_TERM } from '@assets/images/icon_term.svg';
import { ReactComponent as ICON_NOTIFICATION_SVG } from '@assets/images/icon_notification.svg';
import { ReactComponent as LOGO_SVG } from '@assets/images/logo.svg';
import { ReactComponent as ICON_NEXT_NAV_SVG } from '@assets/images/icon_next_nav.svg';
import { ReactComponent as ICON_USER_SVG } from '@assets/images/icon_user.svg';
import { ReactComponent as ICON_SIDEBAR_SVG } from '@assets/images/icon_sidebar.svg';
import { ReactComponent as ICON_HOUSE } from '@assets/images/icon_house.svg';
import { ReactComponent as ICON_DOOR } from '@assets/images/icon_door.svg';
import { ReactComponent as ICON_INTERMEDIARY } from '@assets/images/icon_intermediary.svg';
import { ReactComponent as ICON_KEYBOX } from '@assets/images/icon_keybox_lg.svg';
import { ReactComponent as ICON_EMPLOYEE } from '@assets/images/icon_employee.svg';
import { ReactComponent as ICON_UNLOCK } from '@assets/images/icon_unlock.svg';
import { ReactComponent as ICON_UNLOCK_KEYBOX } from '@assets/images/icon_unlock_keybox.svg';
import { ReactComponent as ICON_USERS } from '@assets/images/icon_users.svg';
import { ReactComponent as ICON_OVERALL } from '@assets/images/icon_overall.svg';
import { ReactComponent as LOGO_MAIN } from '@assets/images/logo_main.svg';
import { ReactComponent as ICON_PAYMENT_SVG } from '@assets/images/icon_payment.svg';
import { ReactComponent as ICON_KEYS_SVG } from '@assets/images/icon_keys.svg';
import { ReactComponent as ICON_MIDDLEMAN_SVG } from '@assets/images/icon_middleman.svg';
import { ReactComponent as ICON_BUILDING_GROUP_SVG } from '@assets/images/icon_building_group.svg';
import { ReactComponent as NO_IMAGE_SVG } from '@assets/images/no_image.svg';
import { ReactComponent as LOGO_BENRI } from '@assets/images/logo_benri.svg';
import { ReactComponent as LOGO_GUARDLOCK } from '@assets/images/logo_guardlock.svg';
import { ReactComponent as LOGO_SIDEBAR } from '@assets/images/logo_sidebar.svg';
import { ReactComponent as ICON_VISA } from '@assets/images/icon_visa.svg';
import { ReactComponent as ICON_MASTER_CARD } from '@assets/images/icon_master_card.svg';
import { ReactComponent as ICON_JCB } from '@assets/images/icon_jcb.svg';
import { ReactComponent as ICON_DINER } from '@assets/images/icon_diner.svg';
import { ReactComponent as ICON_AMERICAN } from '@assets/images/icon_american.svg';
import { ReactComponent as ICON_DOTS } from '@assets/images/icon_dots.svg';
import { ReactComponent as VISA } from '@assets/images/visa.svg';
import { ReactComponent as CHECKED_BLUE } from '@assets/images/checkbox_check_blue.svg';
import { ReactComponent as ICON_HELP } from '@assets/images/icon_help.svg';
export const IMAGES = {
    NO_IMAGE: require('@assets/images/no_image.png'),
    NO_IMAGE_ONE: require('@assets/images/no_image_one.png'),
    NO_IMAGE_TWO: require('@assets/images/no_image_two.png'),
    NO_IMAGE_THREE: require('@assets/images/no_image_three.png'),
    ICON_ARROW_RIGHT_SMALL: require('@assets/images/icon_arrowRight_small.png'),
    ICON_ARROW_LEFT: require('@assets/images/icon_arrowLeft.png'),
    ICON_ARROW_RIGHT: require('@assets/images/icon_arrowRight.png'),
    CALENDAR: require('@assets/images/icon_calendar.png'),
    TEMP_IMAGE: require('@assets/images/tempImage.png'),
    ICON_DROPDOWN: require('@assets/images/icon_dropdown.png'),
    ICON_TOP: require('@assets/images/icon_top.png'),
    ICON_DETAIL: require('@assets/images/icon_detail.png'),
    ICON_NOTIFICATION: require('@assets/images/icon_notification.png'),
    ICON_CLOCK: require('@assets/images/icon_clock.png'),
    ICON_CALENDERCLOCK: require('@assets/images/icon_calendarClock.png'),
    ICON_CLOSE: require('@assets/images/icon_close.png'),
    ICON_RADIO: require('@assets/images/icon_radiobtn.png'),
    ICON_CLOSE_SMALL: require('@assets/images/icon_close_small.png'),
    ICON_CHECKBOX: require('@assets/images/icon_checkbox.png'),
    ICON_PAPER: require('@assets/images/icon_paper.png'),
    DEFAULT_IMAGE: require('@assets/images/default_image.png'),
    ICON_ERROR: require('@assets/images/icon_error.png'),
    ICON_CLOSE_BG: require('@assets/images/icons_close_bg.png'),
    ICON_COMPLETE: require('@assets/images/icon_complete.png'),
    ICON_WARNING: require('@assets/images/icon_warning.png'),
    ICON_CLOSE_MODAL: require('@assets/images/icon_close_modal.png'),
    ICON_REMOVE_CHECKED: require('@assets/images/icon_remove_checked.png'),
    ICON_INPUT_IMG: require('@assets/images/icon_input_img.png'),
    ICON_DEFAULT_INPUT_IMG: require('@assets/images/icon_default_input_img.png'),
    ICON_INPUT_IMG_DISABLED: require('@assets/images/icon_input_img_disabled.png'),
    ICON_CLOSE_NEW: require('@assets/images/icon_close_new.png'),
    ICON_LIST_METHOD: require('@assets/images/list_method.png'),
    ICON_SUCCESS,
    ICON_DASHBOARD,
    ICON_IN_OUT,
    ICON_ASSETS,
    ICON_KEYBOX_SVG,
    ICON_BROKER,
    ICON_USER_INFO_SVG,
    ICON_MANAGER,
    ICON_PAPER_SVG,
    ICON_TERM,
    ICON_NOTIFICATION_SVG,
    LOGO_SVG,
    ICON_NEXT_NAV_SVG,
    ICON_USER_SVG,
    ICON_SIDEBAR_SVG,
    ICON_HOUSE,
    ICON_DOOR,
    ICON_INTERMEDIARY,
    ICON_KEYBOX,
    ICON_EMPLOYEE,
    ICON_UNLOCK,
    ICON_UNLOCK_KEYBOX,
    ICON_USERS,
    ICON_OVERALL,
    LOGO_MAIN,
    ICON_DETAIL_WHITE: require('@assets/images/icon_detail_white.png'),
    ICON_CHEVRON_LEFT: require('@assets/images/icon_chevron-left.png'),
    ICON_CHEVRON_RIGHT: require('@assets/images/icon_chevron-right.png'),
    ICON_PAYMENT_SVG,
    ICON_KEYS_SVG,
    ICON_MIDDLEMAN_SVG,
    ICON_BUILDING_GROUP_SVG,
    NO_IMAGE_SVG,
    LOGO_BENRI,
    LOGO_SIDEBAR,
    LOGO_GUARDLOCK,
    MANUAL: require('@assets/pdf/manual.pdf'),
    ICON_VISA,
    ICON_MASTER_CARD,
    ICON_JCB,
    ICON_DINER,
    ICON_AMERICAN,
    ICON_DOTS,
    VISA,
    CHECKED_BLUE,
    ICON_HELP
};
