import { setUserInfo } from '@redux/actions/userAction';
import store from '@redux/store';
import { getAllInfoUser } from './api';

export const fetchUserInfo = async () => {
  try {
    const response = await getAllInfoUser();
    store.dispatch(setUserInfo(response.data.data));
  } catch (error) {
    console.log(error);
  }
};