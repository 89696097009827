/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames/bind';
import styles from '@components/pages/home/manager/surveyQuestion/SurveyQuestion.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import modalStyles from './Modal.module.scss';
import { Button, FormCheckbox, FormDropdown, FormInput, FormRadio } from '@components/commons';
import { useTranslation } from 'react-i18next';
import ModalInterface from '../commons/Modal';
import { SURVEY_TYPE, images } from '@utils/constants';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import i18n from '@utils/lang/i18next';
import { apiHelper } from '@utils/helpers';
import { setToast } from '@redux/actions';
import { useDispatch } from 'react-redux';
import ModalConfirm from './ModalConfirm';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);
const cxModal = classNames.bind(modalStyles);

interface IOption {
    content: string;
}
interface InitValueFormik {
    content: string;
    type: SURVEY_TYPE.RADIO | SURVEY_TYPE.CHECKBOX;
    options: IOption[];
}

const schema = yup.object().shape({
    content: yup
        .string()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('survey_question.create.contents_of_question') })),
    options: yup
        .array()
        .test('options', function (options, ctx) {
            let count = 0;
            if (!options || (options && options?.length <= 1)) {
                const error: any = [i18n.t<string>('survey_question.message.options')];
                return ctx.createError({ message: {error: error} });
            }

            for (const iterator of options) {
                if (iterator.content) {
                    count++;
                }
            }
            
            if (count < 2) {
                const error:any = [];
                for (const iterator of options) {
                    if (iterator.content) {
                        error.push(null);
                    } else {
                        error.push(i18n.t<string>('survey_question.message.options'));
                    }
                }
                return ctx.createError({ message: {error: error} });
            }

            return true;
        }),
});

const SurveyQuestionCreateModal: IModalComponent<IModalComponentProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showDialogConfirm, setShowDialogConfirm] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<'close' | 'cancel'>('close');
    const { isShow, handleClose, handleOK } = props;

    const initValueFormik: InitValueFormik = {
        content: '',
        type: SURVEY_TYPE.RADIO,
        options: [
            {
                content: '',
            },
        ],
    };

    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: initValueFormik,
        validationSchema: schema,
        onSubmit: async () => {
            try {
                const req = JSON.parse(JSON.stringify(formik.values));
                const options = [];
                for (let i = 0; i < req.options.length; i++) {
                    const element = req.options[i];
                    if (element.content) {
                        options.push(element);
                    }
                }
                req.options = options;
                const res = await apiHelper.postSurveyQuestions(req);
                if (res?.data) {
                    const data = res?.data as IRes;
                    if (data.status === 'SUCCESS') {
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.add_success')}`,
                            }),
                        );
                        handleClose();
                        handleOK();
                    }
                }
            } catch (error: any) {
                if (!error?.data) {
                    console.log(error);
                    return;
                }
                
                if (error?.data.message === 'INVALID_OPTIONS') {
                    const err: any = {error: [i18n.t<string>('survey_question.message.options')]};
                    formik.errors = {
                        ...formik.errors,
                        options: err,
                    };
                }
                if (error?.data.message === 'CONTENT_REQUIRED') {
                    formik.errors = {
                        ...formik.errors,
                        content: i18n.t<string>('message.required', { 0: i18n.t<string>('survey_question.create.contents_of_question') }),
                    };
                }
                if (error?.data.message === 'OPTION_CONTENT_REQUIRED') {
                    const err: any = {error: []};
                    for (let i = 0; i < formik.values.options.length; i++) {
                        if (Array.isArray(formik.values.options) && !formik.values.options[i].content) {
                            err.error.push(i18n.t<string>('survey_question.message.options'));
                        } else {
                            err.error.push(null);
                        }
                    }
                    formik.errors = {
                        ...formik.errors,
                        options: err,
                    };
                }
                formik.setErrors({ ...formik.errors });
            }
        },
    });

    const options = useMemo(() => {
        return formik.values.options;
    }, [formik.values.options]);

    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };

    const handleChangeOption = (value: any, index: number) => {
        formik.setFieldValue(`options[${index}].content`, value);
    };

    const addOption = () => {
        if (formik.values.options.length >= 5) {
            return;
        }
        formik.values.options.push({ content: '' });
        formik.setFormikState((prev) => {
            return {
                ...prev,
                values: {
                    ...prev.values,
                    options: formik.values.options,
                },
            };
        });
        if (formik.values.options.length >= 2 && typeof formik.errors.options === 'string') {
            formik.setErrors({ ...formik.errors, options: '' });
        }
    };

    const removeOption = (index: number) => {
        if (formik.values.options.length === 1 || !formik.values.options[index].content) {
            return;
        }
        formik.values.options.splice(index, 1);
        formik.setFormikState((prev) => {
            return {
                ...prev,
                values: {
                    ...prev.values,
                    options: formik.values.options,
                },
            };
        });
        if (formik.values.options.length >= 2 && typeof formik.errors.options === 'string') {
            formik.setErrors({ options: '' });
        }
    };

    const _renderSurveyType = (type: number) => {
        return (
            <div className={`${cxCommon('bases__width--40', 'bases__height--40', 'bases__margin--right4')} d-flex`}>
                {type == SURVEY_TYPE.RADIO ? (
                    <FormRadio
                        disabledOnlyIcon
                        styleBlock
                        className="d-flex align-items-center"
                        classNameContent="w-100"
                        defaultCheckedIndex={0}
                        classNameInput="size_radio--20"
                        items={[{ value: 1, label: '' }]}
                    />
                ) : (
                    <FormCheckbox
                        classNameInput="size_checkbox--18"
                        classNameGroupItem="d-flex align-items-center"
                        readOnly
                        items={[{ label: '' }]}
                    />
                )}
            </div>
        );
    };

    const _renderModalConfirmCancel = () => {
        return (
            showDialogConfirm && (
                <>
                    <div className={cxModal('opacity-modal')} />
                    <ModalConfirm
                        isShow={showDialogConfirm}
                        handleOK={() => {
                            setShowDialogConfirm(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setShowDialogConfirm(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>(typeModal === 'cancel' ? 'common.confirm_cancel' : 'common.confirm_close')}
                    />
                </>
            )
        );
    };

    return (
        <ModalInterface
            dialogClassname={cxCommon('bases__width--700', 'bases__height--10')}
            modalHeader={t('survey_question.create.title')}
            isShow={isShow}
            handleClose={() => {
                setTypeModal('close');
                setShowDialogConfirm(true);
            }}
            handleCancel={() => {
                setTypeModal('cancel');
                setShowDialogConfirm(true);
            }}
            handleOK={formik.handleSubmit}
            classNameFooter={`pt-1 ${cxCommon('bases__margin--bottom24')}`}
            negativeButtonText="common.cancel"
            positiveButtonText="survey_question.button.create_new">
            <div className={`${cx('modal-detail')}`}>
                <div className={`${cx('modal-content')}`}>
                    <div className="d-flex flex-column">
                        <label className={'fw-bold'}>
                            {t('survey_question.create.contents_of_question')}
                            <span className={cxCommon('text-danger')}>*</span>
                        </label>
                        <div className={`${cxCommon('bases__margin--top10')}`}>
                            <FormInput
                                maxLength={255}
                                classNameTextArea={cxCommon('bases__height-min--128')}
                                textStart
                                onChangeText={(text) => handleChange('content', text)}
                                value={formik.values.content}
                                placeholder="survey_question.create.placeholder_content"
                                error={formik.touched.content && formik.errors.content ? formik.errors.content : undefined}
                            />
                        </div>
                        <div className={`d-flex align-items-center ${cxCommon('bases__margin--top15')}`}>
                            <label className={`fw-bold ${cxCommon('bases__margin--right10')}`}>
                                {t('survey_question.create.choice')}
                                <span className={cxCommon('text-danger')}>*</span>
                            </label>
                            <FormDropdown
                                classNameOption={cxCommon('bases__height--32')}
                                value={formik.values.type}
                                hiddenLabel
                                defaultValue={formik.values.type}
                                options={[
                                    {
                                        value: SURVEY_TYPE.RADIO,
                                        label: t('survey_question.create.radio'),
                                        imageSrc: images.IMAGES.ICON_RADIO,
                                    },
                                    {
                                        value: SURVEY_TYPE.CHECKBOX,
                                        label: t('survey_question.create.checkbox'),
                                        imageSrc: images.IMAGES.ICON_CHECKBOX,
                                    },
                                ]}
                                onChangeOption={(option) => handleChange('type', option.value)}
                            />
                        </div>
                        <div className={cxCommon('bases__margin--top15')}>
                            {options.map((e, index) => {
                                return (
                                    <div className={`d-flex ${index === 0 ? '' : cxCommon('bases__margin--top15')}`} key={index}>
                                        {_renderSurveyType(formik.values.type)}
                                        <FormInput
                                            maxLength={50}
                                            className="w-100"
                                            defaultValue={formik.values.options[index].content}
                                            value={formik.values.options[index].content}
                                            onChangeText={(text) => handleChangeOption(text, index)}
                                            isError={(formik.errors.options as any)?.error[index]}
                                            placeholder={`${t('survey_question.create.option')}${index + 1}`}
                                        />
                                        <img
                                            src={images.IMAGES.ICON_CLOSE_SMALL}
                                            className={`${cx('btn-delete')} ${cxCommon('bases__height--40', 'bases__margin--left10')} ${
                                                options.length === 1 || !formik.values.options[index].content ? cx('cursor__default') : ''
                                            }`}
                                            onClick={() => removeOption(index)}
                                        />
                                    </div>
                                );
                            })}
                            {formik.errors.options && (
                                <div className={'d-flex'}>
                                    <div className={cxCommon('bases__width--40')}></div>
                                    <div className={cxCommon('error-msg')}>
                                        <div className={cxCommon('text-overflow')}>{t('survey_question.message.options')}</div>
                                    </div>
                                </div>
                            )}
                            <div className={`${cxCommon('bases__margin--top15')} d-flex align-items-center`}>
                                <FormRadio disabledOnlyIcon className={cx('opacity--0')} items={[{ value: 1, label: '' }]} />
                                <Button
                                    del
                                    onClick={() => addOption()}
                                    className={cxCommon('bases__width--125')}
                                    disabled={options.length === 5}>
                                    {t('survey_question.button.add_choice')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {_renderModalConfirmCancel()}
        </ModalInterface>
    );
};

SurveyQuestionCreateModal.defaultProps = {
    isShow: false,
};

export default SurveyQuestionCreateModal;
