import React, { createContext, useContext, useEffect, useState } from 'react';
import { USER_ROLE_ENUM } from '../constants';
import { userSignin, signOut } from '../helpers/api';
import Configs from '~/utils/configs';

interface IAuthenticator {
    email: string;
    role: USER_ROLE_ENUM;
}
interface Login {
    access_token: string;
    expires_in: number;
    token_type: string;
    user_name: string;
    user_role: string;
}

interface IAuthContext {
    user?: IAuthenticator;
    signInWithEmail: (email: string, password: string, callback: (role?: USER_ROLE_ENUM, err?: any) => void) => void;
    signInWithToken: (callback: (role?: USER_ROLE_ENUM, err?: string) => void) => void;
    signOut: (callback?: () => void) => void;
}

const authContext = createContext<IAuthContext>({
    user: undefined,
    signInWithEmail: () => undefined,
    signInWithToken: () => undefined,
    signOut: () => undefined,
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: React.ReactNode }) {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

const apiAuthen = async (formData: any) => {
    try {
        const response = await userSignin(formData);
        if (response?.data) {
            return response?.data;
        }
    } catch (err) {
        return err;
    }
};

const logOut = async () => {
    try {
        const response = await signOut();
        if (response?.data) {
            console.log(response.data);

            return response?.data;
        }
    } catch (err) {
        return err;
    }
};

function fakeAuthen() {
    const signInWithEmailAndPassword = async (email: string, password: string) => {
        const response = await apiAuthen({
            username: email,
            password: password,
        });

        return new Promise((resolve, reject) => {
            if (!email || !password) {
                reject('Empty Authenticator');
            } else {
                if (response?.status === 'SUCCESS') {
                    resolve(response?.data);
                } else {
                    reject(response?.data);
                }
            }
        });
        // return new Promise<USER_ROLE_ENUM>((res, rej) => {
        //     if (!email || !password) {
        //         rej('Empty Authenticator');
        //     } else {
        //         let userRole = USER_ROLE_ENUM.admin;
        //         switch (email.toLowerCase()) {
        //             case 'superadmin':
        //                 userRole = USER_ROLE_ENUM.superadmin;
        //                 break;
        //             default:
        //                 userRole = USER_ROLE_ENUM.admin;
        //                 break;
        //         }

        //         res(userRole);
        //     }
        // });
    };

    const signInWithAccessToken = (accessToken: string | null) => {
        return new Promise<any>((res, rej) => {
            if (!accessToken) {
                rej('Empty Authenticator');
            } else {
                const authenticator = JSON.parse(accessToken);
                res(authenticator);
            }
        });
    };

    const signOut = async () => {
        const response = await logOut();
        return new Promise<void>((resolve, reject) => {
            if (response?.status === 'SUCCESS') {
                resolve();
            } else {
                reject();
            }
        });
    };

    return {
        signInWithEmailAndPassword,
        signInWithAccessToken,
        signOut,
    };
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState<IAuthenticator>();
    const authen = fakeAuthen();

    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signInWithEmail = async (
        email: string,
        password: string,
        callback: (role?: USER_ROLE_ENUM, err?: string) => void = () => undefined,
    ) => {
        const emailCheck = email.trim();
        const passwordCheck = password.trim();
        authen
            .signInWithEmailAndPassword(emailCheck, passwordCheck)
            .then((response: Login | any) => {
                localStorage.setItem('accessToken', JSON.stringify(response));
                setUser(response);
                callback(response);
            })
            .catch((err) => {
                callback(undefined, err);
            });
    };

    const signInWithToken = async (callback: (role?: USER_ROLE_ENUM, error?: string) => void = () => undefined) => {
        const accessToken = localStorage.getItem('accessToken');
        authen
            .signInWithAccessToken(accessToken)
            .then((response) => {
                setUser(response);
                callback(response.user_role);
            })
            .catch((err) => {
                localStorage.removeItem('accessToken');
                setUser(undefined);
                callback(undefined, err);
            });
    };

    const signOut = async (callback: () => void = () => undefined) => {
        authen
            .signOut()
            .then(() => {
                console.log('LOGOUT SUCCESS');
            })
            .catch((err) => {
                console.log('LOGOUT ERR', err);
            })
            .finally(() => {
                localStorage.removeItem('accessToken');
                setUser(undefined);
                callback();
            });
    };

    // Return the user object and auth methods
    return {
        user,
        signInWithEmail,
        signInWithToken,
        signOut,
    };
}
