import { Dispatch } from 'redux';
import { apiHelper } from '~/utils/helpers';
import * as Types from './type';
import { AxiosError, AxiosResponse } from 'axios';

interface IDetailAssetRes {
    status: string;
    message: string;
    data: any;
}

interface IErrorAPIRes {
    status: string;
    message: string;
}

export const getAssetAsync = (params: IParamsAsset, assetId?: string) => async (dispatch: Dispatch) => {
    return apiHelper
        .getAsset(params, assetId)
        .then((res) => {
            dispatch({ type: Types.SET_ASSET, payload: res?.data });
        })
        .catch((error) => {
            return error;
        });
};

export const getDetailAssetAsync = async (id: string | number, callback: (result: IDetailAssetRes | IErrorAPIRes | null) => void = () => undefined) => {
    try {
        const res = await apiHelper.getDetailAsset(id);
        if (res?.data) {
            callback(res?.data)
        }
    } catch (err) {
        if (!(err instanceof Error)) {
            const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
            if (res.data) {
                callback(res.data);
            }

        }
    }
};