import { ButtonStyle } from '@assets/styles';
import { Button, FormInput } from '@components/commons';
import ModalInterface from '@components/commons/Modal';
import DataTable from '@components/commons/Table/Table';
import NoDataTable from '@components/layouts/table/NoDataTable';
import { setToast } from '@redux/actions';
import { fetchGetListBrokerCompany, fetchUpdateBrokerCompany } from '@utils/helpers/brokerApi';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';

const cx = classNames.bind(styles);
const cxButton = classNames.bind(ButtonStyle);

const CompanyApprovalSetting: IModalComponent<IModalComponentProps> = (props) => {
    const { isShow, handleClose, handleOK } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [listBroker, setListBroker] = useState<IDataApiBrokerCompanyRes[]>([]);
    const [listBrokerInit, setListBrokerInit] = useState<IDataApiBrokerCompanyRes[]>([]);
    const [searchParams, setSearchParams] = useState<SearchParamsBrokerCompany>({
        limit: 30,
        offset: 0,
        name: '',
    });
    const [listCheckbox, setListCheckbox] = useState<string[]>([]);
    const [initialCheckedValue, setInitialCheckedValue] = useState<string[]>([]);
    const [initialCheckedList, setInitialCheckedList] = useState<string[]>([]);
    const tableRef = createRef<ITableComponentHandle>();
    const [isClearTable, setIsClearTable] = useState(false);
    const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false);
    const [isShowConfirmClose, setIsShowConfirmClose] = useState<boolean>(false);
    const [valueInput, setValueInput] = useState('');
    const [isFirstTime, setIsFirstTime] = useState(true);

    const handleTableDatas = () => {
        return listBroker?.map((item) => {
            return {
                ...item,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${item.id}`,
                                value: item.id?.toString(),
                            },
                        ],
                        // checked: item.is_auto_approve ? [item.id?.toString()] : []
                    },
                ],
                company_name: item.name || '-',
            };
        });
    };

    const table = {
        heads: [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
            },
            {
                title: t('staffworker.list.brokerage_company_name'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                },
                {
                    field: 'company_name',
                },
            ],
            rows: handleTableDatas(),
        },
    };

    const fetchGetListBrokerCompanyAsync = async (searchParams: SearchParamsBrokerCompany) => {
        const response = await fetchGetListBrokerCompany(searchParams);
        if (response.status === 'SUCCESS') {
            setListBroker(response.data);
            setListBrokerInit(response.data);
            setIsFirstTime(true);
            setIsClearTable(true);
        }
    };

    useEffect(() => {
        if (isFirstTime && listBroker.length) {
            const listChecked = listBroker.filter((item) => item.is_auto_approve).map((item) => item.id.toString());
            setInitialCheckedValue([...listChecked]);
            setInitialCheckedList([...listChecked]);
            setIsFirstTime(false);
        }
    }, [listBroker]);

    useEffect(() => {
        const newListBroker = listBroker.map((item) => {
            if (listCheckbox.includes(item.id.toString())) {
                return {
                    ...item,
                    is_auto_approve: true,
                };
            }
            return {
                ...item,
                is_auto_approve: false,
            };
        });
        setListBroker(newListBroker);
    }, [listCheckbox]);
    
    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);

    useEffect(() => {
        fetchGetListBrokerCompanyAsync(searchParams);
    }, [searchParams]);

    const handleSubmit = () => {
        setSearchParams((prevSearchParams) => ({ ...prevSearchParams, name: valueInput }));
    };

    const handleSubmitApprove = () => {
        const changedArray = listBroker.filter((item) => {
            const finedItem = listBrokerInit.find((element) => item.id === element.id);

            return finedItem && finedItem.is_auto_approve !== item.is_auto_approve;
        });

        const listBrokerFilter = changedArray.filter((item) => !(initialCheckedList.includes(item.id.toString()) && item.is_auto_approve));
        let listBrokerUpdate: ISettingAutoApprove[] = [];
        listBrokerUpdate = listBrokerFilter.map((item) => ({
            brokerCompanyId: item.id,
            isAutoApprove: item.is_auto_approve,
        }));

        const body: IBodyAutoApproveBrokerCompany = {
            settings: listBrokerUpdate,
        };

        fetchUpdateBrokerCompany(body).then((res) => {
            if (res.status === 'SUCCESS') {
                handleOK();
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.save_data')}`,
                    }),
                );
            }
        });
    };

    return (
        <ModalInterface
            dialogClassname={cx('content-width', 'modal-fixed')}
            classNameHeader="bases__margin--top22"
            classNameBody="bases__padding--horizontal30"
            classNameFooter="mb-4"
            modalHeader={'仲介会社名自動承認設定'}
            isShow={isShow}
            handleClose={() => {
                setIsShowConfirmCancel(true);
            }}
            handleCancel={() => {
                setIsShowConfirmClose(true);
            }}
            handleOK={handleSubmitApprove}
            positiveButtonText="common.ok"
            hidePositiveButton={isEmpty(listBroker)}
            hideNegativeButton={isEmpty(listBroker)}
        >
            <div className="row">
                <div className="d-flex gap-4 pt-2">
                    <div className="bases__width-percent--80">
                        <FormInput
                            maxLength={255}
                            value={valueInput}
                            className=""
                            placeholder="仲介業者名"
                            onChangeText={(value) => setValueInput(value)}
                        />
                    </div>
                    <div className="bases__width-percent--20">
                        <Button
                            className={cxButton('button-non-min-width')}
                            text={t<string>('common.search')}
                            search
                            onClick={handleSubmit}
                        />
                    </div>
                </div>

                <div className="mt-4">
                    {!isEmpty(listBroker) ? (
                        <DataTable
                            isStickyColumn
                            ref={tableRef}
                            heads={table.heads}
                            body={table.body}
                            onChangeCheckList={(value: string[]) => {
                                setListCheckbox(value);
                            }}
                            isHidePagination={true}
                            initialCheckedValue={initialCheckedValue}
                        />
                    ) : (
                        <NoDataTable />
                    )}
                </div>
            </div>

            {isShowConfirmCancel && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        isShow={isShowConfirmCancel}
                        handleOK={() => {
                            setIsShowConfirmCancel(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmCancel(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_close')}
                    />
                </>
            )}
            {isShowConfirmClose && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowConfirmClose}
                        handleOK={() => {
                            setIsShowConfirmClose(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmClose(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_cancel')}
                    />
                </>
            )}
        </ModalInterface>
    );
};

CompanyApprovalSetting.defaultProps = {
    isShow: false,
};

export default CompanyApprovalSetting;
