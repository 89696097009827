import { regex } from '@utils/constants';
import moment from 'moment';

export const getUTCStringNow = () => {
    return moment().toDate().toISOString();
};

export const formatDateUTC = (date: string, toFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date).utc().format(toFormat);
    }

    return '';
};

export const formatDate = (date: string, toFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date).format(toFormat);
    }

    return '';
};

export const isValid = (date: string, format = 'YYYY/MM/DD') => {
    return moment(date, format, true).isValid();
};

export const formatMonth = (date: Date) => {
    const lang = 'jp';
    const year = date.toLocaleString(lang, { year: 'numeric' });
    const month = date.toLocaleString(lang, { month: 'numeric' });

    return `${year}年 ${month}月`;
};

export const dayOfWeekAsString = (dayIndex: number): string => {
    return ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'][dayIndex] || '';
};

export const compareDateSameOrAfter = (from_date: string, to_date: string, fromFormat = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(from_date, fromFormat).isSameOrAfter(moment(to_date, fromFormat));
};

export const compareDateSameOrBefore = (from_date: string, to_date: string, fromFormat = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(from_date, fromFormat).isSameOrBefore(moment(to_date, fromFormat));
};

export const compareDateBefore = (from_date: string, to_date: string, fromFormat = 'YYYY-MM-DD HH:mm:ss') => {
    return moment(from_date, fromFormat).isBefore(moment(to_date, fromFormat));
};

export const compareSameDate = (dateA: string | Date, dateB: string | Date, type: 'day' | 'month' | 'year') => {
    const dateAMoment = moment(dateA);
    const dateBMoment = moment(dateB);

    if (type === 'day')
        return (
            dateAMoment.isSame(dateBMoment, 'day') && dateAMoment.isSame(dateBMoment, 'month') && dateAMoment.isSame(dateBMoment, 'year')
        );
    if (type === 'month') return dateAMoment.isSame(dateBMoment, 'month') && dateAMoment.isSame(dateBMoment, 'year');
    if (type === 'year') return dateAMoment.isSame(dateBMoment, 'year');
};

export const getDate = () => {
    return moment().toDate();
};

export const formatFromText = (date: string) => {
    return moment(`${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`).toDate();
};

export const isValidDate = (date: Date) => {
    if (date) {
        return moment(date).isValid();
    }

    return false;
};

export const formatToDate = (date: string, fromFormat = 'YYYY/MM/DD') => {
    if (date) {
        return moment(date, fromFormat).toDate();
    }

    return null;
};

export const formatTimeFromText = (current: Date, time: string, type: 'number' | 'free' = 'number') => {
    const date = formatDate(current.toISOString(), 'YYYY-MM-DD');
    let dateTime;
    if (type === 'number') {
        dateTime = moment(`${date} ${time.substring(0, 2)}:${time.substring(2, 4)}:00`);
    } else {
        if (regex.RULE.SHORT_TIME.test(time)) {
            dateTime = moment(`${date} ${time}:00`);
        }
    }
    if (dateTime && dateTime.isValid()) {
        return dateTime.toDate();
    }
    return undefined;
};

export const formatTime = (time: string, toFormat = 'HH:mm') => {
    if (time) {
        return moment(time).format(toFormat);
    }
    return '';
};

export const formatFromTime = (time: string, fromFormat = 'HH:mm', toFormat = 'HH:mm') => {
    if (time) {
        return moment(time, fromFormat).format(toFormat);
    }

    return '';
};

export const isToday = (date: { getDate: () => number; getMonth: () => number; getFullYear: () => number }) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
};

export const formatAndSubtractOrAddDate = (
    date: Date | string,
    type: moment.unitOfTime.DurationConstructor,
    unit = 0,
    format = 'YYYY/MM/DD',
    typeConvert: 'subtract' | 'add',
) => {
    return typeConvert === 'add' ? moment(date).add(unit, type).format(format) : moment(date).subtract(unit, type).format(format);
};
