import * as yup from 'yup';
import i18n from '../lang/i18next';

// const { t } = useTranslation();

export const companyInfoEditSchema = yup.object().shape({
    address: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.company_address') })),
    // assetAmount: yup.number().required(i18n.t<string>('message.required')),
    // assetPublication: yup.number().required(i18n.t<string>('message.required')),
    businessStartHour: yup.string().trim().required(i18n.t<string>('message.required')),
    businessEndHour: yup.string().trim().required(i18n.t<string>('message.required')),
    name: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.company_name') })),
    admin: yup.object().shape({
        manager: yup
            .string()
            .trim()
            .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.manager') })),
        phone: yup
            .string()
            .trim()
            .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.phone_number') }))
            .min(10, i18n.t<string>('message.invalid_phone_number'))
            .matches(
                /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
                i18n.t<string>('message.invalid_format', { 0: i18n.t<string>('company_info.company.phone_number') }),
            ),
        affiliation: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.affiliation') })),
        emailAdmin: yup.string().trim().required(i18n.t<string>('message.required')),
    }),
    reservationEntryOffsetEnabled: yup.number().required(i18n.t<string>('message.required')),
    reservationEntryOffsetStart: yup.number().required(i18n.t<string>('message.required')),
    reservationEntryOffsetEnd: yup.number().required(i18n.t<string>('message.required')),
    reservationRequestLimitEnabled: yup.number().required(i18n.t<string>('message.required')),
    reservationRequestLimitNumber: yup.number().required(i18n.t<string>('message.required')),
    reservationTimeUnitEnabled: yup.number().required(i18n.t<string>('message.required')),
    reservationTimeUnitUnit: yup.number().required(i18n.t<string>('message.required')),
    reservationTimeZoneEnabled: yup.number().required(i18n.t<string>('message.required')),
    reservationTimeZoneStartHour: yup.string().trim().required(i18n.t<string>('message.required')),
    reservationTimeZoneEndHour: yup.string().trim().required(i18n.t<string>('message.required')),
    reservationSurveyRequired: yup.number().required(i18n.t<string>('message.required')),
    unlockApplication: yup.number().required(i18n.t<string>('message.required')),
});

export const companyInfoEditInitialValues: ICompanyInfoDataAPI = {
    address: '',
    affiliation: '',
    assetAmount: 0,
    assetPublication: 0,
    businessStartHour: '',
    businessEndHour: '',
    email: '',
    holidays: '',
    images: [],
    logo: '',
    manager: '',
    name: '',
    phone: '',
    phoneNumber:'',
    reservationEntryOffsetEnabled: 0,
    reservationEntryOffsetStart: 5,
    reservationEntryOffsetEnd: 5,
    reservationRequestLimitEnabled: 0,
    reservationRequestLimitNumber: 10,
    reservationSurveyRequired: 0,
    reservationTimeUnitEnabled: 0,
    reservationTimeUnitUnit: 10,
    reservationTimeZoneEnabled: 0,
    reservationTimeZoneEndHour: '',
    reservationTimeZoneStartHour: '',
    unlockApplication: 0,
    postalCode: '',
    prefecture: '',
    city: '',
    town: '',
    building: '',
    memo: '',
    admin: {
        affiliation: '',
        emailAdmin: '',
        manager: '',
        phone: ''
    }
};


export const companyAccountUpdateSchema = yup.object().shape({
    affiliation: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.affiliation') })),
    email: yup
        .string()
        .trim()
        .required(i18n.t<string>('resetPassword.errorEmail'))
        .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            i18n.t<string>('message.format_email', { 0: i18n.t<string>('company_info.company.email_address_of_person_in_charge') }),
        )
    ,
    manager: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.manager') })),
    name: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.company_name') })),
    phone: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.phone_number') }))
        .min(10, i18n.t<string>('message.invalid_phone_number'))
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            i18n.t<string>('message.invalid_phone_number', { 0: i18n.t<string>('company_info.company.phone_number') }),
        ),
});
