import { Common } from '@assets/styles';
import { Tooltip } from '@components/commons';
import ItemDashboard from '@components/pages/home/dashboard/ItemDashboard';
import { images } from '@utils/constants';
import { formatDate } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { BodyContainer } from '~/components/layouts';
import configs from '~/utils/configs';
import { getAllInfoUser } from '~/utils/helpers/api';
import styles from './Dashboard.module.scss';
import { TYPE_NOTIFICATION } from './NotificationDetail';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

const Dashboard: IDashboardPage<IDashboardPageProps> = () => {
    const navigate = useNavigate();
    const [dataDashboard, setDataDashboard] = useState<IResDataDashboard>();
    const { t } = useTranslation();
    const [userInfo, setUserInfo] = useState<IInfoUserDataAPI>();

    const demoListItem = [
        {
            id: 1,
            image: <images.IMAGES.ICON_INTERMEDIARY />,
            row: 1,
            info1: {
                count: `${dataDashboard?.countInfo?.totalBroker || 0}`,
                text: '仲介業者',
            },
        },
        {
            id: 2,
            image: <images.IMAGES.ICON_KEYBOX />,
            row: 1,
            info1: {
                count: `${dataDashboard?.countInfo.totalKeyBox || 0}`,
                text: 'キーボックス設置',
            },
        },
        {
            id: 3,
            image: <images.IMAGES.ICON_EMPLOYEE />,
            row: 1,
            info1: {
                count: `${dataDashboard?.countInfo.totalEmployee || 0}`,
                text: '自社社員',
            },
            info2: {
                count: `${dataDashboard?.countInfo.totalWorker || 0}`,
                text: '工事業者',
            },
        },
        {
            id: 4,
            image: <images.IMAGES.ICON_UNLOCK />,
            row: 1,
            info1: {
                count: `${dataDashboard?.countInfo.totalEmployeeAllowUnlock || 0}`,
                text: '<span>開錠のみ<br>自社社員</span',
            },
            info2: {
                count: `${dataDashboard?.countInfo.totalWorkerAllowUnlock || 0}`,
                text: '<span>開錠のみ<br>工事業者</span>',
            },
        },
        {
            id: 5,
            image: <images.IMAGES.ICON_UNLOCK_KEYBOX />,
            row: 2,
            info1: {
                count: `${dataDashboard?.countInfo.totalEmployeeAllowSetup || 0}`,
                text: '<span>開錠<br> キーボックス設置<br>自社社員</span>',
            },
            info2: {
                count: `${dataDashboard?.countInfo.totalWorkerAllowSetup || 0}`,
                text: '<span>開錠<br> キーボックス設置<br>工事業者</span>',
            },
        },
        {
            id: 6,
            image: <images.IMAGES.ICON_USERS />,
            row: 2,
            info1: {
                count: `${dataDashboard?.countInfo.totalCountUseToday || 0}`,
                text: '本日の利用者',
            },
        },
        {
            id: 7,
            image: <images.IMAGES.ICON_OVERALL />,
            row: 2,
            info1: {
                count: `${dataDashboard?.countInfo.totalKeyboxUsage || 0}`,
                text: '利用回総合',
            },
        },
    ];

    useEffect(() => {
        getRole();
    }, []);

    const getRole = async () => {
        try {
            const response = await getAllInfoUser();
            if (response.data.status === 'SUCCESS') {
                setUserInfo(response.data.data);
            }
        } catch (error) {
            setUserInfo(undefined);
            console.log(error);
        }
    };

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken') || '') : null;
        if (!accessToken) return;
        const socket = io(configs.appConfig.SOCKET_URL, {
            path: configs.appConfig.SOCKET_PATH,
            transports: ['websocket'],
            auth: {
                Authorization: `Bearer ${accessToken.access_token}`,
            },
        });

        socket.emit('channelJoin', accessToken.user_role !== 1 ? accessToken.company_id : 999999);
        socket.on('serverDashboardSummary', (data: IResDataDashboard) => {
            setDataDashboard(data);
        });

        return () => {
            socket.off('serverDashboardSummary');
        };
    }, []);

    const gotoDetail = (id: number, type: number) => {
        navigate({ pathname: configs.routes.notificationDashboard, search: `?type=${type}&id=${id}` });
    };

    const getTitleByType = (type: number, isReturnTitle?: boolean): string | ReactElement => {
        let title = '';
        switch (type) {
            case TYPE_NOTIFICATION.SYSTEM:
                title = t('dashboard.notification.detail_title.system');
                break;
            case TYPE_NOTIFICATION.REQUEST_DETAIL:
                title = t('dashboard.notification.detail_title.request_app_title');
                break;
            case TYPE_NOTIFICATION.OTP_CREATE:
                title = t('dashboard.notification.detail_title.create_opt');
                break;
            case TYPE_NOTIFICATION.OTP_DELETED:
                title = t('dashboard.notification.detail_title.delete_otp');
                break;
            case TYPE_NOTIFICATION.OTP_EDITED:
                title = t('dashboard.notification.detail_title.edit_otp');
                break;
            case TYPE_NOTIFICATION.APPROVE_DETAIL:
                // title = t('dashboard.notification.detail_title.request_approved');
                // break;
                if(isReturnTitle) {
                    title = t('dashboard.notification.detail_title.request')
                    return title
                }
                return (
                    <>
                        {t('dashboard.notification.detail_title.request_1')}
                        <span style={{ color: '#51a60e' }}>{t('dashboard.notification.detail_title.request_2')}</span>
                        {t('dashboard.notification.detail_title.request_3')}
                    </>
                );
            case TYPE_NOTIFICATION.REJECT_DETAIL:
                title = t('dashboard.notification.detail_title.request_rejected');
                break;
            default:
                break;
        }
        return title;
    };

    return (
        <BodyContainer title="title.dashboard">
            <div className="row">
                <div className={userInfo?.user_roleId === 1 ? 'col-12' : 'col-8'}>
                    {userInfo?.user_roleId === 1 && (
                        <div className="card-body row g-2">
                            <div className="col-6 ">
                                <div className={cx('information')}>
                                    <div className="col-6 d-flex justify-content-end">
                                        <images.IMAGES.ICON_BUILDING_GROUP_SVG />
                                    </div>
                                    <div className="d-flex flex-column text-white col-6">
                                        <span className={cx('number')}>{dataDashboard?.countInfo.totalCompany ?? 0}</span>
                                        <span className={cx('text')}>管理会社</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 ">
                                <div className={cx('information', 'sub-information')}>
                                    <div className="col-6 d-flex justify-content-end">
                                        <images.IMAGES.ICON_MIDDLEMAN_SVG />
                                    </div>
                                    <div className="d-flex flex-column text-white col-6">
                                        <span className={cx('number')}>{dataDashboard?.countInfo.totalBroker ?? 0}</span>
                                        <span className={cx('text')}>仲介業者</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="card-body row mt-2 g-2">
                        <div className="col-6 ">
                            <div className={cx('information')}>
                                <div className="col-6 d-flex justify-content-end">
                                    <images.IMAGES.ICON_HOUSE />
                                </div>
                                <div className="d-flex flex-column text-white col-6">
                                    <span className={cx('number')}>{dataDashboard?.countInfo.totalAsset || 0}</span>
                                    <span className={cx('text')}>物件</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 ">
                            <div className={cx('information', 'sub-information')}>
                                <images.IMAGES.ICON_DOOR />
                                <div className="d-flex flex-column text-white">
                                    <div className={cx('information-row', 'border-door-section')}>
                                        <span className={cx('number')}>{dataDashboard?.countInfo.totalForRentRoom || 0}</span>
                                        <span className={cx('text')}>賃貸中</span>
                                    </div>
                                    <div className={cx('information-row')}>
                                        <span className={cx('number')}>{dataDashboard?.countInfo.totalEmptyRoom || 0}</span>
                                        <span className={cx('text')}>空室</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {userInfo?.user_roleId === 1 && (
                        <div className="card-body mt-2 row g-2">
                            <div className="col-6 ">
                                <div className={cx('information')}>
                                    <div className="col-6 d-flex justify-content-end">
                                        <images.IMAGES.ICON_KEYS_SVG />
                                    </div>
                                    <div className="d-flex flex-column text-white col-6">
                                        <span className={cx('number')}>{dataDashboard?.countInfo.totalKeyBox ?? 0}</span>
                                        <span className={cx('text')}>キーボックス</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {userInfo?.user_roleId === 2 && (
                        <div className="card-body mt-3">
                            <div className={cx('card-row', 'row g-2')}>
                                {demoListItem &&
                                    demoListItem.map((item, index) => {
                                        return (
                                            <ItemDashboard
                                                key={index}
                                                row={item.row}
                                                image={item.image}
                                                info1={item.info1}
                                                index={index}
                                                info2={item.info2}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>

                {userInfo?.user_roleId === 2 && (
                    <div className="col-4">
                        <div className="card card-body bases__height--319">
                            <div className={cx('card-border')}>
                                <span className={cxCommon('title-text', 'font-size-16')}>{t('title.system_notice')}</span>
                            </div>
                            {!isEmpty(dataDashboard?.listNoti.listNotificationAdmin) &&
                                dataDashboard?.listNoti.listNotificationAdmin.map((item: IDataNotification, index: number) => (
                                    <div
                                        key={index}
                                        className={cx('list-notice', item.isRead ? '' : 'fw-bold text-black')}
                                        onClick={() => gotoDetail(item.id, 0)}>
                                        <img src={images.IMAGES.ICON_ARROW_RIGHT} />
                                        <Tooltip className={cx('item-notice')} content={item.subject}>
                                            <span>
                                                {formatDate(item.createdAt) ?? ''} {item.subject}
                                            </span>
                                        </Tooltip>
                                    </div>
                                ))}
                            <div className="mt-auto ms-2">
                                <NavLink
                                    state={{ type: 0 }}
                                    style={{ textDecoration: 'none', color: '#0376C9' }}
                                    to={{ pathname: configs.routes.notificationDashboard, search: '?type=0' }}
                                    className={isEmpty(dataDashboard?.listNoti.listNotificationAdmin) ? 'pe-none' : ''}>
                                    {t('dashboard.display_all_notifications')}
                                </NavLink>
                            </div>
                        </div>

                        <div className="card card-body mt-4 bases__height--319">
                            <div className={cx('card-border')}>
                                <span className={cxCommon('title-text', 'font-size-16')}>{t('title.user_notice')}</span>
                            </div>
                            {!isEmpty(dataDashboard?.listNoti.listNotificationUser) &&
                                dataDashboard?.listNoti.listNotificationUser.map((item: IDataNotification, index: number) => (
                                    <div
                                        key={index}
                                        className={cx('list-notice', item.isRead ? '' : 'fw-bold text-black')}
                                        onClick={() => gotoDetail(item.id, 1)}>
                                        <img src={images.IMAGES.ICON_ARROW_RIGHT} />
                                        <Tooltip className={cx('item-notice')} content={item.subject || getTitleByType(item.type, true)}>
                                            <span>
                                                {formatDate(item.startDate) ?? ''} {item.subject || getTitleByType(item.type)}
                                            </span>
                                        </Tooltip>
                                    </div>
                                ))}
                            <div className="mt-auto ms-2">
                                <NavLink
                                    state={{ type: 1 }}
                                    style={{ textDecoration: 'none', color: '#0376C9' }}
                                    to={{ pathname: configs.routes.notificationDashboard, search: '?type=1' }}
                                    className={isEmpty(dataDashboard?.listNoti.listNotificationUser) ? 'pe-none' : ''}>
                                    {t('dashboard.display_all_notifications')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </BodyContainer>
    );
};

export default Dashboard;
