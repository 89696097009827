import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Configs from '@utils/configs';
import { Button, FormInput } from '@components/commons';

import classNames from 'classnames/bind';
import styles from './CompanyAccountDetails.module.scss';
import tableStyles from '@assets/styles/base/Table.module.scss';
import commonStyles from '@assets/styles/base/Common.module.scss';
import { USER_ROLE_ENUM, images } from '@utils/constants';
import { BodyContainer } from '~/components/layouts';
import { useState, useEffect, useRef } from 'react';
import { IMAGES } from '@utils/constants/images';
import { deleteAccountCompany, getDetailAccountCompany } from '@utils/helpers/api';
import { handleGetFullAddress } from '@utils/helpers/utilities';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '@redux/actions';
import ModalConfirm from '@components/modals/ModalConfirm';
import { ReduxStates } from '@redux/reducers';
import { setShowDeleted } from '@redux/actions/CommonAction';
import useRole from '@utils/hook/useRole';
import { formatDateUTC } from '@utils/helpers/date';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);
interface FileCustom extends File {
    preview?: string;
}

const CompanyAccountDetails: IBaseComp<IBaseCompProps> = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>(undefined);
    const routeParams = useParams();
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageSlide, setImageSlide] = useState<string>('');
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [address, setAddress] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
    });
    const { showFirstModal } = modalState;
    const { showDeleted, userInfo } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);

    useEffect(() => {
        if (listImageSlide?.length) {
            const imageSlide: IImageSlide[] = listImageSlide.filter((image) => image.order === activeIndex);
            setImageSlide(imageSlide[0]?.url);
        }
    }, [activeIndex]);

    useEffect(() => {
        handleAccountCompanyDetail();
    }, [routeParams.id, routeParams.adminId]);

    useEffect(() => {
        const addressParams: IEnterAddressForm = {
            postalCode: data?.postalCode ?? '',
            prefecture: data?.prefecture ?? '',
            city: data?.city ?? '',
            town: data?.town ?? '',
            building: data?.building ?? '',
        };

        setAddress(handleGetFullAddress(addressParams));

        if (data?.images?.length) {
            setListImageSlide(data?.images ?? []);
            setImageSlide(data.images[0]?.url);
        }
    }, [data]);

    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            navigate(Configs.routes.company);
        }
    }, [showDeleted]);

    const handleAccountCompanyDetail = async () => {
        try {
            const { data } = await getDetailAccountCompany(routeParams.id ?? '', routeParams.adminId ?? '');
            setData(data.data);
        } catch (err) {
            throw new Error('Error res');
        }
    }

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    const handleFetchDeletecompanyAccount = () => {
        deleteAccountCompany(routeParams.id ?? 0, routeParams.adminId ?? 0)
            .then(() => {
                navigate(Configs.routes.company);
                dispatch(setToast({
                    isShow: true,
                    message: t<string>('common.has_deleted')
                }));
            })
            .catch((err) => {
                console.log(err);

            });
    }

    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };

    return (
        <>
            {isSuperAdminPermission &&
                <BodyContainer title={t<string>('management_company_account.page_detail.title')}>
                    <div className={cx('container-detail')}>
                        <div className="row mt-4 d-flex justify-content-center">
                            <div className="col-10">
                                <section className={'mt-0 d-block ' + cx('content')}>
                                    <div className="row">
                                        <div className="col">
                                            <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                                                <img
                                                    className={'w-100 ' + cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                                    src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className={cx('slide', 'd-flex justify-content-center align-items-center')}>
                                                <div className={cx('slide_container')}>

                                                    <div ref={slideRef} className={cx('slide_list')}>
                                                        {listImageSlide.map((image, index) => {
                                                            return (
                                                                <div
                                                                    key={image.order}
                                                                    className={
                                                                        cx('slide_img_edit') + ' ' + cx(activeIndex === image.order ? 'slide_img_edit--active' : '')
                                                                    }>
                                                                    <img
                                                                        ref={imageRef}
                                                                        src={image.url}
                                                                        alt=""
                                                                        onClick={() => handleClick(image.order)}
                                                                        className={cx('img_edit')}
                                                                    />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 mt-3 mb-3 card-header d-flex align-items-center">
                                        <span className={cxCommon('fw-bold', 'label-color', 'font-size-16')}>
                                            {t('company_info.company.basic_information')}
                                        </span>
                                    </div>

                                    <section className={'d-block ' + cx('content')}>
                                        <div className="d-flex">
                                            <div className={`${cx('logo-container')} ${(data?.company_logo && data?.company_logo !== '') ? "" : cx('background-none-img')}`}>
                                                <img className={(data?.company_logo && data?.company_logo !== '') ? cx('side-image') : cx('none-logo')} src={(data?.company_logo && data?.company_logo !== '') ? data?.company_logo : images.IMAGES.TEMP_IMAGE} />
                                            </div>
                                            <div className="w-100 bases__margin--left30">
                                                <div className={'d-flex ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        会社名:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.company_name}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        担当者:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.manager}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        所属:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.affiliation}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        会社住所:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {address}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2  ">
                                                        電話番号:
                                                    </div>
                                                    <div className="pe-0 col fw-bold">
                                                        {data?.phone}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        会社メールアドレス (ログインID):
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.email_admin}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        物件管理数:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.amount_asset}
                                                    </div>
                                                </div>

                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        使用状況: 
                                                    </div>
                                                    <div className="pe-0 col fw-bold">
                                                        <span
                                                            className={
                                                                data?.accountStatus === 0
                                                                    ? cxCommon('text-blue')
                                                                    : data?.accountStatus === 1
                                                                    ? cxCommon('text-green')
                                                                    : data?.accountStatus === 2
                                                                    ? cxCommon('text-red')
                                                                    : ''
                                                            }>
                                                            {data?.accountStatus === 0 ? '認証中' : data?.accountStatus === 1 ? '使用中' : data?.accountStatus === 2 ? '停止' : ''}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    <FormInput classNameTextArea={cx('text-area', 'bases__padding--top10')} disabled={true} styleBlock rows={3} label='メモ' textArea type="text" value={data?.memo} />
                                                </div>
                                                <div className={'d-flex mt-3 ' + cx('title')}>
                                                    <div className="h-auto me-2">
                                                        登録日時:
                                                    </div>
                                                    <div className="pe-0 col fw-bold  ">
                                                        {data?.created_at ? formatDateUTC(data.created_at ,'YYYY/MM/DD HH:mm') : ''}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                </section>
                                <div className="d-flex justify-content-center gap-3 mt-xxl-5 mt-4">
                                    <NavLink className={'px-0 text-decoration-none bases__width--170'} to={`${Configs.routes.company}/${routeParams.id}/edit/${routeParams.adminId}`}>
                                        <Button text={t<string>('common.edit')} search></Button>
                                    </NavLink>
                                    <div className={cxCommon('width-170')}>
                                        <Button
                                            className={cxCommon('text-red')}
                                            type="button"
                                            onClick={() => showOrHideModal('showFirstModal')}
                                            text={t<string>('common.remove')}
                                            del></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showFirstModal && (
                            <ModalConfirm
                                isShow={showFirstModal}
                                handleOK={handleFetchDeletecompanyAccount}
                                handleClose={() => showOrHideModal('showFirstModal')}
                                header={'削除'}
                                content={t<string>('common.remove_data')}></ModalConfirm>
                        )
                    }
                </BodyContainer >}
        </>

    );
};

export default CompanyAccountDetails;
