import { createRef, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import './_table.scss';
import Choice from '@components/commons/Choice/Choice';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import Tooltip from '@components/commons/Tooltip/Tooltip';
import TablePagination from './TablePagination';
import { useTranslation } from 'react-i18next';
import { images } from '@utils/constants';
import classNames from 'classnames/bind';
import commonStyles from '@assets/styles/base/Common.module.scss';
import { isEmpty } from 'lodash';

const cxCommon = classNames.bind(commonStyles);
const Table = forwardRef<ITableComponentHandle, ITableComponentProps>((props, ref) => {
    const { t } = useTranslation();
    const {
        className,
        classNameWrapper,
        classNameTable,
        classNameBody,
        classNameTr,
        classNameTh,
        classNameTd,
        heads,
        body,
        onChangeCheckList,
        isStickyColumn,
        type,
        pageData,
        total,
        onChangePage,
        isHidePagination,
        initialCheckedValue,
        styleTable,
        isKeyBox,
        isSuperAdmin
    } = props;
    const [state, setState] = useState<ITableComponentState>({
        checkedValue: [],
        isScrollRightEnd: true,
        page: 1,
    });
    const { checkedValue, isScrollRightEnd } = state;
    const tableWrapperRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (!isEmpty(initialCheckedValue)) {
            setState((prevState) => ({
                ...prevState,
                checkedValue: initialCheckedValue,
            }));
            if (onChangeCheckList) {
                onChangeCheckList(initialCheckedValue || []);
            }
        }
    }, [initialCheckedValue]);

    const isChecked = useMemo(() => {
        const isAll = body?.rows?.every((item) => {
            return checkedValue.includes(item.id?.toString());
        });

        if (isAll && !checkedValue.includes('all')) {
            const newCheckedValue = [...checkedValue];
            newCheckedValue.push('all');
            setState((prevState) => ({
                ...prevState,
                checkedValue: newCheckedValue,
            }));
        }
        if (!isAll && checkedValue.includes('all')) {
            const newCheckedValue = [...checkedValue];
            const allCheckIndex = checkedValue.indexOf('all');
            if (allCheckIndex >= 0) {
                newCheckedValue?.splice(allCheckIndex, 1);
            }
            setState((prevState) => ({
                ...prevState,
                checkedValue: newCheckedValue,
            }));
        }

        return body?.rows?.some((item) => {
            return checkedValue.includes(item.id?.toString());
        });
    }, [body?.rows, state]);

    useImperativeHandle(ref, () => ({
        onClearCheckedList: () => {
            if (onChangeCheckList) {
                onChangeCheckList([]);
            }
            setState((prevState) => ({
                ...prevState,
                checkedValue: [],
            }));
        },
    }));

    const handleCheckList = (checked: string[], isHead: boolean) => {
        let newCheckedValue: string[] = checkedValue ?? [];
        const listIdBody = body?.rows?.map((item) => String(item.id));

        if (isHead) {
            if (checked.includes('all')) {
                if (!newCheckedValue?.includes('all')) {
                    newCheckedValue?.push('all');
                }
                body?.rows?.forEach((itemRow) => {
                    body?.columns?.forEach((itemColumn) => {
                        if (itemColumn.isCheckbox) {
                            const item = itemRow[itemColumn?.field ?? ''][0].data[0];
                            newCheckedValue.push(item?.value ?? '');
                        }
                    });
                });
            } else {
                // newCheckedValue.length = 0;
                newCheckedValue = newCheckedValue.filter((item) => !listIdBody?.includes(item));
            }
        } else {
            const checkedValue = checked[0] ?? '';
            if (checkedValue !== '' && !newCheckedValue.includes(checkedValue)) {
                newCheckedValue?.push(checkedValue);
            }

            const currentCheckedList: string[] = [];
            body?.rows?.forEach((itemRow) => {
                const itemColumn = body?.columns?.find((itemColumn) => itemColumn.isCheckbox);
                const item = itemRow[itemColumn?.field ?? ''][0].data[0];
                if (newCheckedValue.includes(item.value)) {
                    currentCheckedList.push(item.value);
                }
            });

            const allCheckIndex = checked.indexOf('all');
            if (currentCheckedList.length === (body?.rows?.length ?? 0)) {
                if (allCheckIndex < 0) {
                    newCheckedValue?.push('all');
                }
            } else {
                if (allCheckIndex >= 0) {
                    newCheckedValue?.splice(allCheckIndex, 1);
                }
            }
        }

        if (onChangeCheckList) {
            onChangeCheckList(newCheckedValue.filter((value) => value !== 'all'));
        }

        setState((prevState) => ({
            ...prevState,
            checkedValue: newCheckedValue,
        }));
    };

    const renderCheckedAll = () => {
        let countCheckedItem = 0;
        body?.rows?.forEach((itemRow) => {
            const itemColumn = body?.columns?.find((itemColumn) => itemColumn.isCheckbox);
            if (itemColumn) {
                const item = itemRow[itemColumn?.field ?? ''][0].data[0];
                if (checkedValue?.includes(item.value ?? '')) {
                    countCheckedItem++;
                }
            }
        });

        if ((body?.rows?.length ?? 0) > 0 && countCheckedItem === (body?.rows?.length ?? 0)) {
            return ['all'];
        }

        return [];
    };

    const handleScrollHorizontal = () => {
        if (isStickyColumn) {
            const tableWidth = tableWrapperRef.current?.clientWidth ?? 0;
            const tableScrollWidth = tableWrapperRef.current?.scrollWidth ?? 0;
            const scrollLeft = tableWrapperRef.current?.scrollLeft ?? 0;
            setState((prevState) => ({
                ...prevState,
                isScrollRightEnd: tableScrollWidth - scrollLeft === tableWidth,
            }));
        }
    };

    const handleChanePage = (page: number) => {
        setState((prevState) => ({
            ...prevState,
            page,
        }));
        if (onChangePage) {
            onChangePage(page);
        }
    };

    const renderHeads = () => {
        return (
            <tr className={classNameTr}>
                {heads?.map((head, index) => {
                    if (Object.keys(head).length === 0) {
                        return;
                    }
                    return (
                        <th
                            key={`${index}-${head?.key}`}
                            className={`
                                bases__text--bold bases__font--14 
                                ${classNameTh} 
                                ${head?.className ?? ''} 
                                ${head.isSort ? 'bases__p--cursor' : ''}
                                ${head?.isCheckbox ? 'components__table-sticky-checkbox' : ''}
                            `}
                            onClick={() => head && head.onClick && head.onClick()}>
                            {head.isCheckbox ? (
                                <div className="checkbox-parent d-flex align-items-center justify-content-center">
                                    {!checkedValue?.includes('all') && isChecked ? (
                                        <img
                                            onClick={() => handleCheckList([], true)}
                                            className={'icon-remove'}
                                            src={images.IMAGES.ICON_REMOVE_CHECKED}
                                        />
                                    ) : (
                                        <Choice
                                            disabled={head?.isDisable}
                                            type="checkbox"
                                            checked={renderCheckedAll()}
                                            data={(head?.dataCheckbox ?? [])[0]?.data}
                                            className="d-flex justify-content-center"
                                            onChange={(value: string[]) => handleCheckList(value, true)}
                                        />
                                    )}
                                </div>
                            ) : (
                                <>
                                    <span className={head.isSort ? 'components__table-sorted' : ''}>{head?.title}</span>
                                </>
                            )}
                        </th>
                    );
                })}
            </tr>
        );
    };

    const renderRowValue = (itemColumn: ITableBodyColumnItem, itemRow: any) => {
        let rowValue = null;
        const fields = itemColumn?.field?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }
        return rowValue;
    };

    const renderRowRawValue = (itemColumn: ITableBodyColumnItem, itemRow: any) => {
        let rowValue = null;
        const fields = itemColumn?.rawField?.split('.') ?? [];

        for (const field of fields) {
            rowValue = !rowValue ? itemRow[field ?? ''] : rowValue[field];
        }
        return rowValue;
    };

    const renderRows = () => {
        if (body?.rows && body?.rows?.length > 0) {
            return (
                <tbody className={`${classNameBody ?? ''} components__table-body`}>
                    {body?.rows?.map((itemRow, indexRow) => (
                        <tr key={indexRow} className={classNameTr} style={itemRow._style}>
                            {body?.columns?.map((itemColumn, indexColumn) => {
                                if (Object.keys(itemColumn).length === 0) {
                                    return;
                                }

                                return (
                                    <td
                                        key={indexColumn}
                                        className={`
                                            ${classNameTd} 
                                            ${itemColumn.isButton ? 'components__table-btn_body' : ''} 
                                            ${itemColumn?.className ?? ''}
                                            ${itemColumn?.isCheckbox ? 'components__table-sticky-checkbox' : ''}
                                        `}
                                        style={itemColumn._styleTd ? itemColumn._styleTd : { maxWidth: '200px' }}>
                                        <div>
                                            {itemColumn.isCheckbox ? (
                                                itemRow[itemColumn?.field ?? ''] && (
                                                    <Choice
                                                        disabled={itemColumn.isDisable}
                                                        type="checkbox"
                                                        checked={itemRow[itemColumn?.field ?? ''][0].checked ?? checkedValue}
                                                        data={itemRow[itemColumn?.field ?? ''][0].data}
                                                        className="text-center"
                                                        onChange={(value: string[]) => handleCheckList(value, false)}
                                                    />
                                                )
                                            ) : itemColumn.isRadio ? (
                                                itemRow[itemColumn?.field ?? ''] && (
                                                    <Choice
                                                        type="radio"
                                                        checked={itemRow[itemColumn?.field ?? ''][0].checked}
                                                        data={itemRow[itemColumn?.field ?? ''][0].data}
                                                        className="text-center"
                                                    />
                                                )
                                            ) : itemColumn.isDatepicker ? (
                                                <Datepicker
                                                    value={itemRow[itemColumn?.field ?? ''][0].value}
                                                    showTimeInput={true}
                                                    width={itemRow[itemColumn?.field ?? ''][0].width ?? 163}
                                                    height={itemRow[itemColumn?.field ?? ''][0].height ?? 32}
                                                    onChange={itemRow[itemColumn?.field ?? ''][0].onChange}
                                                />
                                            ) : itemColumn.isNotTooltip ? (
                                                renderRowValue(itemColumn, itemRow) ? (
                                                    <div
                                                        className={
                                                            itemColumn?.isLink
                                                                ? 'components__table-link td-span'
                                                                : itemColumn.className
                                                                ? `${itemColumn.className} td-span`
                                                                : ''
                                                        }>
                                                        {renderRowValue(itemColumn, itemRow)}
                                                    </div>
                                                ) : (
                                                    <span className={itemColumn?.isLink ? 'components__table-link' : ''}>-</span>
                                                )
                                            ) : (
                                                <div className="td-span">
                                                    <Tooltip
                                                        className="gl-tooltip"
                                                        content={
                                                            itemColumn.rawField
                                                                ? renderRowRawValue(itemColumn, itemRow)
                                                                : renderRowValue(itemColumn, itemRow)
                                                                ? renderRowValue(itemColumn, itemRow)
                                                                : '-'
                                                        }>
                                                        {renderRowValue(itemColumn, itemRow) ? renderRowValue(itemColumn, itemRow) : '-'}
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            );
        }
    };

    return (
        <div className={`components__table ${className ?? ''} components__table--${type}`}>
            <div
                ref={tableWrapperRef}
                style={styleTable}
                className={`components__table-wrapper ${classNameWrapper ?? ''} ${(pageData?.totalRecords ?? 0) > 0 ? '' : 'border-0'}`}
                onScroll={() => handleScrollHorizontal()}>
                {(pageData?.totalRecords ?? 0) > 0 ? (
                    <>
                        <table
                            className={`
                                components__table-border 
                                ${isStickyColumn ? 'components__table-sticky' : ''} 
                                ${isStickyColumn && !isScrollRightEnd ? 'components__table-sticky-border' : ''} 
                                ${classNameTable}`}>
                            <thead>{renderHeads()}</thead>
                            {renderRows()}
                        </table>

                    </>
                ) : (
                    <div className={`d-flex justify-content-center ${cxCommon('font-size-16')} components__table-no-data`}>
                        {t('common.no_data')}
                    </div>
                )}
            </div>
            { isKeyBox &&
                !isSuperAdmin && (
                    <div className={'keybox_text'}>※ 未契約中でも解約月の当月はキーボックスが利用可能です。</div>
                )}
            {(pageData?.totalRecords ?? 0) > 0 && !isHidePagination && (
                <div className="mt-4">
                    <TablePagination
                        current={pageData?.currentPage}
                        onPageChange={(page: number) => handleChanePage(page)}
                        className="bases__margin--top24"
                        totalPage={Math.ceil((total ?? 0) / (pageData?.limit ?? 30))}
                        totalRecords={pageData?.totalRecords}
                        pageRecords={pageData?.pageRecords}
                        firstRecords={pageData?.firstRecords}
                    />
                </div>
            )}
        </div>
    );
});

Table.defaultProps = {
    className: '',
    classNameTable: '',
    classNameTr: '',
    classNameTh: '',
    classNameThIcon: '',
    classNameTd: '',
    isStickyColumn: false,
    type: 'vertical',
    pageData: {
        firstRecords: 1,
        currentPage: 1,
        totalPage: 1,
        totalRecords: 1,
        pageRecords: 1,
    },
};

Table.displayName = 'Table';

export default Table;
