import * as Types from '@redux/actions/type';

const initialState = {
    loading: false,
    data: {}
};

export default function ChangePasswordReducer(state = initialState, action: any) {
    switch (action.type) {
        case Types.CHANGE_PASSWORD_ACTION:
            return {
                ...state,
                loading: true,
            };
        case Types.CHANGE_PASSWORD_SUCCESS_ACTION:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
