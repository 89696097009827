import { useState, useEffect, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, FormInput } from '@components/commons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames/bind';

import styles from './Login.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';

import { LoginForm } from '@components/forms';
import Configs from '@utils/configs';
import { useAuth } from '~/utils/hook/useAuth';
import { images } from '~/utils/constants';
import { useFormik } from 'formik';
import { RULE } from '~/utils/constants/regex';
import { useDispatch } from 'react-redux';
import { showAlert } from '@redux/actions';

const cx = classNames.bind(styles);
const common = classNames.bind(stylesCommon);

function Login() {
    const auth = useAuth();
    const dispatch = useDispatch();
    const [state, setState] = useState<ILoginPageState>({
        username: '',
        password: '',
        error: false,
        errorEmail: '',
        errorPassword: '',
        notiError: false,
    });

    const { username, password, errorEmail, errorPassword, notiError } = state;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const passwordInputRef = createRef<IValidatorPasswordSubmit>()

    useEffect(() => {
        auth.signInWithToken((response, err) => {
            if (response != null && !err) {
                navigate(Configs.routes.dashboard);
            }
        });
    }, []);

    const handleChangeData = (field: string, value: string | number | boolean) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));

    };

    const validation = () => {
        passwordInputRef?.current?.checkJapanese('')
        setState((prevState) => ({
            ...prevState,
            notiError: false,
        }));
        const isValidEmail = validateEmail(username);
        const isValidPassword = validatePassword(password);
        return !isValidEmail || !isValidPassword ? false : true;
    };

    const validateEmail = (username: string) => {
        if (username === '') {
            setState((prevState) => ({
                ...prevState,
                errorEmail: t<string>('login.errorEmail'),
            }));
            return false;
        } else {
            setState((prevState) => ({
                ...prevState,
                errorEmail: '',
            }));
            return true;
        }
    };

    const validatePassword = (password: string) => {
        if (password === '') {
            setState((prevState) => ({
                ...prevState,
                errorPassword: t<string>('login.errorPassword'),
            }));
            return false;
        } else {
            setState((prevState) => ({
                ...prevState,
                errorPassword: '',
            }));
            return true;
        }
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);
            if (validation()) {
                const reJP = new RegExp(RULE.IS_JP);
                const reEmoji = new RegExp(RULE.IS_EMOJI);
                const rePw = new RegExp(RULE.PASSWORD)
                if (!reJP.test(password) && !reEmoji.test(password) && rePw.test(password)) {
                    auth.signInWithEmail(username, password, (res, err) => {
                        if (err && err.message === 'USER_SUSPENDING') {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: <span>{t('common.locked_account')}</span>,
                                    hiddenCancel: true
                                }),
                            );
                        } else if (err) {
                            setState((prevState) => ({
                                ...prevState,
                                notiError: true,
                            }));
                            handleChangeData('password', '')
                        } else {
                            navigate(Configs.routes.dashboard);
                            setState((prevState) => ({
                                ...prevState,
                                notiError: false,
                            }));
                        }
                    });
                } else {
                    passwordInputRef?.current?.checkJapanese(t('message.login_incorrect'))
                    handleChangeData('password', '')
                }

            }
        },
    });

    return (
        <LoginForm>
            <div className={cx('login')}>
                <div className={cx('login-width')}>
                    <div className={cx('login-wrap')}>
                        <images.IMAGES.LOGO_BENRI className='bases__margin--bottom10'/>
                        <h4 className={cx('label-login')}>{t('login.label_login')}</h4>
                        <div className={cx('login-form')}>
                            <div className="form-group">
                                <form className={cx('form')} onSubmit={formik.handleSubmit}>
                                    <FormInput
                                        checkError={notiError}
                                        styleBlock
                                        label="login.account"
                                        error={errorEmail}
                                        maxLength={255}
                                        classNameInput="fz-14"
                                        className="w-100 mb-1"
                                        type="text"
                                        id="username"
                                        autoComplete="off"
                                        onChangeText={(text) => {
                                            return handleChangeData('username', text);
                                        }}
                                        value={username}
                                        placeholder={t<string>('login.placeholder_acc')}
                                    />
                                    <FormInput
                                        checkError={notiError}
                                        styleBlock
                                        label="login.password"
                                        error={errorPassword}
                                        maxLength={255}
                                        classNameInput="fz-14"
                                        className={cx('form-input')}
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChangeText={(text) => {
                                            handleChangeData('password', text);
                                        }}
                                        placeholder="login.placeholder_pwd"
                                        inputRef={passwordInputRef}
                                    />
                                    {notiError && (
                                        <div className={cx('error-msg')}>
                                            <div className={cx('text-overflow')}>
                                                <img src={images.IMAGES.ICON_ERROR} className={cx('img-error')}></img>
                                                {t('login.notiError')}
                                            </div>
                                        </div>
                                    )}
                                    <div className={cx('error-msg')}>
                                        <div className={cx('text-overflow')}>{notiError}</div>
                                    </div>
                                    <div className={cx('form-group-login')}>
                                        <Button login text={t<string>('login.login')} type="submit" />
                                    </div>

                                    <div className={common('justifyStart', 'text-start')}>
                                        <Button
                                            text={t<string>('login.forgot_pwd')}
                                            className={cx('forgot')}
                                            href={Configs.routes.resetPassword}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoginForm>
    );
}

export default Login;
