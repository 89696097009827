
import styles from './BodyContainer.module.scss';
import classNames from 'classnames/bind';
import { t } from 'i18next';

const cx = classNames.bind(styles);

interface IBodyContainerProp extends IBaseCompProps {
    title: string;
    titleImage?: string;
    classNameBody?: string;
}

const BodyContainer: IBaseComp<IBodyContainerProp> = ({
    children,
    title,
    titleImage,
    classNameBody,
}) => {
    return (
        <div className={cx('container')}>
            <div className={cx('header')}>
                {titleImage && <img src={titleImage} className={cx('header-image')} />}
                <span className={cx('header-text')}>{t(title)}</span>
            </div>
            <div className={cx('content') + ` ${classNameBody}`}>
                {children}
            </div>
        </div>
    );
};

export default BodyContainer;
