import ModalInterface from '@components/commons/Modal';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '~/redux/actions';
import { ReduxStates } from '~/redux/reducers';
import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const Alert: IBaseComp = () => {
    const dispatch = useDispatch();
    const { alert } = useSelector((states: ReduxStates) => states);

    const handleOK = useCallback(() => {
        alert.handleOK && alert.handleOK();
        dispatch(hideAlert());
    }, [alert.handleOK]);

    const handleClose = useCallback(() => {
        alert.handleCancel && alert.handleCancel();
        dispatch(hideAlert());
    }, [alert.handleCancel]);

    const renderContent = useMemo(() => {
        if (typeof alert.content === 'string') {
            return t(alert.content || '');
        }

        return alert.content;
    }, [alert.content]);

    return (
        <>
            {alert.isOverlay && (<div className={cx('opacity-modal')}></div>)}
            <ModalInterface
                hideNegativeButtonClose={alert.hideNegativeButtonClose}
                dialogClassname={cx('modal-delete-width')}
                isShow={alert.isShow}
                modalHeader={alert.header ?? 'common.confirm'}
                handleClose={handleClose}
                negativeButtonText={alert.titleCancel ?? 'common.cancel'}
                hideNegativeButton={alert.hiddenCancel}
                handleOK={handleOK}
                classNameCancel={alert.classNameCancel}
                positiveButtonText={alert.titleOK ?? 'common.ok'}
                hidePositiveButton={alert.hiddenOK}>
                <div className={'col text-center'}>
                    <span className={cx('text-content')}>{renderContent}</span>
                </div>
            </ModalInterface>
        </>
    );
};

export default Alert;
