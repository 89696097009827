import { useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';

import classNames from 'classnames/bind';
import styles from './Loader.module.scss';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

const Loader: ILoaderComponent<ILoaderComponentProps> = () => {
    const { loader } = useSelector((states: ReduxStates) => states);

    useEffect(() => {
        if (loader) {
            document.body.classList.add('no_scroll');
        } else {
            document.body.classList.remove('no_scroll');
        }
    }, [loader]);

    if (loader) {
        return (
            <div className={cx('loading-wrapper')}>
                {/* <FontAwesomeIcon className={cx('loading')} icon={faSpinner as IconProp} /> */}
                <div className={cx('loader')}></div>
            </div>
        );
    }

    return <></>;
};

export default Loader;
