export const stringToListNumber = (values: string): number[] => {
    let result: number[] = [];

    if (!values) return result;

    result = values.split(/ *, */).map((value) => Number(value));
    return result;
};

export const handleGetFullAddress = (values: IEnterAddressForm): string => {
    if (!values.postalCode) return '';
    const address = `〒${values.postalCode}${values.prefecture}${values.city}${values.town}${values.building || ''}`;

    return address;
};

export const formatPostalCode = (postalCode: string): string => {
    if (!postalCode) return '';

    const newPostalCode = postalCode.slice(0, 3) + '-' + postalCode.slice(3);

    return newPostalCode;
};

export const trimedValues = (values: any) => {
    Object.keys(values).forEach((key: string) => {
        if (typeof values[key] === 'string') {
            values[key] = values[key].trim();
        }
    });

    return values;
};



export const convertEmojiText = (content: any) => {
    const emojiRe = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const emojis = content?.match(emojiRe);
    const strings = content?.split(emojiRe);

    let res = '';
    if (emojis?.length) {
        strings?.map((v: any, i: number) => {
            let temp = '';
            if (emojis[i]) {
                temp = emojiUnicode(emojis[i]);
            }
            return (res += v + temp);
        });
        return res;
    }
    return content;
};

const emojiUnicode = (emoji: string) => {
    let comp;
    if (emoji.length === 1) {
        comp = emoji.charCodeAt(0);
    }
    comp =
        (emoji.charCodeAt(0) - 0xd800) * 0x400 +
        (emoji.charCodeAt(1) - 0xdc00) +
        0x10000;
    if (comp < 0) {
        comp = emoji.charCodeAt(0);
    }
    return isNaN(comp) ? emoji : comp.toString(16)?.trim();
};


export const convertToHalfSize = (values: string): string => {
    let halfSizeString = '';
    for (let i = 0; i < values.length; i++) {
        const charCode = values.charCodeAt(i);
        if (charCode === 12288) {
            halfSizeString += String.fromCharCode(32);
        } else if (charCode >= 65281 && charCode <= 65374) {
            halfSizeString += String.fromCharCode(charCode - 65248);
        } else {
            halfSizeString += values.charAt(i);
        }
    }

    return halfSizeString;
};

export const removeSpecialCharacter = (values: string): string => {
    return values.replace(/[^\u3040-\u309F\u30A0-\u30FFa-zA-Z0-9 ]/g, '');
};

export const blockEmojiText = (values: any) => {
    const emojiRe = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return values.replace(emojiRe, '');
};

export const formatMoney = (money: number | null | undefined | string): string => {
    if (!money) return '';

    if (typeof money === 'string') return Number(money).toLocaleString('de-DE');
    return money.toLocaleString('de-DE');
}