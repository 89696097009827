import classNames from 'classnames/bind';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import styles from './PaymentManagement.module.scss';
import { formatMoney } from '@utils/helpers/utilities';

const cx = classNames.bind(styles);

const PaymentCard: IPaymentCardComponent<IPaymentCardComponentProps> = (props) => {
    const { title, subTitle, value, type, isMaxWidth } = props;

    const valueShow = useMemo(() => {
        if (isNumber(value)) return formatMoney(value);
        return value;
    }, [value]);

    return (
        <div
            className={cx(
                'card__container',
                type === 'second' ? 'border__green' : type === 'danger' ? 'border__red' : '',
                isMaxWidth ? 'card__max' : '',
            )}>
            <div className={cx('card__title')}>
                <span className={cx('title__parent')}>{title}</span>
                <span className={cx('title__child')}>{subTitle}</span>
            </div>
            <div className={cx('card__value')}>{valueShow || '-'}</div>
        </div>
    );
};

export default PaymentCard;
