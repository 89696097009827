import * as Types from '@redux/actions/type';

interface IStaffs {
    offset?: number;
    limit?: number;
    created_from?: string;
    created_to?: string;
    username?: string;
    name?: string;
    phone?: string;
    role?: string;
    account_status?: string;
    permission?: string;
}

interface IListStaffs {
    listStaffs: Array<IStaffs>;
}

const defaultState: IListStaffs = {
    listStaffs: [],
};

export default function StaffsReducer(state = defaultState, action: any) {
    switch (action.type) {
        case Types.GET_LIST_STAFFS:
            return {
                ...state,
                listStaffs: action.payload,
            };
        default:
            return state;
    }
}
