import { IAlertReduxAction, IAlertReduxData, ICheckPermisstionReduxAction } from '~/interfaces/redux/alert';
import * as Types from '@redux/actions/type';

const loaderReducer = (state = false, action: ILoaderReduxAction) => {
    switch (action.type) {
        case Types.SET_LOADER:
            return action.data;
        default:
            return state;
    }
};
const modalReducer = (state = false, action: IModalReduxAction) => {
    switch (action.type) {
        case Types.SET_MODAL:
            return action.data;
        default:
            return state;
    }
};

const sidebarReducer = (state = true, action: ISidebarReduxAction) => {
    switch (action.type) {
        case Types.SET_SIDEBAR:
            return action.data;
        default:
            return state;
    }
};

const confirmDeleteReducer = (state: IConfirmDeleteReduxData = { isShow: false }, action: IConfirmDeleteAction) => {
    switch (action.type) {
        case Types.SET_SHOW_CONFIRM_DELETE:
            action.payload.isShow = true;
            return action.payload;
        case Types.SET_HIDE_CONFIRM_DELETE:
            return { isShow: false };
        default:
            return state;
    }
}

const alertReducer = (state: IAlertReduxData = { isShow: false }, action: IAlertReduxAction) => {
    switch (action.type) {
        case Types.SET_SHOW_ALERT:
            action.payload.isShow = true;
            return action.payload;
        case Types.SET_HIDE_ALERT:
            return { isShow: false };
        default:
            return state;
    }
}

const userInfoReducer = (state: IInfoUserDataAPI = {company_name: ''}, action: IInfoUserReduxAction) => {
    switch (action.type) {
        case Types.SET_USER_INFO:
            return action.payload;
        default:
            return state;
    }
}

const checkErrorReducer = (state = false, action: ICheckErrorReduxAction) => {
    switch (action.type) {
        case Types.CHECK_ERROR:
            return action.data;
        default:
            return state;
    }
}

const checkTokenReducer = (state = false, action: ICheckTokenReduxAction) => {
    switch (action.type) {
        case Types.ACCESS_TOKEN_EXPIRED:
            return action.data;
        default:
            return state;
    }
}

const checkAccountExist = (state = false, action: ICheckAccountExist) => {
    switch (action.type) {
        case Types.ACCOUNT_HAS_BEEN_DELETED:
            return action.data;
        default:
            return state;
    }
}

const checkActionAccountExist = (state = false, action: ICheckActionAccountExist) => {
    switch (action.type) {
        case Types.ACCTION_HAS_BEEN_DELETED:
            return action.data;
        default:
            return state;
    }
}

const checkNotiTokenReducer = (state = false, action: ICheckTokenReduxAction) => {
    switch (action.type) {
        case Types.SHOW_POPUP_ACCESS_TOKEN_EXPIRED:
            return action.data;
        default:
            return state;
    }
}

const showNotFoundReducer = (state = false, action: IShowNotFoundReduxAction) => {
    switch (action.type) {
        case Types.SHOW_NOT_FOUND:
            return action.data;
        default:
            return state;
    }
};

const SuccessCallAPI = (state = false, action: IShowNotFoundReduxAction) => {
    switch (action.type) {
        case Types.SET_SUCCESS_CALL_API:
            return action.data;
        default:
            return state;
    }
};

const checkAccountNotPermisstion = (state = false, action: ICheckPermisstionReduxAction) => {
    switch (action.type) {
        case Types.SET_ACCUONT_NOT_PERMISSION:
            return action.data;
        default:
            return state;
    }
}

const showDeletedReducer = (state = false, action: IShowDeletedReduxAction) => {
    switch (action.type) {
        case Types.SHOW_DELETED:
            return action.data;
        default:
            return state;
    }
};

const toastReducer = (state: IToastReduxData = { isShow: false }, action: IToastReduxAction) => {
    switch (action.type) {
        case Types.SET_TOAST:
            return action.data;
        default:
            return state;
    }
};

const listAllReducer = (state: IListDataAllAPI = {}, action: IGetListDataAllAction) => {
    switch (action.type) {
        case Types.SET_LIST_DATA_ALL:
            return action.payload;
        default:
            return state;
    }
}


export {
    confirmDeleteReducer,
    loaderReducer,
    sidebarReducer,
    alertReducer,
    checkErrorReducer,
    modalReducer,
    checkTokenReducer,
    checkNotiTokenReducer,
    showNotFoundReducer,
    userInfoReducer,
    toastReducer,
    showDeletedReducer,
    checkAccountExist,
    checkActionAccountExist,
    SuccessCallAPI,
    listAllReducer,
    checkAccountNotPermisstion
};
