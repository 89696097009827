import './_carousel.scss';
import ReactCarousel from 'react-multi-carousel';
import { images } from '@utils/constants';
import { useEffect, useState } from 'react';


const Carousel: ICarouselComponent<ICarouselComponentProps> = (props) => {
    const { containerClassName, className, items } = props;
    const [carouselIndex, setCarouselIndex] = useState<number>(1);
    const [isChangeComplete, setIsChangeComplete] = useState<boolean>(false);
    const [carouselAction, setCarouselAction] = useState<'next' | 'previous' | ''>('');

    useEffect(() => {
        if (isChangeComplete) {
            if (carouselAction === 'previous') {
                if (carouselIndex === 1) {
                    setCarouselIndex(items?.length ?? 0);
                } else {
                    setCarouselIndex(carouselIndex - 1);
                }
            }
            if (carouselAction === 'next') {
                if (carouselIndex === items?.length) {
                    setCarouselIndex(1);
                } else {
                    setCarouselIndex(carouselIndex + 1);
                }
            }

            setIsChangeComplete(false);
            setCarouselAction('');
        }
    }, [isChangeComplete, carouselAction]);

    const renderItems = () => {
        if (items !== undefined) {
            return items.length ?
                items.map((item, index) => (
                    <div key={index} className="components__carousel-item">
                        {item}
                    </div>
                )) :
                <img className="components__carousel-default-image" src={images.IMAGES.DEFAULT_IMAGE} />
        }

        return <></>;
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 0 },
            items: 1,
        },
    };

    return (
        <div className={`components__carousel ${containerClassName ?? ''}`}>
            <ReactCarousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                infinite={true}
                autoPlay={false}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass={`${className ?? ''}`}
                customLeftArrow={
                    <span onClick={() => setCarouselAction('previous')} className={`components__carousel_arrow components__carousel_arrow-left ${(items?.length ?? 0) <= 1 ? 'd-none' : ''}`}>
                        <img onClick={() => setCarouselAction('previous')} src={images.IMAGES.ICON_CHEVRON_LEFT} />
                    </span>
                }
                customRightArrow={
                    <span onClick={() => setCarouselAction('next')} className={`components__carousel_arrow components__carousel_arrow-right ${(items?.length ?? 0) <= 1 ? 'd-none' : ''}`}>
                        <img onClick={() => setCarouselAction('next')} src={images.IMAGES.ICON_CHEVRON_RIGHT} />
                    </span>
                }
                afterChange={() => setIsChangeComplete(true)}
            >
                {renderItems()}
            </ReactCarousel>
            <div className="components__carousel_item_counter">
                {items?.length ? carouselIndex : 0}/{items?.length ?? 0}
            </div>
        </div>
    );
}

export default Carousel;