import { useTranslation } from 'react-i18next';
import styles from './Breadcrumb.module.scss';
import classNames from 'classnames/bind';
import { images } from '~/utils/constants';
import { useState, useEffect } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Configs from '@utils/configs';
const cx = classNames.bind(styles);

interface IBreadcrumbItemType {
    text: string;
    main?: boolean;
    url?: string;
    params?: string
}

const Breadcrumb: IBreadcrumbComponent<IBreadcrumbComponentProps> = (props) => {
    const [items, setItems] = useState<IBreadcrumbItemType[]>();
    const location = useLocation();
    const { t } = useTranslation();
    const { id, adminId } = useParams();

    useEffect(() => {
        switch (location.pathname) {
            case Configs.routes.notificationDashboard:
                setItems([
                    { main: true, text: 'breadcrumb.dashboard' ,url: Configs.routes.dashboard},
                    { text: 'breadcrumb.notification' }
                ]);
                break;
            case Configs.routes.brokerDetails.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.staffworker.user_info' },
                    { text: 'breadcrumb.staffworker.employee_detail' }
                ]);
                break;
            case Configs.routes.brokerRegister:
                setItems([
                    { main: true, text: 'breadcrumb.staffworker.user_info' },
                    { text: 'breadcrumb.staffworker.employee_add' }
                ]);
                break;
            case Configs.routes.assetDetails.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.asset.name', url: Configs.routes.asset },
                    { text: 'breadcrumb.asset.detail_asset' }
                ]);
                break;
            case Configs.routes.assetRegister:
                setItems([
                    { main: true, text: 'breadcrumb.asset.name', url: Configs.routes.asset },
                    { text: 'asset.register' },
                ]);
                break;
            case Configs.routes.oneTimePasswordResgister:
                setItems([
                    { main: true, text: 'breadcrumb.oneTimePassword.title', url: Configs.routes.oneTimePassword },
                    { text: 'breadcrumb.oneTimePassword.add' },
                ]);
                break;
            case Configs.routes.oneTimePassWordEdit.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.oneTimePassword.title', url: Configs.routes.oneTimePassword },
                    { main: true, text: 'breadcrumb.oneTimePassword.detail', url: Configs.routes.oneTimePasswordDetails.replace(':id', `${id}`) },
                    { text: 'breadcrumb.oneTimePassword.edit' },
                ]);
                break;
            case Configs.routes.oneTimePasswordDetails.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.oneTimePassword.title', url: Configs.routes.oneTimePassword },
                    { text: 'breadcrumb.oneTimePassword.detail' },
                ]);
                break;
            case Configs.routes.companyDetails.replace(':id', `${id}`).replace(':adminId', `${adminId}`):
                setItems([
                    { main: true, text: 'breadcrumb.account.management_company', url: Configs.routes.company },
                    { text: 'management_company_account.page_detail.title' },
                ]);
                break;
            case Configs.routes.companyEdit.replace(':id', `${id}`).replace(':adminId', `${adminId}`):
                setItems([
                    { main: true, text: 'breadcrumb.account.management_company', url: Configs.routes.company },
                    { main: true, text: 'management_company_account.page_detail.title', url: Configs.routes.companyDetails.replace(':id', `${id}`).replace(':adminId', `${adminId}`) },
                    { text: 'title.management_company_account_edit' },
                ]);
                break;
            case Configs.routes.accountDetail:
                setItems([
                    { main: true, text: 'breadcrumb.account.detail.title' },
                    { text: 'breadcrumb.staffworker.register' }
                ]);
                break;
            case Configs.routes.employeeWorkerRegister:
                setItems([
                    { main: true, text: 'breadcrumb.account.detail.title', url: Configs.routes.employeeWorker },
                    { text: 'breadcrumb.staffworker.register' },
                ]);
                break;
            case Configs.routes.employeeWorkerDetails.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.account.detail.title', url: Configs.routes.employeeWorker },
                    { text: 'breadcrumb.account.detail.company_employee_work' },
                ]);
                break;
            case Configs.routes.employeeWorkerEdit.replace(':id', `${id}`):
                setItems([
                    { main: true, text: 'breadcrumb.account.detail.title', url: Configs.routes.employeeWorker },
                    { main: true, text: 'breadcrumb.staffworker.detail', url: Configs.routes.employeeWorkerDetails.replace(':id', `${id}`) },
                    { text: 'breadcrumb.staffworker.edit' },
                ]);
                break;
            case Configs.routes.notificationRegister:
                setItems([
                    { main: true, text: 'notification.title', url: Configs.routes.notification },
                    { text: 'notification.register' },
                ]);
                break;
            case Configs.routes.companyRegister:
                setItems([
                    { main: true, text: 'breadcrumb.account.management_company', url: Configs.routes.company },
                    { text: 'title.management_company_account_registration' },
                ]);
                break;
            case Configs.routes.companyInfoEdit:
                setItems([
                    { main: true, text: 'breadcrumb.company.title', url: Configs.routes.companyInfo },
                    { text: 'breadcrumb.company.company_edit', }
                ]);
                break;
            case Configs.routes.changePassword:
                setItems([
                    { main: true, text: 'breadcrumb.company.title', url: Configs.routes.companyInfo },
                    { text: 'breadcrumb.company.change_password' }
                ]);
                break;
            case Configs.routes.newQuestion:
                setItems([
                    { main: true, text: 'breadcrumb.survey_question.title', url: Configs.routes.surveyQuestion },
                    { text: 'breadcrumb.survey_question.new_question' },
                ]);
                break;
            default:
                setItems([{ text: '' }]);
                break;
        }
    }, [location]);

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items &&
                    items.map((item, index) => {
                        return (
                            <li className='bases__height-min--15' key={index}>
                                {item.url ? (
                                    <NavLink to={item.url}>
                                        <span className={cx('breadcrumb', item.main && 'main')}>{t(item.text)}</span>
                                    </NavLink>
                                ) : (item.text
                                    ? <span className={cx('breadcrumb', item.main && 'main')}>{t(item.text)}</span>
                                    : <span className={cx('breadcrumb', 'breadcrumb--placeholder', item.main && 'main')}></span>
                                )}
                                {index < items.length - 1 && <img className="mx-2" src={images.IMAGES.ICON_ARROW_RIGHT_SMALL} />}
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
};

export default Breadcrumb;
