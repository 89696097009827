import React, { createRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput, FormRadio, } from '@components/commons';
import { images } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import ModalInterface from '../commons/Modal';
import { IMAGES } from '@utils/constants/images';
import { useFormik } from 'formik';
import ModalConfirm from './ModalConfirm';
import { getDetailAsset, updateAsset } from '@utils/helpers/api';
import { useDispatch } from 'react-redux';
import { setToast, showAlert } from '@redux/actions';
import EnterAddressModal from './EnterAddressModal';
import { formatPostalCode, handleGetFullAddress } from '@utils/helpers/utilities';
import { useNavigate, useParams } from 'react-router-dom';
import Configs from '@utils/configs';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);
interface FileCustom extends File {
    preview?: string;
}
interface InitValueFormik {
    room_classification: string;
    images: IImageSlide[];
    name: string;
    publication: number;
    address: string;
    usage_classification: string;
    info: string;
    postal_code: string;
    prefecture: string;
    city: string;
    town: string;
    building: string;
}
const listOption = [
    {
        value: '賃貸居住用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '戸建',
                label: '戸建',
            },
            {
                value: 'マンション',
                label: 'マンション',
            },
            {
                value: 'アパート',
                label: 'アパート',
            },
            {
                value: '文化',
                label: '文化',
            },
            {
                value: '間貸し',
                label: '間貸し',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '賃貸事業用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '店舗',
                label: '店舗',
            },
            {
                value: '事務所',
                label: '事務所',
            },
            {
                value: '倉庫',
                label: '倉庫',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '売買居住用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '戸建',
                label: '戸建',
            },
            {
                value: 'マンション',
                label: 'マンション',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '売買事業用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '店舗',
                label: '店舗',
            },
            {
                value: '事務所',
                label: '事務所',
            },
            {
                value: '倉庫',
                label: '倉庫',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
];
const EditAssetModal: IModalComponent<IModalComponentProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const routeParams = useParams();
    const [imageSlide, setImageSlide] = useState<string>('');
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [listFile, setListFile] = useState<FileCustom[]>([]);
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const [roomOptions, setRoomOptions] = useState<FormOptionType[] | undefined>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { isShow, handleClose, data } = props;
    const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false);
    const [isShowConfirmClose, setIsShowConfirmClose] = useState<boolean>(false);
    const dragItem = useRef<HTMLDivElement | null>(null);
    const dragIndex = useRef(0);
    const dropItem = useRef<HTMLDivElement | null>(null);
    const dropIndex = useRef(0);
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
        showSecondModal: false,
    });
    const { showFirstModal, showSecondModal } = modalState;
    const initValueFormik: InitValueFormik = {
        info: '',
        usage_classification: '',
        room_classification: '',
        images: [],
        address: '',
        name: '',
        publication: 0,
        postal_code: '',
        prefecture: '',
        city: '',
        town: '',
        building: '',
    };
    const passwordInputRef = createRef<IValidatorPasswordSubmit>();
    const validation = () => {
        const isValidName = validateName(formik.values.name);
        const isValidAddress = validateAddress(formik.values.address);
        const isValidRoomType = validateRoomType(formik.values.usage_classification, formik.values.room_classification);
        return !isValidName || !isValidAddress || !isValidRoomType ? false : true;
    };
    const validateName = (name?: string) => {
        if (name === '') {
            formik.setFieldError('name', 'asset.error_empty_asset_name');
            return false;
        } else {
            formik.setFieldError('name', '');
            return true;
        }
    };
    const validateAddress = (address?: string) => {
        if (address === '') {
            formik.setFieldError('address', 'asset.error_empty_address');
            return false;
        } else {
            formik.setFieldError('address', '');
            return true;
        }
    };
    const validateRoomType = (usage_classification?: string, room_classification?: string) => {
        if (usage_classification && room_classification === '') {
            formik.setFieldError('room_classification', 'asset.validate_roomType');
            return false;
        } else {
            formik.setFieldError('room_classification', '');
            return true;
        }
    };
    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: initValueFormik,
        onSubmit: (values) => {
            const formData = new FormData();
            // handle list image
            const listImage = formik.values.images;
            const listOnlyFile = listImage && listImage.filter((image) => image.name);
            if (listOnlyFile?.length) {
                listOnlyFile.map((file) => {
                    formData.append('images', file.file as Blob);
                });
            }
            const asset = { ...formik.values };
            formData.append('data', JSON.stringify(asset));
            if (validation()) {
                getDetailAsset(routeParams.id ?? '').then(({ data }) => {
                    if (values.publication === 1) {
                        if (data.data?.room_amount == 0 || data.data?.total_public_room == 0) {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: 'asset.detail_asset.noti_data_update',
                                    handleOK: () => {
                                        handleClose();
                                    },
                                    hiddenCancel: true,
                                }),
                            );
                        } else {
                            updateAsset(data.data?.id, formData)
                                .then(({ data }) => {
                                    dispatch(
                                        setToast({
                                            isShow: true,
                                            message: `${t('common.save_data')}`,
                                        }),
                                    );
                                    handleClose();
                                })
                                .catch((err) => {
                                    if (err.data.message === 'NAME_ASSET_EXISTED') {
                                        formik.setFieldError('name', 'message.sample_name_asset');
                                    } else {
                                        handleClose();
                                    }
                                });
                        }
                    } else if (values.publication === 0) {
                        if (data.data?.unexpired_appointments !== '0' || data.data?.unexpired_otp !== '0') {
                            dispatch(
                                showAlert({
                                    titleCancel: "common.ok",
                                    header: 'common.confirm',
                                    content: (
                                        <div>
                                            <span>{t('asset.detail_asset.private_viewing_reservation')}</span><br/>
                                            <span className={cxCommon('text-red', 'fw-bold')}>{t('asset.private_popup')}</span>
                                            <span>{t('asset.detail_asset.cannot_changed')}</span>
                                        </div>
                                    ),   
                                    classNameCancel: cx('btnCancel'),
                                    hiddenOK: true
                                }),
                            );
                        } else if (data.data?.unexpired_submit_appointments !== '0'){
                            dispatch(
                                showAlert({
                                    header: 'common.confirm',
                                    content: (
                                        <div>
                                            <span>{t('asset.detail_asset.private_viewing')}</span><br/>
                                            <span>{t('common.really')}</span>
                                            <span className={cxCommon('text-red', 'fw-bold')}>{t('asset.private_popup')}</span>
                                            <span>{t('asset.detail_asset.cannot_change')}</span>
                                        </div>
                                    ),
                                    handleOK: () => {
                                        updateAsset(data.data?.id, formData)
                                        .then(({ data }) => {
                                            dispatch(
                                                setToast({
                                                    isShow: true,
                                                    message: `${t('common.save_data')}`,
                                                }),
                                            );
                                            handleClose();
                                        })
                                        .catch((err) => {
                                            if (err.data.message === 'NAME_ASSET_EXISTED') {
                                                formik.setFieldError('name', 'message.sample_name_asset');
                                            } else if (err.data.message === 'NOT_FOUND') {
                                                dispatch(
                                                    showAlert({
                                                        header: 'common.confirm',
                                                        content: 'common.existed_popup_content',
                                                        handleOK: () => {
                                                            navigate(Configs.routes.asset);
                                                        },
                                                        hiddenCancel: true,
                                                    }),
                                                );
                                            } else {
                                                handleClose();
                                            }
                                        });
                                    },
                                }),
                            );
                           
                        } else{
                            updateAsset(data.data?.id, formData)
                            .then(({ data }) => {
                                    dispatch(
                                        setToast({
                                            isShow: true,
                                            message: `${t('common.save_data')}`,
                                        }),
                                    );
                                    handleClose();
                                })
                                .catch((err) => {
                                    if (err.data.message === 'NAME_ASSET_EXISTED') {
                                        formik.setFieldError('name', 'message.sample_name_asset');
                                    } else {
                                        handleClose();
                                    }
                                });
                        }
                    }
                });
            }
        },
    });
    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };
    const handleUploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listFile: FileCustom[] = Array.from(e.target?.files || []);
        listFile?.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        setListFile(listFile);
        e.target.value = '';
    };
    const handleRemoveImage = (imageId: number, index: number) => {
        let listImageCopy = [...listImageSlide];
        const maxIndexOfList = listImageCopy?.length - 1;
        listImageCopy = listImageCopy.filter((image) => image.order !== imageId);
        const newListImage = listImageCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
        if (index < activeIndex || activeIndex === maxIndexOfList) {
            setActiveIndex(activeIndex - 1);
        }
    };
    useEffect(() => {
        if (listFile?.length > 0) {
            const listImageSlideCopy = [...listImageSlide];
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const totalImage = listImageSlideCopy?.length + listFile?.length;

            if (totalImage > 5) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_5_number');
                showOrHideModal('showFirstModal');
                return;
            }
            listFile.forEach((file) => {
                const fileSize = Number((file.size / 1024 / 1024).toFixed(2));
                if (!validImageTypes.includes(file.type)) {
                    setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                } else if (fileSize > 8) {
                    setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                }

                const orders = listImageSlideCopy?.map((imageSlide) => imageSlide.order);
                const maxOrder = orders?.length > 0 ? Math.max(...orders) : -1;

                const newImage: IImageSlide = {
                    order: maxOrder + 1,
                    url: file.preview || '',
                    file: file,
                    name: file.name,
                };
                listImageSlideCopy.push(newImage);
            });
            setListImageSlide(listImageSlideCopy);
        }
    }, [listFile]);

    const handleSubmitAddress = (values: IEnterAddressForm) => {
        const address = handleGetFullAddress({ ...values, postalCode: formatPostalCode(values.postalCode) });
        handleChange('postal_code', formatPostalCode(values.postalCode));
        handleChange('address', address);
        handleChange('prefecture', values.prefecture);
        handleChange('city', values.city);
        handleChange('town', values.town);
        handleChange('building', values.building);
        showOrHideModal('showSecondModal');
    };

    useEffect(() => {
        if (data?.id) {
            formik.setFieldValue('postal_code', data?.postal_code);
            formik.setFieldValue('prefecture', data?.prefecture);
            formik.setFieldValue('city', data?.city);
            formik.setFieldValue('town', data?.town);
            formik.setFieldValue('building', data?.building);
            formik.setFieldValue('info', data?.info);
            formik.setFieldValue('usage_classification', data?.usage_classification);
            formik.setFieldValue('room_classification', data?.room_classification);
            formik.setFieldValue('address', data?.address);
            formik.setFieldValue('name', data?.name);
            formik.setFieldValue('publication', data?.publication);
            formik.setFieldValue('images', data?.images);
            setListImageSlide(data?.images ?? []);
            setRoomOptions(listOption?.find((item) => item.value === data?.usage_classification)?.option || []);
        }

        if (!isShow) {
            formik.setErrors({})
        }
    }, [isShow]);

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };
    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
        if (type === 'usage_classification') {
            handleChange('room_classification', '');
            if (value) {
                setRoomOptions(listOption?.find((item) => item.value === value)?.option || []);
            }
        }
    };
    useEffect(() => {
        handleUpdateImageSlide();
        handleChange('images', listImageSlide);
    }, [listImageSlide]);

    useEffect(() => {
        handleUpdateImageSlide();
    }, [activeIndex]);
    const handleUpdateImageSlide = () => {
        if (listImageSlide?.length) {
            setImageSlide(listImageSlide[activeIndex].url);
        } else {
            setImageSlide('');
            setActiveIndex(0);
        }
    };

    const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
        dragIndex.current = index;
        dragItem.current = e.target as HTMLDivElement;
        dragItem.current.style.opacity = '0.4';
    };

    const handleDragOver = (index: number, e: React.DragEvent) => {
        dropIndex.current = index;
        dropItem.current = e.target as HTMLDivElement;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        if (dragItem.current) {
            dragItem.current.style.opacity = '1';
        }
        if (dropItem.current === dragItem.current) return;

        const imagesCopy = [...listImageSlide];

        const draggedImage = imagesCopy.splice(dragIndex.current, 1)[0];
        imagesCopy.splice(dropIndex.current, 0, draggedImage);
        const newListImage = imagesCopy?.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
    };

    return (
        <ModalInterface
            handleClose={() => {
                setIsShowConfirmCancel(true);
            }}
            handleCancel={() => {
                setIsShowConfirmClose(true);
            }}
            positiveButtonText='common.save'
            handleOK={formik.handleSubmit}
            classNameBody={cx('body_edit_asset')}
            classNameHeader={cx('title-header')}
            dialogClassname={cx('w_modal_add_room')}
            modalHeader={t<string>('asset.assetEdit')}
            isShow={isShow}>
            <div className={cx('body_modal_edit_asset', 'row')}>
                <div className={cx('border_img', 'row col-7 d-flex align-content-start')}>
                    <div className={cxCommon('bases__height--265', 'row')}>
                        <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                            <img
                                className={cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                            />
                        </div>
                    </div>

                    <div
                        className={
                            cx('slide_container') +
                            ' ' +
                            cxCommon('bases__margin--top15') +
                            ' justify-content-center'
                        }>
                        <div ref={slideRef} className={cx('slide_list')}>
                            {listImageSlide.map((image, index) => {
                                return (
                                    <div
                                        key={image.order}
                                        onDragStart={(e) => handleDragStart(index, e)}
                                        onDragOver={(e) => handleDragOver(index, e)}
                                        onDragEnd={() => handleDragEnd()}
                                        className={cx('slide_img_edit') + ' ' + cx(activeIndex === index ? 'slide_img_edit--active' : '')}>
                                        <img
                                            onClick={() => handleRemoveImage(image.order, index)}
                                            src={IMAGES.ICON_CLOSE_BG}
                                            alt=""
                                            className={cx('img_close')}
                                        />
                                        <img onClick={() => handleClick(index)} src={image.url} alt="" className={cx('img_edit')} />
                                    </div>
                                );
                            })}
                        </div>

                        <div>
                            <div aria-disabled={listImageSlide.length >= 5}>
                                {listImageSlide.length >= 5 ? (
                                    <img className={cx('img-upload')} src={IMAGES.ICON_INPUT_IMG_DISABLED} />
                                ) : (
                                    <img
                                        className={cx('img-upload')}
                                        src={IMAGES.ICON_INPUT_IMG}
                                        onClick={() => fileInputRef1.current?.click()}
                                    />
                                )}
                            </div>
                            <input
                                style={{ display: 'none' }}
                                ref={fileInputRef1}
                                type="file"
                                accept="image/*"
                                className="input-upload"
                                onChange={(e) => handleUploadMultipleFiles(e)}
                                multiple
                            />
                        </div>
                    </div>
                </div>
                <div className={cx('height_form_input', 'col-5 ms-4')}>
                    <FormInput
                        styleBlock
                        textStart
                        maxLength={255}
                        required
                        placeholder="asset.assetName"
                        label="asset.detail_asset.name"
                        value={formik.values.name}
                        onChangeText={(text) => {
                            return handleChange('name', text);
                        }}
                        error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                    />
                    <div className={cxCommon('bases__margin--top20', 'd-flex justify-content-between')}>
                        <div className={cxCommon('bases__padding--right10', 'row col-11')}>
                            <FormInput
                                tooltip={true}
                                styleBlock
                                label="asset.address"
                                required
                                className='bases__height--40'
                                classNameContainerInput={cx('input')}
                                disabled={true}
                                textStart
                                value={formik.values.address}
                                placeholder={t<string>('asset.address')}
                                onChangeText={(text) => handleChange('address', text)}
                                inputRef={passwordInputRef}
                                error={formik.errors.address ? formik.errors.address : undefined}
                            />
                        </div>
                        <div>
                            <Button
                                type="button"
                                onClick={() => {
                                    showOrHideModal('showSecondModal');
                                }}
                                text={t<string>('common.enter')}
                                className={cxCommon('bases__margin--top28')}
                                search></Button>
                        </div>
                    </div>
                    <FormDropdown
                        styleBlock
                        className={cxCommon('bases__margin--top20')}
                        label="asset.type_of_use"
                        value={formik.values.usage_classification}
                        defaultValue={formik.values.usage_classification}
                        classNameOption={cxCommon('bases__height--32')}
                        options={[
                            { value: '', label: '' },
                            { value: '賃貸居住用', label: '賃貸居住用' },
                            { value: '賃貸事業用', label: '賃貸事業用' },
                            { value: '売買居住用', label: '売買居住用' },
                            { value: '売買事業用', label: '売買事業用' },
                        ]}
                        onChangeOption={(option) => handleChange('usage_classification', option.value)}
                    />
                    <FormDropdown
                        styleBlock
                        classNameOption={cxCommon('bases__height--32')}
                        className={cxCommon('bases__margin--top20')}
                        label="asset.room_type"
                        value={formik.values.room_classification}
                        defaultValue={data?.room_classification}
                        options={roomOptions}
                        disabled={formik.values.usage_classification === '' ? true : false}
                        onChangeOption={(option) => handleChange('room_classification', option.value)}
                        error={
                            formik.touched.room_classification && formik.errors.room_classification
                                ? formik.errors.room_classification
                                : undefined
                        }
                    />
                    <div className={cx('form-radio')}>
                        <FormRadio
                            disabled={data?.room_amount == 0 || data?.total_public_room == 0 ? true : false}
                            classNameInput="size_checkbox"
                            className="mt-1"
                            items={[
                                { label: 'asset.public', value: 1, textColorCustom: 'text_color_green' },
                                { label: 'asset.private', value: 0, textColorCustom: 'text_color_red' },
                            ]}
                            defaultCheckedIndex={data?.room_amount && formik.values.publication ? formik.values.publication : 0}
                            onCheckItem={(value) => handleChange('publication', value)}
                        />
                        <div className={cxCommon('bases__margin--left20')}>
                            <span className={cxCommon('text_color_red')}>{t('common.note')}</span>
                            <br />
                            <span className={cxCommon('text_color_green')}>{t('asset.Release')}</span>
                            <span>{t('asset.cannot_change_to')}:</span>
                            <br />
                            <span>{t('asset.property_without_room')}</span>
                            <br />
                            <span>{t('asset.all_room')}</span>
                        </div>
                    </div>
                    <FormInput
                        styleBlock
                        classNameTextArea={cxCommon('bases__height-min--80', 'font-size-12')}
                        textStart
                        textArea
                        rows={3}
                        maxLength={3000}
                        className={cxCommon('bases__margin--top20')}
                        label="asset.property_information"
                        onChangeText={(text) => {
                            return handleChange('info', text);
                        }}
                        value={formik.values.info}
                        placeholder="asset.placeholder_property"
                    />
                </div>
            </div>
            {showFirstModal && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        hideNegativeButton
                        isShow={showFirstModal}
                        handleOK={() => showOrHideModal('showFirstModal')}
                        handleClose={() => showOrHideModal('showFirstModal')}
                        header={t<string>('company_info.company_edit.modal.error_title')}
                        content={t<string>(errorMessage)}
                    />
                </>
            )}
            {showSecondModal && (
                <>
                    <div className={cx('opacity-modal')} />
                    <EnterAddressModal
                        dataInit={{
                            postalCode: formik.values.postal_code ? formik.values.postal_code.replace('-', '') : '',
                            prefecture: formik.values.prefecture,
                            city: formik.values.city,
                            town: formik.values.town,
                            building: formik.values.building,
                        }}
                        isShow={showSecondModal}
                        handleOK={handleSubmitAddress}
                        handleClose={() => showOrHideModal('showSecondModal')}
                    />
                </>
            )}
            {isShowConfirmCancel && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowConfirmCancel}
                        handleOK={() => {
                            setIsShowConfirmCancel(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmCancel(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_close')}
                    />
                </>
            )}
            {isShowConfirmClose && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowConfirmClose}
                        handleOK={() => {
                            setIsShowConfirmClose(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmClose(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_cancel')}
                    />
                </>
            )}
        </ModalInterface>
    );
};

EditAssetModal.defaultProps = {
    isShow: false,
};

export default EditAssetModal;
