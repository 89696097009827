import { useTranslation } from "react-i18next";

import { Header, Sidebar } from '@components/layouts';
import classNames from 'classnames/bind';
import styles from './NotFound.module.scss';

import { useSelector } from "react-redux";
import { ReduxStates } from "@redux/reducers";
import { fetchUserInfo } from "@utils/helpers/userApi";
import { useEffect } from 'react';

const cx = classNames.bind(styles);

const NotFound: INotFoundPage<INotFoundPageProps> = () => {
    const { t } = useTranslation();
    const { sidebar } = useSelector((states: ReduxStates) => states);
    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        fetchUserInfo();
    }, []);

    return (
        <>
            <div>
                { accessToken && (<Sidebar />) }
                <div className={cx(accessToken && 'wrapper', accessToken && sidebar ? '' : 'sidebar--close')}>
                    { accessToken && (<Header />) }
                    <div className={
                        cx (
                            accessToken && 'pages__notFound-container',
                        )
                    }>
                        <div className={
                            cx (
                                !accessToken ? 'pages__notFound-noAuthWrapper' : 'pages__notFound-wrapper',
                                'd-flex',
                                'align-items-center',
                                'justify-content-center'
                            )}
                        >
                            <div>
                                <div className={
                                    cx (
                                        'pages__notFound-title',
                                        'text-center'
                                    )
                                }>
                                    { t('notFound.title') }
                                </div>
                                <div className={
                                    cx (
                                        'pages__notFound-message',
                                        'text-center'
                                    )
                                }>
                                    { t('notFound.message') }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;