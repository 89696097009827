import { getLisRoomNumber, getListAssetName, getOneTimePassList, getScheduleByRoomId } from './api';

export const fetchGetListOneTimePassword = async (
    params: IOneTimePassSearchParams,
): Promise<IBaseAPISearchRes<IDataOneTimePassSearchRes>> => {
    try {
        const response = await getOneTimePassList(params);
        return response.data;
    } catch (error: any) {
        return error;
    }
};

export const fetchGetListAssetOTP = async (): Promise<IBaseAPISearchRes<IListAssetName>> =>{
    try {
        const response = await getListAssetName();
        return response.data;
    }catch (error: any){
        return  error;
    }

}

export const fetchGetListRoomNumber = async (data: any) :Promise<IBaseAPISearchRes<IListRoomNumber>> =>{
    try {
        const response = await getLisRoomNumber(data);
        return response.data;
    }catch (error: any){
        return  error;
    }
}

export const fetchGetListSchedule = async (data: any ): Promise<IBaseAPISearchRes<IListScheduleRoom>> =>{
    try {
        const response = await getScheduleByRoomId(data);
        return response.data;
    }catch (error: any){
        return error
    }
}
