import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const Button: IButtonComponent<IButtonComponentProps> = (props) => {
    const {
        type,
        to,
        href,
        text,
        disabled,
        login,
        signup,
        children,
        className,
        search,
        del,
        edit,
        create,
        style,
        iconRight,
        filter,
        add,
        top,
        image,
        onClick,
        warning,
        danger,
        textAlign = 'center',
        classNameLabel,
        classNameImg,
    } = props;
    const classes = cx('wrapper', {
        [className]: className,
        login,
        signup,
        disabled,
        search,
        del,
        edit,
        create,
        filter,
        add,
        top,
        image,
        warning,
        danger,
    });

    const { t } = useTranslation();

    const textAlignStyle = useMemo<string>(() => {
        switch (textAlign) {
            case 'start':
                return ' justify-content-start';
            case 'end':
                return 'justify-content-end';
            default:
                return '';
        }
    }, [textAlign]);
    const element = image ? (
        <div className={'d-flex justify-content-between align-items-center'}>
            <img src={image} className={cx('image-button-style') + ` ${classNameImg}`} />
            <span>{t(text || '')}</span>
        </div>
    ) : (
        <span className={classNameLabel}>{t(text || '')}</span>
    );
    return (
        <>
            {!to && !href ? (
                <button className={classes + textAlignStyle} type={type ?? 'button'} disabled={disabled} onClick={() => (onClick ? onClick() : {})}>
                    {element}
                    {children ? children : <></>}
                </button>
            ) : (
                <></>
            )}
            {to ? (
                <Link to={to} className={classes + textAlignStyle}>
                    {element}
                    {children ? children : <></>}
                </Link>
            ) : (
                <></>
            )}
            {href ? (
                <a href={href} className={classes + textAlignStyle}>
                    {element}
                    {children ? children : <></>}
                </a>
            ) : (
                <></>
            )}
        </>
    );
};

Button.defaultProps = {
    to: '',
    href: '',
    text: '',
    style: {},
    styleText: {},
    styleIcon: {},
    disabled: false,
    login: false,
    signup: false,
    search: false,
    del: false,
    edit: false,
    add: false,
    create: false,
    onClick: () => undefined,
};

export default Button;
