import { forwardRef } from 'react';
import './_choice.scss'

const Choice = forwardRef<HTMLInputElement, IChoiceComponentProps>((props, ref) => {
    const {
        data,
        onChange,
        className,
        type, checked,
        viewMode,
        id,
        classNameLabel,
        classNameInput,
        classNameWrapper,
        classNameLabelWrapper,
        disabled
    } = props;

    const onChangeCheckboxValues = (value: string) => {
        const valueChecked = checked ?? [];

        const existedIndex = valueChecked.indexOf(value);
        if (existedIndex >= 0) {
            valueChecked.splice(existedIndex, 1);
        } else {
            valueChecked.push(value);
        }

        if (onChange) {
            onChange(valueChecked);
        }
    };

    if (type === 'checkbox') {
        return (
            <div className={'components__choice'}>
                {data?.map((item, index) => (
                    <div
                        key={index}
                        className={`${item.label ? (viewMode === 'horizontal' ? 'bases__margin--right16' : 'bases__margin--vertical16') : ''
                            } d-flex justify-content-center align-items-center ${classNameWrapper}`}
                    >
                        <input
                            disabled={disabled}
                            ref={ref}
                            className={`${item.label ? `bases__margin--right4 components__choice--checkbox ${classNameInput}` : `components__choice--checkbox ${classNameInput}`}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeCheckboxValues(event.target.value)}
                            type="checkbox"
                            value={item.value}
                            id={`${id}_${item.id}`}
                            checked={checked && checked?.includes(item.value ?? '')}
                        />
                        <label htmlFor={`${id}_${item.id}`} className={classNameLabel}>{item.label}</label>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className={`components__choice ${viewMode === 'horizontal' ? 'd-flex align-items-center flex-wrap' : ''} ${className}`}>
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`${item.label ? (viewMode === 'horizontal' ? 'bases__margin--right16' : 'bases__margin--vertical16') : ''
                            } d-flex justify-content-center align-items-center`}
                    >
                        <input
                            className={`${item.label ? 'bases__margin--right4' : ''}`}
                            type="radio"
                            value={item.value}
                            name={id}
                            id={`${id}_${item.id}`}
                            checked={checked && checked?.includes(item.value ?? '')}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => (onChange ? onChange([event.target.value]) : {})}
                        />
                        <label htmlFor={`${id}_${item.id}`}>{item.label}</label>
                    </div>
                ))}
            </div>
        );
    }

});
Choice.displayName = 'Choice';

Choice.defaultProps = {
    className: '',
    type: 'radio',
    checked: [],
    viewMode: 'horizontal',
    id: 'choice',
    classNameLabel: '',
    classNameInput: '',
    classNameWrapper: '',
    classNameLabelWrapper: '',

};

export default Choice;
