import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput, FormRadio } from '@components/commons';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import ModalInterface from '@components/commons/Modal/Modal';
import DataTable from '@components/commons/Table/Table';
import { SearchForm } from '@components/forms';
import { BodyContainer } from '@components/layouts';
import PaymentModal from '@components/modals/PaymentModal';
import { showAlert } from '@redux/actions';
import { setListDataAll, setToast, showConfirmDelete } from '@redux/actions/CommonAction';
import { ReduxStates } from '@redux/reducers';
import { USER_ROLE_ENUM, images, pageStateInit } from '@utils/constants';
import { createPayment } from '@utils/helpers/api';
import { formatAndSubtractOrAddDate, formatDate, formatDateUTC } from '@utils/helpers/date';
import { fetchDeleteCard, fetchGetListPayment, fetchUnlockCard } from '@utils/helpers/paymentApi';
import { formatMoney, handleGetFullAddress } from '@utils/helpers/utilities';
import useRole from '@utils/hook/useRole';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import PaymentCard from './PaymentCard';
import styles from './PaymentManagement.module.scss';

PaymentManagement.propTypes = {};

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const initialValueSearch: SearchParamsPayment = {
    status: '',
    dateFrom: '',
    dateTo: '',
    month: '',
    companyName: '',
    offset: 0,
    limit: 30,
};

function PaymentManagement() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [data, setData] = useState<ListItemPayment[]>([]);
    const [dataSuperAdmin, setDataSuperAdmin] = useState<ListItemPayment[]>([]);
    const [dataSum, setDataSum] = useState<IDataSumPayment>();
    const [cardNumber, setCardNumber] = useState<string | null>(null);
    const [dataRadio, setDataRadio] = useState<any[]>([]);
    const [checkedRadio, setCheckedRadio] = useState(0);
    const [isShowMore, setIsShowMore] = useState(false);
    const [isShowPaymentModal, setIsShowPaymentModal] = useState(false);
    const [isCharge, setIsCharge] = useState(false);
    const { userInfo, listAll } = useSelector((states: ReduxStates) => states);
    const isSuperAdmin = useRole(userInfo, USER_ROLE_ENUM.superadmin);

    const [searchParams, setSearchParams] = useSearchParams(initialValueSearch as URLSearchParamsInit);
    const [monthSearch, setMonthSearch] = useState(new Date());

    const dataTable = useMemo(() => {
        const dataRow: ListItemPayment[] = data?.map((item) => {
            const yearPayment = moment(item.dataMonth).format('YYYY年');
            const monthPayment = moment(item.dataMonth).format('M月');

            return {
                ...item,
                date_payment: formatDateUTC(item?.timeCharge, 'YYYY/MM/DD HH:mm'),
                year_payment: yearPayment,
                month_payment: monthPayment,
                total_keybox: item?.keyBoxUsage,
                status: (
                    <span
                        className={
                            item.isCharge === 0
                                ? cxCommon('text-purple')
                                : item.isCharge === 1
                                ? cxCommon('text-green')
                                : item.isCharge === 2
                                ? cxCommon('text-red')
                                : ''
                        }>
                        {item.isCharge === 0 ? '未決済' : item.isCharge === 1 ? '決済済み' : item.isCharge === 2 ? '決済失敗' : '-'}
                    </span>
                ),
                raw_status: (
                    <span>
                        {item.isCharge === 0 ? '未決済' : item.isCharge === 1 ? '決済済み' : item.isCharge === 2 ? '決済失敗' : '-'}
                    </span>
                ),
                total_amount: `${formatMoney(item?.money)}円`,
            };
        });

        const heads = [
            {
                title: t('payment.title_table.date_payment'),
            },
            {
                title: t('payment.title_table.year_payment'),
            },
            {
                title: t('payment.title_table.month_payment'),
            },
            {
                title: t('payment.title_table.total_keybox'),
            },
            {
                title: t('payment.title_table.status'),
            },
            {
                title: t('payment.title_table.total_amount'),
            },
        ];

        const body = {
            columns: [
                {
                    field: 'date_payment',
                },
                {
                    field: 'year_payment',
                },
                {
                    field: 'month_payment',
                },
                {
                    field: 'total_keybox',
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'total_amount',
                },
            ],
            rows: dataRow,
        };

        return {
            heads,
            body,
        };
    }, [data]);

    const dataTableSuperAdmin = useMemo(() => {
        const dataRow: ListItemPayment[] = dataSuperAdmin?.map((item) => {
            // handle get address
            const addressParams: IEnterAddressForm = {
                postalCode: item.company?.postalCode || '',
                prefecture: item.company?.prefecture || '',
                city: item.company?.city || '',
                town: item.company?.town || '',
                building: item.company?.building || '',
            };

            const address = handleGetFullAddress(addressParams);
            return {
                ...item,
                company_logo: (
                    <div className="components__table-avatar">
                        {item.company?.logo ? (
                            <img className="bases__table--image" src={item.company?.logo} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                company_name: item.company?.name,
                company_address: address,
                key_box_quantity: item?.keyBoxUsage,
                payment_date: formatDateUTC(item?.timeCharge, 'YYYY/MM/DD HH:mm'),
                payment_method: <span>{item.typeCharge === 0 ? 'クレジットカード' : item.typeCharge === 1 ? '他の方法' : '-'}</span>,
                payment_amount: `${formatMoney(item?.money)}円`,
                status: (
                    <span
                        className={
                            item.isCharge === 0
                                ? cxCommon('text-purple')
                                : item.isCharge === 1
                                ? cxCommon('text-green')
                                : item.isCharge === 2
                                ? cxCommon('text-red')
                                : ''
                        }>
                        {item.isCharge === 0 ? '未決済' : item.isCharge === 1 ? '決済済み' : item.isCharge === 2 ? '決済失敗' : '-'}
                    </span>
                ),
                raw_status: (
                    <span>
                        {item.isCharge === 0 ? '未決済' : item.isCharge === 1 ? '決済済み' : item.isCharge === 2 ? '決済失敗' : '-'}
                    </span>
                ),
                payment_status: (
                    <div className="td-last d-flex justify-content-center align-items-center gap-2">
                        <button
                            disabled={item.isCharge === 1}
                            className={cx('bases__width-max--80', 'btn')}
                            onClick={() => showConfirmPopup(item.companyId)}>
                            決済済み
                        </button>
                    </div>
                ),
            };
        });

        const heads = [
            {
                title: t('payment.title_table.company_logo'),
            },
            {
                title: t('payment.title_table.company_name'),
            },
            {
                title: t('payment.title_table.company_address'),
            },
            {
                title: t('payment.title_table.key_box_quantity'),
            },
            {
                title: t('payment.title_table.payment_date'),
            },
            {
                title: t('payment.title_table.payment_method'),
            },
            {
                title: t('payment.title_table.payment_amount'),
            },
            {
                title: t('payment.title_table.status'),
            },
            {
                title: t('payment.title_table.payment_status'),
            },
        ];

        const body = {
            columns: [
                {
                    field: 'company_logo',
                    isNotTooltip: true,
                },
                {
                    field: 'company_name',
                },
                {
                    field: 'company_address',
                },
                {
                    field: 'key_box_quantity',
                },
                {
                    field: 'payment_date',
                },
                {
                    field: 'payment_method',
                },
                {
                    field: 'payment_amount',
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'payment_status',
                    isNotTooltip: true,
                },
            ],
            rows: dataRow,
        };

        return {
            heads,
            body,
        };
    }, [dataSuperAdmin]);

    useEffect(() => {
        fetchGetListPaymentAsync(searchParams as SearchParamsPayment);
    }, []);

    useEffect(() => {
        setDataSum(listAll.paymentList?.data?.dataSum);
    }, [listAll.paymentList?.data?.dataSum]);

    useEffect(() => {
        if (isSuperAdmin) {
            const newMonthSearch = formatDate(monthSearch.toDateString(), 'YYYY/MM');
            const searchParamsObject = Object.fromEntries(searchParams.entries());
            const newSearchParams = {
                ...searchParamsObject,
                month: newMonthSearch,
            };
            setSearchParams(newSearchParams);
        }
    }, [monthSearch]);

    useEffect(() => {
        const cardNumber = listAll.paymentList?.data?.maskedCardUsage;
        if (cardNumber) {
            setCardNumber(`「****-****-****-${cardNumber.slice(-4)}」`);
        } else {
            setCardNumber(null);
            setCheckedRadio(1);
        }

        const historyChargeOld = listAll.paymentList?.data?.historyChargeOld;
        if (isEmpty(historyChargeOld) || historyChargeOld?.isCharge === 1) {
            setIsCharge(true);
        } else {
            setIsCharge(false);
        }
    }, [listAll.paymentList?.data]);

    const handleClickPayment = () => {
        if (!cardNumber) {
            setIsShowPaymentModal(true);
        } else {
            if (checkedRadio) {
                setIsShowPaymentModal(true);
            } else {
                const params: IPaymentForm = {
                    cvv: '',
                    name: '',
                    number: '',
                    expires: '',
                    isNew: false,
                };

                createPayment(params)
                    .then((res) => {
                        if (res.status === 200) {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.transaction_success')}`,
                                }),
                            );
                            fetchGetListPaymentAsync(searchParams as SearchParamsPayment);
                        }
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            const message = err.data.message;
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: <span>{message}</span>,
                                    hiddenCancel: true,
                                }),
                            );
                        }
                    });
            }
        }
    };

    useEffect(() => {
        if (cardNumber) {
            setDataRadio([
                {
                    label: cardNumber,
                    value: 0,
                },
                {
                    label: '新しいカードで押し込み',
                    value: 1,
                },
            ]);
        }
    }, [cardNumber]);

    useEffect(() => {
        const response = listAll.paymentList?.data;
        if (response) {
            if (isSuperAdmin) {
                setDataSuperAdmin(response.result);
            } else {
                setData(response.listData || []);
            }
            setPageState({
                firstRecords: response.metadata?.offset * response.metadata?.limit + 1,
                currentPage: response.metadata?.offset + 1,
                totalPage: Math.ceil(response.metadata?.total / response.metadata?.limit),
                pageRecords: response.metadata?.count + response.metadata?.offset * response.metadata?.limit,
                totalRecords: response.metadata?.total,
            });
        }
    }, [listAll.paymentList]);

    const fetchGetListPaymentAsync = async (searchParams: SearchParamsPayment) => {
        const response = await fetchGetListPayment(searchParams);
        dispatch(setListDataAll({ ...listAll, paymentList: response }));
    };

    const showConfirmPopup = (companyId: number) => {
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: <span>{t('payment.modal.confirm_content')}</span>,
                handleOK: async () => {
                    const body = {
                        companyIdUnlock: companyId,
                    };
                    const response = await fetchUnlockCard(body);
                    if (response.status === 'SUCCESS') {
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.unlock_success')}`,
                            }),
                        );
                        fetchGetListPaymentAsync(searchParams as SearchParamsPayment);
                    }
                },
            }),
        );
    };

    const handleDeleteCard = () => {
        dispatch(
            showConfirmDelete({
                isShow: true,
                handleOK: async () => {
                    const response = await fetchDeleteCard();
                    if (response.status === 'SUCCESS') {
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.delete_success')}`,
                            }),
                        );
                        fetchGetListPaymentAsync(searchParams as SearchParamsPayment);
                    }
                },
            }),
        );
    };

    return (
        <BodyContainer title={t('title.payment')}>
            {!isSuperAdmin && (
                <Fragment>
                    <div className="row gap-2 flex-xxl-nowrap flex-wrap">
                        <div className="col-xxl-6 col-12">
                            <div className={cx('payment__title')}>{formatDate(new Date().toDateString(), 'YYYY年M月の金額')}</div>
                            <div className={cx('payment__card')}>
                                <PaymentCard
                                    title={`決済期日 `}
                                    type="primary"
                                    value={`${formatAndSubtractOrAddDate(
                                        new Date(),
                                        'months',
                                        1,
                                        'MM/01',
                                        'add',
                                    )} ~ ${formatAndSubtractOrAddDate(new Date(), 'months', 1, 'MM/10', 'add')}`}
                                    subTitle={`(${formatAndSubtractOrAddDate(new Date(), 'months', 1, 'YYYY', 'add')}年)`}
                                />
                                <PaymentCard
                                    title="キーボックス数 "
                                    type="primary"
                                    value={listAll.paymentList?.data?.numberOfKeyBoxUsage || 0}
                                    subTitle="(台)"
                                />
                                <PaymentCard
                                    title="合計金額 "
                                    type="primary"
                                    value={listAll.paymentList?.data?.moneyCharge || 0}
                                    subTitle="(円)"
                                />
                            </div>
                        </div>
                        <div className="col-xxl-6 col-12">
                            <div className={cx('payment__title', 'd-flex justify-content-between align-items-center flex-wrap')}>
                                <span>クレジットカード情報</span>
                                <span className="bases__error fw-normal">
                                    ※ お支払いおよびサービスを継続してご利用いただくために、カード情報をご申し込みください。
                                </span>
                            </div>
                            <div className={cx('payment__card', 'd-flex justify-content-between')}>
                                <div className={cx('col d-flex flex-column gap-3')}>
                                    <div className="bases__text--label">クレジットカード決済</div>
                                    {cardNumber ? (
                                        <Fragment>
                                            <div className="row justify-content-between">
                                                <div className="col-8">
                                                    <FormRadio
                                                        styleBlock
                                                        classNameInput="size_checkbox"
                                                        classNameContent="col-12"
                                                        items={dataRadio}
                                                        defaultCheckedIndex={checkedRadio}
                                                        onCheckItem={(value) => setCheckedRadio(value)}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <Button
                                                        del
                                                        onClick={() => handleDeleteCard()}
                                                        text="削除"
                                                        className="bases__width--60 bases__height--27 text-danger bases__border-red bases__text--red mt-1"
                                                    />
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <FormRadio
                                            styleBlock
                                            classNameInput="size_checkbox"
                                            classNameContent="col-12"
                                            items={[
                                                {
                                                    label: '新しいカードで押し込み',
                                                    value: 1,
                                                },
                                            ]}
                                            defaultCheckedIndex={checkedRadio}
                                        />
                                    )}
                                </div>
                                <div className={cx('payment__slat')}></div>
                                <div className={'col d-flex flex-column gap-3 ' + (isCharge ? 'justify-content-between' : '')}>
                                    {!isCharge ? (
                                        <Fragment>
                                            <div className="bases__text--label">{`決済期日：${formatAndSubtractOrAddDate(
                                                new Date(),
                                                'months',
                                                0,
                                                'YYYY/MM/01',
                                                'subtract',
                                            )} ~ ${formatAndSubtractOrAddDate(new Date(), 'months', 0, 'YYYY/MM/10', 'subtract')}`}</div>
                                            <div className="bases__text--label">
                                                <span className="bases__margin--right20">{`キーボックス数：${
                                                    listAll.paymentList?.data?.historyChargeOld?.keyBoxUsage || 0
                                                }台`}</span>
                                                <span>{`ご利用金額：${
                                                    formatMoney(listAll.paymentList?.data?.historyChargeOld?.money) || 0
                                                }円`}</span>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <div className="bases__text--red">決済期日に決済が行えます。</div>
                                    )}
                                    <Button
                                        disabled={isCharge}
                                        className={cx('bases__width--170')}
                                        add
                                        text={t<string>(
                                            isCharge
                                                ? `${formatDate(new Date().toDateString(), 'M月の金額決済')}`
                                                : formatAndSubtractOrAddDate(new Date(), 'months', 1, 'M月の金額決済', 'subtract'),
                                        )}
                                        onClick={handleClickPayment}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={cx('payment__title', 'mt-3')}>決済履歴</div>
                    </div>
                    <div className="mt-2">
                        <DataTable
                            total={pageState.totalRecords}
                            isStickyColumn
                            heads={dataTable.heads}
                            body={dataTable.body}
                            pageData={pageState}
                            onChangePage={(page) => {
                                console.log('Change page...', page);
                            }}
                        />
                    </div>
                </Fragment>
            )}
            {isSuperAdmin && (
                <Fragment>
                    <div className="row align-items-center">
                        <div className="bases__text--label bases__width--60">年月</div>
                        <div className="bases__width--200 me-3">
                            <Datepicker
                                value={monthSearch}
                                onChange={(value) => setMonthSearch(value)}
                                placeholder={t<string>('common.start')}
                                dateFormat="yyyy年MM月"
                                showMonthYearPicker
                                showYearDropDown
                            />
                        </div>
                        <Button
                            onClick={() => {
                                fetchGetListPaymentAsync(searchParams as SearchParamsPayment);
                            }}
                            text={t<string>('common.renew')}
                            className="bases__width--125 bases__border-blue bases__text--blue"
                        />
                    </div>
                    <hr />
                    <div className="d-flex justify-content-xxl-around gap-4 mb-4 flex-wrap">
                        <PaymentCard
                            title="決済期日 "
                            type="primary"
                            value={`${formatAndSubtractOrAddDate(
                                dataSum?.dateTime || new Date(),
                                'months',
                                1,
                                'MM/01',
                                'add',
                            )} ~ ${formatAndSubtractOrAddDate(dataSum?.dateTime || new Date(), 'months', 1, 'MM/10', 'add')}`}
                            subTitle={`(${formatAndSubtractOrAddDate(dataSum?.dateTime || new Date(), 'months', 1, 'YYYY', 'add')}年)`}
                            isMaxWidth
                        />
                        <PaymentCard title="管理会社数" type="primary" value={dataSum?.totalCompany} isMaxWidth />
                        <PaymentCard title="キーボックス数 " type="primary" value={dataSum?.totalKeyBox} subTitle="(台)" isMaxWidth />
                        <PaymentCard title="合計金額 " type="primary" value={dataSum?.totalMoney} subTitle="(円)" isMaxWidth />
                        <PaymentCard title="決済済み " type="second" value={dataSum?.totalMoneyPay} subTitle="(円)" isMaxWidth />
                        <PaymentCard title="未決済 " type="danger" value={dataSum?.totalMoneyUnPaid} subTitle="(円)" isMaxWidth />
                    </div>

                    <Formik
                        initialValues={initialValueSearch}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            const newSearchParam = {
                                ...searchParams,
                                ...values,
                                month: formatDate(monthSearch.toDateString(), 'YYYY/MM'),
                            };
                            setSearchParams(newSearchParam);
                            fetchGetListPaymentAsync(newSearchParam as SearchParamsPayment);
                        }}>
                        {(props) => {
                            const { handleSubmit, values, setFieldValue, resetForm } = props;
                            return (
                                <SearchForm
                                    btnDelete
                                    onSubmit={handleSubmit}
                                    onDelete={resetForm}
                                    isDisableBtnDelete={isEqual(initialValueSearch, values)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row  align-items-center bases__height-percent--100">
                                                <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                                    <label className={cxCommon('label-color')}>{t('payment.title.date')}</label>
                                                </div>
                                                <div className="col-4">
                                                    <Datepicker
                                                        maxDate={values.dateTo ? new Date(values.dateTo) : null}
                                                        placeholder={t<string>('common.start')}
                                                        value={values.dateFrom ? new Date(values.dateFrom) : null}
                                                        onChange={(value) => setFieldValue('dateFrom', moment(value).format('YYYY/MM/DD'))}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-center align-items-center">
                                                    <span>~</span>
                                                </div>
                                                <div className="col-4">
                                                    <Datepicker
                                                        minDate={values.dateFrom ? new Date(values.dateFrom) : null}
                                                        placeholder={t<string>('common.end')}
                                                        value={values.dateTo ? new Date(values.dateTo) : null}
                                                        onChange={(value) => setFieldValue('dateTo', moment(value).format('YYYY/MM/DD'))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row align-items-center bases__height-percent--100">
                                                <FormInput
                                                    maxLength={255}
                                                    name="companyName"
                                                    value={values.companyName}
                                                    className="col-md"
                                                    label="payment.title.company_name"
                                                    placeholder="payment.title.company_name"
                                                    onChangeText={(value) => setFieldValue('companyName', value)}
                                                />
                                                <FormDropdown
                                                    classNameLabel={cx('bases__text--label', 'col-md-4 col-form-label text-end pe-0')}
                                                    label="payment.title.status"
                                                    className={cx('pr-22', 'col-6')}
                                                    classNameStyle="align-items-xxl-start align-items-center"
                                                    name="status"
                                                    options={[
                                                        { value: '', label: t('notification.notificationTypeValue.all') },
                                                        { value: '0', label: t('未決済') },
                                                        { value: '1', label: t('決済済み') },
                                                        { value: '2', label: t('決済失敗') },
                                                    ]}
                                                    value={values.status}
                                                    onChangeOption={(option) => setFieldValue('status', option.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SearchForm>
                            );
                        }}
                    </Formik>

                    <div className="mt-4">
                        <DataTable
                            total={pageState.totalRecords}
                            isStickyColumn
                            heads={dataTableSuperAdmin.heads}
                            body={dataTableSuperAdmin.body}
                            pageData={pageState}
                            onChangePage={(page) => {
                                const searchParamsObject = Object.fromEntries(searchParams.entries());
                                const newSearchParam: SearchParamsPayment = {
                                    ...searchParamsObject,
                                    offset: page - 1,
                                };
                                setSearchParams(newSearchParam as URLSearchParamsInit);
                                fetchGetListPaymentAsync(newSearchParam);
                            }}
                        />
                    </div>
                </Fragment>
            )}
            {isShowMore && (
                <ModalInterface
                    modalHeader="VISA、Mastercard、JCB、AMEX、Diners、ディスカバーの国際ブランド含む30社以上のクレジットカード会社に対応しています。"
                    isShow={isShowMore}
                    hideNegativeButton
                    hidePositiveButton
                    handleClose={() => setIsShowMore(false)}
                    classNameHeader="bases__margin--top24"
                    classNameBody="pt-0">
                    <div className="d-flex">
                        <img className="w-100 mt-2" src={images.IMAGES.ICON_LIST_METHOD}></img>
                    </div>
                </ModalInterface>
            )}
            <PaymentModal
                isShow={isShowPaymentModal}
                handleClose={() => setIsShowPaymentModal(false)}
                handleOK={() => fetchGetListPaymentAsync(searchParams as SearchParamsNotification)}
            />
        </BodyContainer>
    );
}

export default PaymentManagement;
