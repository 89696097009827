import * as yup from 'yup';
import i18n from '../lang/i18next';

export const paymentSchema = yup.object().shape({
    number: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('カード番号') })),
    expires: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('カード有効期限') })),
    cvv: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('セキュリティコード') })),
    name: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('カード名義') })),
});

export const paymentInitialValues: IPaymentForm = {
    number: '',
    expires: '',
    cvv: '',
    name: '',
};
