import { OverlayTrigger as OverlayTriggerBootstrap, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const Tooltip: ITooltipComponent<ITooltipComponentProps> = (props) => {
    const { content, className, children } = props;
    const [timer, setTimer] = useState<number>(0);
    const [show, setShow] = useState<string>('');

    useEffect(() => {
        window.addEventListener('scroll', function () {
            setTimer(timer + 1)
        }, true);
    }, []);

    useEffect(() => {
        setShow('invisible')
        setTimeout(() => {
            setShow('')
            setTimer(0)
        }, 500);
    }, [timer]);

    return (
        <OverlayTriggerBootstrap
            placement="top"
            overlay={
                <TooltipBootstrap style={{ zIndex: 9999, position:'fixed' }} className={`z-3 ${show ?? ''}`}>
                    {(content && content?.length >= 256) ? content?.substring(0, 257).concat('...') : content}
                </TooltipBootstrap>
            }
        >
            <span className={className}>{children}</span>
        </OverlayTriggerBootstrap>
    );
};
Tooltip.defaultProps = {
    content: '',
    className: '',
};

export default Tooltip;
