import { Dispatch } from 'redux';
import { apiHelper } from '~/utils/helpers';
import * as Types from './type';


export const getStaffsAsync = (params: IStaffsSearchParams) => async (dispatch: Dispatch) => {
    return apiHelper
        .getListStaffs(params)
        .then((res) => {
            dispatch({ type: Types.GET_LIST_STAFFS, payload: res?.data });
        })
        .catch((error) => {
            return error;
        });
};

