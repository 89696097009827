import { Button } from '@components/commons';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import commonStyles from '@assets/styles/base/Common.module.scss';
import ModalConfirm from '@components/modals/ModalConfirm';
import Configs from '@utils/configs';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { BodyContainer } from '~/components/layouts';
import { useAuth } from '~/utils/hook/useAuth';
import styles from './Company.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '~/redux/actions';
import useRole from '@utils/hook/useRole';
import { USER_ROLE_ENUM } from '@utils/constants';
import { ReduxStates } from '@redux/reducers';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const CompanyForm: IBaseComp<IBaseCompProps> = (props) => {
    const { t } = useTranslation();
    const { children } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const auth = useAuth();
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);

    const showLogoutAlert = useCallback(() => {
        dispatch(showAlert({
            content: `${t('company_info.company.logout_confirm')}`,
            handleOK: () => {
                localStorage.clear();
                auth.signOut(() => {
                    navigate(Configs.routes.login);
                });
            }
        }));
    }, []);

    function handleHeader() {
        switch (location.pathname) {
            case Configs.routes.companyInfo:
                return t('company_info.company.title');
            case Configs.routes.companyInfoEdit:
                return t('breadcrumb.company.company_edit');
            default:
                return t('change_password.title');
        }
    }

    const handleRedirect = (url: string) => {
        if (location.pathname === url) {
            navigate(0);
        } else {
            navigate(url);
        }
    };

    return (
        <BodyContainer title={handleHeader()}>
            <div className={cxCommon('container-asset')}>
                <div className="row mt-4 d-flex ">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2 d-flex flex-column">
                                {!isSuperAdminPermission &&
                                    <Button
                                        className={cx(
                                            'button-navigate',
                                            'button-container',
                                            location.pathname === Configs.routes.companyInfo ||
                                                location.pathname === Configs.routes.companyInfoEdit
                                                ? 'active-button'
                                                : '',
                                        )}
                                        textAlign="start"
                                        onClick={() => handleRedirect(Configs.routes.companyInfo)}
                                        text={'会社情報'}></Button>}
                                <Button
                                    className={cx(
                                        'button-navigate',
                                        'button-container',
                                        location.pathname === Configs.routes.changePassword ? 'active-button' : 'gl-border-bottom-0',
                                        location.pathname === Configs.routes.companyInfo ||
                                            location.pathname === Configs.routes.companyInfoEdit
                                            ? 'btn-gl-focus-has'
                                            : '',
                                    )}
                                    textAlign="start"
                                    onClick={() => handleRedirect(Configs.routes.changePassword)}
                                    text={'パスワード変更'}></Button>
                                <Button
                                    onClick={() => {
                                        showLogoutAlert();
                                     
                                    }}
                                    className={cx(
                                        'button-navigate',
                                        'button-container',
                                        location.pathname === Configs.routes.companyInfo ||
                                            location.pathname === Configs.routes.companyInfoEdit
                                            ? 'btn-gl-focus'
                                            : '',
                                    )}
                                    text={'ログアウト'}
                                    textAlign="start"></Button>
                            </div>
                            <div className="col-10">
                                <section className={'mt-0 d-block ' + cx('content')}>{children}</section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyContainer>
    );
};

export default CompanyForm;
