import { addBrokerToCompany, getListBroker, getDetailBroker, getListBrokerRegistered, getListBrokerCompany, updateBrokerCompany, updateBrokerCompanyAsset } from './api';

export const fetchGetListBroker = async (params: IIntermediariesSearchParams): Promise<IBaseAPISearchRes<IDataApiIntermediariesRes>> => {
    try {
        const response = await getListBroker(params);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchAddBrokerToCompany = async (id: number) => {
    try {
        const response = await addBrokerToCompany(id);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchDetailBroker = async (id: string | number, params: IDetailBrokerParam) => {
    try {
        const response = await getDetailBroker(id, params);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchGetListBrokerRegistered = async (params: SearchParamsRegisteredBroker): Promise<IBaseAPISearchRes<IDataApiIntermediariesRes>> => {
    try {
        const response = await getListBrokerRegistered(params);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchGetListBrokerCompany = async (params: SearchParamsBrokerCompany): Promise<IBaseAPISearchRes<IDataApiBrokerCompanyRes>> => {
    try {
        const response = await getListBrokerCompany(params);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchUpdateBrokerCompany = async (body: IBodyAutoApproveBrokerCompany): Promise<IBaseAPIRes> => {
    try {
        const response = await updateBrokerCompany(body);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchUpdateBrokerCompanyAsset = async (body: IBodyAutoApproveBrokerCompany): Promise<IBaseAPIRes> => {
    try {
        const response = await updateBrokerCompanyAsset(body);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};
