import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormDropdown, FormInput, FormRadio, Tooltip } from '@components/commons';
import classNames from 'classnames/bind';
import styles from './AssetRegister.module.scss';
import { ButtonStyle, TableStyle, Layout, Input, Common } from '@assets/styles';
import { USER_ROLE_ENUM, images } from '@utils/constants';
import { BodyContainer } from '~/components/layouts';
import EditRoomModal from '~/components/modals/EditRoomModal';
import { IMAGES } from '@utils/constants/images';
import { useFormik } from 'formik';
import ModalConfirm from '@components/modals/ModalConfirm';
import EnterAddressModal from '@components/modals/EnterAddressModal';
import { formatPostalCode, handleGetFullAddress } from '@utils/helpers/utilities';
import ModalInterface from '@components/commons/Modal';
import { registerAsset, exportRoom, importRoom } from '@utils/helpers/api';
import { setToast, showAlert } from '@redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Configs from '@utils/configs';
import DataTable from '@components/commons/Table/Table';
import { ReduxStates } from '@redux/reducers';
import { downloadTextFile } from '@utils/helpers/file';
import useRole from '@utils/hook/useRole';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);
const cxTable = classNames.bind(TableStyle);
const cxButton = classNames.bind(ButtonStyle);
const cxLayout = classNames.bind(Layout);
const cxInput = classNames.bind(Input);
const cxCommon = classNames.bind(Common);
interface FileCustom extends File {
    preview?: string;
}
interface InitValueFormik {
    room_classification: string;
    images: IImageSlide[];
    name: string;
    publication: number;
    address: string;
    usage_classification: string;
    info: string;
    postal_code: string;
    prefecture: string;
    city: string;
    town: string;
    building: string;
}
const listOption = [
    {
        value: '賃貸居住用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '戸建',
                label: '戸建',
            },
            {
                value: 'マンション',
                label: 'マンション',
            },
            {
                value: 'アパート',
                label: 'アパート',
            },
            {
                value: '文化',
                label: '文化',
            },
            {
                value: '間貸し',
                label: '間貸し',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '賃貸事業用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '店舗',
                label: '店舗',
            },
            {
                value: '事務所',
                label: '事務所',
            },
            {
                value: '倉庫',
                label: '倉庫',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '売買居住用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '戸建',
                label: '戸建',
            },
            {
                value: 'マンション',
                label: 'マンション',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
    {
        value: '売買事業用',
        option: [
            {
                value: '',
                label: '',
            },
            {
                value: '店舗',
                label: '店舗',
            },
            {
                value: '事務所',
                label: '事務所',
            },
            {
                value: '倉庫',
                label: '倉庫',
            },
            {
                value: 'その他',
                label: 'その他',
            },
        ],
    },
];
const AssetRegister: IAssetRegisterPage<IAssetRegisterPageProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [imageSlide, setImageSlide] = useState<string>('');
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const [state, setState] = useState<IAssetRegisterPageState>({
        dataEdit: {},
    });
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [showModalAdd, setShowModalAdd] = useState<IModalAssetRegister>({
        isShow: false,
        data: {},
    });
    const [csvErrorMess, setCcsvErrorMess] = useState<string[]>([]);
    const [csvImageRoomErrorIndex, setCsvImageRoomErrorIndex] = useState<number[]>([]);
    const [csvImageKeyStoreErrorIndex, setCsvImageKeyStoreErrorIndex] = useState<number[]>([]);
    const { sidebar, userInfo } = useSelector((states: ReduxStates) => states);
    const isAdminPermission = useRole(userInfo, USER_ROLE_ENUM.admin);

    const inputCsvFileRef = createRef<HTMLInputElement>();

    const [listFile, setListFile] = useState<FileCustom[]>([]);
    const [showModalEdit, setShowModalEdit] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [roomEdittingId, setRoomEdittingId] = useState<number>();
    const dragItem = useRef<HTMLDivElement | null>(null);
    const dragIndex = useRef(0);
    const dropItem = useRef<HTMLDivElement | null>(null);
    const dropIndex = useRef(0);
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
        showSecondModal: false,
        showThirdModal: false,
    });
    const [dataRoom, setDataRoom] = useState<IAddRoom[]>([]);
    const [roomOptions, setRoomOptions] = useState<FormOptionType[] | undefined>([]);
    const { showFirstModal, showSecondModal, showThirdModal } = modalState;
    const [isEdit, setIsEdit] = useState(false);
    const [isUploadCsv, setIsUploadCsv] = useState(false);
    const { dataEdit } = state;
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);

    const initValueFormik: InitValueFormik = {
        info: '',
        room_classification: '',
        images: [],
        address: '',
        usage_classification: '',
        name: '',
        publication: 0,
        postal_code: '',
        prefecture: '',
        city: '',
        town: '',
        building: '',
    };
    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave)

    useEffect(() => {
        if (isEdit && showModalAdd?.data) {
            setState((prevState) => ({ ...prevState, dataEdit: showModalAdd?.data }));
        }
    }, [isEdit, showModalAdd?.data]);

    useEffect(() => {
        if (showModalAdd.isShow) {
            window.onpopstate = function () {
                setShowModalAdd({
                    ...showModalAdd,
                    isShow: false,
                });
            };
        }
        else {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = () => {
                if (window.confirm('サイトから移動しますか？\n変更内容が保存されない可能性があります。')) {
                    window.onpopstate = null;
                    history.go(-4);
                    document.body.style.height = '4000px';
                } else {
                    window.history.pushState(null, "", window.location.href);

                }
            };
        }
    }, [showModalAdd.isShow]);

    useEffect(() => {
        if (!isEdit) {
            setState((prevState) => ({ ...prevState, dataEdit: null }));
        }
    }, [isEdit]);

    const handleCloseRoomModalEdit = () => {
        setShowModalEdit(false);
    };
    const handleCloseRoomModalAdd = () => {
        setShowModalAdd({
            ...showModalAdd,
            isShow: false,
        });
    };

    const roomEditModal = (
        <EditRoomModal
            positiveButtonText="common.renew"
            isShow={showModalEdit}
            header={t<string>('部屋編集')}
            handleClose={handleCloseRoomModalEdit}></EditRoomModal>
    );
    const handleOk = (values: any) => {
        if (isEdit) {
            const list = [...dataRoom];
            const indexEdit = list?.findIndex((item) => item?.id === values?.id);
            list[indexEdit] = values;
            setDataRoom([...list]);
            dispatch(
                setToast({
                    isShow: true,
                    message: `${t('common.save_data')}`,
                }),
            );
        } else {
            const data = {
                ...values,
                id: dataRoom?.length + 1,
            };
            dataRoom?.unshift(data);
            setDataRoom([...dataRoom]);
            dispatch(
                setToast({
                    isShow: true,
                    message: `${t('common.add_success')}`,
                }),
            );
            setDataRoom([...dataRoom]);
        }
    };

    const roomAddModal = (
        <EditRoomModal
            roomEdittingId={roomEdittingId}
            dataListRoom={dataRoom}
            positiveButtonText={roomEdittingId ? 'common.save' : 'common.add'}
            data={dataEdit}
            isShow={showModalAdd?.isShow}
            handleOK={handleOk}
            header={t<string>(`${roomEdittingId ? 'asset.title_edit_room' : 'asset.title_register_property'}`)}
            handleClose={() => {
                handleCloseRoomModalAdd();
                setRoomEdittingId(undefined);
            }}
        />
    );

    const handleConfirmDeleteRoom = (index: any) => {
        dispatch(
            showAlert({
                header: 'common.remove',
                content: (
                    <span>
                        {t('common.delete_data')}
                        <br />
                        {t('common.confirm_delete')}
                    </span>
                ),
                handleOK: () => {
                    setDataRoom(dataRoom.filter((item, i) => i !== index).map((item, index) => ({ ...item, id: index + 1 })));
                    dispatch(
                        setToast({
                            isShow: true,
                            message: `${t('common.delete_success')}`,
                        }),
                    );
                },
            }),
        );
    };

    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };

    const handleRemoveImage = (imageId: number, index: number) => {
        let listImageCopy = [...listImageSlide];
        const maxIndexOfList = listImageCopy.length - 1;
        listImageCopy = listImageCopy.filter((image) => image.order !== imageId);
        const newListImage = listImageCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
        if (index < activeIndex || activeIndex === maxIndexOfList) {
            setActiveIndex(activeIndex - 1);
        }
    };
    const validation = () => {
        const isValidName = validateName(formik.values.name);
        const isValidAddress = validateAddress(formik.values.address);
        const isValidRoomType = validateRoomType(formik.values.usage_classification, formik.values.room_classification);
        return !isValidName || !isValidAddress || !isValidRoomType ? false : true;
    };
    const validateName = (name?: string) => {
        if (name === '') {
            formik.setFieldError('name', 'asset.error_empty_asset_name');
            return false;
        } else {
            formik.setFieldError('name', '');
            return true;
        }
    };
    const validateAddress = (address?: string) => {
        if (address === '') {
            formik.setFieldError('address', 'asset.error_empty_address');
            return false;
        } else {
            formik.setFieldError('address', '');
            return true;
        }
    };
    const validateRoomType = (usage_classification?: string, room_classification?: string) => {
        if (usage_classification && room_classification === '') {
            formik.setFieldError('room_classification', 'asset.validate_roomType');
            return false;
        } else {
            formik.setFieldError('room_classification', '');
            return true;
        }
    };
    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: initValueFormik,
        onSubmit: (values) => {
            const formData = new FormData();
            // handle list image
            const listImage = formik.values.images;
            const listOnlyFile = listImage && listImage.filter((image) => image);
            if (listOnlyFile?.length) {
                listOnlyFile.map((file) => {
                    formData.append('images', file.file as Blob);
                });
            }
            let format;
            if (dataRoom.length) {
                format = dataRoom.map((item, index) => {
                    if (item.images.length) {
                        item.images.map((file) => {
                            formData.append((index + 1).toString(), file.file as Blob);
                        });
                    }
                    if (item.storeKeyImage) {
                        formData.append(`${index + 1}_store_key_image`, item.storeKeyImage as Blob);
                    }
                    return { ...item, index: index + 1 };
                });
            }

            const asset = { ...formik.values, rooms: format };
            formData.append('data', JSON.stringify(asset));
            //handleClose()
            if (validation()) {
                setIsShowPopupLeave(false)
                registerAsset(formData)
                    .then(({ data }) => {
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.add_success')}`,
                            }),
                        );
                        navigate(Configs.routes.asset);
                        setIsShowPopupLeave(true)
                    })
                    .catch((err) => {
                        if (err.data.message === 'NAME_ASSET_EXISTED') {
                            formik.setFieldError('name', 'message.sample_name_asset');
                            setIsShowPopupLeave(true)
                        }
                    });
            }
        },
    });
    const passwordInputRef = createRef<IValidatorPasswordSubmit>();

    const handleUploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listFile: FileCustom[] = Array.from(e.target?.files || []);
        listFile.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        setListFile(listFile);
        e.target.value = '';
    };

    useEffect(() => {
        if (listFile.length > 0) {
            const listImageSlideCopy = [...listImageSlide];
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const totalImage = listImageSlideCopy.length + listFile.length;

            if (totalImage > 5) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_5_number');
                showOrHideModal('showFirstModal');
                return;
            }
            listFile.forEach((file) => {
                const fileSize = Number((file.size / 1024 / 1024).toFixed(2));
                if (!validImageTypes.includes(file.type)) {
                    setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                    showOrHideModal('showFirstModal');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                } else if (fileSize > 8) {
                    setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                }
                const orders = listImageSlideCopy.map((imageSlide) => imageSlide.order);
                const maxOrder = orders.length > 0 ? Math.max(...orders) : -1;

                const newImage: IImageSlide = {
                    order: maxOrder + 1,
                    url: file.preview || '',
                    file: file,
                    name: file.name,
                };
                listImageSlideCopy.push(newImage);
            });
            setListImageSlide(listImageSlideCopy);
        }
    }, [listFile]);

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };
    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };
    useEffect(() => {
        handleUpdateImageSlide();
        handleChange('images', listImageSlide);
    }, [listImageSlide]);

    useEffect(() => {
        handleUpdateImageSlide();
    }, [activeIndex]);
    const handleUpdateImageSlide = () => {
        if (listImageSlide?.length) {
            setImageSlide(listImageSlide[activeIndex].url);
        } else {
            setImageSlide('');
            setActiveIndex(0);
        }
    };
    const handleSubmitAddress = (values: IEnterAddressForm) => {
        const address = handleGetFullAddress({ ...values, postalCode: formatPostalCode(values.postalCode) });
        handleChange('postal_code', formatPostalCode(values.postalCode));
        handleChange('address', address);
        handleChange('prefecture', values.prefecture);
        handleChange('city', values.city);
        handleChange('town', values.town);
        handleChange('building', values.building);
        showOrHideModal('showSecondModal');
    };
    const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
        dragIndex.current = index;
        dragItem.current = e.target as HTMLDivElement;
        dragItem.current.style.opacity = '0.4';
    };

    const handleDragOver = (index: number, e: React.DragEvent) => {
        dropIndex.current = index;
        dropItem.current = e.target as HTMLDivElement;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        if (dragItem.current) {
            dragItem.current.style.opacity = '1';
        }
        if (dropItem.current === dragItem.current) return;

        const imagesCopy = [...listImageSlide];

        const draggedImage = imagesCopy.splice(dragIndex.current, 1)[0];
        imagesCopy.splice(dropIndex.current, 0, draggedImage);
        const newListImage = imagesCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
    };

    useEffect(() => {
        handleChange('room_classification', '');
        if (formik.values.usage_classification) {
            setRoomOptions(listOption?.find((item) => item.value === formik.values.usage_classification)?.option || []);
        }
    }, [formik.values.usage_classification]);
    const handleConfirmCloseAssset = () => {
        setIsShowPopupLeave(false)
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: <span>{t('common.confirm_cancel')}</span>,
                handleOK: () => {
                    navigate(Configs.routes.asset);
                    setIsShowPopupLeave(true)
                },
            }),
        );
    };
    useEffect(() => {
        if (!dataRoom.length || !dataRoom?.find((item) => item.publication === 1)) {
            formik.setFieldValue('publication', 0);
        }
    }, [dataRoom]);

    const handleFetchExportRoom = () => {
        exportRoom()
            .then((res) => {
                downloadTextFile('Template-import.csv', res.data.trim());
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFetchImportRoom = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event?.target?.files;
        const file = files ? files[0] : null;
        setIsUploadCsv(true);
        if (files) {
            if (!file?.type?.includes('zip') && !file?.type?.includes('csv')) {
                dispatch(
                    showAlert({
                        header: 'common.error_title',
                        content: `${t('message.invalid_import_type')}`,
                        hiddenCancel: true,
                    }),
                );
            } else if (file?.size >= 1073741824) {
                dispatch(
                    showAlert({
                        header: 'common.error_title',
                        content: `${t('message.invalid_import_size')}`,
                        hiddenCancel: true,
                    }),
                );
            } else {
                const data = {
                    ...(file?.type?.includes('zip') ? { images: file } : {}),
                    ...(file?.type?.includes('csv') ? { data: file } : {}),
                };
                importRoom(data)
                    .then((res) => {
                        let rooms = res.data.data.rooms;
                        const messages = res.data.data.messages;
                        const messagesDetect = [
                            'INVALID_#ROOM_NUMBER#',
                            'INVALID_#STATUS#',
                            'INVALID_#PUBLICATION#',
                            'ROOM_EXISTED#',
                            'INVALID_#IMAGE_OVER_SIZE#',
                            'INVALID_#IMAGE_TYPE#',
                        ];

                        let csvInvalidRoomNumberErrorMess = '';
                        let csvInvalidStatusErrorMess = '';
                        let csvInvalidPublicationErrorMess = '';
                        let csvRoomNameExistedErrorMess = '';
                        let csvInvalidImageTypeErrorMess = '';
                        let csvInvalidImageOverSizeErrorMess = '';
                        messages.forEach((message: string) => {
                            messagesDetect.forEach((detect, index) => {
                                if (message.includes(detect)) {
                                    const rowPath = message.replace(detect, '').replace('#', '');
                                    const rows = rowPath.split(',');
                                    for (const row of rows) {
                                        const rowNum = parseInt(row);
                                        if (index === 0) {
                                            if (!csvInvalidRoomNumberErrorMess) {
                                                csvInvalidRoomNumberErrorMess = `${t('message.import_room_name_required')}${rowNum + 1}`;
                                            } else {
                                                csvInvalidRoomNumberErrorMess = csvInvalidRoomNumberErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                        if (index === 1) {
                                            if (!csvInvalidStatusErrorMess) {
                                                csvInvalidStatusErrorMess = `${t('message.import_room_status_invalid')}${rowNum + 1}`;
                                            } else {
                                                csvInvalidStatusErrorMess = csvInvalidStatusErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                        if (index === 2) {
                                            if (!csvInvalidPublicationErrorMess) {
                                                csvInvalidPublicationErrorMess = `${t('message.import_room_publication_invalid')}${rowNum + 1
                                                    }`;
                                            } else {
                                                csvInvalidPublicationErrorMess = csvInvalidPublicationErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                        if (index === 3) {
                                            if (!csvRoomNameExistedErrorMess) {
                                                csvRoomNameExistedErrorMess = `${t('message.import_room_existed')}${rowNum + 1}`;
                                            } else {
                                                csvRoomNameExistedErrorMess = csvRoomNameExistedErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                        if (index === 4) {
                                            if (!csvInvalidImageOverSizeErrorMess) {
                                                csvInvalidImageOverSizeErrorMess = `${t('message.import_csv_invalid_image_size')}${rowNum + 1
                                                    }`;
                                            } else {
                                                csvInvalidImageOverSizeErrorMess = csvInvalidImageOverSizeErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                        if (index === 5) {
                                            if (!csvInvalidImageTypeErrorMess) {
                                                csvInvalidImageTypeErrorMess = `${t('message.import_csv_invalid_image_type')}${rowNum + 1}`;
                                            } else {
                                                csvInvalidImageTypeErrorMess = csvInvalidImageTypeErrorMess + `、${rowNum + 1}`;
                                            }
                                        }
                                    }
                                }
                            });
                        });

                        let csvNotFoundImageErrorMess = '';
                        let csvRoomNameExistedTableErrorMess = '';
                        const csvImageRoomErrorIndex = [];
                        const csvImageKeyStoreErrorIndex = [];
                        for (const [index, room] of rooms.entries()) {
                            if ((room.image && room?.images?.length === 0) || (room.store_key_image && !room?.logo?.preview)) {
                                if (!csvNotFoundImageErrorMess) {
                                    csvNotFoundImageErrorMess = `${t('message.import_not_found_image')}${index + 1}`;
                                } else {
                                    csvNotFoundImageErrorMess = csvNotFoundImageErrorMess + `、${index + 1}`;
                                }
                                if (room.image && room?.images?.length === 0 && csvImageRoomErrorIndex.indexOf(index) < 0) {
                                    csvImageRoomErrorIndex.push(index);
                                }
                                if (room.store_key_image && !room?.logo?.preview && csvImageKeyStoreErrorIndex.indexOf(index) < 0) {
                                    csvImageKeyStoreErrorIndex.push(index);
                                }
                            }
                            if (dataRoom.find((item: any) => item.room_number === room.room_number)) {
                                if (!csvRoomNameExistedTableErrorMess) {
                                    csvRoomNameExistedTableErrorMess = `${t('message.import_room_existed')}${index + 1}`;
                                } else {
                                    csvRoomNameExistedTableErrorMess = csvRoomNameExistedTableErrorMess + `、${index + 1}`;
                                }
                            }
                        }
                        if (csvRoomNameExistedTableErrorMess) {
                            csvRoomNameExistedErrorMess = csvRoomNameExistedTableErrorMess;
                        }
                        setCsvImageRoomErrorIndex(csvImageRoomErrorIndex);
                        setCsvImageKeyStoreErrorIndex(csvImageKeyStoreErrorIndex);

                        rooms = rooms
                            ?.filter((room: any) => !dataRoom.find((item: any) => item.room_number === room.room_number))
                            .map((room: any, index: number) => {
                                return {
                                    ...room,
                                    id: index + 1,
                                };
                            })
                            .concat(dataRoom);
                        setDataRoom(rooms);
                        setCcsvErrorMess([
                            csvNotFoundImageErrorMess,
                            csvInvalidRoomNumberErrorMess,
                            csvInvalidStatusErrorMess,
                            csvInvalidPublicationErrorMess,
                            csvRoomNameExistedErrorMess,
                            csvInvalidImageTypeErrorMess,
                            csvInvalidImageOverSizeErrorMess,
                        ]);
                        setIsUploadCsv(false);
                    })
                    .catch((err) => {
                        const message = err.data.message;

                        if (message === 'INVALID_CSV_HEADER') {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: `${t('message.csv_invalid_header')}`,
                                    hiddenCancel: true,
                                }),
                            );
                        }
                        if (message === 'CSV_CONTENT_REQUIRED') {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: `${t('message.csv_invalid_content')}`,
                                    hiddenCancel: true,
                                }),
                            );
                        }
                        if (message === 'INVALID_CSV_DATA') {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: `${t('message.csv_invalid_zip')}`,
                                    hiddenCancel: true,
                                }),
                            );
                        }
                        if (message === 'INVALID_CSV_MAX_ROW_CONTENT') {
                            dispatch(
                                showAlert({
                                    header: 'common.error_title',
                                    content: `${t('message.csv_max_record')}`,
                                    hiddenCancel: true,
                                }),
                            );
                        }
                        setIsUploadCsv(false);
                    });
            }
        }
    };

    const handleTableDatas = () => {
        return dataRoom?.map((item: any, index) => {
            return {
                ...item,
                room_image: (
                    <img
                        className={
                            cx('asset-image') +
                            (csvImageRoomErrorIndex.indexOf(index) >= 0 && !item?.images?.length ? ' bases__border--red' : '')
                        }
                        src={item?.images?.length ? item.images[0].url : images.IMAGES.TEMP_IMAGE}
                        alt=""
                    />
                ),
                key_storage_photo: (
                    <img
                        className={
                            cx('asset-image') +
                            (csvImageKeyStoreErrorIndex.indexOf(index) >= 0 && !item?.storeKeyImage?.preview ? ' bases__border--red' : '')
                        }
                        src={item?.storeKeyImage?.preview ? item?.storeKeyImage?.preview : images.IMAGES.TEMP_IMAGE}
                        alt=""
                    />
                ),
                status: (
                    <div className={!item.status ? cx('text-positive') : cx('text-danger')}>
                        {item.status ? t('asset.rent') : t('asset.vacant')}
                    </div>
                ),
                raw_status: item.status ? t('asset.rent') : t('asset.vacant'),
                publication: (
                    <div className={item.publication ? cx('text-positive') : cx('text-danger')}>
                        {item.publication ? t('asset.public') : t('asset.private')}
                    </div>
                ),
                raw_publication: item.publication ? t('asset.public') : t('asset.private'),
                action: (
                    <div className="h-100 d-flex justify-content-center">
                        <Button
                            onClick={() => {
                                setShowModalAdd({
                                    isShow: true,
                                    data: dataRoom[index],
                                });
                                setIsEdit(true);
                                setRoomEdittingId(dataRoom[index].id);
                            }}
                            className={cxButton('button-approve') + ' ' + cxCommon('font-size-12')}
                            text="編集"
                        />

                        <Button
                            onClick={() => handleConfirmDeleteRoom(index)}
                            className={cxButton('button-warning', 'ms-1') + ' ' + cxCommon('font-size-12')}
                            text="削除"
                        />
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('asset.detail_asset.room_number'),
            },
            {
                title: t('asset.room_photo'),
            },
            {
                title: t('asset.key_storage'),
            },
            {
                title: t('asset.key_storage_photo'),
            },
            {
                title: t('asset.rentVacant'),
            },
            {
                title: t('asset.publicPrivate'),
            },
            {
                title: t('asset.edit_delete'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'room_number',
                },
                {
                    field: 'room_image',
                    isNotTooltip: true,
                },
                {
                    field: 'key_storage',
                },
                {
                    field: 'key_storage_photo',
                    isNotTooltip: true,
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'publication',
                    rawField: 'raw_publication',
                },
                {
                    field: 'action',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };

    return (
        <>
            {isAdminPermission && (
                <BodyContainer title={t('title.property_register')}>
                    <form className={'position-relative w-100'} onSubmit={formik.handleSubmit}>
                        <div className="row w-100">
                            <div className={'col-6 ' + cx('text-title')}>{t('asset.assetDetail')}</div>
                            <div className={'col-6 ' + cx('text-title')}>{t('asset.roomDetail')}</div>
                        </div>
                        <div className="row w-100 mt-3">
                            <div className="col-6 h-auto">
                                <div className={'height-content h-100 ' + cx('content')}>
                                    <div>
                                        <div className="col m-auto">
                                            <div className="row">
                                                <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                                                    <img
                                                        className={cx(
                                                            'company-image',
                                                            imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover',
                                                        )}
                                                        src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        'd-flex justify-content-center align-items-center col-12 m-auto ' + cx('slide')
                                                    }>
                                                    <div className={cx('slide_container') + ' d-flex'}>
                                                        <div ref={slideRef} className={cx('slide_list')}>
                                                            {listImageSlide.map((image, index) => {
                                                                return (
                                                                    <div
                                                                        key={image.order}
                                                                        onDragStart={(e) => handleDragStart(index, e)}
                                                                        onDragOver={(e) => handleDragOver(index, e)}
                                                                        onDragEnd={() => handleDragEnd()}
                                                                        className={
                                                                            cx('slide_img_edit') +
                                                                            ' ' +
                                                                            cx(activeIndex === index ? 'slide_img_edit--active' : '')
                                                                        }>
                                                                        <img
                                                                            onClick={() => handleRemoveImage(image.order, index)}
                                                                            src={IMAGES.ICON_CLOSE_BG}
                                                                            alt=""
                                                                            className={cx('img_close')}
                                                                        />
                                                                        <img
                                                                            onClick={() => handleClick(index)}
                                                                            src={image.url}
                                                                            alt=""
                                                                            className={cx('img_edit')}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            <div className={cx('slide_btn_upload')}>
                                                                <div aria-disabled={listImageSlide.length >= 5}>
                                                                    {listImageSlide.length >= 5 ? (
                                                                        <img
                                                                            className={cx('img-upload')}
                                                                            src={IMAGES.ICON_INPUT_IMG_DISABLED}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className={cx('img-upload')}
                                                                            src={IMAGES.ICON_INPUT_IMG}
                                                                            onClick={() => fileInputRef1.current?.click()}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <input
                                                                    style={{ display: 'none' }}
                                                                    ref={fileInputRef1}
                                                                    type="file"
                                                                    accept="image/*"
                                                                    className="input-upload"
                                                                    onChange={(e) => handleUploadMultipleFiles(e)}
                                                                    multiple
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cxCommon('bases__margin--top30')}>
                                            <FormInput
                                                textStart
                                                maxLength={255}
                                                required
                                                placeholder="asset.assetName"
                                                label="asset.detail_asset.name"
                                                value={formik.values.name}
                                                onChangeText={(text) => {
                                                    return handleChange('name', text);
                                                }}
                                                error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                                            />
                                            <div className={cxCommon('bases__margin--top20', 'd-flex row')}>
                                                <div className="col-md-4">
                                                    <div
                                                        className={cxCommon(
                                                            'label_asterisk',
                                                            'label-color',
                                                        )}
                                                        >{t('asset.address')}
                                                    </div>
                                                </div>
                                                <div className="row col">
                                                    <div className="col-xxl-9 col-8">
                                                        <FormInput
                                                            tooltip={true}
                                                            required
                                                            disabled={true}
                                                            textStart
                                                            value={formik.values.address}
                                                            placeholder={t<string>('asset.address')}
                                                            onChangeText={(text) => handleChange('address', text)}
                                                            inputRef={passwordInputRef}
                                                            error={formik.errors.address ? formik.errors.address : undefined}
                                                        />
                                                    </div>
                                                    <div className='col-xxl-3 col-4 d-flex justify-content-center'>
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                showOrHideModal('showSecondModal');
                                                            }}
                                                            text={t<string>('common.enter')}
                                                            className='bases__width-percent--100 bases__height--40'
                                                            search></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <FormDropdown
                                                className={cxCommon('bases__margin--top20')}
                                                label="asset.type_of_use"
                                                value={formik.values.usage_classification}
                                                defaultValue={formik.values.usage_classification}
                                                classNameOption={cxCommon('bases__height--32')}
                                                options={[
                                                    { value: '', label: '' },
                                                    { value: '賃貸居住用', label: '賃貸居住用' },
                                                    { value: '賃貸事業用', label: '賃貸事業用' },
                                                    { value: '売買居住用', label: '売買居住用' },
                                                    { value: '売買事業用', label: '売買事業用' },
                                                ]}
                                                onChangeOption={(option) => handleChange('usage_classification', option.value)}
                                            />
                                            <FormDropdown
                                                classNameOption={cxCommon('bases__height--32')}
                                                className={cxCommon('bases__margin--top20')}
                                                label="asset.room_type"
                                                value={formik.values.room_classification}
                                                defaultValue={formik.values.room_classification}
                                                options={roomOptions}
                                                disabled={formik.values.usage_classification === '' ? true : false}
                                                onChangeOption={(option) => handleChange('room_classification', option.value)}
                                                error={
                                                    formik.touched.room_classification && formik.errors.room_classification
                                                        ? formik.errors.room_classification
                                                        : undefined
                                                }
                                            />
                                            <div className={cx('form-radio')}>
                                                <FormRadio
                                                    disabled={dataRoom?.find((item) => item.publication === 1) ? false : true}
                                                    classNameInput="size_checkbox"
                                                    className="mt-1"
                                                    classNameContent="w-100"
                                                    items={[
                                                        { label: 'asset.public', value: 1, textColorCustom: 'text_color_green' },
                                                        { label: 'asset.private', value: 0, textColorCustom: 'text_color_red' },
                                                    ]}
                                                    defaultCheckedIndex={formik.values.publication}
                                                    onCheckItem={(value) => handleChange('publication', value)}
                                                />
                                                <div className={cxCommon('bases__margin--left20')}>
                                                    <span className={cxCommon('text_color_red')}>{t('common.note')}</span>
                                                    <br />
                                                    <span className={cxCommon('text_color_green')}>{t('asset.Release')}</span>
                                                    <span>{t('asset.cannot_change_to')}:</span>
                                                    <br />
                                                    <span>{t('asset.property_without_room')}</span>
                                                    <br />
                                                    <span>{t('asset.all_room')}</span>
                                                </div>
                                            </div>
                                            <FormInput
                                                classNameTextArea={cxCommon('bases__height-min--128')}
                                                textStart
                                                textArea
                                                rows={6}
                                                maxLength={3000}
                                                className={cxCommon('bases__margin--top20')}
                                                label="asset.property_information"
                                                onChangeText={(text) => {
                                                    return handleChange('info', text);
                                                }}
                                                value={formik.values.info}
                                                placeholder="asset.placeholder_property"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 h-auto">
                                <div className={cxCommon('height-content h-100 ') + ' ' + cx('content')}>
                                    <div className="row">
                                        <div className="">
                                            <div className="row mb-3">
                                                <div className="col-4">
                                                    <Button
                                                        onClick={() => {
                                                            setShowModalAdd({
                                                                isShow: true,
                                                                data: {},
                                                            });
                                                            setIsEdit(false);
                                                        }}
                                                        className={cx('button-content')}
                                                        text={t<string>('asset.addRoom')}
                                                        add
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                {csvErrorMess.map((mess, index) => (
                                                    <div key={index} className={'mb-2 ' + cxCommon('text_color_red', 'font-size-12')}>
                                                        {mess}
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                <DataTable
                                                    classNameWrapper={`${csvErrorMess.length
                                                        ? 'bases__height-max--460'
                                                        : sidebar
                                                            ? 'bases__height-max--700'
                                                            : 'bases__height-max--750'
                                                        }`}
                                                    isStickyColumn
                                                    heads={table.heads}
                                                    body={table.body}
                                                    isHidePagination={true}
                                                    total={dataRoom.length}
                                                    pageData={{
                                                        totalRecords: dataRoom.length,
                                                    }}
                                                />
                                                {dataRoom.length > 0 && (
                                                    <div className="mt-2">
                                                        <span
                                                            className={cxCommon(
                                                                'text-color-blue-custom',
                                                                'bases__padding0',
                                                                'font-size-12',
                                                            )}>
                                                            {dataRoom && dataRoom.length}件
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="col d-flex justify-content-center flex-wrap">
                                                    <Button
                                                        className={`mt-3 ${!sidebar ? 'me-3' : ''} ` + cx('button-option')}
                                                        text={t<string>('asset.btn_register.registration_format_download')}
                                                        onClick={() => handleFetchExportRoom()}
                                                    />
                                                    <Button
                                                        className={'mt-3 ' + cx('button-option')}
                                                        text={t<string>('asset.btn_register.CSV_import')}
                                                        onClick={() => inputCsvFileRef.current?.click()}
                                                    />
                                                    <input
                                                        type="file"
                                                        key={isUploadCsv.toString()}
                                                        ref={inputCsvFileRef}
                                                        className="d-none"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleFetchImportRoom(event)
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center text-center">
                                                    <div className={cxCommon('font-size-12', 'mt-1')}>
                                                        <span>{t('asset.sample_form')}</span>
                                                        <span
                                                            onClick={() => {
                                                                showOrHideModal('showThirdModal');
                                                            }}
                                                            className={cx('text-noti')}>
                                                            {t('asset.here')}
                                                        </span>
                                                        <span>{t('asset.check')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="row col-12 gap-4 justify-content-center">
                                <Button
                                    type="submit"
                                    className={cx('button-bottom')}
                                    text={t<string>('asset.btn_register.register')}
                                    edit
                                />
                                <Button
                                    onClick={() => handleConfirmCloseAssset()}
                                    className={cx('button-bottom')}
                                    text={t<string>('asset.btn_register.cancel')}
                                    del
                                />
                            </div>
                        </div>
                    </form>
                    {showFirstModal && (
                        <ModalConfirm
                            hideNegativeButton
                            isShow={showFirstModal}
                            handleOK={() => showOrHideModal('showFirstModal')}
                            handleClose={() => showOrHideModal('showFirstModal')}
                            header={t<string>('company_info.company_edit.modal.error_title')}
                            content={t<string>(errorMessage)}></ModalConfirm>
                    )}
                    {showThirdModal && (
                        <ModalInterface
                            classNameCancel={cxCommon('bases__margin--bottom20')}
                            classNameHeader={cxCommon('bases__margin--top20')}
                            hidePositiveButton
                            dialogClassname={cx('text_notification', 'bases__height-max-vh--70')}
                            negativeButtonText="common.close"
                            isShow={showThirdModal}
                            modalHeader={t('asset.header_modal_sample_form')}
                            positiveButtonText="common.ok"
                            classNameBody={cx('content-popup')}
                            classNamebutton={cx('button')}
                            handleClose={() => showOrHideModal('showThirdModal')}>
                            <span className="fw-bold">{t('asset.notification1')}</span>
                            <span>{t('asset.notification2')}</span>
                            <span className="fw-bold">{t('asset.notification3')}</span>
                            <span>{t('asset.notification4')}</span>
                            <span className="fw-bold">{t('asset.notification5')}</span>
                            <span>{t('asset.notification6')}</span>
                            <span className="fw-bold">{t('asset.notification7')}</span>
                            <span>{t('asset.notification8')}</span>
                        </ModalInterface>
                    )}
                    {showSecondModal && (
                        <EnterAddressModal
                            dataInit={{
                                postalCode: formik.values.postal_code ? formik.values.postal_code.replace('-', '') : '',
                                prefecture: formik.values.prefecture,
                                city: formik.values.city,
                                town: formik.values.town,
                                building: formik.values.building,
                            }}
                            isShow={showSecondModal}
                            handleOK={handleSubmitAddress}
                            handleClose={() => showOrHideModal('showSecondModal')}
                        />
                    )}
                    {roomEditModal}
                    {roomAddModal}
                </BodyContainer>
            )}
        </>
    );
};

export default AssetRegister;
