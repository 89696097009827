import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FormInput } from '@components/commons';

import { ButtonStyle, Common } from '@assets/styles';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import DataTable from '@components/commons/Table/Table';
import BrokerDetailModal from '@components/modals/BrokerDeatailModal';
import CompanyApprovalSetting from '@components/modals/CompanyApprovalSetting';
import PropertyApprovalSetting from '@components/modals/PropertyApprovalSetting';
import { setToast, showAlert } from '@redux/actions';
import { ReduxStates } from '@redux/reducers';
import { fetchDetailBroker, fetchGetListBrokerRegistered, fetchUpdateBrokerCompanyAsset } from '@utils/helpers/brokerApi';
import { convertToHalfSize, removeSpecialCharacter } from '@utils/helpers/utilities';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import { APPROVE_STATUS, images, pageStateInit } from '~/utils/constants';
import styles from './BrokerUser.module.scss';
import { fetchUserInfo } from '@utils/helpers/userApi';
import { setListDataAll } from '@redux/actions/CommonAction';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);
const cxButton = classNames.bind(ButtonStyle);

const initialValueSearch: SearchParamsRegisteredBroker = {
    register_from_time: '',
    register_to_time: '',
    usage_time_from: '',
    usage_time_to: '',
    user_name: '',
    phone: '',
    broker_name: '',
    store_name: '',
    offset: 0,
    limit: 30,
};

const BrokerUser: IStaffWorkerListPage<IStaffWorkerListPageProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userInfo, listAll } = useSelector((states: ReduxStates) => states);
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [data, setData] = useState<IDataApiIntermediariesRes[]>([]);
    const [searchParams, setSearchParams] = useSearchParams(initialValueSearch as URLSearchParamsInit);
    const tableRef = createRef<ITableComponentHandle>();
    const [isClearTable, setIsClearTable] = useState(false);
    const [listCheckbox, setListCheckbox] = useState<string[]>([]);
    const [dataInitDetail, setDataInitDetail] = useState<IDataApiIntermediariesRes | null>(null);
    const [isShowPopupDetail, setIsShowPopupDetail] = useState(false);

    const [modalState, setModalState] = useState<IStaffWorkerListPageState>({
        showModalCompany: false,
        showModalProperty: false,
    });

    const isAutoApprove = useMemo(() => Boolean(Number(userInfo.company_unlock_application)), [userInfo.company_unlock_application]);
    useEffect(() => {
        if (isShowPopupDetail || modalState.showModalCompany) {
            window.addEventListener('popstate', (event) => {
                setIsShowPopupDetail(false);
                setModalState((preState) => ({
                    ...preState,
                    showModalCompany: false,
                }));
            });
        }
    }, [isShowPopupDetail, modalState.showModalCompany]);

    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);
    useEffect(() => {
        const response: any = listAll.brokerUser;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.brokerUser]);
    const fetchGetListBrokerRegisteredAsync = async (searchParams: SearchParamsRegisteredBroker) => {
        await fetchUserInfo();
        fetchGetListBrokerRegistered(searchParams)
            .then((res) => {
                if (res?.data) {
                    dispatch(setListDataAll({ ...listAll, brokerUser: res as any }));
                }
            })
            .catch();
    };

    const showConfirmAutoApproveAllPopup = useCallback((data: string[]) => {
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: (
                    <span>
                        {t('intermediaries.modal.confirm_auto_approve_1')}
                        <span style={{ color: '#51A60E' }}>{t('intermediaries.modal.confirm_auto_approve_2')}</span>
                        {t('intermediaries.modal.confirm_auto_approve_3')}
                    </span>
                ),
                handleOK: () => {
                    const listAutoApprove: ISettingAutoApprove[] = data.map((item) => ({
                        brokerId: Number(item),
                        // brokerCompanyId: Number(item),
                        isAutoApprove: true,
                    }));
                    const body: IBodyAutoApproveBrokerCompany = {
                        settings: listAutoApprove,
                    };
                    fetchUpdateBrokerCompanyAsset(body).then((res) => {
                        if (res.status === 'SUCCESS') {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.save_data')}`,
                                }),
                            );
                            fetchGetListBrokerRegisteredAsync(searchParams as SearchParamsRegisteredBroker);
                        }
                    });
                    setIsClearTable(true);
                },
                handleCancel: () => {
                    console.log('OK');
                },
            }),
        );
    }, []);

    const showConfirmAutoApprovePopup = (id: number, status: APPROVE_STATUS) => {
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: (
                    <span>
                        <span style={{ color: status ? '#4FA50C' : '#EF5050' }}>
                            {t(status ? 'intermediaries.modal.confirm_auto_approve_4' : 'intermediaries.modal.confirm_auto_approve_6')}
                        </span>
                        {t('intermediaries.modal.confirm_auto_approve_5')}
                    </span>
                ),
                handleOK: () => {
                    const body: IBodyAutoApproveBrokerCompany = {
                        settings: [
                            {
                                brokerId: id,
                                isAutoApprove: !!status,
                                // brokerCompanyId: id,
                            },
                        ],
                    };
                    fetchUpdateBrokerCompanyAsset(body).then((res) => {
                        if (res.status === 'SUCCESS') {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.save_data')}`,
                                }),
                            );
                            fetchGetListBrokerRegisteredAsync(searchParams as SearchParamsRegisteredBroker);
                        }
                    });
                    setIsClearTable(true);
                },
            }),
        );
    };

    const handleTableDatas = () => {
        return data?.map((item) => {
            return {
                ...item,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${item.id}`,
                                value: item.id.toString(),
                            },
                        ],
                    },
                ],
                created_time: item?.created_time || '-',
                avatar: (
                    <div className="components__table-avatar">
                        {item.avatar ? (
                            <img className="bases__table--image" src={item.avatar} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                name: item.name,
                email: item.email,
                phone: item.phone,
                broker_name: item.broker_name,
                store_name: item.store_name,
                created_time_used: item?.usage_time || '-',
                approve_status: (
                    <div className="td-last d-flex justify-content-center align-items-center gap-2">
                        <button
                            disabled={!isAutoApprove || !!item.auto_approve_status}
                            onClick={() => showConfirmAutoApprovePopup(item.id, APPROVE_STATUS.AUTO)}
                            className={cx(
                                'bases__width-max--80',
                                'btn',
                                item.auto_approve_status ? 'btn__approved' : '',
                                isAutoApprove && item.auto_approve_status ? 'btn-disable' : '',
                            )}>
                            {t<string>('staffworker.list.auto_approve')}
                        </button>
                        <button
                            disabled={!isAutoApprove || !item.auto_approve_status}
                            onClick={() => showConfirmAutoApprovePopup(item.id, APPROVE_STATUS.NON_AUTO)}
                            className={cx(
                                'bases__width-max--80',
                                'btn',
                                !item.auto_approve_status ? 'btn__non-approved' : '',
                                isAutoApprove && !item.auto_approve_status ? 'btn-disable' : '',
                            )}>
                            {t<string>('staffworker.list.not_auto_approve')}
                        </button>
                        <Button
                            onClick={() => handleClickDetail(item.id)}
                            className={cx('button-detail', 'bases__width--60')}
                            text={t<string>('common.detail')}>
                            <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                        </Button>
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
                isDisable: !isAutoApprove,
            },
            {
                title: t('staffworker.list.usage_start_date'),
            },
            {
                title: t('staffworker.list.image'),
            },
            {
                title: t('staffworker.list.user'),
            },
            {
                title: t('staffworker.list.email'),
            },
            {
                title: t('staffworker.list.phone_number'),
            },
            {
                title: t('staffworker.list.brokerage_company_name'),
            },
            {
                title: t('staffworker.list.store_name'),
            },
            {
                title: t('staffworker.list.created_date'),
            },
            {
                title: t('staffworker.list.auto_approve_status'),
            },
            // {
            //     title: t('common.detail'),
            // },
        ],
        body: {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                    isDisable: !isAutoApprove,
                },
                {
                    field: 'created_time_used',
                },
                {
                    field: 'avatar',
                    isNotTooltip: true,
                },
                {
                    field: 'name',
                },
                {
                    field: 'email',
                },
                {
                    field: 'phone',
                },
                {
                    field: 'broker_name',
                },
                {
                    field: 'store_name',
                },
                {
                    field: 'created_time',
                },
                {
                    field: 'approve_status',
                    isNotTooltip: true,
                    _styleTd: {
                        width: 'unset !important',
                    },
                },
                // {
                //     field: 'detail',
                //     isNotTooltip: true,
                // },
            ],
            rows: handleTableDatas(),
        },
    };

    // useEffect(() => {
    //     if (!searchParams.get('broker_id')) {
    //         setSearchParams(initialValueSearch as URLSearchParamsInit);
    //     }
    // }, []);

    const handleClickDetail = (id: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            broker_id: id.toString(),
        });
    };

    const handleFetchDetail = async (id: number) => {
        try {
            const params: IDetailBrokerParam = {
                page_source: 'registered',
            };
            const response = await fetchDetailBroker(id, params);
            if (response.status === 'SUCCESS') {
                setDataInitDetail(response.data);
                setIsShowPopupDetail(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (searchParams.get('broker_id')) {
            handleFetchDetail(Number(searchParams.get('broker_id')));
        }
        fetchGetListBrokerRegisteredAsync(searchParams as SearchParamsRegisteredBroker);
    }, [searchParams]);

    return (
        <BodyContainer title={t('title.list_worker')}>
            <Formik
                initialValues={initialValueSearch}
                enableReinitialize={true}
                onSubmit={(values, helper) => {
                    const newPhoneNumber = convertToHalfSize(values?.phone || '');
                    const newValues = {
                        ...values,
                        phone: removeSpecialCharacter(newPhoneNumber).trim(),
                    };
                    setSearchParams((params) => {
                        return {
                            ...params,
                            ...newValues,
                        };
                    });
                    helper.setFieldValue('phone', newPhoneNumber);
                    setIsClearTable(true);
                    fetchGetListBrokerRegisteredAsync(newValues as SearchParamsRegisteredBroker);
                }}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, resetForm } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={resetForm}
                            isDisableBtnDelete={isEqual(initialValueSearch, values)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('intermediaries.label.date_time_picker')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.register_to_time ? new Date(values.register_to_time) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.register_from_time ? new Date(values.register_from_time) : null}
                                                onChange={(value) =>
                                                    setFieldValue('register_from_time', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.register_from_time ? new Date(values.register_from_time) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.register_to_time ? new Date(values.register_to_time) : null}
                                                onChange={(value) => setFieldValue('register_to_time', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <FormInput
                                            maxLength={255}
                                            name="user_name"
                                            value={values.user_name}
                                            className="col-md"
                                            label="intermediaries.user"
                                            placeholder="intermediaries.user"
                                            onChangeText={(value) => setFieldValue('user_name', value)}
                                        />
                                        <FormInput
                                            maxLength={255}
                                            value={values.phone}
                                            className="col-md"
                                            label="staffworker.list.phone_number"
                                            placeholder="staffworker.list.phone_number"
                                            onChangeText={(value) => setFieldValue('phone', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('staffworker.list.usage_date')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.usage_time_to ? new Date(values.usage_time_to) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.usage_time_from ? new Date(values.usage_time_from) : null}
                                                onChange={(value) => setFieldValue('usage_time_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.usage_time_from ? new Date(values.usage_time_from) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.usage_time_to ? new Date(values.usage_time_to) : null}
                                                onChange={(value) => setFieldValue('usage_time_to', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <FormInput
                                            maxLength={255}
                                            value={values.broker_name || ''}
                                            className="col-md"
                                            classNameLabel="ps-0"
                                            label="staffworker.list.brokerage_company_name"
                                            placeholder="staffworker.list.brokerage_company_name"
                                            onChangeText={(value) => {
                                                setFieldValue('broker_name', value);
                                            }}
                                        />
                                        <FormInput
                                            maxLength={255}
                                            value={values.store_name}
                                            className="col-md"
                                            label="staffworker.list.store_name"
                                            placeholder="staffworker.list.store_name"
                                            onChangeText={(value) => setFieldValue('store_name', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>

            {!isEmpty(data) && (
                <div className="d-flex mt-3 w-100 gap-3">
                    <Button
                        disabled={!(isAutoApprove && !isEmpty(listCheckbox))}
                        onClick={() => showConfirmAutoApproveAllPopup(listCheckbox)}
                        text={t<string>('common.approve-all')}
                        className="bases__width--170 bases__text--label"
                        del
                    />
                    <Button
                        disabled={!isAutoApprove}
                        onClick={() => {
                            setModalState((prevState) => ({
                                ...prevState,
                                showModalCompany: true,
                            }));
                            const searchParamsObject = Object.fromEntries(searchParams.entries());
                            setSearchParams({
                                ...searchParamsObject,
                                action: 'auto_approve',
                            });
                        }}
                        text={t<string>('staffworker.list.setting_auto_approve')}
                        className={cxButton('bases__width--170 bases__text--label')}
                        del
                    />
                </div>
            )}

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={table.heads}
                    body={table.body}
                    pageData={pageState}
                    onChangePage={(page) => {
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        setSearchParams({
                            ...searchParamsObject,
                            offset: (page - 1).toString(),
                        });
                    }}
                    onChangeCheckList={(value: string[]) => {
                        setListCheckbox(value);
                    }}
                />
            </div>
            <BrokerDetailModal
                hidePositiveButton={true}
                typeBroker="brokerRegistered"
                handleOK={() => {
                    setIsShowPopupDetail(false);
                }}
                dataInit={dataInitDetail}
                isShow={isShowPopupDetail}
                handleClose={() => {
                    setIsShowPopupDetail(false);
                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                    delete searchParamsObject.broker_id;
                    setSearchParams(searchParamsObject);
                }}
            />
            {modalState.showModalCompany && (
                <CompanyApprovalSetting
                    handleOK={() => {
                        fetchGetListBrokerRegisteredAsync(searchParams as SearchParamsRegisteredBroker);
                        setModalState((prevState) => ({
                            ...prevState,
                            showModalCompany: false,
                        }));
                        setIsClearTable(true);
                    }}
                    handleClose={() => {
                        setModalState((prevState) => ({
                            ...prevState,
                            showModalCompany: false,
                        }));
                        searchParams.delete('action');
                        setSearchParams(searchParams);
                    }}
                    isShow={modalState.showModalCompany}
                />
            )}
            <PropertyApprovalSetting
                handleClose={() => {
                    setModalState((prevState) => ({
                        ...prevState,
                        showModalProperty: false,
                    }));
                }}
                isShow={modalState.showModalProperty}
            />
        </BodyContainer>
    );
};

export default BrokerUser;
