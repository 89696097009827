import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import ModalInterface from '@components/commons/Modal';
import { FormInput } from '@components/commons';
import { isEmpty } from 'lodash';

const cx = classNames.bind(styles);

const ModalKeyStorage: IModalComponent<IModalKeyStorageProps> = (props) => {
    const { header, isShow, handleClose, url, valueInput, negativeButtonText } = props;

    return (
        <ModalInterface
            classNameBody={cx('body_add_room')}
            classNameHeader={cx('title-header')}
            modalHeader={header}
            handleClose={handleClose}
            hidePositiveButton={true}
            negativeButtonText={negativeButtonText}
            isShow={isShow}>
            <div className={cx('content-storage')}>
                <div className='row w-100'>
                    <div className='col bases__margin--left24'>
                        <FormInput
                            readOnly={true}
                            classNameTextArea={cx('storage-input')}
                            textArea
                            rows={6}
                            styleBlock
                            maxLength={3000}
                            value={valueInput}

                        />
                    </div>
                    <div className='col'>{  
                        !isEmpty(url) ? <img className={cx('modal-image-item')} src={url} /> : <div className={cx('modal-image-item')}></div>
                    }
                        
                    </div>
                </div>
            </div>
        </ModalInterface>
    );
};

ModalKeyStorage.defaultProps = {
    isShow: false,
};

export default ModalKeyStorage;
