import commonStyle from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput } from '@components/commons';
import { images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import styles from './EmployeeHistory.module.scss';
import { Formik } from 'formik';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import moment from 'moment';
import DataTable from '@components/commons/Table/Table';
import { getDetailHistoryEmploye, getListHistory } from '@utils/helpers/api';
import {} from '@redux/actions';
import ModalInterface from '@components/commons/Modal';
import { convertToHalfSize, removeSpecialCharacter } from '@utils/helpers/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyle);

interface InitValueFormik {
    offset?: number | string;
    limit?: number | string;
    name?: string;
    phone?: string;
    room_name?: string;
    status?: string;
    date_from?: string;
    date_to?: string;
    asset_name?: string;
}

const EmployeeHistory: IHistoryPage<IHistoryPageProps> = (props) => {
    const dispatch = useDispatch();
    const { listAll } = useSelector((states: ReduxStates) => states);
    const { t } = useTranslation('translation', { useSuspense: true });
    const [searchParams, setSearchParams] = useSearchParams({
        name: '',
        asset_name: '',
        room_name: '',
        status: '',
        date_from: '',
        date_to: '',
        offset: '0',
        limit: '30',
        phone: '',
    });
    const [listEmployee, setListEmployee] = useState<any[]>([]);
    const [dataDetail, setDataDetail] = useState<any>();
    const [popupDetail, setPopupDetail] = useState(false);
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const tableRef = createRef<ITableComponentHandle>();
    const [initialValueSearch, setInitialValueSearch] = useState<InitValueFormik>({
        date_from: searchParams.get('date_from') ?? '',
        date_to: searchParams.get('date_to') ?? '',
        name: searchParams.get('name') ?? '',
        asset_name: searchParams.get('asset_name') ?? '',
        room_name: searchParams.get('room_name') ?? '',
        status: searchParams.get('status') ?? '',
        offset: searchParams.get('offset') ?? 0,
        limit: searchParams.get('limit') ?? 30,
        phone: searchParams.get('phone') ?? '',
    });

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        fetchGetListHistory(searchParamsObject);
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get('Employe_id')) {
            detailHistoryKeybox(Number(searchParams.get('Employe_id')));
        }
    }, []);

    useEffect(() => {
        const response: any = listAll.employeeHistory;
        if (response && response.data.status === 'SUCCESS') {
            setListEmployee(response.data?.data);
            setPageState({
                firstRecords: response.data.metadata?.offset * response.data.metadata?.limit + 1,
                currentPage: response.data.metadata?.offset + 1,
                totalPage: Math.ceil(response.data.metadata?.total / response.data.metadata?.limit),
                pageRecords: response.data.metadata?.count + response.data.metadata?.offset * response.data.metadata?.limit,
                totalRecords: response.data.metadata?.total,
            });
        }
    }, [listAll.employeeHistory]);
    const fetchGetListHistory = async (searchParams: IHistoryPageState) => {
        const response = await getListHistory(1, searchParams);
        dispatch(setListDataAll({ ...listAll, employeeHistory: response as any }));
    };
    const handleTableDatas = () => {
        return listEmployee?.map((item) => {
            return {
                ...item,
                raw_statusKeybox: item?.statusKeybox == 0 ? t('history.locking') : t('history.unlocking'),
                statusKeybox: (
                    <span className={item?.statusKeybox == 0 ? cxCommon('text-red') : cxCommon('text-green')}>
                        {item?.statusKeybox == 0 ? t('history.locking') : t('history.unlocking')}
                    </span>
                ),
                operationDate: item.operationDate ? moment.utc(item?.operationDate).format('YYYY/MM/DD HH:mm') : '-',
                staffName: item?.staffName,
                staffEmail: item?.staffEmail,
                staffPhone: item?.staffPhone,
                staffStoreName: item?.staffStoreName,
                assetName: item?.assetName,
                address: item?.address,
                roomName: item?.roomName,
                keyboxSeri: item?.keyboxSeri,
                batteryLevel:
                    Number(item?.batteryLevel) <= 5 ? (
                        <span className={cxCommon('text-red')}>{Number(item?.batteryLevel) + '%'}</span>
                    ) : (
                        Number(item?.batteryLevel) + '%'
                    ),
                raw_batteryLevel: Number(item?.batteryLevel) + '%',
                detail: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <Button
                            onClick={() => detailHistoryKeybox(item.id)}
                            className={cx('button-detail')}
                            text={t<string>('common.detail')}>
                            <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                        </Button>
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('history.unlockOrLock'),
            },
            {
                title: t('history.keyboxHistory.operating_date'),
            },
            {
                title: t('history.keyboxHistory.user'),
            },
            {
                title: t('history.emailAddress'),
            },
            {
                title: t('history.phoneNumber'),
            },
            {
                title: t('history.keyboxHistory.belong'),
            },
            {
                title: t('history.keyboxHistory.asset_name'),
            },
            {
                title: t('history.keyboxHistory.propertyaddress'),
            },
            {
                title: t('history.keyboxHistory.room_number'),
            },
            {
                title: t('history.keyboxHistory.search.keybox'),
            },
            {
                title: t('history.batteryLevel'),
            },
            {
                title: t('common.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'statusKeybox',
                    rawField: 'raw_statusKeybox',
                },
                {
                    field: 'operationDate',
                },
                {
                    field: 'staffName',
                },
                {
                    field: 'staffEmail',
                },
                {
                    field: 'staffPhone',
                },
                {
                    field: 'staffStoreName',
                },
                {
                    field: 'assetName',
                },
                {
                    field: 'address',
                },
                {
                    field: 'roomName',
                },
                {
                    field: 'keyboxSeri',
                },
                {
                    field: 'batteryLevel',
                    rawField: 'raw_batteryLevel',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };

    const resetParams = () => {
        const newValue = {
            name: '',
            asset_name: '',
            room_name: '',
            status: '',
            date_from: '',
            date_to: '',
            offset: '0',
            limit: '30',
            phone: '',
        };
        setInitialValueSearch(newValue);
    };
    const handleSearch = (values: any, helper: any) => {
        const newPhoneNumber = convertToHalfSize(values?.phone || '');
        const newSearchParams = {
            ...searchParams,
            ...values,
            offset: '0',
            phone: removeSpecialCharacter(newPhoneNumber).trim(),
        };
        setSearchParams(newSearchParams);
        helper.setFieldValue('phone', newPhoneNumber);
        fetchGetListHistory(newSearchParams as IParamsAsset);
    };

    useEffect(() => {
        if (popupDetail) {
            window.addEventListener('popstate', (event) => {
                setPopupDetail(false);
            });
        }
    }, [popupDetail]);

    const showPopupDetail = () => {
        return (
            <ModalInterface
                classNameCancel={cxCommon('bases__margin--bottom20')}
                classNameHeader={cxCommon('bases__margin--top20')}
                hidePositiveButton
                dialogClassname={cx('modal')}
                isShow={popupDetail}
                negativeButtonText="common.close"
                handleClose={() => {
                    setPopupDetail(false);
                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                    delete searchParamsObject.Employe_id;
                    setSearchParams(searchParamsObject);
                }}
                modalHeader={t<string>('common.detail')}
                positiveButtonText="common.ok"
                classNameBody={cx('content-popup')}>
                <div className={cxCommon('label-color')}>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.unlockOrLock')}</div>
                        <div className="col fw-bold pe-4">
                            <span className={dataDetail?.data?.type == 0 ? cxCommon('text-red') : cxCommon('text-green')}>
                                {dataDetail?.data?.type == 0 ? t('history.locking') : t('history.unlocking')}
                            </span>
                        </div>
                    </div>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.openingDate')}</div>
                        <div className="col fw-bold pe-4">
                            {dataDetail?.data?.created_at ? moment.utc(dataDetail?.data?.created_at).format('YYYY/MM/DD HH:mm') : '-'}
                        </div>
                    </div>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.keyboxHistory.user')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.name ? dataDetail?.data?.name : '-'}</div>
                    </div>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.emailAddress')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.email ? dataDetail?.data?.email : '-'}</div>
                    </div>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.phoneNumber')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.phone ? dataDetail?.data?.phone : '-'}</div>
                    </div>
                    <div className="row mt-3 ms-2">
                        <div className="bases__width-percent--30">{t('history.keyboxHistory.belong')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.store_name ? dataDetail?.data?.store_name : '-'}</div>
                    </div>
                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{t('history.assetName')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.asset_name ? dataDetail?.data?.asset_name : '-'}</div>
                    </div>
                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{t('history.assetAddress')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.address ? dataDetail?.data?.address : '-'}</div>
                    </div>
                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{t('history.roomNumber')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.room_number ? dataDetail?.data?.room_number : '-'}</div>
                    </div>

                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{t('history.keybox')}</div>
                        <div className="col fw-bold pe-4">{dataDetail?.data?.keybox_number ? dataDetail?.data?.keybox_number : '-'}</div>
                    </div>

                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{t('history.batteryLevel')}</div>
                        <div className="col fw-bold pe-4">
                            {Number(dataDetail?.data?.battery_level) <= 5 ? (
                                <span className={cxCommon('text-red')}>{Number(dataDetail?.data?.battery_level) + '%'}</span>
                            ) : (
                                Number(dataDetail?.data?.battery_level) + '%'
                            )}
                        </div>
                    </div>
                </div>
            </ModalInterface>
        );
    };
    const detailHistoryKeybox = (id: string | number) => {
        getDetailHistoryEmploye(1, id)
            .then(({ data }) => {
                const searchParamsObject = Object.fromEntries(searchParams.entries());
                setSearchParams({
                    ...searchParamsObject,
                    Employe_id: id.toString(),
                });
                setDataDetail(data);
                setPopupDetail(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <BodyContainer title={t('title.employee_history')}>
            <Formik
                initialValues={initialValueSearch}
                enableReinitialize={true}
                onSubmit={(values, helper) => handleSearch(values, helper)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset, getFieldProps } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                handleReset();
                                resetParams();
                            }}
                            isDisableBtnDelete={
                                getFieldProps('date_from').value == '' &&
                                getFieldProps('date_to').value == '' &&
                                getFieldProps('name').value == '' &&
                                getFieldProps('asset_name').value == '' &&
                                getFieldProps('phone').value == '' &&
                                getFieldProps('room_name').value == '' &&
                                getFieldProps('status').value == ''
                            }>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('history.date_of_operation')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.date_to ? new Date(values.date_to) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.date_from ? new Date(values.date_from) : null}
                                                onChange={(value) => setFieldValue('date_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.date_from ? new Date(values.date_from) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.date_to ? new Date(values.date_to) : null}
                                                onChange={(value) => setFieldValue('date_to', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <FormInput
                                            classNameLabel={cx('label_input')}
                                            maxLength={255}
                                            value={values.name}
                                            className="col-md"
                                            label="history.keyboxHistory.user"
                                            placeholder="history.keyboxHistory.user"
                                            onChangeText={(value) => setFieldValue('name', value)}
                                        />

                                        <FormInput
                                            classNameLabel={cx('label_input')}
                                            maxLength={255}
                                            value={values.phone}
                                            className="col-md"
                                            label="history.phoneNumber"
                                            placeholder="history.phoneNumber"
                                            onChangeText={(value) => setFieldValue('phone', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <FormInput
                                                classNameLabel={cx('label_input')}
                                                maxLength={255}
                                                value={values.asset_name}
                                                className="col-md"
                                                label="history.keyboxHistory.asset_name"
                                                placeholder="history.keyboxHistory.asset_name"
                                                onChangeText={(value) => setFieldValue('asset_name', value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <FormInput
                                            classNameLabel={cx('label_input')}
                                            maxLength={255}
                                            value={values.room_name}
                                            className="col-md"
                                            label="history.keyboxHistory.room_number"
                                            placeholder="history.keyboxHistory.room_number"
                                            onChangeText={(value) => setFieldValue('room_name', value)}
                                        />
                                        <FormDropdown
                                            classNameLabel={cx('label_input', 'col-md-4 col-form-label text-end pe-0')}
                                            label="history.unlockOrLock"
                                            className={cxCommon('bases__text--label') + ' ' + cx('pr-22', 'col-6')}
                                            name="status"
                                            options={[
                                                { value: '', label: t('staffworker.list.all') },
                                                { value: '1', label: t('history.unlocking') },
                                                { value: '0', label: t('history.locking') },
                                            ]}
                                            value={values.status}
                                            onChangeOption={(option) => setFieldValue('status', option.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>
            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={table.heads}
                    body={table.body}
                    pageData={pageState}
                    onChangePage={(page) => {
                        setPageState((prevState) => ({
                            ...prevState,
                            currentPage: page,
                        }));
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        setSearchParams({
                            ...searchParamsObject,
                            offset: (page - 1)?.toString(),
                            limit: '30',
                        });
                    }}
                />
            </div>
            {showPopupDetail()}
        </BodyContainer>
    );
};

export default EmployeeHistory;
