import { createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormInput } from '@components/commons';
import commonStyles from '@assets/styles/base/Common.module.scss';
import classNames from 'classnames/bind';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import { images, pageStateInit } from '~/utils/constants';
import { useSearchParams } from 'react-router-dom';
import styles from './QuestionnaireManagement.module.scss';
import { Formik } from 'formik';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import DataTable from '@components/commons/Table/Table';
import moment from 'moment';
import { dateHelper } from '@utils/helpers';
import { getAnswerDetail, getAnswers } from '@utils/helpers/api';
import SurveyAnswerDetailModal from '@components/modals/SurveyAnswerDetailModal';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const QuestionnaireManagement = () => {
    const dispatch = useDispatch();
    const { listAll } = useSelector((states: ReduxStates) => states);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDisableBtnDelete, setIsDisableBtnDelete] = useState(true);
    const tableRef = createRef<ITableComponentHandle>();
    const [data, setData] = useState<IDataApiAnswersRes[]>([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detailData, setDetailData] = useState<IDataApiAnswerDetailRes>();

    const limitPage = 30;

    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        createdFrom: searchParams.get('createdFrom') ?? '',
        createdTo: searchParams.get('createdTo') ?? '',
        user: searchParams?.get('user') ?? '',
        brokerCompanyName: searchParams.get('brokerCompanyName') ?? '',
        name: searchParams.get('name') ?? '',
        assetName: searchParams.get('assetName') ?? '',
        roomNumber: searchParams.get('roomNumber') ?? '',
        offset: searchParams.get('offset') ?? 0,
        limit: searchParams.get('limit') ?? limitPage,
    });

    const handleSearch = (values: any) => {
        setSearchParams((params) => {
            return {
                ...params,
                ...values,
            };
        });
        fetchAnswers(values as IAnswersSearchParams);
    }

    useEffect(() => {
        if (showDetail) {
            window.addEventListener('popstate', () => {
                setShowDetail(false);
            });
        }
    }, [showDetail]);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        if (!searchParamsObject.offset && !searchParamsObject.limit) {
            setSearchParams({
                ...searchParamsObject,
                offset: '0',
                limit: limitPage.toString(),
                createdFrom: '',
                createdTo: '',
                brokerCompanyName: '',
                name: '',
                assetName: '',
                roomNumber: '',
            });
            fetchAnswers(searchParamsObject as IAnswersSearchParams);
        } else {
            fetchAnswers(searchParamsObject as IAnswersSearchParams);
        }
    }, [searchParams]);

    useEffect(() => {
        const checkDefault =
            !searchParams.get('createdFrom') &&
            !searchParams.get('createdTo') &&
            searchParams.get('user') === '' &&
            searchParams.get('brokerCompanyName') === '' &&
            searchParams.get('assetName') === '' &&
            searchParams.get('roomNumber') === '' &&
            searchParams.get('name') === '';
        setIsDisableBtnDelete(checkDefault);
    }, [searchParams]);
    useEffect(() => {
        const response: any = listAll.questionnaireManagement;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.questionnaireManagement]);
    const fetchAnswers = async (param: IAnswersSearchParams) => {
        try {
            const res = await getAnswers(param);
            if (res && res.data && res.data.status === 'SUCCESS') {
                const data = res.data;
                dispatch(setListDataAll({ ...listAll, questionnaireManagement: data as any }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchAnswersDetail = async (submitId: string) => {
        try {
            const res = await getAnswerDetail(submitId);
            if (res && res.data && res.data.status === 'SUCCESS') {
                const data = res.data.data as IDataApiAnswerDetailRes;
                setDetailData(data);
                setShowDetail(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const dataTable = useMemo(() => {
        const dataRow: any = data.map((item) => {
            return {
                ...item,
                answer_date: dateHelper.formatDateUTC(item?.createdAt ?? '', 'YYYY/MM/DD HH:mm'),
                user: item.otpId ? item.otpUserInfo : item.appUserInfo,
                broker_company_name: item.otpId ? '-' : item.appBrokerCompanyName,
                store_name: item.otpId ? '-' : item.appStoreName,
                telephone_number: item.otpId ? item.otpPhone : item.appBrokerPhone,
                asset_name: item.assetName,
                address: item.address,
                room_number: item.otpId ? item.otpRoomNumber : item.appRoomNumber,
                other_ratings: item.planText,
                detail: (
                    <Button onClick={() => fetchAnswersDetail(item.submitId)} className={cx('button-detail')} text={t<string>('common.detail')}>
                        <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                    </Button>
                ),
            };
        });

        const heads = [
            {
                title: t('answers.answer_date'),
            },
            {
                title: t('answers.user'),
            },
            {
                title: t('answers.brokerage_company_name'),
            },
            {
                title: t('answers.store_name'),
            },
            {
                title: t('answers.telephone_number'),
            },
            {
                title: t('answers.asset_name'),
            },
            {
                title: t('answers.address'),
            },
            {
                title: t('answers.room_number'),
            },
            {
                title: t('answers.other_ratings'),
            },
            {
                title: t('common.detail'),
            },
        ];

        const body = {
            columns: [
                {
                    field: 'answer_date',
                },
                {
                    field: 'user',
                },
                {
                    field: 'broker_company_name',
                },
                {
                    field: 'store_name',
                },
                {
                    field: 'telephone_number',
                },
                {
                    field: 'asset_name',
                },
                {
                    field: 'address',
                },
                {
                    field: 'room_number',
                },
                {
                    field: 'other_ratings',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: dataRow,
        };

        return {
            heads,
            body,
        };
    }, [data]);

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const newParams = {
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: limitPage.toString(),
        };
        setSearchParams(newParams);
        fetchAnswers(newParams as IAnswersSearchParams)
    };


    const onClear = () => {
        const newValue = {
            createdFrom: '',
            createdTo: '',
            user: '',
            brokerCompanyName: '',
            name: '',
            assetName: '',
            roomNumber: '',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    }
    return (
        <BodyContainer title={t('answers.title')}>
            <Formik initialValues={initialValueSearch} enableReinitialize={true} onSubmit={(values) => handleSearch(values)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset } = props;
                    return (
                        <SearchForm btnDelete onSubmit={handleSubmit} onDelete={() => { onClear(); handleReset() }} isDisableBtnDelete={isDisableBtnDelete}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('answers.answer_time')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.createdTo !== '' ? new Date(values.createdTo) : null}
                                                value={values.createdFrom !== '' ? new Date(values.createdFrom) : null}
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) => setFieldValue('createdFrom', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.createdFrom !== '' ? new Date(values.createdFrom) : null}
                                                value={values.createdTo !== '' ? new Date(values.createdTo) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('createdTo', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user" className={cxCommon('label-color')}>
                                                {t('answers.user')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.user || ''}
                                            name="user"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="answers.user"
                                            onChangeText={(value) => setFieldValue('user', value)}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('answers.brokerage_company_name')}</label>
                                        </div>
                                        <FormInput
                                            value={values.brokerCompanyName || ''}
                                            name="phone"
                                            maxLength={255}
                                            onChangeText={(value) => setFieldValue('brokerCompanyName', value)}
                                            className="col"
                                            styleBlock
                                            placeholder="answers.brokerage_company_name"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('answers.asset_name')}</label>
                                        </div>
                                        <FormInput
                                            value={values.assetName || ''}
                                            name="assetName"
                                            maxLength={255}
                                            onChangeText={(value) => setFieldValue('assetName', value)}
                                            formContainer
                                            className="col"
                                            styleBlock
                                            placeholder="answers.asset_name"
                                        />
                                        <div className="col-2"></div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('answers.room_number')}</label>
                                        </div>
                                        <FormInput
                                            value={values.roomNumber || ''}
                                            name="roomNumber"
                                            maxLength={255}
                                            onChangeText={(value) => setFieldValue('roomNumber', value)}
                                            className="col"
                                            styleBlock
                                            placeholder="answers.room_number"
                                        />
                                        <div className="col-2"></div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={dataTable.heads}
                    body={dataTable.body}
                    pageData={pageState}
                    onChangePage={(page) => handleChangePage(page)}
                />
            </div>
            {showDetail && <SurveyAnswerDetailModal isShow={showDetail} handleClose={() => setShowDetail(false)} data={detailData} />}
        </BodyContainer>
    );
};

export default QuestionnaireManagement;
