import commonStyle from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput } from '@components/commons';
import Configs from '@utils/configs';
import { images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import styles from './EmployeeWorkerAccount.module.scss';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { convertToHalfSize, removeSpecialCharacter } from '@utils/helpers/utilities';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import moment from 'moment';
import { dateHelper } from '@utils/helpers';
import DataTable from '@components/commons/Table/Table';
import { getListStaffs } from '@utils/helpers/api';
import { resetPasswordStaffs } from '@utils/helpers/api';
import { setToast, showAlert } from '@redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { getStaffsAsync } from '@redux/actions/StaffsAction';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyle);

interface InitValueFormik {
    offset?: number | string;
    limit?: number | string;
    created_from?: string;
    created_to?: string;
    username?: string;
    name?: string;
    phone?: string;
    role?: number | string;
    account_status?: number | string;
    permission?: number | string;
}

const EmployeeWorkerAccount: IHistoryPage<IHistoryPageProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('translation', { useSuspense: true });
    const [data, setData] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams({
        created_from: '',
        created_to: '',
        username: '',
        name: '',
        phone: '',
        role: '',
        account_status: '',
        permission: '',
        offset: '0',
        limit: '30',
    });
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [listCheckbox, setListCheckbox] = useState<string[]>([]);
    const tableRef = createRef<ITableComponentHandle>();
    const { getStaffs } = useSelector((states: ReduxStates) => states);
    const [initialValueSearch, setInitialValueSearch] = useState<InitValueFormik>({
        created_from: searchParams.get('created_from') ?? '',
        created_to: searchParams.get('created_to') ?? '',
        username: searchParams.get('username') ?? '',
        name: searchParams.get('name') ?? '',
        phone: searchParams.get('phone') ?? '',
        role: searchParams.get('role') ?? '',

        account_status: searchParams.get('account_status') ?? '',
        permission: searchParams.get('permission') ?? '',
        offset: searchParams.get('offset') ?? 0,
        limit: searchParams.get('limit') ?? 30,
    });
    const [isClearTable, setIsClearTable] = useState(false);

    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        dispatch(getStaffsAsync(searchParamsObject));
        if (!searchParamsObject.offset) {
            setSearchParams({
                ...searchParamsObject,
                limit: '30',
            });
            dispatch(getStaffsAsync(searchParams as IParamsAsset));
        } else {
            dispatch(getStaffsAsync(searchParamsObject));
        }
    }, [searchParams]);

    useEffect(() => {
        const limit = getStaffs.listStaffs.metadata?.limit;
        const offset = getStaffs.listStaffs.metadata?.offset;
        const count = getStaffs.listStaffs.metadata?.count;
        const total = getStaffs.listStaffs.metadata?.total;
        setPageState({
            firstRecords: offset * limit + 1,
            currentPage: offset + 1,
            totalPage: Math.ceil(total / limit),
            pageRecords: count + offset * limit,
            totalRecords: total,
        });
        setData(getStaffs.listStaffs?.data);
    }, [getStaffs, data]);

    const handleTableDatas = () => {
        return data?.map((item) => {
            return {
                ...item,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${item.id}`,
                                value: item.id.toString(),
                            },
                        ],
                    },
                ],
                created_time: item.created_at ? moment.utc(item?.created_at).format('YYYY/MM/DD HH:mm') : '-',
                avatar: (
                    <div className="components__table-avatar">
                        {item.avatar ? (
                            <img className="bases__table--image" src={item.avatar} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                username: item.username,
                name: item.name,
                role:
                    item.role === 3 ? (
                        <span>{t('staffworker.list.company_employee')}</span>
                    ) : (
                        <span>{t('staffworker.list.contractor')}</span>
                    ),
                affiliation: item.store_name,
                phone: item.phone,
                email: item.email,
                start_used_at: item.start_used_at ? moment.utc(item?.start_used_at).format('YYYY/MM/DD HH:mm') : '-',
                raw_permission:
                    item.permission === 4 ? t('staffworker.register.use_only') : t('staffworker.register.can_be_installed'),
                permission:
                    item.permission === 4 ? (
                        <span className={cxCommon('text-blue')}>{t('staffworker.register.use_only')}</span>
                    ) : (
                        <span className={cxCommon('text-purple')}>{t('staffworker.register.can_be_installed')}</span>
                    ),
                raw_account_status: item.account_status === 1 ? t('staffworker.register.using') : t('staffworker.register.stop'),
                account_status:
                    item.account_status === 1 ? (
                        <span className={cxCommon('text-green')}>{t('staffworker.register.using')}</span>
                    ) : (
                        <span className={cxCommon('text-red')}>{t('staffworker.register.stop')}</span>
                    ),
                detail: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <Button
                            onClick={() => navigate(`${Configs.routes.employeeWorker}/${item.id}`)}
                            className={cx('button-detail')}
                            text={t<string>('common.detail')}>
                            <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                        </Button>
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
                //isDisable: !isAutoApprove,
            },
            {
                title: t('staffworker.list.created_date'),
            },
            {
                title: t('staffworker.list.image'),
            },
            {
                title: t('staffworker.detail.user_name'),
            },
            {
                title: t('staffworker.list.user'),
            },
            {
                title: t('staffworker.detail.kinds'),
            },
            {
                title: t('staffworker.detail.affiliation'),
            },
            {
                title: t('staffworker.register.phone_number'),
            },
            {
                title: t('staffworker.register.email'),
            },
            {
                title: t('staffworker.list.usage_start_date'),
            },
            {
                title: t('staffworker.detail.usage_right'),
            },
            {
                title: t('staffworker.register.status_of_use'),
            },
            {
                title: t('common.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                    // isDisable: !isAutoApprove,
                },
                {
                    field: 'created_time',
                },
                {
                    field: 'avatar',
                    isNotTooltip: true,
                },
                {
                    field: 'username',
                },
                {
                    field: 'name',
                },
                {
                    field: 'role',
                },
                {
                    field: 'affiliation',
                },
                {
                    field: 'phone',
                },
                {
                    field: 'email',
                },
                {
                    field: 'start_used_at',
                },
                {
                    field: 'permission',
                    rawField: 'raw_permission',
                },
                {
                    field: 'account_status',
                    rawField: 'raw_account_status',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };
    const handleCheckResetPassword = () => {
        const lastDay = localStorage.getItem('checkResetPassword');
        if (moment(lastDay).add(1, 'minutes').isAfter(moment())) {
            dispatch(
                showAlert({
                    hideNegativeButtonClose: true,
                    header: 'common.confirm',
                    content: 'staffworker.list.noti_reset_password',
                    titleCancel: 'Ok',
                    classNameCancel: cx('btnCancel'),
                    hiddenOK: true,
                }),
            );
        } else {
            dispatch(
                showAlert({
                    header: 'staffworker.list.reset_password',
                    content: 'staffworker.list.confirm_reset_password',
                    handleOK: () => {
                        let listSelected = listCheckbox.map((item) => Number(item));
                        const listFilter = data.filter((item) => listSelected.includes(+item.id));
                        listSelected = listFilter.map((item) => item.id);
                        const body = {
                            userIds: listSelected,
                        };
                        resetPasswordStaffs(body)
                            .then(({ data }) => {
                                const today = moment().format('YYYY-MM-DD HH:mm:ss');
                                localStorage.setItem('checkResetPassword', today);
                                dispatch(
                                    setToast({
                                        isShow: true,
                                        message: `${t('staffworker.list.reset_completed')}`,
                                    }),
                                );
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    },
                }),
            );
        }
    };
    const resetParams = () => {
        const newValue = {
            created_from: '',
            created_to: '',
            username: '',
            name: '',
            phone: '',
            role: '',
            account_status: '',
            permission: '',
            offset: '0',
            limit: '30',
        };
        setInitialValueSearch(newValue);
    };
    const handleSearch = (values: any, helper: any) => {
        setIsClearTable(true);
        const newPhoneNumber = convertToHalfSize(values?.phone || '');
        setSearchParams((params) => {
            return {
                ...params,
                ...values,
                offset: '0',
                phone: removeSpecialCharacter(newPhoneNumber).trim(),
            };
        });
        helper.setFieldValue('phone', newPhoneNumber);
        dispatch(getStaffsAsync(searchParams as IParamsAsset));
    };
    return (
        <BodyContainer title={t('staffworker.list.title')}>
            <NavLink className="me-2" style={{ textDecoration: 'none' }} to={Configs.routes.employeeWorkerRegister}>
                <Button
                    className={cxCommon('bases__margin--bottom20', 'bases__width--170')}
                    text={t<string>('staffworker.list.btn_add')}
                    add
                />
            </NavLink>
            <Formik
                initialValues={initialValueSearch}
                enableReinitialize={true}
                onSubmit={(values, helper) => handleSearch(values, helper)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset, getFieldProps } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                handleReset();
                                resetParams();
                                setIsClearTable(true);
                            }}
                            isDisableBtnDelete={
                                getFieldProps('created_from').value == '' &&
                                getFieldProps('created_to').value == '' &&
                                getFieldProps('username').value == '' &&
                                getFieldProps('name').value == '' &&
                                getFieldProps('phone').value == '' &&
                                getFieldProps('role').value == 0 &&
                                getFieldProps('account_status').value == 0 &&
                                getFieldProps('permission').value == 0
                            }>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('staffworker.list.date_time_picker')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.created_to ? new Date(values.created_to) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.created_from ? new Date(values.created_from) : null}
                                                onChange={(value) => setFieldValue('created_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.created_from ? new Date(values.created_from) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.created_to ? new Date(values.created_to) : null}
                                                onChange={(value) => setFieldValue('created_to', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <FormInput
                                            classNameLabel={cx('label_input')}
                                            maxLength={255}
                                            name="username"
                                            value={values.username}
                                            className="col-md"
                                            label="staffworker.detail.user_name"
                                            placeholder="staffworker.detail.user_name"
                                            onChangeText={(value) => setFieldValue('username', value)}
                                        />
                                        <FormInput
                                            classNameLabel={cx('label_input')}
                                            maxLength={255}
                                            value={values.name}
                                            className="col-md"
                                            label="staffworker.list.user"
                                            placeholder="staffworker.list.user"
                                            onChangeText={(value) => setFieldValue('name', value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <FormInput
                                                classNameLabel={cx('label_input')}
                                                maxLength={255}
                                                value={values.phone}
                                                className="col-md"
                                                label="staffworker.list.phone_number"
                                                placeholder="staffworker.list.phone_number"
                                                onChangeText={(value) => setFieldValue('phone', value)}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center p-0">
                                            <span className={cxCommon('bases__text--label')}>{t('staffworker.detail.kinds')}</span>
                                        </div>
                                        <div className="col-4">
                                            <FormDropdown
                                                hiddenLabel
                                                className={cxCommon('bases__text--label') + ' ' + cx('pr-22')}
                                                name="role"
                                                options={[
                                                    { value: '', label: t('staffworker.list.all') },
                                                    { value: '3', label: t('staffworker.list.company_employee') },
                                                    { value: '5', label: t('staffworker.list.contractor') },
                                                ]}
                                                value={values.role}
                                                onChangeOption={(option) => setFieldValue('role', option.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <FormDropdown
                                            classNameLabel={cx('label_input', 'col-md-4 col-form-label text-end pe-0')}
                                            label="staffworker.detail.usage_right"
                                            className={cxCommon('bases__text--label') + ' ' + cx('pr-22', 'col-6')}
                                            name="permission"
                                            options={[
                                                { value: '', label: t('staffworker.list.all') },
                                                { value: '4', label: t('staffworker.list.use_only') },
                                                { value: '2', label: t('staffworker.list.can_be_installed') },
                                            ]}
                                            value={values.permission}
                                            onChangeOption={(option) => setFieldValue('permission', option.value)}
                                        />
                                        <FormDropdown
                                            classNameLabel={cx('label_input', 'col-md-4 col-form-label text-end pe-0')}
                                            label="staffworker.detail.usage_status"
                                            className={cxCommon('bases__text--label') + ' ' + cx('pr-22', 'col-6')}
                                            name="account_status"
                                            options={[
                                                { value: '', label: t('staffworker.list.all') },
                                                { value: '1', label: t('staffworker.list.using') },
                                                { value: '2', label: t('staffworker.list.stop') },
                                            ]}
                                            value={values.account_status}
                                            onChangeOption={(option) => setFieldValue('account_status', option.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>
            {data?.length ? (
                <div className="d-flex">
                    <Button
                        disabled={!listCheckbox.length}
                        onClick={() => handleCheckResetPassword()}
                        className={cx('btnResetPassword') + ' ' + cxCommon('bases__margin--top20')}
                        text="staffworker.list.reset_password"
                        warning
                    />
                    <span className={cxCommon('text-red', 'font-size-12', 'align-self-end', 'bases__margin--left20')}>
                        {t('staffworker.list.noti')}
                    </span>
                </div>
            ) : (
                ''
            )}

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={table.heads}
                    body={table.body}
                    pageData={pageState}
                    onChangePage={(page) => {
                        setPageState((prevState) => ({
                            ...prevState,
                            currentPage: page,
                        }));
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        setSearchParams({
                            ...searchParamsObject,
                            offset: (page - 1)?.toString(),
                            limit: '30',
                        });
                        getStaffsAsync({
                            ...searchParamsObject,
                            offset: (page - 1)?.toString(),
                            limit: '30',
                        });
                    }}
                    onChangeCheckList={(value: string[]) => {
                        setListCheckbox(value);
                    }}
                />
            </div>
        </BodyContainer>
    );
};

export default EmployeeWorkerAccount;
