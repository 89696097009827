export const SUCCESS_CODE = 200;
export const VALIDATION_FAIL_CODE = 400;
export const AUTHENTICATION_FAIL_CODE = 401;
export const NOT_ALLOW_CODE = 403;
export const NOT_FOUND_CODE = 404;
export const ERROR_EXCEPTION_CODE = 500;
export const ERROR_GONE_CODE = 410;
export const ERROR_OTP_DUPLICATE = 502;
export const ERROR_LOCKED = 423;

export const ERROR_MESSAGE_CODE = {
    EXCEPTION: 50001,
};

export const SUCCESS_STATUS_MESSAGE = 'SUCCESS';
export const ERROR_STATUS_MESSAGE = 'NG';
export const KEY_GEOCODING_API_DEV = 'AIzaSyA-G-MgBDuucMWcZRjAsL3xmblzccyYl9o';
