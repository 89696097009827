const timeDropDown: IItemDropDown[] = [
    { value: 30, label: '30分' },
    { value: 60, label: '1時' },
    { value: 90, label: '1時30分' },
    { value: 120, label: '2時' },
    { value: 150, label: '2時30分' },
    { value: 180, label: '3時' },
    { value: 210, label: '3時30分' },
    { value: 240, label: '4時' },
    { value: 270, label: '4時30分' },
    { value: 300, label: '5時' },
    { value: 330, label: '5時30分' },
    { value: 360, label: '6時' },
    { value: 390, label: '6時30分' },
    { value: 420, label: '7時' },
    { value: 450, label: '7時30分' },
    { value: 480, label: '8時' },
    { value: 510, label: '8時30分' },
    { value: 540, label: '9時' },
    { value: 570, label: '9時30分' },
    { value: 600, label: '10時' },
    { value: 630, label: '10時30分' },
    { value: 660, label: '11時' },
    { value: 690, label: '11時30分' },
    { value: 720, label: '12時' },
    { value: 750, label: '12時30分' },
    { value: 780, label: '13時' },
    { value: 810, label: '13時30分' },
    { value: 840, label: '14時' },
    { value: 870, label: '14時30分' },
    { value: 900, label: '15時' },
    { value: 930, label: '15時30分' },
    { value: 960, label: '16時' },
    { value: 990, label: '16時30分' },
    { value: 1020, label: '17時' },
    { value: 1050, label: '17時30分' },
    { value: 1080, label: '18時' },
    { value: 1110, label: '18時30分' },
    { value: 1140, label: '19時' },
    { value: 1170, label: '19時30分' },
    { value: 1200, label: '20時' },
    { value: 1230, label: '20時30分' },
    { value: 1260, label: '21時' },
    { value: 1290, label: '21時30分' },
    { value: 1320, label: '22時' },
    { value: 1350, label: '22時30分' },
    { value: 1380, label: '23時' },
    { value: 1410, label: '23時30分' },
    { value: 1440, label: '24時' },
];

export { timeDropDown };
