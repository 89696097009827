import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { BodyContainer } from '~/components/layouts';
import styles from './Manual.module.scss';

const cx = classNames.bind(styles);

const Manual: IBaseComp = () => {
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }: any) {
        setNumPages(numPages);
    }

    return (
        <BodyContainer title={t('manual.title')}>
            <div className={cx('manual__container')}>
                <Document
                    file="https://guardlock-stg-storage.s3.ap-northeast-1.amazonaws.com/%E5%86%85%E8%A6%A7Benri.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={cx('manual__pdf')}>
                    {Array.from(new Array(numPages), (_, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            className="pdf-page"
                            pageNumber={index + 1}
                            width={2000}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    ))}
                </Document>
            </div>
        </BodyContainer>
    );
};

export default Manual;
