import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { BodyContainer } from '~/components/layouts';
import styles from './TermsOfService.module.scss';
const cx = classNames.bind(styles);

const TermsOfService: IBaseComp = () => {
    const { t } = useTranslation();

    return (
        <BodyContainer title={t('terms.title')}>
            <div className="d-flex justify-content-center flex-column">
                <div className={cx('bases__width-percent--100 text-break bases__padding--horizontal40', 'term')}>
                    <p>〇〇 ＝ 商品名</p>
                    <br />
                    <p className={cx('term-background')}>〇〇内覧サービス利用規約</p>
                    <p className="bases__margin--left10">
                        この「○○内覧サービス利用規約」(以下「本規約」といいます)は、株式会社ガードロックが開発し、運用を行う「○○内覧サービス」をお客様に提供するに際して、お客様の利用に関する条件等を定めるものです。
                    </p>
                    <p className="bases__margin--left10">
                        お客様が本規約を承諾されない場合、お客様は本サービス、及び本○○アプリをご利用いただくことはできません。
                    </p>
                    <br />
                    <p className={cx('term-bd-bottom', 'bases__margin--bottom10')}>
                        <b>第1章</b>&nbsp;&nbsp; 総論
                    </p>
                    <b>第1条</b>&nbsp;&nbsp;(利用規約の目的)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        本規約は、株式会社○○(以下、「当社」といいます。)が提供するIoT鍵ツール「○○キーボックス」(以下、「本製品」といい、第3条で定義します。)を使用するためのスマートフォン用アプリおよびWebシステムの利用条件を定めるものです。
                    </p>
                    <b>第2条</b>&nbsp;&nbsp;(利用規約の対象)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        本規約は、本サービス(第3条で定義します。)を利用する全てのお客様を対象とします。
                    </p>
                    <b>第3条</b>&nbsp;&nbsp;(定義)
                    <p className="bases__margin--left10">
                        本規約において使用される以下の語は以下に定義する意味とします。
                    </p>
                    <div className={cx('term-list-1', 'bases__margin--left10 bases__margin--bottom10')}>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(1)</p>
                            <p>「お客様」とは、本サービスを利用する個人または法人を意味します。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(2)</p>
                            <p>
                                「本製品」とは、内覧用の住宅物件の扉等(設備も含む)に取付け、お客様のスマートフォンと無線通信(Bluetooth®)により開閉するキーボックス(製品名称「〇〇キーボックス」)を意味します。
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(3)</p>
                            <p>「本サービス」とは、次のソフトウェアを利用したサービスを意味します。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">1</p>
                            <p>本製品を開錠させるスマートフォン用アプリ「○○キーアプリ」(以下「アプリ」といいます。)</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">2</p>
                            <p>
                                本製品を取り付けた賃貸物件の登録、内覧予約・承認、スタッフ権限管理等を行うWebシステム(以下、「管理画面」といいます。)
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(4)</p>
                            <p>「システム管理者」とは、管理画面を利用するお客様を意味します。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(5)</p>
                            <p>「アプリユーザ」とは、アプリを利用するお客様を意味します。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(6)</p>
                            <p>
                                「利用契約」とは、第8条の定めにより成立する本サービスの利用に関する権利義務関係を内容とする契約を意味します。
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(7)</p>
                            <p>
                                「知的財産権等」とは、著作権(著作権法第27条および第28条に定める権利を含みます。)、特許権、実用新案権、商標権、意匠権その他の知的財産権(それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。)およびノウハウを意味します。
                            </p>
                        </div>
                    </div>
                    <b>第4条</b>&nbsp;&nbsp; (本サービスの内容)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        本サービスの詳細は、当社ホームページに記載するものとします。
                    </p>
                    <b>第5条</b>&nbsp;&nbsp; (通知)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            当社からお客様への通知は、利用契約に特段の定めのない限り、通知内容を電子メール、本サービスへの表示など、当社が適当と判断する方法により行います。
                        </li>
                        <li>
                            前項の規定に基づき、当社からお客様への通知を電子メール、本サービスへの表示により行う場合には、お客様に対する当該通知は、それぞれ電子メールの送信、本サービス等への表示がなされた時点から効力を生じるものとします。
                        </li>
                    </ul>
                    <b>第6条</b>&nbsp;&nbsp; (本規約の変更)
                    <ul className={cx('term-list-1', 'bases__margin--left20')}>
                        <li>
                            当社は、本規約を予告なく追加・変更・削除(以下「変更」といいます。)することがあります。なお、この場合には、お客様の利用条件の内容は、変更後の利用規約を適用するものとします。
                        </li>
                    </ul>
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        前項の本規約の変更は、当社が別途定める場合を除き、本サービスに変更後の内容を表示した時点をもってその効力を生じるものとします。なお、お客様は当該期間の経過後の本サービスの利用により当該変更に同意したものとみなされます。
                    </p>
                    <b>第7条</b>&nbsp;&nbsp; (本サービス利用のための設定・維持)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        本サービスを利用する前提として、本サービスを利用するためのインターネット接続環境、本製品と通信を行うために必要となるBluetooth®に対応した携帯端末をお客様の責任で準備して頂くものとします。
                    </p>
                    <b>第8条</b>&nbsp;&nbsp; (利用契約の締結等)
                    <ul className={cx('term-list-1', 'bases__margin--left20')}>
                        <li>
                            本サービスの利用契約の成立については、第23条(管理画面利用契約の成立)および第27条(アプリ利用契約の成立)に従うものとします。なお、本サービスの利用希望者は本規約の内容を承諾の上申込みを行うものとし、当社は、お客様が本サービスの申込みを行った時点で、お客様が本規約の内容を承諾しているものとみなします。
                        </li>
                        <li>
                            当社は、前項の規定にかかわらず、本サービスの利用希望者が次の各号のいずれかに該当する場合には、利用契約を締結しないことができます。この場合の不締結の理由は非公開とします。
                        </li>
                    </ul>
                    <div className={cx('term-list-1', 'bases__margin--left10 bases__margin--bottom10')}>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(1)</p>
                            <p>
                                本サービスに関する金銭債務の不履行、その他利用契約に違反したことを理由として利用契約を解除されたことがあるとき
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(2)</p>
                            <p>利用申込または利用変更申込に虚偽の記載、誤記があったときまたは記入もれがあったとき</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(3)</p>
                            <p>金銭債務その他利用契約に基づく債務の履行を怠るおそれがある当社が判断したとき</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(4)</p>
                            <p>利用申込者が本サービスと同様または類似の製品・サービスを提供しているとき</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(5)</p>
                            <p>その他当社が不適当と判断したとき</p>
                        </div>
                    </div>
                    <b>第9条</b>&nbsp;&nbsp; (IDおよびパスワードの管理)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            本サービスを利用するに際し、お客様はユーザ登録が必要となります。お客様がユーザ登録を行うに当たっては、当社が別途指定する方法により、真実かつ正確で最新の情報を当社に提供することとします。
                        </li>
                        <li>
                            お客様は、
                            IDおよびパスワードの認証情報を第三者に開示、貸与、共有しないとともに、第三者に漏洩することのないよう厳重に管理(パスワードの適宜変更を含みます。)するものとします。IDおよびパスワードの認証情報の管理不備、使用上の過誤、第三者の使用等によりお客様自身およびその他の第三者が損害を被った場合、当社は一切の責任を負わないものとします。お客様のIDおよびパスワードによる本サービスの利用その他の行為は、全てお客様による利用とみなすものとされ、お客様はかかる利用についての利用料金の支払その他の債務一切を負担するものとします。
                        </li>
                    </ul>
                    <b>第10条</b>&nbsp;&nbsp; (禁止事項)
                    <p className="bases__margin--left10">
                        お客様は、本サービスの利用にあたり、以下の各号に該当する行為を行うことを禁止します。お客様の行為が以下各号に該当する場合または当社が該当すると判断した場合、当社は当該お客様との利用契約の全部または一部の解約もしくは利用の停止をすることができるものとします。
                    </p>
                    <div className={cx('term-list-1', 'bases__margin--left40 bases__margin--bottom10')}>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(1)</p>
                            <p>法令に違反する行為または犯罪行為に関連する行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(2)</p>
                            <p>当社、本サービスを利用する他のお客様またはその他の第三者に対する詐欺または脅迫行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(3)</p>
                            <p>公序良俗に反する行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(4)</p>
                            <p>
                                当社、本サービスを利用する他のお客様またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(5)</p>
                            <p>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(6)</p>
                            <p>本サービスの運営を妨害するおそれのある行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(7)</p>
                            <p>本サービスまたは本製品のリバースエンジニアリング等の解析行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(8)</p>
                            <p>当社のネットワークまたはシステムもしくは本製品に不正にアクセスし、または不正なアクセスを試みる行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(9)</p>
                            <p>第三者に成りすます行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(10)</p>
                            <p>当社が許諾していない本サービス上での宣伝、広告、勧誘、または営業行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(11)</p>
                            <p>本サービスを利用する他のお客様またはその他の第三者に不利益、損害、不快感を与える行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(12)</p>
                            <p>反社会的勢力等への利益供与</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(13)</p>
                            <p>当社の同意なく、本サービスまたは本製品の再頒布、貸与、販売をする行為</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">(14)</p>
                            <p>
                                個人間での短期住居賃貸など日本国内での適法性に疑義のあるサービスに利用することその他当社が不適切と判断する行為
                            </p>
                        </div>
                    </div>
                    <b>第11条</b>&nbsp;&nbsp; (一時中断および提供停止)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            当社は、次の各号のいずれかに該当する場合には、お客様への事前の通知または承諾を要することなく、本サービスの提供を中断することができるものとします。
                        </li>
                        <div className={cx('term-list-1', 'bases__margin--left10')}>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(1)</p>
                                <p>本サービスに係るシステムの保守を定期的にまたは緊急に行う場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(2)</p>
                                <p>
                                    天災地変、停電、戦争、暴動、騒乱、労働争議その他の不可抗力により本サービス等の提供ができなくなった場合
                                </p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(3)</p>
                                <p>本サービスに係るシステム上の障害等により本サービスの提供ができなくなった場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(4)</p>
                                <p>AppStore®または Google Play®の事情により本サービスの提供ができなくなった場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(5)</p>
                                <p>その他、運用上あるいは技術上当社が本サービスの中断を必要と判断した場合</p>
                            </div>
                        </div>
                        <li>
                            当社は、お客様が第13条(当社からの利用契約の解約等)第1項各号または第2項のいずれかに該当する場合、またはお客様が利用料金等未払いその他利用契約に違反した場合には、お客様への事前の通知もしくは催告を要することなく本サービスの全部または一部の提供を停止することができるものとします。
                        </li>
                        <li>
                            当社は、前各項に定める事由のいずれかにより本サービスを提供できなかったことに関してお客様またはその他の第三者が損害を被った場合であっても、一切責任を負わないものとします。
                        </li>
                    </ul>
                    <b>第12条</b>&nbsp;&nbsp; (本サービスの変更または廃止)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            当社は、10日前までにお客様に通知をすることで、本サービスの全部または一部を変更することができるものとします。
                        </li>
                        <li>
                            当社は、30日前までにお客様に通知をすることで本サービスの全部または一部を廃止できるものとします。その場合、廃止日の属する月の利用料金は、1か月を30日として月額料金を利用日数に応じて日割計算した額とします。
                        </li>
                    </ul>
                    <b>第13条</b>&nbsp;&nbsp; (当社からの利用契約の解約等)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            当社は、お客様が次の各号のいずれかに該当すると判断した場合、お客様への事前の通知または催告を要することなく利用契約の全部または一部を解約もしくは利用の停止をすることができるものとします。
                        </li>
                        <div className={cx('term-list-1', 'bases__margin--left10')}>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(1)</p>
                                <p>利用申込に虚偽記入または記入もれがあった場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(2)</p>
                                <p>支払停止または支払不能となった場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(3)</p>
                                <p>手形または小切手が不渡りとなった場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(4)</p>
                                <p>差押え、仮差押えもしくは競売の申立があったときまたは公租公課の滞納処分を受けた場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(5)</p>
                                <p>
                                    破産、会社更生手続開始もしくは民事再生手続開始の申立があったときまたは信用状態に重大な不安が生じた場合
                                </p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(6)</p>
                                <p>監督官庁から営業許可の取消、停止等の処分を受けた場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(7)</p>
                                <p>利用契約に違反し当社がかかる違反の是正を催告した後合理的な期間内に是正されない場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(8)</p>
                                <p>解散、減資、事業の全部または重要な一部の譲渡等の決議をした場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(9)</p>
                                <p>本サービスと同様または類似のサービスを現に提供している場合または将来行う予定があることが判明した場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(10)</p>
                                <p>反社会的活動を行っている場合その他反社会的勢力に該当する場合もしくはこれら企業と関連がある場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">(11)</p>
                                <p>その他利用契約を履行することが困難となる事由が生じたと当社が判断した場合</p>
                            </div>
                        </div>
                        <li>
                            前項(7)において、アプリユーザが利用契約に違反し、当社がかかる違反の是正を催告した後合理的な期間内に是正されない場合、当社はアプリユーザと間の利用契約に加え、当該アプリユーザを承認したシステム管理者との利用契約もその全部又は一部を解約もしくは利用の停止をすることができるものとします。
                        </li>
                        <li>
                            お客様は、前項各号の事由に該当した場合、当社に対して負っている債務の一切について期限の利益を失い、前項による利用契約の解約または停止があった時点において未払いの利用料金または支払遅延損害金がある場合には、直ちにこれを支払わなければならないものとします。
                        </li>
                    </ul>
                    <b>第14条</b>&nbsp;&nbsp; (知的財産権等の取扱い)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            本サービスまたは本製品に関する知的財産権等は全て当社または当社にライセンスを許諾している者に帰属しており、お客様は許容された範囲内のみでご利用ができる権利を許諾されています。
                        </li>
                        <li>
                            お客様は、本サービスに対して、リバースエンジニアリング、逆コンパイル、又は逆アセンブラ、その他本サービスを解析しようと試みてはならないものとします。
                        </li>
                    </ul>
                    <div className="d-flex bases__margin--bottom10">
                        <p>
                            <b className="text-nowrap">第15条</b>&nbsp;&nbsp;
                        </p>
                        <p>
                            (秘密保持)お客様は、当社が秘密に取り扱うことを求めて開示した本サービスに関する情報について、目的外で使用してはならず、当社の書面による承諾がある場合を除き、第三者への開示・漏洩してはならないものとします。
                        </p>
                    </div>
                    <b>第16条</b>&nbsp;&nbsp;(個人情報保護)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        当社は本サービスに関連して取得したお客様の個人情報を別途定める当社のプライバシーポリシー (
                        <a href="https://www" target="blank" className={cx('term-link')}>
                            https://www
                        </a>
                        ) に従い、適切に取り扱うものとします。
                    </p>
                    <b>第17条</b>&nbsp;&nbsp;(免責)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            当社は、本サービスの提供の中断、停止、終了、利用不能または変更、本サービスのダウンロードまたは利用による携帯端末の故障または損傷、本製品の利用不能その他本サービスに関してお客様が被った損害につき賠償する責任を一切負わないものとします。
                        </li>
                        <li>
                            天災地変、戦争・内乱・暴動、法令の改廃・制定、公権力による命令・処分、労働争議、
                            輸送機関・通信回線の事故、原材料・運賃の高騰、為替の大幅な変動その他当社の責めに帰すことのできない不可抗力による利用契約の全部または一部の履行遅滞、履行不能または不完
                            全履行については、当社は責任を負わないものとします。
                        </li>
                    </ul>
                    <b>第18条</b>&nbsp;&nbsp;(再委託)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        当社は、お客様に対する本サービスの提供に関して必要となる業務の全部または一部を当社の判断にて第三者に再委託することができます。この場合、当社は、当該再委託先(以下「再委託先」といいます。)に対し、第16条(個人情報保護)のほか当該再委託業務遂行について利用契約所定の当社の義務と同等の義務を負わせるものとします。
                    </p>
                    <b>第19条</b>&nbsp;&nbsp; (利用契約の契約期間)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        利用契約の契約期間は、第26条(管理画面)および第30条(アプリ利用)の定めに従うものとします。
                    </p>
                    <b>第20条</b>&nbsp;&nbsp; (権利義務の譲渡)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        お客様は、当社の書面による事前の承諾を得ない限り、本規約に基づく権利または義務を、全部であるか一部であるかを問わず、第三者に譲渡し、引受けさせ、担保に供し、その他如何なる態様によっても処分することができないものとします。
                    </p>
                    <b>第21条</b>&nbsp;&nbsp;(合意管轄)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        利用契約に関して生じた一切の争訟については、<b>大阪地方裁判所</b>を第一審の専属的合意管轄裁判所とします。
                    </p>
                    <b>第22条</b>&nbsp;&nbsp; (協議)
                    <p className="bases__margin--left10">
                        本規約に規定のない事項および規定された項目について疑義が生じた場合、当社およびお客様は誠意をもって協議の上解決することとします。
                    </p>
                    <br />
                    <p className={cx('term-bd-bottom', 'bases__margin--bottom10')}>
                        <b>第2章</b>&nbsp;&nbsp; システム管理者に関する取り決め
                    </p>
                    <b>第23条</b>&nbsp;&nbsp;(管理画面利用契約の成立)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        管理画面の利用契約は、利用希望者が当社所定の申込用紙を提出し、またはWeb画面より申込フォームを送信し、当社がユーザ登録を完了したときに成立するものとします。
                    </p>
                    <b>第24条</b>&nbsp;&nbsp;(管理画面の利用方法)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        システム管理者は、当社が提示したWeb画面からユーザIDおよびパスワードでログインし、管理画面を利用します。利用方法の詳細は管理画面のユーザマニュアルを参照するものとします。
                    </p>
                    <b>第25条</b>&nbsp;&nbsp;(管理画面の利用料金)
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            管理画面の利用料金・支払方法は、当社が交付する「ご利用料金表」に定めるとおりとします。なお、お客様が本条に定める支払を完了しない場合、当社は本サービスの提供を行わないことができるものとします。
                        </li>
                        <li>
                            利用期間において、第11条(一時中断および提供停止)に定める本サービスの提供の中断、停止その他の事由により本サービスを利用することができない状態が生じたときであっても、月額利用料金は全額支払われるものとします。
                        </li>
                    </ul>
                    <b>第26条</b>&nbsp;&nbsp;(管理画面利用契約の契約期間)
                    <ul className={cx('term-list-1', 'bases__margin--left20')}>
                        <li>
                            管理画面利用契約の契約期間は、システム管理者のユーザ登録日から1年間とします。但し、契約期間の終了する1ヶ月前までにお客様から当社指定のフォーマットにより解約の通知がなされない場合は、利用契約は同一条件にて同一期間さらに自動更新されるものとし、以後も同様とします。
                        </li>
                        <li>
                            前項の規定に関わらず、システム管理者が利用契約を契約期間内に解約することを希望する場合、当社に対し当社指定のフォーマットで通知することにより、当該通知日の属する月の最終日をもって利用契約を解約することができます。
                        </li>
                    </ul>
                    <br />
                    <p className={cx('term-bd-bottom', 'bases__margin--bottom10')}>
                        <b>第3章</b>&nbsp;&nbsp; アプリ利用者に関する取り決め
                    </p>
                    <b>第27条</b>&nbsp;&nbsp;(アプリ利用契約の成立)
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        アプリ利用契約は、アプリ利用希望者がアプリをインストールし、アプリIDおよびパスワードで起動し、ログインしたときに成立するものとします。
                    </p>
                    <b>第28条</b>&nbsp;&nbsp;(アプリの利用方法)
                    <p className="bases__margin--left10">管理会社アプリ</p>
                    <p className="bases__margin--left10">
                        アプリユーザは、App Store®またはGoogle
                        Play®からアプリをダウンロード・インストールし、システム管理者から付与されたアプリログインIDおよびパスワードでログインし、アプリを利用します。利用方法の詳細はアプリページを参照するものとします。
                    </p>
                    <p className="bases__margin--left10">仲介業者アプリ</p>
                    <p className="bases__margin--left10 bases__margin--bottom10">
                        アプリユーザは、App Store®またはGoogle
                        Play®からアプリをダウンロード・インストールし、新規登録により付与されたアプリログインIDおよびパスワードでログインし、アプリを利用します。利用方法の詳細はアプリページを参照するものとします。
                    </p>
                    <b>第29条</b>&nbsp;&nbsp;(アプリの利用料金)
                    <p className="bases__margin--left10 bases__margin--bottom10">アプリのみを利用する場合、利用料金はかかりません。</p>
                    <b>第30条</b>&nbsp;&nbsp;(アプリ利用契約の契約期間)
                    <p className="bases__margin--left10">管理会社アプリ</p>
                    <p className="bases__margin--left10">
                        アプリ利用契約の契約期間は、別途システム管理者とアプリユーザの間で合意された期間とします。ただし、当社とシステム管理者との間の利用契約が終了した場合、アプリ利用契約は自動的に終了するものとします。
                    </p>
                    <p className="bases__margin--left10">仲介業者アプリ</p>
                    <p className="bases__margin--left10">
                        アプリ利用契約の契約期間は、新規登録でログインID及びパスワードを付与され利用する期間とします。
                    </p>
                    <br />
                    <p className={cx('term-bd-bottom', 'bases__margin--bottom10')}>
                        <b>第4章</b>&nbsp;&nbsp; 附則
                    </p>
                    <p className="bases__margin--left10">2018 年 7 月 1 日制定 </p>
                    <p className={cx('term-background')}>○○キーボックス保証規定</p>
                    <ul className={cx('term-list-1', 'bases__margin--left20 bases__margin--bottom10')}>
                        <li>
                            保証期間内に、設直ガイド・オンラインマニュアルなどの注意書きにしたがった正常な使用状態で故障した場合には、無償修理いたします。
                        </li>
                        <li>
                            修理の必要が生じた場合は、本保証書と本製品を弊社までご持参いただくかあるいはご送付ください。無料で修理または交換をおこないます。なお、この場合の交通費、送料および諸掛かりはお客さまのご負担となりますので、あらかじめご了承願います。
                        </li>
                        <li>保証期間中では次の場合は有償修理となります。</li>
                        <div className={cx('term-list-1', 'bases__margin--left10')}>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">1</p>
                                <p>取扱い上の不注意、誤用による故障および損傷（LEDや蓋の割れなど）</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">2</p>
                                <p>お買い上げ後の落下、輸送などによる故障および損傷</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">3</p>
                                <p>弊社以外での修理、改造による故障および損傷</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">4</p>
                                <p>火災・地震・水害・落雷・その他天災地変、公害や異常電圧による故障および損傷</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">5</p>
                                <p>本保証書の提示がない場合</p>
                            </div>
                            <div className="d-flex align-items-start">
                                <p className="bases__margin--right8">6</p>
                                <p>
                                    本保証書にお買い上げ年月日、お客さま情報の記入の無い場合あるいは字句を書き換えた場合お客さまに迅速かつ確実なサービスをお届けするため、同一機種または弊社規定にしたがい、同程度の仕様製品との機械交換方式を採用しています。なお、故障状況により、部品交換方式を採用させていただく場合がございますので、あらかじめご了承願います。
                                </p>
                            </div>
                        </div>
                        <li>消耗品類は、本保証書による保証の対象とはなりません。</li>
                        <li>
                            保証期間内であっても、本製品の故障または本製品の使用によって生じた直接、間接の損害については、弊社ではその責任を負いかねますので、ご了承願います。
                        </li>
                        <li>本保証書は、日本国内においてのみ有効です。</li>
                        <li>本保証書は再発行いたしませんので、紛失しないように大切に保管してください。</li>
                    </ul>
                    <div className={cx('term-list-1', 'bases__margin--left10')}>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">※</p>
                            <p>
                                本保証書は、以上の保証規定により無償修理をお約束するためのものであり、これにより弊社および弊社以外の事業者に対するお客さまの法律上の権利を制限するものではありません。
                            </p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">※</p>
                            <p>保証期間経過後は、弊社規定の修理交換料金を申し受けます。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">※</p>
                            <p>保証内容や修理のお問い合わせは、<b>株式会社ガードロックカスタマーセンター</b>までお願いします。</p>
                        </div>
                        <div className="d-flex align-items-start">
                            <p className="bases__margin--right8">※</p>
                            <p>上記の記載内容は都合により予告なく変更する場合がありますのでご了承ください。</p>
                        </div>
                    </div>
                    <br />
                    <br />
                    <p className="bases__margin--left10">(2018年2月1日現在)</p>
                </div>
            </div>
        </BodyContainer>
    );
};

export default TermsOfService;
