import { api } from '@utils/constants';
import { axios } from '@utils/plugins';

export const getUser = async () => {
    return await axios.get<IGetUserAPIRes>(api.API.USER);
};

export const getAllInfoUser = async () => {
    return await axios.get<IGetInfoUserAPIRes>(api.API.USER_INFO);
};

export const userSignin = async (formData: any) => {
    return await axios.post('/auth/login', formData);
};

export const signOut = async () => {
    return await axios.post(api.API.LOGOUT);
};

export const getCompanyInfo = async () => {
    return await axios.get<IGetCompanyInfoAPIRes>(api.API.COMPANY_INFO);
};

export const editCompanyInfo = async (body: ICompanyInfoDataAPI) => {
    return await axios.put<IBaseAPIRes>(api.API.COMPANY_INFO, body);
};

export const editCompanyInfoNew = async (formData: FormData) => {
    return await axios.put<IBaseAPIRes>(api.API.COMPANY_INFO, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: () => {
            return formData;
        },
    });
};

export const getAsset = async (params?: IParamsAsset, assetId?: string) => {
    return await axios.get(`/assets/${assetId ? assetId : ''}`, { params });
};
export const getRoomAsset = async (params?: IParamsAsset, assetId?: string) => {
    return await axios.get(`${api.API.ASSET_ROOM}${assetId}`, { params });
};
export const getRoomDetail = async (roomNumber?: string) => {
    return await axios.get(`${api.API.ROOM_DETAIL}${roomNumber}`);
};
export const changePassword = async (formData: any) => {
    return await axios.patch(api.API.CHANGE_PASSWORD, formData);
};
export const resetPassword = async (formData: any) => {
    return await axios.post('/auth/reset-password', formData);
};
export const confirmResetPassword = async (formData: any, token?: string | null) => {
    return await axios.post('/auth/confirm-reset-password', formData, {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
};
export const apiCheckToken = async (token: string) => {
    return await axios.get('/auth/check-reset-password', {
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
};
export const getNotification = async (params?: IParamsNotification) => {
    return await axios.get<IBaseAPISearchRes<IDataNotification>>(api.API.NOTIFICATION, {
        params,
    });
};

export const getRefreshToken = async (formData: IRefreshTokenDataAPI) => {
    return await axios.post(api.API.REFRESH_TOKEN, formData);
};

export const getAddressByPostalCode = async (postalCode: string) => {
    return await axios.get(`${api.API.GET_ADDRESS_BY_POSTAL_CODE}${postalCode}`);
};

export const changeStatusAsset = async (body?: any) => {
    return await axios.post(api.API.CHANGE_STATUS, body);
};
export const deleteAsset = async (body?: any) => {
    return await axios.delete(api.API.DELETE_ASSET, body);
};
export const getListBroker = async (params: IIntermediariesSearchParams) => {
    return await axios.get<IBaseAPISearchRes<IDataApiIntermediariesRes>>(api.API.GET_LIST_BROKER, {
        params,
    });
};
export const getListBrokerRegistered = async (params: SearchParamsRegisteredBroker) => {
    return await axios.get<IBaseAPISearchRes<IDataApiIntermediariesRes>>(api.API.GET_LIST_BROKER_REGISTERED, {
        params,
    });
};

export const getListBrokerCompany = async (params: SearchParamsBrokerCompany) => {
    return await axios.get<IBaseAPISearchRes<IDataApiBrokerCompanyRes>>(api.API.GET_LIST_BROKER_COMPANY, {
        params,
    });
};

export const updateBrokerCompany = async (body: IBodyAutoApproveBrokerCompany) => {
    return await axios.put<IBaseAPIRes>(api.API.AUTO_APPROVE_BROKER_COMPANY, body);
};

export const updateBrokerCompanyAsset = async (body: IBodyAutoApproveBrokerCompany) => {
    return await axios.put<IBaseAPIRes>(api.API.AUTO_APPROVE_BROKER_COMPANY_BDS, body);
};

export const addBrokerToCompany = async (id: number) => {
    return await axios.put<IBaseAPIRes>(`${api.API.ADD_BROKER_TO_COMPANY}${id}`);
};

export const getListKeybox = async (params: IKeyboxSearchParams) => {
    return await axios.get(api.API.GET_LIST_KEYBOX, {
        params,
    });
};

export const getDetailKeyBox = async (id: number) => {
    return await axios.get<IBaseAPIRes>(`${api.API.GET_LIST_KEYBOX}/${id}`);
};

export const cancelContractKeyBox = async (id: number) => {
    return await axios.post<IBaseAPIRes>(`${api.API.GET_LIST_KEYBOX}/${id}`);
};

export const deleteKeybox = async (params: IDeleteKeyboxParams) => {
    return await axios.delete(`${api.API.DELETE_KEYBOX}`, { params });
};

export const addRoomAsset = async (id: string, data: IAddRoomAssetRequest) => {
    return await axios.post(
        `${api.API.ADD_ROOM_ASSET}${id}`,
        { data: data },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return data;
            },
        },
    );
};

export const getDetailBroker = async (id: string | number, params: IDetailBrokerParam) => {
    return await axios.get(`${api.API.GET_LIST_BROKER}/${id}`, { params });
};

export const deleteRooms = async (params: IDeleteRoomsParams) => {
    return await axios.delete(`${api.API.ROOMS}`, { params });
};

export const registerAsset = async (params: any) => {
    return await axios.post(
        api.API.REGISTER_ASSET,
        { data: params },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return params;
            },
        },
    );
};

export const updateAsset = async (id: string, params: any) => {
    return await axios.put(
        `${api.API.REGISTER_ASSET}${id}`,

        { data: params },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return params;
            },
        },
    );
};

export const detailRoom = async (id: string | number) => {
    return await axios.get(`${api.API.ROOMS}/${id}`);
};

export const getListRoom = async (id: string | number) => {
    return await axios.get(`${api.API.ROOMS}/${api.API.LIST}/${id}`);
};

export const getDetailAsset = async (id: string | number) => {
    return await axios.get(`${api.API.ASSET}/${id}`);
};

export const updateRoomOfAsset = async (id: string, data: IEditRoomAssetRequest) => {
    return await axios.put(
        `${api.API.ADD_ROOM_ASSET}${id}`,
        { data: data },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return data;
            },
        },
    );
};

export const exportRoom = async () => {
    return await axios.post(`${api.API.EXPORT_ROOM}`);
};

export const importRoom = async (data: IImportRoomAssetRequest) => {
    const formData = new FormData();
    formData.append('data', data.data);
    formData.append('images', data.images);

    return await axios.post(
        `${api.API.IMPORT_ROOM}`,
        { data: formData },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return formData;
            },
        },
    );
};

export const registerStaffs = async (params: any) => {
    return await axios.post(
        api.API.REGISTER_STAFFS,
        { data: params },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return params;
            },
        },
    );
};

export const getDetailStaffs = async (id: string | number) => {
    return await axios.get(`${api.API.REGISTER_STAFFS}${id}`);
};

export const editStaffs = async (id: string | number, params: IStaffAdd) => {
    return await axios.put(
        `${api.API.REGISTER_STAFFS}${id}`,
        { data: params },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: () => {
                return params;
            },
        },
    );
};

export const getListAccountCompany = async (params?: ICompanyAccountSearchParams) => {
    return await axios.get(api.API.ACCOUNT_COMPANY, {
        params,
    });
};

export const getDetailAccountCompany = async (company_id: string | number, admin_id: string | number) => {
    return await axios.get(`${api.API.ACCOUNT_COMPANY}/details/${company_id}/${admin_id}`);
};

export const updateAccountCompany = async (id: string | number, admin_id: string | number, formData: FormData) => {
    return await axios.put<IBaseAPIRes>(`${api.API.ACCOUNT_COMPANY}/${id}/${admin_id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: () => {
            return formData;
        },
    });
};

export const createAccountCompany = async (formData: FormData) => {
    return await axios.post<IBaseAPIRes>(api.API.ACCOUNT_COMPANY, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: () => {
            return formData;
        },
    });
};

export const deleteAccountCompany = async (id: string | number, admin_id: string | number) => {
    return await axios.delete(`${api.API.ACCOUNT_COMPANY}/${id}/${admin_id}`);
};

export const getListStaffs = async (params: IStaffsSearchParams) => {
    return await axios.get(api.API.GET_LIST_STAFFS, {
        params,
    });
};

export const resetPasswordStaffs = async (body?: any) => {
    return await axios.post(api.API.RESETPASSWORD_STAFFS, body);
};

export const getListCompany = async () => {
    return await axios.get(`${api.API.ACCOUNT_COMPANY}/list/all`);
};

export const deleteStaffs = async (id: string) => {
    return await axios.delete(`${api.API.GET_LIST_STAFFS}${id}`);
};

export const getListHistorySetupKeybox = async (params: IHistoryPageState) => {
    return await axios.get(api.API.GET_LIST_HISTORY_SETUP_KEYBOX, { params });
};

export const getListNotification = async (params: SearchParamsNotification) => {
    return await axios.get<IBaseAPISearchRes<INotificationList>>(api.API.GET_LIST_NOTIFICATION, {
        params,
    });
};

export const readListNotification = async (body: IParamReadNotification) => {
    return await axios.post<IBaseAPIRes>(api.API.READ_LIST_NOTIFICATION, body);
};

export const deleteNotification = async (params: IParamsDeleteNotification) => {
    return await axios.delete<IBaseAPIRes>(`${api.API.GET_LIST_NOTIFICATION}/`, {
        params,
    });
};

export const createNotification = async (body: IAddNotificationForm) => {
    return await axios.post<IBaseAPIRes>(api.API.GET_LIST_NOTIFICATION, body);
};

export const getDetailNotification = async (id: number, params?: IParamNotificationDetail) => {
    return await axios.get<IBaseAPIRes>(`${api.API.GET_LIST_NOTIFICATION}/${id}`, {
        params,
    });
};

export const getDetailHistorySetupKeybox = async (id: string | number) => {
    return await axios.get(`${api.API.GET_DETAIL_HISTORY_SETUP_KEYBOX}${id}`);
};

export const getDetailHistoryOneTimePwdKeybox = async (type: number, id: string | number) => {
    return await axios.get(`${api.API.GET_DETAIL_HISTORY}${type}/${id}`);
};

export const getDetailHistoryWorker = async (type: number, id: string | number) => {
    return await axios.get(`${api.API.GET_DETAIL_HISTORY}${type}/${id}`);
};

export const getDetailHistoryEmploye = async (type: number, id: string | number) => {
    return await axios.get(`${api.API.GET_DETAIL_HISTORY}${type}/${id}`);
};

export const getDetailHistoryBroker = async (type: number, id: string | number) => {
    return await axios.get(`${api.API.GET_DETAIL_HISTORY}${type}/${id}`);
};

export const getSurveyQuestions = async (params?: IBaseAPISearchParams) => {
    return await axios.get(api.API.GET_SURVEY_QUESTIONS, {
        params,
    });
};

export const getSurveyQuestionsDetail = async (id: number) => {
    return await axios.get(`${api.API.GET_SURVEY_QUESTIONS}${id}`);
};

export const getSurveyQuestionsPublic = async () => {
    return await axios.get(api.API.GET_SURVEY_QUESTIONS_PUBLIC);
};

export const putSurveyQuestionsPublic = async (id: number[]) => {
    return await axios.put(api.API.GET_SURVEY_QUESTIONS_PUBLIC, { id: id });
};

export const postSurveyQuestions = async (data: any) => {
    return await axios.post(api.API.GET_SURVEY_QUESTIONS, { question: data });
};

export const getListHistory = async (type: number, params: IHistoryPageState) => {
    return await axios.get(`${api.API.GET_LIST_HISTORY}${type}/historyOpenKeybox`, { params });
};

export const getAnswers = async (params: IAnswersSearchParams) => {
    return await axios.get<IBaseAPISearchRes<IDataApiAnswersRes>>(api.API.GET_SURVEY_ANSWERS, {
        params,
    });
};

export const getAnswerDetail = async (submitId: string) => {
    return await axios.get<IBaseAPIRes>(`${api.API.GET_SURVEY_ANSWER_DETAIL}/${submitId}`);
};

export const getAnswerStatistical = async () => {
    return await axios.get<IBaseAPIRes>(api.API.GET_SURVEY_ANSWER_STATISTICAL);
};
// Appointment Api - START
export const getAppointmentList = async (params: IAppointmentSearchParams) => {
    return await axios.get(api.API.APPOINTMENT_LIST, { params });
};

export const updateStatusAppointment = async (body: IAppointmentUpdateStatus) => {
    return await axios.post(api.API.APPOINTMENT_UPDATE, body);
};

export const getDetailAppointment = async (id: string | number) => {
    return await axios.get(`${api.API.APPOINTMENT_LIST}/${id}`);
};
// Appointment Api - END

// OneTimePassword - START
export const getOneTimePassList = async (params: IOneTimePassSearchParams) => {
    return await axios.get(api.API.ONE_TIME_PASS_LIST, { params });
};

export const getDetailOneTimePass = async (id: number) => {
    return await axios.get(`${api.API.ONE_TIME_PASS_LIST}/${id}`);
};

export const deleteOneTimePass = async (id: number) => {
    return await axios.delete(`${api.API.ONE_TIME_PASS_LIST}/${id}`);
};

export const addOneTimePass = async (data: IAddOneTimePassForm) => {
    return await axios.post(`${api.API.ONE_TIME_PASS_LIST}`, data);
};

export const editOneTimePass = async (id: number, data: IAddOneTimePassForm) => {
    return await axios.put(`${api.API.ONE_TIME_PASS_LIST}/${id}`, data);
};

export const checkAssetIsPublic = async () => {
    return await axios.get(`${api.API.ONE_TIME_PASS_LIST}/check`);
};

export const getListAssetName = async () => {
    return await axios.get(`${api.API.ONE_TIME_PASS_LIST}/asset`);
};
export const getLisRoomNumber = async (data: any) => {
    return await axios.post(`${api.API.ONE_TIME_PASS_LIST}/roomByAsset`, data);
};

export const getScheduleByRoomId = async (data: any) => {
    return await axios.post(api.API.ONE_TIME_PASS_SCHEDULE, data);
};
// OneTimePassword - END
export const getListPayment = async (params: SearchParamsNotification) => {
    return await axios.get<IBaseAPIResGeneric<IDataPayment>>(api.API.PAYMENT, { params });
};

export const createPayment = async (data: IPaymentForm) => {
    return await axios.post(api.API.PAYMENT, data);
};

export const deleteCard = async () => {
    return await axios.delete<IBaseAPIRes>(api.API.PAYMENT);
};

export const unlockCard = async (body: { companyIdUnlock: number }) => {
    return await axios.put<IBaseAPIRes>(api.API.PAYMENT, body);
};
