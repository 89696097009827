import classNames from 'classnames/bind';
import { Pagination } from '~/components/commons';
import styles from './Table.module.scss';

const cx = classNames.bind(styles);

interface ITableProp extends IBaseCompProps {
    className?: string;
    pageData?: ICommonPageState;
    onChangePage?: (page: number) => void;
}

const Table: IBaseComp<ITableProp> = ({
    children,
    className,
    pageData,
    onChangePage
}) => {
    return (
        <div className={cx('table-container') + ' ' + className}>
            <table className="table table-bordered">
                {children}
            </table>
            {pageData && <Pagination {...pageData} onChangePage={onChangePage} />}
        </div>
    );
};

export default Table;
