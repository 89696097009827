import { FormInput } from '@components/commons';
import stylesModal from '@components/modals/Modal.module.scss';
import classNames from 'classnames/bind';
import commonStyles from '@assets/styles/base/Common.module.scss';
import { useEffect, useRef, useState } from 'react';
import { values } from 'lodash';
const cxModal = classNames.bind(stylesModal);
const cxCommon = classNames.bind(commonStyles);

const WarningModal = (props: any) => {
    const { showModal, reasonReject, onChangeText } = props;
    const formInputRef = useRef<HTMLTextAreaElement>();

    return (
        <>
            <div className={'row mt-2'}>
                <div className={cxCommon('label-color', 'col overflow-auto','bases__height-max--400')}>
                    <FormInput
                        textAreaRef={formInputRef}  
                        onChangeText={(values) => {
                            onChangeText(values);
                            if (formInputRef?.current) {
                                formInputRef?.current?.setAttribute('style', 'min-height: 1px');
                                formInputRef?.current?.setAttribute('style', `min-height: ${formInputRef?.current?.scrollHeight > 120 ? `${25 + formInputRef?.current?.scrollHeight}px` : '120px'}`);
                            }
                        }}
                        name="content"
                        styleBlock
                        styleArea={{minHeight: '120px'}}
                        textArea
                        rows={4}
                        value={reasonReject}
                        maxLength={3000}
                        classNameTextArea={`bases__text--font14 overflow-hidden ${showModal ? cxModal('warning') : ''} `}
                    />
                </div>
            </div>
        </>
    );
};

export default WarningModal;
