import classNames from 'classnames/bind';
import styles from '@components/pages/home/manager/surveyQuestion/SurveyQuestion.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import { FormCheckbox, FormRadio } from '@components/commons';
import { useTranslation } from 'react-i18next';
import ModalInterface from '../commons/Modal';
import { COMMON_STATUS, SURVEY_TYPE } from '@utils/constants';
import { FormItemType } from '@components/commons/Form/FormRadio';
import { formatDateUTC } from '@utils/helpers/date';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

const SurveyQuestionDetailModal: IModalComponent<IModalComponentProps> = (props) => {
    const { t } = useTranslation();
    const { isShow, handleClose, data } = props;

    return (
        <ModalInterface
            modalHeader={t('survey_question.detail.title')}
            isShow={isShow}
            classNameHeader={cxCommon('bases__margin--top20')}
            hidePositiveButton
            handleClose={() => handleClose(false)}
            classNameFooter={`pt-1 ${cxCommon('bases__margin--bottom24')}`}
            negativeButtonText="common.close">
            <div className={`${cx('modal-detail')}`}>
                <div className={`${cx('modal-content')}`}>
                    <div className="d-flex flex-column">
                        <div>
                            {t('survey_question.detail.question_number')}: <span className={'fw-bold'}>{`#${data?.id}`}</span>
                        </div>
                        <div className={`${cxCommon('bases__margin--top10')}`}>
                            {t('survey_question.detail.creation_date')}:{' '}
                            <span className={'fw-bold'}>{formatDateUTC(data?.createdAt) ?? '-'}</span>
                        </div>
                        <div className={`${cxCommon('bases__margin--top10')}`}>
                            <span className={`fw-bold ${data?.isPublic ? cx('text-public') : cx('text-private')}`}>
                                {data?.isPublic ? t('survey_question.button.public') : t('survey_question.button.private')}
                            </span>
                        </div>
                        <div className={`${cxCommon('bases__margin--top10')}`}>
                            <span>{t('survey_question.detail.contents_of_question')}</span>
                        </div>
                        <div className={`${cxCommon('bases__margin--top5')}`}>
                            <div className={cx('survey-public-content')}>
                                <div className="d-flex justify-content-between">
                                    <span className="fw-bold">{data?.content}</span>
                                </div>
                                <div className={`${cxCommon('bases__margin--left10', 'bases__text--font12')}`}>
                                    {data?.type == SURVEY_TYPE.RADIO ? (
                                        <FormRadio
                                            disabledOnlyIcon={true}
                                            classNameContent={cxCommon('col-md-12')}
                                            styleBlock
                                            defaultCheckedIndex={0}
                                            classNameInput="size_radio--20"
                                            items={
                                                data.options?.map((e: { content: string }, index: number) => {
                                                    return {
                                                        value: index + 1,
                                                        label: e.content,
                                                        key: `${index}`,
                                                    } as FormItemType;
                                                }) ?? []
                                            }
                                        />
                                    ) : (
                                        <FormCheckbox
                                            classNameContent="d-flex flex-column"
                                            classNameInput="size_checkbox--18"
                                            classNameGroupItem="d-flex align-items-center"
                                            label=""
                                            readOnly
                                            items={
                                                data?.options?.map((e: { content: string }, index: number) => {
                                                    return {
                                                        value: index + 1,
                                                        label: e.content,
                                                        key: `${index}`,
                                                    } as FormItemType;
                                                }) ?? []
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalInterface>
    );
};

SurveyQuestionDetailModal.defaultProps = {
    isShow: false,
};

export default SurveyQuestionDetailModal;
