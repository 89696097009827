import React from 'react';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import { images } from '@utils/constants';
import styles from './Form.module.scss';

const cx = classNames.bind(styles);

interface IInputDateRangeProps extends IBaseCompProps {
    className?: string;
    label?: string;
    labelEnd?: string;
    startDate?: string;
    endDate?: string;
    defaultStartDate?: string;
    defaultEndDate?: string
    onChangeDate?: (startDate: string, endDate: string) => void;
}

const FormDateRange: IBaseComp<IInputDateRangeProps> = ({
    className = '',
    label = '',
    labelEnd = '',
    startDate,
    endDate,
    defaultStartDate = '',
    defaultEndDate = '',
    onChangeDate,
}) => {
    const handleDataChange = (text: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeDate) {
            if (text.toString() === '') {
                onChangeDate('', '');
            } else {
                onChangeDate(text.toString(), text.toString());
            }
        }
    };

    return (
        <div className={className}>
            {!labelEnd && <div className="row align-items-start">
                <label className="col-md-3 col-form-label text-end pe-0">{t(label)}</label>
                <div className="col pe-0">
                    <div className={cx('form-control', 'input-group', 'input-group-gl')}>
                        <input
                            type="text"
                            className={cx('w-100', 'gl-input')}
                            value={startDate}
                            defaultValue={defaultStartDate}
                            placeholder={t<string>('common.start')}
                            onChange={handleDataChange}
                        />
                        <img src={images.IMAGES.CALENDAR} className={cx('input_image')} />
                    </div>
                </div>
                <label className="col-md-1 col-form-label text-center">~</label>
                <div className="col ps-0">
                    <div className={cx('form-control', 'input-group', 'input-group-gl')}>
                        <input
                            type="text"
                            className={cx('w-100', 'gl-input')}
                            value={endDate}
                            defaultValue={defaultEndDate}
                            placeholder={t<string>('common.end')}
                            onChange={handleDataChange}
                        />
                        <img src={images.IMAGES.CALENDAR} className={cx('input_image')} />
                    </div>
                </div>
            </div>}
            {labelEnd && <div className="row align-items-start">
                <div className={cx("width-47")}>
                    <label className="col-form-label pe-0">{t(label)}</label>
                    <div className="col pe-0">
                        <div className={cx('form-control', 'input-group', 'input-group-gl')}>
                            <input
                                type="text"
                                className={cx('w-100', 'gl-input')}
                                value={startDate}
                                defaultValue={defaultStartDate}
                                placeholder={t<string>('common.start')}
                                onChange={handleDataChange}
                            />
                            <img src={images.IMAGES.CALENDAR} className={cx('input_image')} />
                        </div>
                    </div>
                </div>
                <div className={cx("width-6") + "  ps-2"}>
                    <label className="col-form-label pe-0 w-100"></label>
                    <label className="col-form-label text-center pt-3 ps-2">~</label>
                </div>
                <div className={cx("width-47")}>
                    <label className="col-form-label pe-0">{t(labelEnd)}</label>
                    <div className="col pe-0">
                        <div className={cx('form-control', 'input-group', 'input-group-gl')}>
                            <input
                                type="text"
                                className={cx('w-100', 'gl-input')}
                                value={startDate}
                                defaultValue={defaultStartDate}
                                placeholder={t<string>('common.end')}
                                onChange={handleDataChange}
                            />
                            <img src={images.IMAGES.CALENDAR} className={cx('input_image')} />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default FormDateRange;
