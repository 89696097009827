export const formatToSlug = (text : string) => {
    return text.toLowerCase().replace(/ /g, '_');
};

export const randomUid = () => Math.random().toString(16).slice(2);

export const limitText = (text: string, char: number) => {
    let allText = text;
    const textLength = allText?.length;
    const startText = allText?.slice(0, char);
    textLength > char ? (allText = `${startText} ...`) : (allText = startText);
    return allText;
};

export const formatPhoneNumber = (value?: string) => {
    if (!value) return value;
    const phoneNumber = value?.split('-').join('').slice(0, 11);
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength > 0) {
        return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
    return phoneNumber;
};

export const formatZipCode = (value: string) => {
    if (!value) return value;
    const zipCode = value?.split('-').join('').slice(0, 7);
    const zipCodeLength = zipCode.length;
    if (zipCodeLength === 7) {
        return zipCode.replace(/(\d{3})(\d{4})/, '$1-$2');
    }
    return zipCode;
};

export const formatFullWidthText = (text: string) => {
    return text.replace(
        /[0-9０-９`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/：；＜＝＞？＠～！＃＄％＾＆＊（）＿｛｝「」￥｜””・～ ’ 。　　、＋]/gi,
        '',
    );
};

export const convertFullSpaceToHalfSpace = (text: string) => {
    return text.replace(/[　]/gi, ' ');
};

export const formatTextFurigana = (text: string) => {
    return text.replace(/[0-9０-９`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/：；＜＝＞？＠～！＃＄％＾＆＊（）＿｛｝「」￥｜””＋]/gi, '');
};

export const formatUuid = (text: string) => {
    return text.replace(/[０-９`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/：；＜＝＞？＠～！＃＄％＾＆＊（）＿｛｝「」￥｜””＋]/gi, '');
};

export const formatFullAlphabet = (text: string) => {
    return text.replace(
        /[0-9０-９`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/：；＜＝＞？＠～！＃＄％＾＆＊（）＿｛｝「」￥｜””＋ぁ-んァ-ン一-龥]+$/,
        '',
    );
};

export const formatHalfWidthText = (text: string) => {
    return text.replace(/[\uff01-\uff5e]/g, (character) => {
        return String.fromCharCode(character.charCodeAt(0) - 0xfee0);
    });
};
