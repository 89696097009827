import { useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';

import classNames from 'classnames/bind';
import styles from './ModalNotification.module.scss';
import ModalInterface from '../Modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

const ModalArlet: IModalComponent<IModalComponentProps> = () => {
    const { t } = useTranslation();
    const { modal } = useSelector((states: ReduxStates) => states);
    const [isShow, setIsShow] = useState(true);
    const handleClose = () => {
        setIsShow(false);
    };
    if (modal) {
        return (
            <ModalInterface
                dialogClassname={cx('modal')}
                isShow={isShow}
                modalHeader="確認"
                hidePositiveButton
                hideNegativeButton
                button
                handleBtn={handleClose}
                classNameBody={cx('content-popup')}
                classNamebutton={cx('button')}
                textBtn={t('common.close') ?? ''}
                handleClose={handleClose}>
                <span className='text-content'>
                    {t('modal.notiResetPassword')}<br/>{t('modal.notiLogoutRequired')} <br/>{t('modal.notiResetLink')}<br/>{t('modal.notiAgain')}
                </span>
            </ModalInterface>
        );
    }

    return <></>;
};

export default ModalArlet;
