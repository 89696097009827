import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { BodyContainer } from '~/components/layouts';
import styles from './SummaryQuest.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import { getAnswerStatistical } from '@utils/helpers/api';
import { dateHelper } from '@utils/helpers';
import NoDataTable from '@components/layouts/table/NoDataTable';
import { isEmpty } from 'lodash';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);
const SummaryQuestion = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<IDataApiSummaryQuestionRes[]>();
    useEffect(() => {
        fetchAnswerStatistical();
    }, []);

    const fetchAnswerStatistical = async () => {
        try {
            const res = await getAnswerStatistical();
            if (res?.data) {
                const data = res?.data as IRes;
                if (data.status === 'SUCCESS') {
                    setData(data.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <BodyContainer
            title={t('survey_summary.title')}
            classNameBody={cxCommon('bases__padding--left20', 'bases__padding--right20', 'bases__text--label')}>
            {!isEmpty(data) ? (
                <div className="d-flex flex-wrap">
                    {data?.map((e, index) => {
                        return (
                            <div className={`d-flex flex-column ${cx('element')}`} key={index}>
                                <span className="fw-bold text-center">{index + 1}</span>
                                <div className={cx('separation')}></div>
                                <div className={`d-flex ${cxCommon('bases__margin--bottom20')}`}>
                                    <div className={cxCommon('bases__width--100')}>{t('survey_summary.question_id')}</div>
                                    <span className={`fw-bold ${cx('f-1')}`}>#{e.questionId}</span>
                                </div>
                                <div className={`d-flex ${cxCommon('bases__margin--bottom20')}`}>
                                    <div className={cxCommon('bases__width--100')}>{t('survey_summary.created_date')}</div>
                                    <span className={`fw-bold ${cx('f-1', 'word-spacing')}`}>
                                        {dateHelper.formatDateUTC(e.createdAt ?? '', 'YYYY/MM/DD HH:mm')}
                                    </span>
                                </div>
                                <div className="d-flex">
                                    <div className={cxCommon('bases__width--100')}>{t('survey_summary.number_of_answers')}</div>
                                    <span className={`fw-bold ${cx('f-1')}`}>{e.total}件</span>
                                </div>
                                <div className={cx('separation')}></div>
                                <div className={`${cxCommon('bases__margin--bottom20')}`}>
                                    <span className="fw-bold">{e.questionContent}</span>
                                </div>
                                {e.options.map((o, index) => {
                                    return (
                                        <div
                                            className={`d-flex flex-column ${cx('option_content')} ${cxCommon('bases__margin--bottom20')}`}
                                            key={index}>
                                            <div className="d-flex">
                                                <div className={cx('f-1')}>{o.content}</div>
                                                <div
                                                    className={`text-end d-flex justify-content-end align-items-end ${cxCommon(
                                                        'bases__width--40',
                                                    )}`}>
                                                    {o.percent}
                                                </div>
                                            </div>
                                            <div className={cx('percent_bar')}>
                                                <div
                                                    className={cx('percent')}
                                                    style={{ width: o.percent, padding: o.percent == '0%' ? 0 : '' }}></div>
                                            </div>
                                            <div className="d-flex justify-content-end">{o.ratio}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <NoDataTable />
            )}
        </BodyContainer>
    );
};

export default SummaryQuestion;
