import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import { useTranslation } from 'react-i18next';
import ModalInterface from '@components/commons/Modal';
import imageAlert from '@assets/images/icon_warning.png';

const cx = classNames.bind(styles);

const ModalAlertAccountDeleted: IModalComponent<IModalNotificationProps> = (props) => {
    const { header, isShow, handleOK } = props;
    const { t } = useTranslation();

    return (
        <ModalInterface
            hideNegativeButton
            dialogClassname={cx('modal-delete-width')}
            modalHeader={header}
            isShow={isShow}
            handleOK={handleOK}
            positiveButtonText='common.ok'>
            <div className="row d-flex flex-column p-1">
                <div className={'d-flex justify-content-center'}>
                    <img src={imageAlert} alt="" />
                </div>
                <hr className={'w-100 my-2'} />
                <div className={'col text-center'}>
                    <span className={cx('text-content', 'text-bold')}>{t('message.user_has_been_deleted')}</span>
                    <br />
                    <span className={cx('text-content')}>{t('message.user_has_been_deleted_content')}</span>
                </div>
            </div>
        </ModalInterface>
    );
};

ModalAlertAccountDeleted.defaultProps = {
    isShow: false,
};

export default ModalAlertAccountDeleted;
