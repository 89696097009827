import { Button, FormInput, FormRadio } from '@components/commons';
import { useTranslation } from 'react-i18next';

import commonStyles from '@assets/styles/base/Common.module.scss';
import DateTimePicker from '@components/commons/Dropdown/DateTimePicker';
import { setToast, showAlert } from '@redux/actions';
import configs from '@utils/configs';
import { createNotification } from '@utils/helpers/api';
import { compareDateSameOrAfter, compareDateSameOrBefore, formatDate, isValid } from '@utils/helpers/date';
import { addNotificationInitialValues, addNotificationSchema } from '@utils/schema/notification.form';
import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BodyContainer } from '~/components/layouts';
import { usePrompt } from '@utils/hook/usePormpt';
import useRole from '@utils/hook/useRole';
import { ReduxStates } from '@redux/reducers';
import { USER_ROLE_ENUM } from '@utils/constants';

const cxCommon = classNames.bind(commonStyles);

const NotificationRegister: IAppointmentPage<IAppointmentPageProps> = () => {
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const maxDateDefault = useMemo(() => {
        return moment().add(1, 'year').endOf('year').endOf('month').toDate();
    }, []);
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave);

    const handleConfirmCancel = () => {
        setIsShowPopupLeave(false);
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: <span>{t('common.confirm_cancel')}</span>,
                handleOK: () => {
                    navigate(configs.routes.notification);
                    setIsShowPopupLeave(true);
                },
            }),
        );
    };

    return (
        <BodyContainer title={t('notification.register')}>
            <Formik
                initialValues={addNotificationInitialValues}
                enableReinitialize={true}
                validationSchema={addNotificationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => {
                    setIsShowPopupLeave(false);
                    const newValues: IAddNotificationForm = {
                        ...values,
                        startDate: formatDate(values.startDate, 'YYYY/MM/DD HH:mm'),
                        endDate: formatDate(values.endDate, 'YYYY/MM/DD HH:mm'),
                    };

                    createNotification(newValues).then((res) => {
                        if (res.data.status === 'SUCCESS') {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.add_success')}`,
                                }),
                            );
                            navigate(configs.routes.notification);
                            setIsShowPopupLeave(true);
                        }
                    });
                }}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, touched, errors } = props;

                    return (
                        <Form>
                            <div className={cxCommon('container-asset')}>
                                <div className="row d-flex justify-content-center w-100">
                                    <div className="col-xxl-7 col-10">
                                        <div className="row">
                                            <div className={cxCommon('col', 'label_asterisk', 'label-color')}>
                                                {t<string>('notification.user_registered')}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <FormInput
                                                    styleBlock
                                                    name="creator"
                                                    required
                                                    maxLength={255}
                                                    placeholder="notification.user_registered"
                                                    value={values.creator}
                                                    onChangeText={(text) => setFieldValue('creator', text)}
                                                    error={touched.creator && errors.creator ? errors.creator : undefined}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className={cxCommon('col d-flex align-items-center', 'label_asterisk', 'label-color')}>
                                                {t('notification.start_date')}
                                            </div>
                                            <div className="col-1"></div>
                                            <div className="col d-flex align-items-center">
                                                <div className="row w-100">
                                                    <div className="col-5 d-flex align-items-center">
                                                        <span className={cxCommon('mr-10', 'label_asterisk', 'label-color')}>
                                                            {t('notification.end_date')}
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <FormRadio
                                                            classNameInput="size_checkbox"
                                                            classNameLabel="label_asterisk"
                                                            classNameContent="ml-10"
                                                            items={[
                                                                {
                                                                    label: 'notification.permission.no',
                                                                    value: 0,
                                                                    textColorCustom: 'text_color_red',
                                                                },
                                                                {
                                                                    label: 'notification.permission.yes',
                                                                    value: 1,
                                                                    textColorCustom: 'text_color_green',
                                                                },
                                                            ]}
                                                            defaultCheckedIndex={values.permission}
                                                            onCheckItem={(value) => {
                                                                setFieldValue('permission', value);
                                                                if (!value) {
                                                                    setFieldValue('endDate', '');
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-2 d-flex align-items-center">
                                            <div className="col">
                                                <DateTimePicker
                                                    value={values.startDate}
                                                    onChange={(value) => {
                                                        return setFieldValue('startDate', value);
                                                    }}
                                                    disableTime={!values.startDate}
                                                    placeholderDate={t<string>('notification.start_date_placeholder')}
                                                    placeholderTime={t<string>('notification.start_time_placeholder')}
                                                    maxDate={values.endDate ? new Date(values.endDate) : maxDateDefault}
                                                    maxTime={
                                                        values.endDate &&
                                                        compareDateSameOrBefore(values.endDate, values.startDate, 'YYYY-MM-DD') &&
                                                        isValid(values.endDate, 'YYYY-MM-DD HH:mm:ss')
                                                            ? new Date(values.endDate)
                                                            : null
                                                    }
                                                    error={touched.startDate && errors.startDate ? errors.startDate : undefined}
                                                />
                                            </div>
                                            <div className="col-1 text-center">~</div>
                                            <div className="col">
                                                <DateTimePicker
                                                    value={values.endDate}
                                                    onChange={(value) => setFieldValue('endDate', value)}
                                                    disableDate={!values.permission}
                                                    disableTime={!values.endDate || !values.permission}
                                                    placeholderDate={t<string>('notification.end_date_placeholder')}
                                                    placeholderTime={t<string>('notification.end_time_placeholder')}
                                                    maxDate={maxDateDefault}
                                                    minDate={values.startDate ? new Date(values.startDate) : null}
                                                    minTime={
                                                        values.startDate &&
                                                        compareDateSameOrAfter(values.startDate, values.endDate, 'YYYY-MM-DD') &&
                                                        isValid(values.startDate, 'YYYY-MM-DD HH:mm:ss')
                                                            ? new Date(values.startDate)
                                                            : null
                                                    }
                                                    error={touched.endDate && errors.endDate ? errors.endDate : undefined}
                                                />
                                            </div>
                                        </div>
                                        {(errors.startDate || errors.endDate) && (
                                            <div className="row mt-2">
                                                <div className="col bases__text--red">{errors.startDate}</div>
                                                <div className="col-1"></div>
                                                <div className="col bases__text--red">{errors.endDate}</div>
                                            </div>
                                        )}
                                        {isSuperAdminPermission && (
                                            <div className="row mt-3">
                                                <div className={cxCommon('col mb-2', 'label_asterisk', 'label-color')}>
                                                    {t<string>('notification.notificationType')}
                                                </div>
                                                <FormRadio
                                                    classNameInput="size_checkbox"
                                                    classNameLabel="label_asterisk"
                                                    classNameContent="col-4"
                                                    items={[
                                                        {
                                                            label: 'notification.notificationTypeValue.system',
                                                            value: 0,
                                                        },
                                                        {
                                                            label: 'notification.notificationTypeValue.user',
                                                            value: 1,
                                                        },
                                                    ]}
                                                    defaultCheckedIndex={values.type}
                                                    onCheckItem={(value) => setFieldValue('type', value)}
                                                />
                                            </div>
                                        )}
                                        <div className="row mt-3">
                                            <div className={cxCommon('col', 'label_asterisk', 'label-color')}>
                                                {t<string>('notification.subject')}
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col">
                                                <FormInput
                                                    styleBlock
                                                    name="subject"
                                                    required
                                                    maxLength={255}
                                                    placeholder="notification.subject"
                                                    value={values.subject}
                                                    onChangeText={(text) => setFieldValue('subject', text)}
                                                    error={touched.subject && errors.subject ? errors.subject : undefined}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="row mb-2">
                                                <div className={cxCommon('col', 'label_asterisk', 'label-color')}>
                                                    {t<string>('notification.content_title')}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <FormInput
                                                    name="content"
                                                    styleBlock
                                                    textArea
                                                    rows={6}
                                                    placeholder="notification.content_title"
                                                    value={values.content}
                                                    onChangeText={(value) => setFieldValue('content', value)}
                                                    maxLength={3000}
                                                    error={touched.content && errors.content ? errors.content : undefined}
                                                    classNameTextArea="bases__height-min--128"
                                                />
                                            </div>
                                        </div>
                                        <div className={'row mt-3 gap-4 d-flex justify-content-center'}>
                                            <Button
                                                onClick={handleSubmit}
                                                text="common.register"
                                                search
                                                className="bases__width--170"></Button>
                                            <Button
                                                onClick={handleConfirmCancel}
                                                text="common.cancel"
                                                className="bases__width--170 bases__text--label"
                                                del></Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </BodyContainer>
    );
};

export default NotificationRegister;
