import { Common } from '@assets/styles';
import { FormInput } from '@components/commons';
import ModalInterface from '@components/commons/Modal';
import { dateHelper } from '@utils/helpers';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './Modal.module.scss';
import { useState } from 'react';
import ModalConfirm from './ModalConfirm';
import { deleteNotification } from '@utils/helpers/api';
import { setToast } from '@redux/actions';
import { useDispatch } from 'react-redux';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

type IModalNotificationDetailProps = Pick<IModalComponentProps, 'isShow' | 'handleClose' | 'hidePositiveButton' | 'buttonGreen'> & {
    dataInit: IDataNotificationDetail | null;
    handleCancel: () => void;
};

const NotificationDetailModal: IModalComponent<IModalNotificationDetailProps> = (props) => {
    const { isShow, handleClose, handleCancel, dataInit } = props;
    const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleDelete = () => {
        if (dataInit?.id) {
            const listIdDelete: number[] = [dataInit?.id];

            const params: IParamsDeleteNotification = {
                ids: JSON.stringify(listIdDelete),
            };
            deleteNotification(params).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    dispatch(
                        setToast({
                            isShow: true,
                            message: `${t('common.delete_success')}`,
                        }),
                    );
                    setIsShowConfirmDelete(false);
                    handleCancel();
                }
            });
        }
    };

    return (
        <ModalInterface
            dialogClassname={cx('content-width', 'modal-fixed')}
            modalHeader={'お知らせ詳細'}
            isShow={isShow}
            positiveButtonText="common.close"
            negativeButtonText="common.remove"
            handleClose={handleClose}
            handleCancel={() => setIsShowConfirmDelete(true)}
            classNameHeader="bases__margin--top24"
            classNameBody="pt-0"
            classNamePositive="btn-custom"
            classNameCancel="bases__text--red">
            <div className={cxCommon('label-color')}>
                <div className="row mt-3 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.created_date')}</div>
                    <div className="col fw-bold pe-4">{dateHelper.formatDate(dataInit?.createdAt ?? '', 'YYYY/MM/DD HH:mm') || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.user_registered')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.creator || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.start_date')}</div>
                    <div className="col fw-bold pe-4">{dateHelper.formatDate(dataInit?.startDate ?? '', 'YYYY/MM/DD HH:mm') || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.end_date')}</div>
                    <div className="col fw-bold pe-4">{dateHelper.formatDate(dataInit?.endDate ?? '', 'YYYY/MM/DD HH:mm') || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.notificationType')}</div>
                    <div className="col fw-bold pe-4">
                        {t<string>(
                            dataInit?.type ? 'notification.notificationTypeValue.user' : 'notification.notificationTypeValue.system',
                        )}
                    </div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.subject')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.subject || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('notification.content_title')}</div>
                    <div className="col fw-bold pe-4">
                        <FormInput
                            name="content"
                            styleBlock
                            textArea
                            rows={6}
                            value={dataInit?.content}
                            maxLength={3000}
                            classNameTextArea="bases__height-min--128 bases__text--font14"
                            disabled
                        />
                    </div>
                </div>
            </div>
            {isShowConfirmDelete && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        isShow={isShowConfirmDelete}
                        handleOK={handleDelete}
                        handleClose={() => {
                            setIsShowConfirmDelete(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.remove_data')}
                    />
                </>
            )}
        </ModalInterface>
    );
};

NotificationDetailModal.defaultProps = {
    isShow: false,
};

export default NotificationDetailModal;
