import { Button, FormInput } from '@components/commons';
import { LoginForm } from '@components/forms';
import Configs from '@utils/configs';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ModalInterface from '~/components/commons/Modal/Modal';
import { setModal } from '~/redux/actions/CommonAction';
import configs from '~/utils/configs';
import { images } from '~/utils/constants';
import { RULE } from '~/utils/constants/regex';
import { apiCheckToken, confirmResetPassword, resetPassword } from '~/utils/helpers/api';
import { useAuth } from '~/utils/hook/useAuth';
import styles from './ResetPassword.module.scss';

const cx = classNames.bind(styles);

function ResetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useAuth();
    const regexPassword = /(?=(.*[0-9]))(?=.*[\\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
    const [state, setState] = useState<IResetPasswordPageState>({
        email: '',
        password: '',
        passwordConfirm: '',
        status: 0,
        errorEmail: '',
        errorPassWord: '',
        errorConfirmPassWord: '',
    });
    const [isShow, setIsShow] = useState(false);
    const [isShowWarning, setIsShowWarning] = useState(false);
    const [isShowWarningToken, setIsShowWarningToken] = useState(false);
    const [isShowSuccess, setIsShowSuccess] = useState(false);
    const {
        email,
        password,
        passwordConfirm,
        status,
        errorPassWord,
        errorEmail,
        errorConfirmPassWord
    } = state;
    const [searchParams] = useSearchParams();
    const [checkEmail, setCheckEmail] = useState<string>();
    const checkTrim = email?.trim();
    const passwordInputRef = createRef<IValidatorPasswordSubmit>()

    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            apiCheckToken(token)
                .then(({ data }) => {
                    if (data.status === 'SUCCESS') {
                        setState((prevState) => ({
                            ...prevState,
                            status: 1,
                        }));
                    }
                })
                .catch((err) => {
                    if (err.message !== 'Network Error') {
                        setIsShowWarningToken(true);
                    }
                });
        }
    }, []);
    useEffect(() => {
        auth.signInWithToken((response, err) => {
            if (response != null && !err) {
                navigate(Configs.routes.dashboard);
                dispatch(setModal(true));
            }
        });
    }, []);
    const validation = () => {
        passwordInputRef?.current?.checkJapanese('')
        const isValidEmail = validatePassword(password);
        const isValidPassword = validatePasswordConfirm(passwordConfirm);
        return !isValidEmail || !isValidPassword ? false : true;
    };
    const validatePassword = (password?: string) => {
        if (password === '') {
            setState((prevState) => ({
                ...prevState,
                errorPassWord: t('resetPassword.errorPassWordEmpty'),
            }));
            return false;
        } else {
            if (password?.match(regexPassword)) {
                setState((prevState) => ({
                    ...prevState,
                    errorPassWord: '',
                }));
                return true;
            } else {
                setState((prevState) => ({
                    ...prevState,
                    errorPassWord: t('resetPassword.errorPasswordFormat'),
                }));
                return false;
            }
        }
    };
    const validatePasswordConfirm = (passwordConfirm?: string) => {
        if (passwordConfirm === '') {
            setState((prevState) => ({
                ...prevState,
                errorConfirmPassWord: t('resetPassword.errorConfirmPassWordEmpty'),
            }));
            return false;
        } else {
            if (password !== passwordConfirm) {
                setState((prevState) => ({
                    ...prevState,
                    errorConfirmPassWord: t('resetPassword.errorConfirmPassWordDuplicate'),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    errorConfirmPassWord: '',
                }));
                return true;
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false);

            const data = {
                email: checkTrim,
            };
            if (checkTrim === '') {
                setState((prevState) => ({
                    ...prevState,
                    errorEmail: t('resetPassword.errorEmail'),
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    errorEmail: '',
                }));
                if (checkEmail !== checkTrim) {
                    resetPassword(data)
                        .then(({ data }) => {
                            if (data.status === 'SUCCESS') {
                                setIsShow(true);
                                setCheckEmail(checkTrim);
                            }
                        })
                        .catch((err) => {
                            if (err.status === 429) {
                                setIsShowWarning(true);
                            } else {
                                setState((prevState) => ({
                                    ...prevState,
                                    errorEmail: t('resetPassword.errorNotRegister') + '\n' + t('resetPassword.errorNotRegisterCheckAgain'),
                                }));
                            }
                        });
                } else {
                    setIsShowWarning(true);
                }
            }
        },
    });

    useEffect(() => {
        if (checkEmail) {
            const timer = 5 * 60 * 1000; // 5m
            const timeout = setTimeout(() => {
                setCheckEmail(undefined);
            }, timer);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [checkEmail]);

    const handleSubmitResetPassword = (e: any) => {
        e.preventDefault();
        const data = {
            password: password,
        };
        const token = searchParams.get('token');
        if (validation()) {
            const reJP = new RegExp(RULE.IS_JP);
            const reEmoji = new RegExp(RULE.IS_EMOJI);
            const rePw = new RegExp(RULE.PASSWORD);
            if (password) {
                if (!reJP.test(password) && !reEmoji.test(password) && rePw.test(password)) {
                    confirmResetPassword(data, token)
                        .then(({ data }) => {
                            if (data.status === 'SUCCESS') {
                                setIsShowSuccess(true); 
                                setCheckEmail(checkTrim);
                            }
                        })
                        .catch((err) => {
                            if (err.message !== 'Network Error') {
                                setIsShowWarningToken(true);
                                setState((prevState) => ({
                                    ...prevState,
                                    status: 0,
                                }));
                            }
                        });
                } else {
                    passwordInputRef?.current?.checkJapanese(t('resetPassword.errorPasswordFormat'))
                }
            }
        }
    };
    const handleChangeData = (field: string, value: string | number | boolean) => {
        setState((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };
    const close = () => {
        setIsShow(false);
        setIsShowWarning(false);
        setIsShowWarningToken(false);
    };
    const moveLogin = () => {
        navigate(configs.routes.login);
    };

    const popup = () => {
        return (
            <ModalInterface
                size="sm"
                isShow={isShow}
                modalHeader={
                    <div className={cx('brick')}>
                        <img className="mt-2 mb-2" src={images.IMAGES.ICON_COMPLETE} />{' '}
                    </div>
                }
                hidePositiveButton
                hideNegativeButton
                button
                handleBtn={close}
                textBtn="OK"
                classNameBody={cx('content-popup')}
                classNamebutton={cx('buttonModal')}>
                <span>{t('resetPassword.sendLink')}</span>
            </ModalInterface>
        );
    };
    const popupWarningToken = () => {
        return (
            <ModalInterface
                size="sm"
                isShow={isShowWarningToken}
                modalHeader={
                    <div className={cx('brick')}>
                        <img className="mt-2 mb-2" src={images.IMAGES.ICON_WARNING} />{' '}
                    </div>
                }
                hidePositiveButton
                hideNegativeButton
                button
                handleBtn={close}
                textBtn="OK"
                classNameBody={cx('content-popup')}
                classNamebutton={cx('buttonModal')}>
                <span className="fw-bold"> {t('resetPassword.invalidLink')}</span>
                <span>
                    <br />
                    {t('resetPassword.confirm')}
                </span>
            </ModalInterface>
        );
    };
    const popupWarning = () => {
        return (
            <ModalInterface
                size="sm"
                isShow={isShowWarning}
                modalHeader={
                    <div className={cx('brick')}>
                        <img className="mt-2 mb-2" src={images.IMAGES.ICON_WARNING} />{' '}
                    </div>
                }
                hidePositiveButton
                hideNegativeButton
                button
                handleBtn={close}
                textBtn="OK"
                classNameBody={cx('content-popup')}
                classNamebutton={cx('buttonModal')}>
                <span className="fw-bold">{t('resetPassword.link')}</span>
                <span>
                    <br />
                    {t('resetPassword.waitFiveMinute')} <br /> {t('resetPassword.wait')}
                </span>
            </ModalInterface>
        );
    };
    const popupSuccess = () => {
        return (
            <ModalInterface
                size="sm"
                isShow={isShowSuccess}
                modalHeader={
                    <div className={cx('brick')}>
                        <img className="mt-2 mb-2" src={images.IMAGES.ICON_COMPLETE} />{' '}
                    </div>
                }
                hidePositiveButton
                hideNegativeButton
                button
                handleBtn={moveLogin}
                textBtn="OK"
                classNameBody={cx('content-popup')}
                classNamebutton={cx('buttonModal')}>
                <span className="fw-bold">
                    {t('resetPassword.NotificationSuccess')}
                    <br />
                </span>
                <span> {t('resetPassword.NotificationLogin')}</span>
            </ModalInterface>
        );
    };
    const inputEmail = () => {
        return (
            <>
                <form className={cx('form')} onSubmit={formik.handleSubmit}>
                    <images.IMAGES.LOGO_BENRI className='bases__margin--bottom10 w-100'/>
                    <p className={cx('title-reset_password')}>{t('resetPassword.reset_pwd')}</p>
                    <div className="d-flex justify-content-between mb-3">
                        <FormInput
                            styleBlock
                            label="resetPassword.email"
                            error={errorEmail}
                            maxLength={255}
                            className="w-100"
                            type="text"
                            id="email"
                            value={email}
                            autoComplete="off"
                            onChangeText={(text) => {
                                return handleChangeData('email', text);
                            }}
                            defaultValue={email}
                            placeholder={t<string>('resetPassword.placeholder_acc')}
                        />
                    </div>
                    <Button login text={t<string>('common.send')} type="submit" />
                </form>
            </>
        );
    };
    const newPassword = () => {
        return (
            <>
                <form className={cx('form')} onSubmit={handleSubmitResetPassword}>
                    <images.IMAGES.LOGO_BENRI className='bases__margin--bottom10 w-100'/>
                    <p className={cx('title-reset_password')}>{t('resetPassword.title_new_pwd')}</p>

                    <FormInput
                        styleBlock
                        label="resetPassword.label_new_pwd"
                        error={errorPassWord}
                        maxLength={255}
                        className="w-100 mb-3"
                        type="password"
                        id="password"
                        isPassword
                        autoComplete="off"
                        onChangeText={(text) => {
                            handleChangeData('password', text);
                        }}
                        defaultValue={password}
                        inputRef={passwordInputRef}
                        placeholder={t<string>('resetPassword.input_new_pwd')}
                    />
                    <FormInput
                        styleBlock
                        label="resetPassword.label_confirm_new_pwd"
                        error={errorConfirmPassWord}
                        maxLength={255}
                        className="w-100 mb-3"
                        type="password"
                        id="passwordConfirm"
                        autoComplete="off"
                        isPassword
                        onChangeText={(text) => {
                            handleChangeData('passwordConfirm', text);
                        }}
                        defaultValue={passwordConfirm}
                        placeholder={t<string>('resetPassword.input_new_pwd')}
                    />
                    <Button login text={t<string>('common.create')} type="submit" />
                </form>
            </>
        );
    };

    return (
        <div className={cx('wrapper')}>
            {popup()}
            {popupSuccess()}
            {popupWarning()}
            {popupWarningToken()}
            <LoginForm>
                <section className={cx('section-resetpwd')}>
                    <div className={cx('form-resetpwd')}>{!status ? inputEmail() : newPassword()}</div>
                </section>
            </LoginForm>
        </div>
    );
}

export default ResetPassword;
