const useRole = (admin: IInfoUserDataAPI, function_id: string) => {
    let isPermission = false;

    if (admin?.user_roleId?.toString() === function_id ) {
        isPermission = true;
    }

    return isPermission;
};

export default useRole;