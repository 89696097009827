import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, FormDropdown, FormInput, FormRadio } from '@components/commons';
import { useFormik } from 'formik';
import classNames from 'classnames/bind';
import styles from './EmployeeWorkerRegister.module.scss';
import tableStyles from '@assets/styles/base/Table.module.scss';
import commonStyle from '@assets/styles/base/Common.module.scss';
import buttonStyle from '@assets/styles/base/ButtonStyle.module.scss';
import { images } from '@utils/constants';
import Configs from '@utils/configs';
import { BodyContainer } from '~/components/layouts';
import DataTable from '@components/commons/Table/Table';
import { IMAGES } from '@utils/constants/images';
import ModalConfirm from '@components/modals/ModalConfirm';
import { getAssetAsync } from '@redux/actions/AssetAction';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { editStaffs, getDetailStaffs, registerStaffs } from '@utils/helpers/api';
import { setToast, showAlert } from '@redux/actions';
import { setShowDeleted } from '@redux/actions/CommonAction';
import { usePrompt } from '@utils/hook/usePormpt';
const cx = classNames.bind(styles);
const cxTable = classNames.bind(tableStyles);
const cxButton = classNames.bind(buttonStyle);
const cxCommon = classNames.bind(commonStyle);
interface InitValueFormik {
    user_name: string;
    name: string;
    role: number;
    logo: File | null;
    store_name: string;
    phone?: string;
    email: string;
    account_status: number;
    permission: number;
    asset_limit: number;
    exclude_assets: string[];
}
interface FileCustom extends File {
    preview?: string;
}
const EmployeeWorkerRegister: IHistoryPage<IHistoryPageProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const isEdit = useMemo(() => id != null, [id]);
    const [logo, setLogo] = useState<string>('');
    const [fileLogo, setFileLogo] = useState<FileCustom | null>(null);
    const fileInputRef2 = useRef<HTMLInputElement | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [dataDetail, setDataDetail] = useState<any>();
    const { asset } = useSelector((states: ReduxStates) => states);
    const { showDeleted } = useSelector((states: ReduxStates) => states);
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
    });
    const { showFirstModal } = modalState;
    const [listDropdown, setListDropdown] = useState<{ value: string; label: string; data: any }[]>([]);

    const initValueFormik: InitValueFormik = {
        user_name: '',
        name: '',
        role: 3,
        logo: null,
        store_name: '',
        phone: '',
        email: '',
        account_status: 2,
        permission: 4,
        asset_limit: 2,
        exclude_assets: [],
    };
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave);

    useEffect(() => {
        if (isEdit) {
            getDetailStaffs(id?.toString() ?? '')
                .then(({ data }) => {
                    setDataDetail(data.data);
                    const dataFormik = {
                        user_name: data.data?.user_username,
                        name: data.data?.staff_name,
                        role: data.data?.user_role_id,
                        logo: data.data?.staff_avatar,
                        store_name: data.data?.staff_store_name,
                        phone: data.data?.staff_phone,
                        email: data.data?.user_email,
                        account_status: data.data?.user_account_status,
                        permission: data.data?.permission,
                        asset_limit: 2,
                        exclude_assets: [],
                    };
                    setLogo(data.data?.staff_avatar);
                    formik.setValues(dataFormik);
                    if (data.data?.exclude_assets?.length) {
                        formik.setFieldValue('asset_limit', 1);
                    }
                })
                .catch((err) => {
                    console.log('🚀 ~ file: EmployeeWorkerRegister.tsx:79 ~ useEffect ~ err:', err);
                });
        }
        dispatch(getAssetAsync({ orderBy: JSON.stringify({ order: 'ASC', by: 'asset.name' }) }));
    }, []);
    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            navigate(Configs.routes.employeeWorker);
        }
    }, [showDeleted]);
    useEffect(() => {
        if (asset && asset?.listAsset?.data) {
            if (dataDetail && dataDetail.exclude_assets.length) {
                const listDropdown: { value: string; label: string; data: any[] }[] = [];
                const options = asset.listAsset?.data?.map((item: { id: number; name: string }) => ({
                    value: item.id.toString(),
                    label: item.name,
                }));
                for (let i = 0; i < dataDetail?.exclude_assets.length; i++) {
                    const optionSelected = options.find((option: any) => option.value === dataDetail.exclude_assets[i].id.toString());
                    listDropdown?.push({
                        value: optionSelected?.value ?? '',
                        label: optionSelected?.label ?? '',
                        data: options,
                    });
                }
                const newListDropDown = listDropdown.map((item, index) => {
                    const data = asset.listAsset.data
                        .filter((dropDownItem: { id: string }) => {
                            return !listDropdown.find(
                                (item, itemIndex) => dropDownItem.id.toString() === item.value && itemIndex !== index,
                            );
                        })
                        .map((item: { id: string; name: string }) => ({
                            value: item.id.toString(),
                            label: item.name,
                        }));
                    return {
                        ...item,
                        data,
                    };
                });

                setListDropdown(newListDropDown);
            } else {
                handleAddDropdown([]);
            }
        }
    }, [asset, dataDetail]);

    const validation = () => {
        const isValidUserName = validateUserName(formik.values.user_name);
        const isValidName = validateName(formik.values.name);
        const isValidateStoreName = validateStoreName(formik.values.store_name);
        const isValidPhone = validatePhone(formik.values.phone);
        const isValidEmail = validateEmail(formik.values.email);
        return !isValidUserName || !isValidName || !isValidateStoreName || !isValidPhone || !isValidEmail ? false : true;
    };
    const validateUserName = (user_name?: string) => {
        if (user_name === '') {
            formik.setFieldError('user_name', 'message.require_username_staffs');
            return false;
        } else {
            formik.setFieldError('user_name', '');
            return true;
        }
    };
    const validateName = (name?: string) => {
        if (name === '') {
            formik.setFieldError('name', 'message.require_name_staffs');
            return false;
        } else {
            formik.setFieldError('name', '');
            return true;
        }
    };

    const validateStoreName = (store_name?: string) => {
        if (store_name === '') {
            formik.setFieldError('store_name', 'message.require_affiliation_staffs');
            return false;
        } else {
            formik.setFieldError('store_name', '');
            return true;
        }
    };
    const regexPhone = /^\d{10,11}$/;
    const validatePhone = (phone?: string) => {
        if (phone && !phone?.match(regexPhone)) {
            formik.setFieldError('phone', 'message.invalid_phone_number');
            return false;
        } else {
            formik.setFieldError('phone', '');
            return true;
        }
    };
    const regexEmail =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validateEmail = (email?: string) => {
        if (email && !email?.match(regexEmail)) {
            formik.setFieldError('email', 'message.format_email');
            return false;
        } else {
            formik.setFieldError('email', '');
            return true;
        }
    };
    const formik = useFormik({
        initialValues: initValueFormik,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            const formData = new FormData();
            if (fileLogo) formData.append('avatar', fileLogo);
            if (values.asset_limit === 1) {
                const check = listDropdown.filter((dropDown) => dropDown?.value);
                if (check?.length) {
                    formik.values.exclude_assets = check.map((dropDown) => dropDown?.value);
                } else {
                    formik.values.exclude_assets = [];
                }
            } else {
                formik.values.exclude_assets = [];
            }

            const staffs = { ...formik.values, asset_limit: undefined };
            formData.append('data', JSON.stringify(staffs));
            if (validation()) {
                setIsShowPopupLeave(false);
                if (isEdit) {
                    editStaffs(id as string, formData as any)
                        .then(({ data }) => {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.save_data')}`,
                                }),
                            );
                            navigate(`${Configs.routes.employeeWorker}/${id}`);
                            setIsShowPopupLeave(true);
                        })
                        .catch((err) => {
                            if (err.data.message === 'EXISTS_USER') {
                                formik.setFieldError('user_name', 'message.duplicate_user_name');
                            }
                            if (err.data.message === 'EXISTS_EMAIL') {
                                formik.setFieldError('email', 'message.duplicate_email');
                            }
                        });
                } else {
                    registerStaffs(formData)
                        .then(({ data }) => {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.add_success')}`,
                                }),
                            );
                            navigate(Configs.routes.employeeWorker);
                            setIsShowPopupLeave(true);
                        })
                        .catch((err) => {
                            if (err.data.message === 'EXISTS_USER') {
                                formik.setFieldError('user_name', 'message.duplicate_user_name');
                            }
                            if (err.data.message === 'EXISTS_EMAIL') {
                                formik.setFieldError('email', 'message.duplicate_email');
                            }
                        });
                }
            }
        },
    });
    const handleConfirmCloseAssset = () => {
        setIsShowPopupLeave(false);
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: <span>{t('common.confirm_cancel')}</span>,
                handleOK: () => {
                    if (id) {
                        navigate(`${Configs.routes.employeeWorker}/${id}`);
                    } else {
                        navigate(`${Configs.routes.employeeWorker}`);
                    }
                    setIsShowPopupLeave(true);
                },
            }),
        );
    };
    const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = e.target?.files ? e.target?.files[0] : null;
        file.preview = URL.createObjectURL(file);
        setFileLogo(file);
        e.target.value = '';
    };
    const handleRemoveLogo = () => {
        formik.setFieldValue('logo', null);
        setLogo('');
        setFileLogo(null);
    };
    useEffect(() => {
        if (fileLogo) {
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const fileSize = Number((fileLogo.size / 1024 / 1024).toFixed(2));
            if (!validImageTypes.includes(fileLogo.type)) {
                setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                showOrHideModal('showFirstModal');
                return;
            } else if (fileSize > 8) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                showOrHideModal('showFirstModal');
                return;
            }
            setLogo(fileLogo.preview || '');
        }
    }, [fileLogo]);
    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };
    const { t } = useTranslation('translation', { useSuspense: true });

    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };

    const handleConfirmDeleteDropdown = (index: any) => {
        listDropdown.splice(index, 1);
        setListDropdown(listDropdown);
        handleFilterDropdownOptions(listDropdown);
    };

    const handleAddDropdown = (initData?: { value: string; label: string; data: any }[]) => {
        const list = initData !== undefined ? initData : listDropdown;
        const options = asset.listAsset?.data?.map((item: { id: number; name: string }) => ({
            value: item.id.toString(),
            label: item.name,
        }));
        list.push({
            value: '',
            label: '',
            data: options,
        });
        setListDropdown([...list]);
        if (!initData) {
            handleFilterDropdownOptions(list);
        }
    };

    const handleSelectedDropDown = (value: string, index: number) => {
        const item = listDropdown[index].data.find((data: { value: string }) => data.value === value);
        if (item) {
            listDropdown[index] = {
                ...listDropdown[index],
                ...item,
            };
            setListDropdown(listDropdown);
            handleFilterDropdownOptions(listDropdown);
        }
    };

    const handleFilterDropdownOptions = (dropDowns: { value: string; label: string; data: any }[]) => {
        const newListDropDown = dropDowns.map((item, index) => {
            const data = asset.listAsset.data
                .filter((dropDownItem: { id: string }) => {
                    return !listDropdown.find((item, itemIndex) => dropDownItem.id.toString() === item.value && itemIndex !== index);
                })
                .map((item: { id: string; name: string }) => ({
                    value: item.id.toString(),
                    label: item.name,
                }));
            return {
                ...item,
                data,
            };
        });
        setListDropdown(newListDropDown);
    };

    const handleTableDatas = () => {
        return listDropdown?.map((item: any, index) => {
            return {
                ...item,
                name: (
                    <FormDropdown
                        tooltip
                        hiddenLabel
                        //classNameMenu={cx('item_option')}
                        className={cxCommon('bases__margin--vertical20')}
                        classNameStyle={cxCommon('bases__height--42')}
                        classNameDropdown={cx('fix_dropdown')}
                        value={item.value}
                        defaultValue={item.value}
                        classNameItemOption={cx('item_option')}
                        classNameOption={cxCommon('bases__height--32', 'overflow-hidden')}
                        options={item.data}
                        onChangeOption={(option) => handleSelectedDropDown(option.value.toString(), index)}
                    />
                ),

                action: (
                    <div className="h-100 d-flex justify-content-center">
                        <Button
                            onClick={() => handleConfirmDeleteDropdown(index)}
                            className={cx('button-delete') + ' ' + cxCommon('font-size-12')}
                            text="common.remove"
                        />
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('staffworker.register.asset_name'),
            },
            {
                title: t('staffworker.register.responsible_person'),
                classNameTh: 'col-1',
            },
        ],
        body: {
            columns: [
                {
                    field: 'name',
                    isNotTooltip: true,
                },
                {
                    field: 'action',
                    isNotTooltip: true,
                    className: 'bases__width--50',
                },
            ],
            rows: handleTableDatas(),
        },
    };
    return (
        <BodyContainer title={isEdit ? t('title.edit_staffs') : t('staffworker.register.title')}>
            <form className={'position-relative'} onSubmit={formik.handleSubmit}>
                <div className="row d-flex justify-content-center w-100">
                    <div className="col-9">
                        <section className={'d-block bg-white ' + cxCommon('content')}>
                            <div className="row justify-content-center">
                                <div className={cx('responsive-img')}>
                                    <div className="text-center position-relative">
                                        <img
                                            className={cx('image-register') + ' ' + cxCommon('bases__margin--bottom10')}
                                            src={logo ? logo : images.IMAGES.NO_IMAGE_ONE}
                                        />
                                        {logo && (
                                            <img onClick={handleRemoveLogo} src={IMAGES.ICON_CLOSE_BG} alt="" className={cx('img_close')} />
                                        )}

                                        <div className={cx('change-logo')}>
                                            <Button
                                                type="button"
                                                onClick={() => fileInputRef2.current?.click()}
                                                text={isEdit ? 'common.edit_img' : 'common.addPhoto'}
                                                className={cx('add-img')}
                                                del
                                            />
                                            <input
                                                style={{ display: 'none' }}
                                                ref={fileInputRef2}
                                                type="file"
                                                accept="image/*"
                                                className="input-upload"
                                                onChange={(e) => handleUploadFiles(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8 align-item-center">
                                    <FormInput
                                        disabled={isEdit}
                                        maxLength={255}
                                        required
                                        styleBlock
                                        className={cxCommon('bases__margin--bottom20')}
                                        label="staffworker.register.username"
                                        placeholder="staffworker.register.placeholder_username"
                                        value={formik.values.user_name}
                                        onChangeText={(text) => {
                                            return handleChange('user_name', text);
                                        }}
                                        error={formik.touched.user_name && formik.errors.user_name ? formik.errors.user_name : undefined}
                                    />
                                    {!isEdit && (
                                        <FormInput
                                            maxLength={255}
                                            disabled={true}
                                            styleBlock
                                            className={cxCommon('bases__margin--bottom20')}
                                            label="staffworker.register.password"
                                            value={dataDetail?.user_password}
                                            placeholder="staffworker.register.placeholder_password"
                                        />
                                    )}

                                    <FormInput
                                        maxLength={255}
                                        required
                                        styleBlock
                                        type="text"
                                        className={cxCommon('bases__margin--bottom20')}
                                        label="staffworker.register.user"
                                        placeholder="staffworker.register.user"
                                        value={formik.values.name}
                                        onChangeText={(text) => {
                                            return handleChange('name', text);
                                        }}
                                        error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                                    />
                                    <FormDropdown
                                        styleBlock
                                        required
                                        className={cxCommon('bases__margin--bottom20')}
                                        label="staffworker.register.kinds"
                                        value={formik.values.role}
                                        error={formik.touched.role && formik.errors.role ? formik.errors.role : undefined}
                                        classNameOption={cxCommon('bases__height--32')}
                                        options={[
                                            { value: 3, label: '自社社員' },
                                            { value: 5, label: '工事業者' },
                                        ]}
                                        onChangeOption={(option) => handleChange('role', option.value)}
                                    />
                                    <FormInput
                                        maxLength={255}
                                        required
                                        styleBlock
                                        className={cxCommon('bases__margin--bottom20')}
                                        label="staffworker.register.belong"
                                        placeholder="staffworker.register.placeholder_belong"
                                        value={formik.values.store_name}
                                        onChangeText={(text) => {
                                            return handleChange('store_name', text);
                                        }}
                                        error={formik.touched.store_name && formik.errors.store_name ? formik.errors.store_name : undefined}
                                    />
                                    <FormInput
                                        styleBlock
                                        maxLength={11}
                                        className={cxCommon('bases__margin--bottom20')}
                                        label="staffworker.register.phone_number"
                                        placeholder="staffworker.register.phone_number"
                                        value={formik.values?.phone}
                                        onChangeText={(text) => {
                                            return handleChange('phone', text);
                                        }}
                                        error={formik.touched.phone && formik.errors.phone ? formik.errors.phone : undefined}
                                    />
                                    <FormInput
                                        maxLength={255}
                                        styleBlock
                                        className={cxCommon('bases__margin--bottom10')}
                                        label="staffworker.register.email"
                                        placeholder="staffworker.register.email"
                                        value={formik.values.email}
                                        onChangeText={(text) => {
                                            return handleChange('email', text);
                                        }}
                                        error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
                                    />

                                    <div className={'row'}>
                                        <div className="col-12 col-xxl-9">
                                            <FormRadio
                                                label="staffworker.register.status_of_use"
                                                classNameInput="size_checkbox"
                                                className={cxCommon('bases__margin--bottom10')}
                                                classNameLabel="col-3"
                                                classNameContent="col-9"
                                                items={[
                                                    { label: 'staffworker.register.stop', value: 2, textColorCustom: 'text_color_red' },
                                                    { label: 'staffworker.register.using', value: 1, textColorCustom: 'text_color_green' },
                                                ]}
                                                defaultCheckedIndex={formik.values.account_status}
                                                onCheckItem={(value) => handleChange('account_status', value)}
                                            />
                                            <FormRadio
                                                classNameLabel="col-3"
                                                classNameContent="col-9"
                                                label="staffworker.register.usage_rights"
                                                classNameInput="size_checkbox"
                                                className={cxCommon('bases__margin--bottom10')}
                                                items={[
                                                    {
                                                        label: 'staffworker.register.use_only',
                                                        value: 4,
                                                        textColorCustom: 'text-blue',
                                                    },
                                                    {
                                                        label: 'staffworker.register.can_be_installed',
                                                        value: 2,
                                                        textColorCustom: 'text-purple',
                                                    },
                                                ]}
                                                defaultCheckedIndex={formik.values.permission}
                                                onCheckItem={(value) => handleChange('permission', value)}
                                            />
                                            <FormRadio
                                                classNameLabel="col-3"
                                                classNameContent="col-9"
                                                label="staffworker.register.property_limit"
                                                classNameInput="size_checkbox"
                                                className={cxCommon('bases__margin--bottom10')}
                                                items={[
                                                    {
                                                        label: 'staffworker.register.restrict',
                                                        value: 1,
                                                    },
                                                    { label: 'staffworker.register.no_limit', value: 2 },
                                                ]}
                                                defaultCheckedIndex={
                                                    dataDetail?.exclude_assets && formik.values.asset_limit === 1
                                                        ? 1
                                                        : formik.values.asset_limit
                                                }
                                                onCheckItem={(value) => handleChange('asset_limit', value)}
                                            />
                                        </div>
                                    </div>
                                    {formik.values.asset_limit === 1 ? (
                                        <div className={'p-2 px-3 ' + cx('table-section')}>
                                            <div className={cxCommon('bases__margin--bottom10', 'bases__margin--top10', 'row')}>
                                                <div className={cx('text', 'col fw-bold')}>
                                                    {t('staffworker.register.list_of_property')}
                                                </div>
                                            </div>
                                            <DataTable
                                                classNameTd="overflow-visible"
                                                isStickyColumn
                                                heads={table.heads}
                                                body={table.body}
                                                isHidePagination={true}
                                                total={listDropdown?.length}
                                                pageData={{
                                                    totalRecords: listDropdown?.length,
                                                }}
                                            />
                                            <div className="row mt-2">
                                                <div className="col-3">
                                                    <Button
                                                        onClick={() => handleAddDropdown()}
                                                        text={t<string>('staffworker.register.addition')}
                                                        className={cx('add_dropdown')}
                                                        del
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="row mt-2 w-100">
                    <div className="col-6 d-flex justify-content-end">
                        <div className="row col-3 me-1">
                            <Button
                                type="submit"
                                text={isEdit ? t<string>('common.save') : t<string>('common.register')}
                                search
                                className={cxButton('button-non-min-width')}></Button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row col-3 ms-1">
                            <Button
                                onClick={() => handleConfirmCloseAssset()}
                                text={t<string>('staffworker.register.cancel')}
                                del
                                className={cxButton('button-non-min-width')}></Button>
                        </div>
                    </div>
                </div>
            </form>
            {showFirstModal && (
                <ModalConfirm
                    hideNegativeButton
                    isShow={showFirstModal}
                    handleOK={() => showOrHideModal('showFirstModal')}
                    handleClose={() => showOrHideModal('showFirstModal')}
                    header={t<string>('company_info.company_edit.modal.error_title')}
                    content={t<string>(errorMessage)}></ModalConfirm>
            )}
        </BodyContainer>
    );
};

export default EmployeeWorkerRegister;
