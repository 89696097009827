import { Button } from '@components/commons';
import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { images } from '~/utils/constants';
import styles from './Modal.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';

const ModalInterface: IModalComponent<IModalComponentProps> = (props) => {
    const {
        size = 'lg',
        children,
        modalHeader,
        isShow,
        handleOK,
        handleClose,
        dialogClassname,
        positiveButtonText,
        negativeButtonText,
        hidePositiveButton,
        textBtn,
        buttonGreen = false,
        handleBtn,
        hideNegativeButton,
        button,
        className,
        classNameClose,
        classNameBody,
        classNamebutton,
        classNamePositive,
        keyboard = false,
        disableBtnOk,
        classNameHeader,
        handleCancel,
        classNameFooter,
        classNameCancel,
        hideNegativeButtonClose,
        disableBtnCancel
    } = props;
    const cxCommon = classNames.bind(stylesCommon);
    const cx = classNames.bind(styles);
    const { t } = useTranslation();
    const renderModalHeader = useMemo(() => {
        if (typeof modalHeader === 'string') {
            return t(modalHeader);
        }

        return modalHeader;
    }, [modalHeader]);
    return (
        <Modal
            dialogClassName={cxCommon('bases__height--10') + ' ' + dialogClassname}
            className={cx('gl-modal', className ?? '')}
            show={isShow}
            onHide={handleClose}
            size={size}
            centered
            backdrop={'static'}
            keyboard={keyboard}>
            {!hideNegativeButtonClose ? (
                <div className={`${classNameClose ?? 'pt-3'} position-relative`}>
                    <button
                        type="button"
                        className={cx('btn', 'header-btn_close', 'position-absolute')}
                        aria-label="Close"
                        onClick={handleClose}>
                        <img src={images.IMAGES.ICON_CLOSE_MODAL} />
                    </button>
                </div>
            ) : (
                <div className="pt-3" />
            )}

            <Modal.Header className={cx('header', 'border-0 text-center pt-0')}>
                <div className={'w-100 ' + cx('header-text', classNameHeader, 'header-fixed')}>{renderModalHeader}</div>
            </Modal.Header>
            <Modal.Body className={cx('body', classNameBody, 'body-fixed')}>{children}</Modal.Body>
            <Modal.Footer
                className={cx(
                    'footer',
                    'border-0 d-flex justify-content-center modal-footer text-center',
                    classNameFooter,
                    'footer-fixed',
                    'position-relative',
                )}>
                <div className={cx('d-flex justify-content-center w-100', 'gl-gap-20')}>
                    {buttonGreen ? (
                        <Button disabled={disableBtnOk} className={'me-1 ' + cx('button-green')} text={textBtn} onClick={handleOK} />
                    ) : (
                        ''
                    )}
                    {button ? <Button className={cx('button', classNamebutton, ' me-1')} text={textBtn} onClick={handleBtn} /> : ''}
                    {!hidePositiveButton && (
                        <div className={cx('gl-w-170')}>
                            <Button className={cx(classNamePositive)} onClick={handleOK ?? handleClose} search>
                                {t(positiveButtonText ?? 'common.edit')}
                            </Button>
                        </div>
                    )}
                    {!hideNegativeButton && (
                        <div className={cx('gl-w-170')}>
                            <Button disabled={disableBtnCancel} className={classNameCancel} onClick={handleCancel ? handleCancel : handleClose} del>
                                {t(negativeButtonText ?? 'common.cancel')}
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Footer>
        </Modal>
    );
};

ModalInterface.defaultProps = {
    isShow: false,
};

export default ModalInterface;
