import { useTranslation } from 'react-i18next';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import classNames from 'classnames/bind';

function NoDataTable() {
  const cxCommon = classNames.bind(stylesCommon);
  const { t } = useTranslation();
  return (
    <div className={cxCommon('bases__text--font16','row d-flex justify-content-center mt-4')}>{t('common.no_data')}</div>
  );
}

export default NoDataTable;