import stylesCommon from '@assets/styles/base/Common.module.scss';
import { Button, FormInput, FormRadio } from '@components/commons';
import ModalInterface from '@components/commons/Modal';
import Configs from '@utils/configs';
import { images } from '@utils/constants';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { IMAGES } from '~/utils/constants/images';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';
import { getRoomDetail } from '@utils/helpers/api';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);
interface FileCustom extends File {
    preview?: string;
}
const EditRoomModal: IModalComponent<IModalComponentProps> = (props) => {
    const {
        header,
        isShow,
        handleClose,
        handleOK,
        negativeButtonText,
        positiveButtonText,
        dataListRoom,
        data,
        roomEdittingId,
        isValidateNameRoom,
    } = props;
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [imageSlide, setImageSlide] = useState<string>('');
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const fileInputRef2 = useRef<HTMLInputElement | null>(null);
    const [listFile, setListFile] = useState<FileCustom[]>([]);
    const [logo, setLogo] = useState<string>();
    const [fileLogo, setFileLogo] = useState<FileCustom | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dragItem = useRef<HTMLDivElement | null>(null);
    const dragIndex = useRef(0);
    const dropItem = useRef<HTMLDivElement | null>(null);
    const dropIndex = useRef(0);
    const location = useLocation();
    const [isShowConfirmDeleteKeybox, setIsShowConfirmDeleteKeybox] = useState(false);
    const [confirmChangeData, setConfirmChangeData] = useState<any>({});

    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
        showSecondModal: false,
        showThirdModal: false,
        errorRoomNumber: '',
        isShowCannotChangePublication: false,
        isShowConfirmChangePublication: false,
    });
    const {
        showFirstModal,
        showSecondModal,
        showThirdModal,
        errorRoomNumber,
        isShowCannotChangePublication,
        isShowConfirmChangePublication,
    } = modalState;

    const { t } = useTranslation();

    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };

    const handleRemoveImage = (imageId: number, index: number) => {
        let listImageCopy = [...listImageSlide];
        const maxIndexOfList = listImageCopy?.length - 1;
        listImageCopy = listImageCopy.filter((image) => image.order !== imageId);
        const newListImage = listImageCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
        if (index < activeIndex || activeIndex === maxIndexOfList) {
            setActiveIndex(activeIndex - 1);
        }
    };
    const initValueFormik: IAddRoom = {
        id: 0,
        images: [],
        storeKeyImage: null,
        key_storage: '',
        room_number: '',
        publication: 1,
        status: 0,
        keybox: '',
        box_id: undefined,
        index: 0,
    };

    useEffect(() => {
        setLogo(data?.store_key_image ?? '');
    }, [data?.store_key_image])
    useEffect(() => {
        setLogo(data?.storeKeyImage?.preview);
    }, [data?.storeKeyImage?.preview]);

    useEffect(() => {
        if (data) {
            formik.setFieldValue('id', Number(data?.id));
            formik.setFieldValue('room_number', data?.room_number);
            formik.setFieldValue('storeKeyImage', data?.store_key_image);
            formik.setFieldValue('key_storage', data?.key_storage);
            formik.setFieldValue('publication', data?.publication ?? 1);
            formik.setFieldValue('status', data?.status ?? 0);
            formik.setFieldValue('keybox', data?.box_number || '-');
            formik.setFieldValue('box_id', data?.box_id ?? '');
            setListImageSlide(data?.images ?? []);
            formik.setFieldValue('images', data?.images);
        } else {
            setFileLogo(null);
            setListImageSlide([]);
            formik.setValues(initValueFormik);
            setModalState((prevState) => ({ ...prevState, errorRoomNumber: '' }));
            setLogo('');
        }

        if (!isShow) {
            setFileLogo(null);
        }
    }, [isShow, data]);

    useEffect(() => {
        if (isValidateNameRoom === false) {
            setModalState((prevState) => ({ ...prevState, errorRoomNumber: t<string>('message.sample_name_room') }));
        } else {
            setModalState((prevState) => ({ ...prevState, errorRoomNumber: '' }));
        }
    }, [isValidateNameRoom]);

    const formik = useFormik({
        initialValues: initValueFormik,
        validationSchema: yup.object({
            room_number: yup.string().trim().required('asset.error_empty_room_number'),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            const checkRoomNumber = dataListRoom?.find((item: any) => {
                const roomNumberCondition = item.room_number === formik.values.room_number;
                return (roomEdittingId && item.id !== roomEdittingId && roomNumberCondition) || (!roomEdittingId && roomNumberCondition);
            });
            if (checkRoomNumber) {
                formik.setFieldError('room_number', 'message.sample_name_room');
            } else {
                const formData = new FormData();
                // handle list image
                const listImage = formik.values.images;
                const listOnlyFile = listImage && listImage.filter((image) => image);
                if (listOnlyFile?.length) {
                    listOnlyFile?.map((file) => {
                        formData.append('images', file.file as Blob);
                    });
                }

                //handle logo
                if (fileLogo) formData.append('store_key_image', fileLogo);
                const values = formik.values;
                values.keybox = values.box_id ? values.box_id.toString() : null;
                formData.append('data', JSON.stringify(values));

                if (data && data.publication === 1 && values.publication === 0) {
                    const room = await handleGetRoomDetail();
                    if (room?.unexpired_appointments !== '0' || room?.unexpired_otp !== '0') {
                        showOrHideModal('isShowCannotChangePublication');
                    } else if (room?.unexpired_submit_appointments !== '0') {
                        showOrHideModal('isShowConfirmChangePublication');
                        setConfirmChangeData({ values, formData });
                    } else {
                        handleOK(values, formData);
                    }
                } else {
                    handleOK(values, formData);
                }
                if (isValidateNameRoom === undefined) {
                    handleClose();
                }
            }
        },
    });

    const handleGetRoomDetail = async () => {
        try {
            const roomData = await getRoomDetail(data?.id);
            return roomData.data.data;
        } catch (err) {
            return null;
        }
    };

    const handleUploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listFile: FileCustom[] = Array.from(e.target?.files || []);
        listFile?.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        setListFile(listFile);
        e.target.value = '';
    };
    const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = e.target?.files ? e.target?.files[0] : null;
        file.preview = URL.createObjectURL(file);
        setFileLogo(file);
        e.target.value = '';
    };
    const handleRemoveLogo = () => {
        formik.setFieldValue('storeKeyImage', null);
        setLogo('');
        setFileLogo(null);
    };

    useEffect(() => {
        if (fileLogo) {
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const fileSize = Number((fileLogo.size / 1024 / 1024).toFixed(2));
            if (!validImageTypes.includes(fileLogo.type)) {
                setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                showOrHideModal('showFirstModal');
                setFileLogo(null);
                setLogo('');
                return;
            } else if (fileSize > 8) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                showOrHideModal('showFirstModal');
                setFileLogo(null);
                setLogo('');
                return;
            } else {
                formik.setFieldValue('storeKeyImage', fileLogo);
                setLogo(fileLogo.preview || '');
            }
        }
    }, [fileLogo]);

    useEffect(() => {
        if (listFile?.length > 0) {
            const listImageSlideCopy = [...listImageSlide];
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const totalImage = listImageSlideCopy?.length + listFile?.length;

            if (totalImage > 5) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_5_number');
                showOrHideModal('showFirstModal');
                return;
            }
            listFile.forEach((file) => {
                const fileSize = Number((file.size / 1024 / 1024).toFixed(2));
                if (!validImageTypes.includes(file.type)) {
                    setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                    showOrHideModal('showFirstModal');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                } else if (fileSize > 8) {
                    setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                    setModalState((prev: any) => {
                        return { ...prev, showFirstModal: true };
                    });
                    return;
                }
                const orders = listImageSlideCopy?.map((imageSlide) => imageSlide.order);
                const maxOrder = orders?.length > 0 ? Math.max(...orders) : -1;

                const newImage: IImageSlide = {
                    order: maxOrder + 1,
                    url: file.preview || '',
                    file: file,
                    name: file.name,
                };
                listImageSlideCopy.push(newImage);
            });
            setListImageSlide(listImageSlideCopy);
        }
    }, [listFile]);
    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };
    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };
    useEffect(() => {
        handleUpdateImageSlide();
        handleChange('images', listImageSlide);
    }, [listImageSlide]);

    useEffect(() => {
        handleUpdateImageSlide();
    }, [activeIndex]);
    const handleUpdateImageSlide = () => {
        if (listImageSlide?.length) {
            setImageSlide(listImageSlide[activeIndex].url);
        } else {
            setImageSlide('');
            setActiveIndex(0);
        }
    };
    const handleClosePopup = () => {
        setModalState((prevState) => ({ ...prevState, showSecondModal: true }));
    };
    const handleCancelPopup = () => {
        setModalState((prevState) => ({ ...prevState, showThirdModal: true }));
    };
    const handelOkClosePopup = () => {
        handleClose();
        formik.setFieldValue('room_number', '');
        formik.setFieldValue('key_storage', '');
        formik.setFieldValue('status', 0);
        formik.setFieldValue('publication', 1);
        formik.setErrors({});
        setListImageSlide([]);
        setModalState((prevState) => ({
            ...prevState,
            showSecondModal: false,
            showThirdModal: false,
            errorRoomNumber: '',
        }));
    };
    const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
        dragIndex.current = index;
        dragItem.current = e.target as HTMLDivElement;
        dragItem.current.style.opacity = '0.4';
    };

    const handleDragOver = (index: number, e: React.DragEvent) => {
        dropIndex.current = index;
        dropItem.current = e.target as HTMLDivElement;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        if (dragItem.current) {
            dragItem.current.style.opacity = '1';
        }
        if (dropItem.current === dragItem.current) return;

        const imagesCopy = [...listImageSlide];

        const draggedImage = imagesCopy.splice(dragIndex.current, 1)[0];
        imagesCopy.splice(dropIndex.current, 0, draggedImage);
        const newListImage = imagesCopy?.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
    };
    useEffect(() => {
        if (formik.values.status === 1) {
            formik.setFieldValue('publication', 0);
        }
    }, [formik.values.status]);

    return (
        <ModalInterface
            classNameBody={cx('body_add_room')}
            classNameHeader={cx('title-header')}
            dialogClassname={cx('w_modal_add_room')}
            negativeButtonText={negativeButtonText}
            positiveButtonText={positiveButtonText}
            modalHeader={header}
            isShow={isShow}
            handleOK={formik.handleSubmit}
            handleClose={() => handleClosePopup()}
            handleCancel={() => handleCancelPopup()}>
            <div className={cx('body_modal_add_room', 'row overflow-hidden')}>
                <div className={cx('border_img', 'row col-7') + ' ' + cxCommon('bases__height--427')}>
                    <div className={cxCommon('bases__height--265', 'row')}>
                        <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                            <img
                                className={cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                            />
                        </div>
                    </div>

                    <div className={cx('slide_container') + ' d-flex justify-content-center'}>
                        <div ref={slideRef} className={cx('slide_list')}>
                            {listImageSlide.map((image, index) => {
                                return (
                                    <div
                                        key={image.order}
                                        onDragStart={(e) => handleDragStart(index, e)}
                                        onDragOver={(e) => handleDragOver(index, e)}
                                        onDragEnd={() => handleDragEnd()}
                                        className={cx('slide_img_edit') + ' ' + cx(activeIndex === index ? 'slide_img_edit--active' : '')}>
                                        <img
                                            onClick={() => handleRemoveImage(image.order, index)}
                                            src={IMAGES.ICON_CLOSE_BG}
                                            alt=""
                                            className={cx('img_close')}
                                        />
                                        <img onClick={() => handleClick(index)} src={image.url} alt="" className={cx('img_edit')} />
                                    </div>
                                );
                            })}
                        </div>

                        <div>
                            <div aria-disabled={listImageSlide.length >= 5}>
                                {listImageSlide.length >= 5 ? (
                                    <img className={cx('img-upload')} src={IMAGES.ICON_INPUT_IMG_DISABLED} />
                                ) : (
                                    <img
                                        className={cx('img-upload')}
                                        src={IMAGES.ICON_INPUT_IMG}
                                        onClick={() => fileInputRef1.current?.click()}
                                    />
                                )}
                            </div>
                            <input
                                style={{ display: 'none' }}
                                ref={fileInputRef1}
                                type="file"
                                accept="image/*"
                                className="input-upload"
                                onChange={(e) => handleUploadMultipleFiles(e)}
                                multiple
                            />
                        </div>
                    </div>
                </div>
                <div className={cx('set-scroll', 'col-5 ms-4 overflow-auto')}>
                    <FormInput
                        required
                        styleBlock
                        maxLength={255}
                        className="col-md-12"
                        label="asset.detail_asset.room_number"
                        onChangeText={(text) => {
                            return handleChange('room_number', text);
                        }}
                        error={
                            formik.touched.room_number && formik.errors.room_number
                                ? formik.errors.room_number
                                : undefined || errorRoomNumber
                                ? errorRoomNumber
                                : undefined
                        }
                        value={formik.values.room_number}
                        placeholder="101"
                    />
                    <div className="d-flex">
                        <div>
                            <FormInput
                                classNameTextArea={cx('text-area')}
                                textArea
                                rows={6}
                                styleBlock
                                maxLength={3000}
                                className={cx('mr-input')}
                                label="鍵置場"
                                onChangeText={(text) => {
                                    return handleChange('key_storage', text);
                                }}
                                value={formik.values.key_storage}
                                placeholder="置場..."
                            />
                        </div>
                        <div className="position-relative">
                            <img
                                className={cx('img-upload-asset', 'gl-object-fit-contain')}
                                src={logo ? logo : IMAGES.ICON_DEFAULT_INPUT_IMG}
                                onClick={() => fileInputRef2.current?.click()}
                            />
                            <input
                                style={{ display: 'none' }}
                                ref={fileInputRef2}
                                type="file"
                                accept="image/*"
                                className="input-upload"
                                onChange={(e) => handleUploadFiles(e)}
                            />
                            {logo && <img onClick={handleRemoveLogo} src={IMAGES.ICON_CLOSE_BG} alt="" className={cx('img_close_asset')} />}
                        </div>
                    </div>

                    {location.pathname !== Configs.routes.assetRegister && data?.room_number ? (
                        <div className="d-flex">
                            <FormInput
                                disabled
                                styleBlock
                                className="col-md-7 mt-2 h-100"
                                label="キーボックス番号"
                                value={!formik.values.keybox ? '-' : formik.values.keybox}
                            />
                            <Button
                                disabled={formik.values.keybox === '-'}
                                className={cx('button-form-edit-room')}
                                text="設置解除"
                                del
                                onClick={() => setIsShowConfirmDeleteKeybox(true)}
                            />
                        </div>
                    ) : (
                        ''
                    )}

                    <FormRadio
                        classNameInput="size_checkbox"
                        className="mt-2"
                        items={[
                            { label: 'asset.vacant', value: 0, textColorCustom: 'text_color_green' },
                            { label: 'asset.for_rent', value: 1, textColorCustom: 'text_color_red' },
                        ]}
                        defaultCheckedIndex={formik.values.status}
                        onCheckItem={(value) => handleChange('status', value)}
                    />
                    <FormRadio
                        disabled={formik.values.status === 1 ? true : false}
                        classNameInput="size_checkbox"
                        className="mt-2"
                        items={[
                            { label: 'asset.public', value: 1, textColorCustom: 'text_color_green' },
                            { label: 'asset.private', value: 0, textColorCustom: 'text_color_red' },
                        ]}
                        defaultCheckedIndex={formik.values.publication}
                        onCheckItem={(value) => handleChange('publication', value)}
                    />
                </div>
            </div>
            {showFirstModal && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        hideNegativeButton
                        isShow={showFirstModal}
                        handleOK={() => showOrHideModal('showFirstModal')}
                        handleClose={() => showOrHideModal('showFirstModal')}
                        header={t<string>('company_info.company_edit.modal.error_title')}
                        content={t<string>(errorMessage)}></ModalConfirm>
                </>
            )}
            {showSecondModal && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        header={t<string>('common.confirm')}
                        isShow={showSecondModal}
                        handleOK={() => handelOkClosePopup()}
                        handleClose={() => showOrHideModal('showSecondModal')}
                        content={t<string>('common.confirm_close')}></ModalConfirm>
                </>
            )}
            {showThirdModal && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        header={t<string>('common.confirm')}
                        isShow={showThirdModal}
                        handleOK={() => handelOkClosePopup()}
                        handleClose={() => showOrHideModal('showThirdModal')}
                        content={t<string>('common.confirm_cancel')}></ModalConfirm>
                </>
            )}
            {isShowConfirmDeleteKeybox && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        isShow={isShowConfirmDeleteKeybox}
                        handleOK={() => {
                            formik.setFieldValue('keybox', '-');
                            formik.setFieldValue('box_id', '');
                            setIsShowConfirmDeleteKeybox(false);
                        }}
                        handleClose={() => setIsShowConfirmDeleteKeybox(false)}
                        header={t<string>('common.confirm')}
                        content={t<string>('modal.confirm_delete_keybox_content')}
                    />
                </>
            )}
            {isShowCannotChangePublication && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        hideNegativeButton
                        isShow={isShowCannotChangePublication}
                        handleOK={() => showOrHideModal('isShowCannotChangePublication')}
                        handleClose={() => showOrHideModal('isShowCannotChangePublication')}
                        header={t<string>('common.confirm')}
                        content={
                            <div>
                                <span>{t('asset.detail_asset.private_viewing_reservation')}</span>
                                <br />
                                <span className={cxCommon('text-red', 'fw-bold')}>{t('asset.private_popup')}</span>
                                <span>{t('asset.detail_asset.cannot_changed')}</span>
                            </div>
                        }></ModalConfirm>
                </>
            )}
            {isShowConfirmChangePublication && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        isShow={isShowConfirmChangePublication}
                        handleOK={() => {
                            handleOK(confirmChangeData.values, confirmChangeData.formData);
                            showOrHideModal('isShowConfirmChangePublication');
                        }}
                        hideNegativeButton={false}
                        handleClose={() => showOrHideModal('isShowConfirmChangePublication')}
                        header={t<string>('common.confirm')}
                        content={
                            <div>
                                <span>{t('asset.detail_asset.private_viewing')}</span>
                                <br />
                                <span>{t('common.really')}</span>
                                <span className={cxCommon('text-red', 'fw-bold')}>{t('asset.private_popup')}</span>
                                <span>{t('asset.detail_asset.cannot_change')}</span>
                            </div>
                        }></ModalConfirm>
                </>
            )}
        </ModalInterface>
    );
};

EditRoomModal.defaultProps = {
    isShow: false,
};

export default EditRoomModal;
