import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dropdown, FormDropdown, FormInput, FormRadio, Pagination } from '@components/commons';

import classNames from 'classnames/bind';
import styles from './NewQuestion.module.scss';
import { images, pageStateInit } from '@utils/constants';

import { BodyContainer } from '~/components/layouts';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);

const EmployeeWorkerDetails: IHistoryPage<IHistoryPageProps> = (props) => {
    const { t } = useTranslation();
    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。',true)

    return (
        <BodyContainer title="質問新規作成">
            <div className="d-flex justify-content-center ">
                <div className={'col-6 d-block justify-content-center ' + cx('content')}>
                    <div className="d-flex justify-content-end">
                        <img src={images.IMAGES.ICON_CLOSE} className={cx('image-button-style')} />
                    </div>
                    <div className="row">
                        <FormInput
                            styleBlock
                            className="col-md-12 mt-2"
                            label="質問内容"
                            defaultValue="内容を入力"
                            placeholder="内容を入力"
                        />
                        <FormDropdown
                            className="col-4 mx-3 mt-3"
                            label="選択肢"
                            defaultValue="ラジオボタン"
                            options={[
                                { value: 1, label: '10分', imageSrc: images.IMAGES.ICON_RADIO },
                                { value: 2, label: '20分', imageSrc: images.IMAGES.ICON_RADIO },
                            ]}
                        />

                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="ラジオボタン"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="良い"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="普通"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '76px' }}>
                        <Button className="mt-3 " text="選択肢を追加" del></Button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <div className={'col-6 d-block justify-content-center ' + cx('content')}>
                    <div className="d-flex justify-content-end">
                        <img src={images.IMAGES.ICON_CLOSE} className={cx('image-button-style')} />
                    </div>
                    <div className="row">
                        <FormInput
                            styleBlock
                            className="col-md-12 mt-2"
                            label="質問内容"
                            defaultValue="内容を入力"
                            placeholder="内容を入力"
                        />
                        <FormDropdown
                            className="col-4 mx-3 mt-3"
                            label="選択肢"
                            defaultValue="ラジオボタン"
                            options={[
                                { value: 1, label: '10分', imageSrc: images.IMAGES.ICON_RADIO },
                                { value: 2, label: '20分', imageSrc: images.IMAGES.ICON_CHECKBOX },
                            ]}
                        />

                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="ラジオボタン"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="良い"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                        <div className="d-flex col-12 align-items-center mt-3">
                            <div className="col-1">
                                <input className="form-check-input" type="radio" />
                            </div>
                            <div className="col-10">
                                <FormInput label="" className="w-100" defaultValue="普通"></FormInput>
                            </div>
                            <div className="col-1">
                                <img src={images.IMAGES.ICON_CLOSE_SMALL} className={cx('image-button-style')} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '76px' }}>
                        <Button className="mt-3 " text="選択肢を追加" del></Button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <Button className="col-6" text="+ 質問追加" del></Button>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <Button className="me-2" text="+ 質問追加" search></Button>
                <Button text="+ 質問追加" del></Button>
            </div>
        </BodyContainer>
    );
};

export default EmployeeWorkerDetails;
