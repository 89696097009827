import moment from 'moment';
import * as yup from 'yup';
import i18n from '../lang/i18next';
import { isValid } from '@utils/helpers/date';

export const addNotificationSchema = yup.object().shape({
    creator: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('notification.user_registered') })),
    subject: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('notification.subject') })),
    content: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('notification.content_title') })),
    permission: yup.number().required(i18n.t<string>('message.required', { 0: i18n.t<string>('notification.end_date') })),
    startDate: yup
        .string()
        .trim()
        .test('startDate', i18n.t<string>('message.not_choose', { 0: i18n.t<string>('notification.start_date_add') }), (value) => {
            return isValid(value || '', 'YYYY-MM-DD HH:mm:ss');
        }),
    endDate: yup
        .string()
        .trim()
        .when('permission', {
            is: 1,
            then: yup
                .string()
                .trim()
                .test('endDate', i18n.t<string>('message.not_choose', { 0: i18n.t<string>('notification.end_date') }), (value) => {
                    return isValid(value || '', 'YYYY-MM-DD HH:mm:ss');
                })
                .test('endDate', i18n.t<string>('message.invalid_end_date'), (value) => {
                    const currentDate = moment(new Date()).utc();
                    const selectedDate = moment(value).utc();
                    return !moment(selectedDate).isBefore(currentDate);
                }),
            otherwise: yup.string().trim(),
        }),
});

export const addNotificationInitialValues: IAddNotificationForm = {
    type: 0,
    startDate: '',
    endDate: '',
    creator: '',
    subject: '',
    content: '',
    permission: 0,
};
