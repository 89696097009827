import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { images } from '@utils/constants';

import classNames from 'classnames/bind';
import styles from './LoginForm.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';

const cx = classNames.bind(styles);
const common = classNames.bind(stylesCommon);

const LoginForm: ILoginFormComponent<ILoginFormComponentProps> = (props) => {
    const { children } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div>
            <div className={'animate__animated animate__fadeIn'}>
                <div className={cx('container-fluid', 'login-page')}>
                    <div className={cx('form-login')}>
                        <div className={cx('logo')}>
                        <images.IMAGES.LOGO_MAIN />
                        </div>
                        {children}
                        <div className={cx('footer')}>
                        <span>
                            {t('company.guardlock')} - {t('company.name')}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
