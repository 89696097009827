import './_table.scss';
import { images } from '@utils/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TablePagination: ITablePaginationComponent<ITablePaginationComponentProps> = (props) => {
    const {
        className,
        current,
        onPageChange,
        totalPage,
        pageRecords,
        totalRecords,
        firstRecords
    } = props;

    const { t } = useTranslation()

    useEffect(() => {
        if (current && current > totalPage && current !== 1 && onPageChange) {
            onPageChange(current - 1);
        }
    }, [totalRecords])

    const handleChangePage = (action: 'set' | 'next' | 'previous', value: number) => {
        let page = value;
        if (action === 'next') {
            if ((current ?? 1) < totalPage) {
                page += 1;
            }
        }
        if (action === 'previous') {
            if ((current ?? 1) > 1) {
                page -= 1;
            }
        }

        window?.scrollTo(0, 0);
        if (onPageChange) {
            onPageChange(page);
        }
    };

    const renderPage = () => {
        let firstPage = 1;
        let lastPage = totalPage;
        if (totalPage > 8) {
            if ((current ?? 1) <= 3) {
                lastPage = 6;
            } else {
                firstPage = (current ?? 1) - 1;
                lastPage = (current ?? 1) + 2;
                if ((current ?? 1) > totalPage - 4) {
                    firstPage = totalPage - 5;
                    lastPage = totalPage;
                }
            }
        }

        return [...Array(lastPage)].map((_item, index) => {
            const page = index + 1;
            if (page >= firstPage) {
                return renderButton(page.toString());
            }
        });
    };

    const renderButton = (page: string) => {
        const isCurrent = page !== '...' ? (current ?? 1) === parseInt(page) : false;
        return (
            <span
                onClick={() => {
                    if (!isCurrent && page !== '...') handleChangePage('set', parseInt(page));
                }}
                key={page}
                className={page === '...' ? 'bases__p-event--none ' : (Number(page) === current) ? 'current' : ' '}
            >
                {page}
            </span>
        );
    };

    const renderPageGroup = (position: 'first' | 'last') => {
        return (
            <>
                {renderButton(position === 'first' ? '1' : '...')}
                {renderButton(position === 'first' ? '...' : `${totalPage}`)}
            </>
        );
    };

    return (
        <div className='position-relative'>
            {pageRecords && totalRecords && (
                <span className={'pagination_total'}>{t('common.paginationShowing', { firstRecords, pageRecords, totalRecords })}</span>
            )}
            <div className={`components__pagination ${className} d-flex flex-wrap justify-content-center align-items-center`}>
                <span
                    onClick={() => {
                        if ((current ?? 1) > 1) handleChangePage('previous', current ?? 1);
                    }}
                    className={!current || current <= 1 ? 'components__next_prev_disable' : ''}
                >
                    <img src={images.IMAGES.ICON_ARROW_LEFT} />
                </span>
                {totalPage > 8 && (current ?? 1) > 3 && renderPageGroup('first')}
                {renderPage()}
                {totalPage > 8 && (current ?? 1) < totalPage - 3 && renderPageGroup('last')}
                <span
                    onClick={() => {
                        if ((current ?? 1) < totalPage) handleChangePage('next', current ?? 1);
                    }}
                    className={!current || current >= totalPage  ? 'components__next_prev_disable' : ''}
                >
                    <img src={images.IMAGES.ICON_ARROW_RIGHT} />
                </span>
            </div>
        </div>
    );
};

TablePagination.displayName = 'TablePagination'

TablePagination.defaultProps = {
    className: '',
    current: 1,
};



export default TablePagination;
