import { combineReducers } from 'redux';
import {
    loaderReducer,
    sidebarReducer,
    checkErrorReducer,
    modalReducer,
    checkTokenReducer,
    alertReducer,
    checkNotiTokenReducer,
    confirmDeleteReducer,
    showNotFoundReducer,
    userInfoReducer,
    toastReducer,
    showDeletedReducer,
    checkAccountExist,
    checkActionAccountExist,
    SuccessCallAPI,
    listAllReducer,
    checkAccountNotPermisstion
} from './CommonReducer';
import AssetReducer from './AssetReducer';
import ChangePasswordReducer from './ChangePasswordReducer';
import StaffsReducer from './StaffsReducer';

const rootReducers = combineReducers({
    confirmDelete: confirmDeleteReducer,
    modal: modalReducer,
    loader: loaderReducer,
    alert: alertReducer,
    sidebar: sidebarReducer,
    asset: AssetReducer,
    changePassword: ChangePasswordReducer,
    checkError: checkErrorReducer,
    checkToken: checkTokenReducer,
    checkNotiToken: checkNotiTokenReducer,
    showNotFound: showNotFoundReducer,
    userInfo: userInfoReducer,
    toast: toastReducer,
    showDeleted: showDeletedReducer,
    getStaffs: StaffsReducer,
    checkAccountHasBeenDeleted: checkAccountExist,
    checkActionAccountExist:checkActionAccountExist,
    successCallAPI:SuccessCallAPI,
    listAll: listAllReducer,
    checkAccountNotPermisstion:checkAccountNotPermisstion
    //other reducers come here...
});

export type ReduxStates = ReturnType<typeof rootReducers>;
export type State = ReturnType<typeof rootReducers>;
export default rootReducers;
