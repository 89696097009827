import { regex } from '@utils/constants';

export const isEmail = (value: string) => {
    return regex.RULE.EMAIL.test(value);
};

export const isEmpty = (value: string) => {
    return !value;
};

export const isEmptyObject = (obj: any): boolean => {
    return Object.keys(obj).length === 0;
};

export const isHalfWidth = (value: string) => {
    return regex.RULE.HALF_WIDTH.test(value);
};

export const isHiragana = (value: string) => {
    return regex.RULE.HIRAGANA.test(value);
};

export const isNumberPhone = (value: string) => {
    return regex.RULE.NUMBERPHONE.test(value);
};

export const isNumber = (value: string) => {
    return regex.RULE.NUMBER.test(value);
};

export const isFloatNumber = (value: string) => {
    return regex.RULE.FLOAT_NUMBER.test(value);
};

export const isDate = (value: string) => {
    return regex.RULE.DATE.test(value);
};

export const isTime = (value: string) => {
    return regex.RULE.TIME.test(value);
};

export const isDateOnly = (value: string) => {
    return regex.RULE.DATE_ONLY.test(value);
};

export const isTimeOnly = (value: string) => {
    return regex.RULE.TIME_ONLY.test(value);
};

export const isDateTime = (value: string) => {
    return regex.RULE.DATE_TIME.test(value);
};

export const isSignedNumber = (value: string) => {
    return regex.RULE.SIGNED_NUMBER.test(value);
};

export const isPassword = (value: string) => {
    return regex.RULE.PASSWORD.test(value);
};

export const isMaxImageSize = (size: number) => {
    return size <= 5120000;
};

export const isSpecialCharacter = (value: string) => {
    return regex.RULE.SPECIAL_CHARACTER.test(value);
};
