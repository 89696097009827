import { useEffect, useState } from 'react';
import styles from './AssetDetails.module.scss';
import commonStyles from '@assets/styles/base/Common.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { USER_ROLE_ENUM, images, pageStateInit } from '~/utils/constants';
import { Button } from '@components/commons';
import EditAssetModal from '@components/modals/EditAssetModal';
import EditRoomModal from '@components/modals/EditRoomModal';
import { BodyContainer } from '~/components/layouts';
import ModalConfirm from '~/components/modals/ModalConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    addRoomAsset,
    deleteAsset,
    deleteRooms,
    getDetailAsset,
    getRoomAsset,
    getRoomDetail,
    updateRoomOfAsset,
} from '~/utils/helpers/api';
import DataTable from '@components/commons/Table/Table';
import moment from 'moment';
import Carousel from '@components/commons/Carousel/Carousel';
import ModalKeyStorage from '@components/modals/ModalKeyStorage';
import Configs from '@utils/configs';
import { setToast, showAlert } from '@redux/actions';
import { setShowDeleted } from '@redux/actions/CommonAction';
import { ReduxStates } from '@redux/reducers';
import useRole from '@utils/hook/useRole';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const AssetDetails = () => {
    const { t } = useTranslation();
    const routeParams = useParams();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const dispatch = useDispatch();
    const [data, setData] = useState<any>(undefined);
    const [dataListRoom, setDataListRoom] = useState<any>([]);
    const [roomDetail, setRoomDetail] = useState<any>(undefined);
    const [activeRoomID, setactiveRoomID] = useState<any>('');
    const [isInteractRoom, setIsInteractRoom] = useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({ limit: '10', offset: '0' });
    const { showDeleted, userInfo } = useSelector((states: ReduxStates) => states);
    const isAdminPermission = useRole(userInfo, USER_ROLE_ENUM.admin);

    useEffect(() => {
        fetchRoomAsset(searchParams as IParamsAsset);
    }, [searchParams.get('offset')]);

    useEffect(() => {
        const fetchRoomDetail = async () => {
            if (!showRoomEditPopUp) {
                const roomId = searchParams.get('room_id');
                setactiveRoomID(roomId);
                if (roomId) {
                    try {
                        await handleGetRoomDetail(roomId);
                        setState((prevState) => ({ ...prevState, showRoomEditPopUp: true }));
                    } catch (err) {
                        setState((prevState) => ({ ...prevState, showRoomEditPopUp: false }));
                    }
                }
            }
        };
        if (searchParams.has('room_id')) {
            fetchRoomDetail();
        } else {
            setState((prevState) => ({ ...prevState, showRoomEditPopUp: false }));
        }
    }, [searchParams.has('room_id')]);

    useEffect(() => {
        const limit = 10;
        const offset = dataListRoom.metadata?.offset;
        const count = dataListRoom.metadata?.count;
        const total = dataListRoom.metadata?.total;
        setPageState({
            firstRecords: offset * limit + 1,
            currentPage: offset + 1,
            totalPage: Math.ceil(total / limit),
            pageRecords: count + offset * limit,
            totalRecords: total,
            limit,
        });
    }, [dataListRoom]);

    const [state, setState] = useState<IAssetDetailPageState>({
        showRoomEditPopUp: false,
        showRoomAddPopUp: false,
        showAssetEditPopUp: false,
        showModalKeyStorage: false,
        showModalDeleteRoom: false,
        showModalDeleteAsset: false,
        isValidateNameRoom: true,
    });
    const {
        showAssetEditPopUp,
        showRoomEditPopUp,
        showRoomAddPopUp,
        showModalKeyStorage,
        showModalDeleteRoom,
        showModalDeleteAsset,
        isValidateNameRoom,
    } = state;

    useEffect(() => {
        window.addEventListener('popstate', () => {
            if (showRoomEditPopUp || showRoomAddPopUp || showAssetEditPopUp || showModalKeyStorage || showModalDeleteRoom || showModalDeleteAsset) {
                setState((prevState) => ({
                    ...prevState,
                    showRoomEditPopUp: false,
                    showRoomAddPopUp: false,
                    showAssetEditPopUp: false,
                    showModalKeyStorage: false,
                    showModalDeleteRoom: false,
                    showModalDeleteAsset: false,
                }));
            }
        });

    }, [showRoomEditPopUp, showRoomAddPopUp, showAssetEditPopUp, showModalKeyStorage, showModalDeleteRoom, showModalDeleteAsset]);

    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            if (!isInteractRoom) {
                navigate(Configs.routes.asset);
            } else {
                setIsInteractRoom(false);
                fetchRoomAsset(searchParams as IParamsAsset);
            }
        }
    }, [showDeleted]);

    useEffect(() => {
        if (activeRoomID) {
            handleGetRoomDetail(activeRoomID);
        }
    }, [activeRoomID, showRoomEditPopUp]);

    const handleAddRoom = (values: any, formData: any) => {
        addRoomAsset(routeParams.id ?? '', formData)
            .then(async () => {
                handleCloseRoomAddModal();
                setState((prevState) => ({ ...prevState, isValidateNameRoom: true }));
                const searchParamsObject = Object.fromEntries(searchParams.entries());
                setSearchParams({
                    ...searchParamsObject,
                    offset: '0',
                    limit: '10',
                });
                await fetchRoomAsset(searchParams as IParamsAsset);
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.add_success')}`,
                    }),
                );
            })
            .catch((err) => {
                if (err.status === 400) {
                    setState((prevState) => ({ ...prevState, isValidateNameRoom: false }));
                } else {
                    handleCloseRoomAddModal();
                }
            });
    };

    const handleDeleteAsset = () => {
        handleGetAssetDetail((data: any) => {
            handleCloseModalDelete('showModalDeleteAsset');
            if (data.unexpired_appointments !== '0' || data.unexpired_otp !== '0') {
                dispatch(
                    showAlert({
                        header: 'common.confirm',
                        content: 'asset.detail_asset.cannot_delete',
                        hiddenCancel: true,
                    }),
                );
            } else if (data.unexpired_submit_appointments !== '0') {
                dispatch(
                    showAlert({
                        header: 'common.delete_confirm',
                        content: 'asset.detail_asset.confirm_delete',
                        handleOK: () => handleFetchDeleteAsset(),
                    }),
                );
            } else {
                handleFetchDeleteAsset();
            }
        });
    };

    const handleFetchDeleteAsset = () => {
        const body = {
            params: { assets: `[${routeParams.id ?? ''}]` },
        };

        deleteAsset(body)
            .then(() => {
                navigate(Configs.routes.asset);
                dispatch(
                    setToast({
                        isShow: true,
                        message: t<string>('common.has_deleted'),
                    }),
                );
            })
            .catch((err) => {
                handleCloseModalDelete('showModalDeleteAsset');
            });
    };

    const handleUpdateRoom = (values: any, formData: any) => {
        setIsInteractRoom(true);
        updateRoomOfAsset(activeRoomID, formData)
            .then(async () => {
                handleCloseRoomEditModal();
                await fetchRoomAsset(searchParams as IParamsAsset, false);
                setRoomDetail(null);
                handleGetRoomDetail(activeRoomID);
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.save_data')}`,
                    }),
                );
                setState((prevState) => ({ ...prevState, isValidateNameRoom: true }));
            })
            .catch((err) => {
                if (err.status === 400) {
                    setState((prevState) => ({ ...prevState, isValidateNameRoom: false }));
                } else {
                    handleCloseRoomEditModal();
                }
            });
    };

    const handleDeleteRoom = () => {
        handleGetRoomDetail(activeRoomID, (roomDetail: any) => {
            handleCloseModalDelete('showModalDeleteRoom');
            if (roomDetail.unexpired_appointments !== '0' || roomDetail.unexpired_otp !== '0') {
                dispatch(
                    showAlert({
                        header: 'common.confirm',
                        content: 'asset.detail_asset.cannot_delete',
                        hiddenCancel: true,
                    }),
                );
            } else if (roomDetail.unexpired_submit_appointments !== '0') {
                dispatch(
                    showAlert({
                        header: 'common.delete_confirm',
                        content: 'asset.detail_asset.confirm_delete',
                        handleOK: () => handleFetchDeleteRoom(),
                    }),
                );
            } else {
                handleFetchDeleteRoom();
            }
        })
    };

    const handleFetchDeleteRoom = () => {
        setIsInteractRoom(true);
        deleteRooms({ rooms: `[${activeRoomID ?? ''}]` })
            .then(async () => {
                await fetchRoomAsset(searchParams as IParamsAsset);
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.delete_success')}`,
                    }),
                );
            })
            .catch((err) => {
                handleCloseModalDelete('showModalDeleteRoom');
            });
    };

    const handleGetAssetDetail = async (callBack?: (data: any) => void) => {
        try {
            const { data } = await getDetailAsset(routeParams.id ?? '');
            setData(data.data);
            if (callBack) {
                callBack(data.data);
            }
        } catch (err) {
            throw new Error('Error res');
        }
    };
    const handleGetRoomDetail = async (room_number: string, callBack?: (data: any) => void) => {
        setIsInteractRoom(true);
        setactiveRoomID(room_number);
        try {
            const { data } = await getRoomDetail(room_number);
            setRoomDetail(data.data);
            if (callBack) {
                callBack(data.data)
            }
        } catch (err) {
            throw new Error('Error res');
        }
    };

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '10',
        });
    };

    const fetchRoomAsset = async (params: IParamsAsset, isReload = true) => {
        await handleGetAssetDetail();
        try {
            const { data } = await getRoomAsset(params as IParamsAsset, routeParams?.id);
            if (isReload) {
                if (data?.data.length > 0) {
                    handleGetRoomDetail(data?.data[0]?.id);
                } else {
                    setRoomDetail(null);
                }
            }
            setDataListRoom(data);
        } catch (err) {
            throw new Error('Error res');
        }
    };
    const handleOpenModal = () => {
        setState((prevState) => ({ ...prevState, showAssetEditPopUp: true }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'assetEdit',
        });
    };
    const handleCloseModal = () => {
        setState((prevState) => ({ ...prevState, showAssetEditPopUp: false }));
        searchParams.delete('action');
        setSearchParams(searchParams);
    };
    const handleOpenRoomEditModal = () => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            room_id: activeRoomID,
        });
        setState((prevState) => ({ ...prevState, showRoomEditPopUp: true }));
    };
    const handleCloseRoomEditModal = () => {
        setState((prevState) => ({ ...prevState, showRoomEditPopUp: false, isValidateNameRoom: true }));
        searchParams.delete('room_id');
        setSearchParams(searchParams);
    };
    const handleOpenRoomAddModal = () => {
        setState((prevState) => ({ ...prevState, showRoomAddPopUp: true }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'registerRoom',
        });
    };
    const handleCloseRoomAddModal = () => {
        setState((prevState) => ({ ...prevState, showRoomAddPopUp: false, isValidateNameRoom: true }));
        searchParams.delete('action');
        setSearchParams(searchParams);
    };
    const handleOpenKeyStorageModal = () => {
        setState((prevState) => ({ ...prevState, showModalKeyStorage: true }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'showKeyStorage',
        });
    };
    const handleCloseKeyStorageModal = () => {
        setState((prevState) => ({ ...prevState, showModalKeyStorage: false }));
        searchParams.delete('action');
        setSearchParams(searchParams);
    };
    const modal = (
        <EditAssetModal
            data={data}
            dataListRoom={dataListRoom}
            isShow={showAssetEditPopUp}
            handleClose={() => {
                fetchRoomAsset(searchParams as IParamsAsset);
                handleCloseModal();
            }}></EditAssetModal>
    );
    const handleOpenModalDelete = (modal: 'showModalDeleteRoom' | 'showModalDeleteAsset') => {
        setState((prevState) => ({ ...prevState, [modal]: true }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'delete',
        });
    };
    const handleCloseModalDelete = (modal: 'showModalDeleteRoom' | 'showModalDeleteAsset') => {
        setState((prevState) => ({ ...prevState, [modal]: false }));
        searchParams.delete('action');
        setSearchParams(searchParams);
    };
    const roomEditModal = (
        <EditRoomModal
            positiveButtonText="common.save"
            isShow={showRoomEditPopUp}
            header={t<string>('asset.edit_room_details')}
            data={{
                id: activeRoomID,
                ...roomDetail,
            }}
            handleOK={handleUpdateRoom}
            isValidateNameRoom={isValidateNameRoom}
            handleClose={handleCloseRoomEditModal}></EditRoomModal>
    );

    const roomAddModal = (
        <EditRoomModal
            positiveButtonText="common.add"
            isShow={showRoomAddPopUp}
            header={t<string>('asset.title_popup_add')}
            handleOK={handleAddRoom}
            handleClose={handleCloseRoomAddModal}
            isValidateNameRoom={isValidateNameRoom}></EditRoomModal>
    );

    const confirmModal = (
        <ModalConfirm
            isShow={showModalDeleteRoom || showModalDeleteAsset}
            handleOK={showModalDeleteRoom ? handleDeleteRoom : handleDeleteAsset}
            handleClose={() => handleCloseModalDelete(showModalDeleteRoom ? 'showModalDeleteRoom' : 'showModalDeleteAsset')}
            header={t<string>('common.delete_confirm')}
            content={`${t<string>('common.delete_data')}\n${t<string>('common.confirm_delete')}`}></ModalConfirm>
    );

    const imageKeyboxModal = (
        <ModalKeyStorage
            isShow={showModalKeyStorage}
            url={roomDetail?.store_key_image}
            valueInput={roomDetail?.key_storage}
            handleClose={() => handleCloseKeyStorageModal()}
            header={t<string>('common.key_storage_detail')}
            negativeButtonText="common.close"
        />
    );

    const handleTableDatas = () => {
        return dataListRoom.data?.map((item: any) => {
            return {
                ...item,
                box_battery: (
                    <span className={cxCommon(item.box_battery && item.box_battery <= 5 ? 'text-red' : '')}>
                        {item.box_battery ? `${item.box_battery}%` : '-'}
                    </span>
                ),
                raw_box_battery: item.box_battery ? `${item.box_battery}%` : '-',
                box_status: (
                    <span className={cxCommon(item.box_status ? 'text_color_green' : 'text-red')}>
                        {item.box_status === 1 ? t('asset.installed') : t('asset.not_installed')}
                    </span>
                ),
                raw_box_status: item.box_status === 1 ? t('asset.installed') : t('asset.not_installed'),
                publication: (
                    <span className={cxCommon(item.publication === 1 ? 'text_color_green' : 'text-red')}>
                        {item.publication === 1 ? t('asset.public') : t('asset.private')}
                    </span>
                ),
                raw_publication: item.publication === 1 ? t('asset.public') : t('asset.private'),
                status: (
                    <span className={cxCommon(!item.status ? 'text_color_green' : 'text-red')}>
                        {item.status === 1 ? t('asset.rent') : t('asset.vacant')}
                    </span>
                ),
                raw_status: item.status === 1 ? t('asset.rent') : t('asset.vacant'),
                room_view_amount: item.room_view_amount ? `${item.room_view_amount}${t('asset.subject')}` : '',
                detail: (
                    <Button
                        className={
                            cx(activeRoomID.toString() === item?.id.toString() ? 'button-detail__active' : 'button-detail') + ' w-100'
                        }
                        onClick={() => handleGetRoomDetail(item.id)}
                        text={t<string>('common.detail')}>
                        <img
                            style={{ marginLeft: '3px' }}
                            src={
                                activeRoomID.toString() === item?.id.toString()
                                    ? images.IMAGES.ICON_DETAIL_WHITE
                                    : images.IMAGES.ICON_DETAIL
                            }></img>
                    </Button>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('asset.detail_asset.room_number'),
            },
            {
                title: t('asset.detail_asset.key_box_installation'),
            },
            {
                title: t('asset.batteryLevel'),
            },
            {
                title: t('asset.detail_asset.key_storage'),
            },
            {
                title: t('asset.rentVacant'),
            },
            {
                title: t('asset.publicPrivate'),
            },
            {
                title: t('asset.detail_asset.view_number'),
            },
            {
                title: t('common.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'room_number',
                },
                {
                    field: 'box_status',
                    rawField: 'raw_box_status',
                },
                {
                    field: 'box_battery',
                    rawField: 'raw_box_battery',
                },
                {
                    field: 'key_storage',
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'publication',
                    rawField: 'raw_publication',
                },
                {
                    field: 'room_view_amount',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };

    return (
        <>
            {isAdminPermission && (
                <BodyContainer title={t('title.detail_asset')}>
                    <div className={cx('container-asset')}>
                        <div className="row w-100">
                            <div className={cx('list-room', 'col-7')}>
                                <section className={cx('content')}>
                                    <div>
                                        {data && (
                                            <Carousel
                                                containerClassName={cx('mx-auto', 'content__carousel')}
                                                items={
                                                    data?.images?.map((image: any, index: number) => <img key={index} src={image.url} />) ??
                                                    []
                                                }
                                            />
                                        )}
                                        <div className="p-4">
                                            <div className={'d-flex ' + cx('title')}>
                                                <div className="h-auto me-2">{t('asset.detail_asset.name')}:</div>
                                                <div className={'pe-0 col'}>{data?.name ? data?.name : '-'}</div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2">{t('asset.detail_asset.address')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.address ? data?.address : '-'}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2">{t('asset.type_of_use')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.usage_classification ? data?.usage_classification : '-'}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2">{t('asset.room_type')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.room_classification ? data?.room_classification : '-'}</span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2">{t('asset.number_room')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.room_amount ? data?.room_amount : '-'}</span>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2"> {t('asset.numberOfKeybox')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.box_amount ? data?.box_amount : '-'}</span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <span
                                                        className={
                                                            data?.publication === 1
                                                                ? cxCommon('text_color_green', 'fw-bold')
                                                                : cxCommon('text_color_red', 'fw-bold')
                                                        }>
                                                        {data?.publication === 1 ? t('asset.public') : t('asset.private')}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className={'d-flex '}>
                                                    <span className="h-auto me-2"> {t('asset.update_date')}:</span>
                                                    <span className={'pe-0 col fw-bold'}>{data?.updated_date
                                                        ? moment.utc(data?.updated_date).format('YYYY/MM/DD HH:mm')
                                                        : '-'}</span>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <div className={'d-flex '}>
                                                        <div className="h-auto me-2">  {t('asset.detail_asset.view_asset')}:</div>
                                                        <div className={cxCommon('text_color_blue', 'fw-bold', 'pe-0 col')}>  {data?.asset_most_viewer ? data?.asset_most_viewer : '-'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 d-flex justify-content-end">
                                                <Button
                                                    onClick={handleOpenModal}
                                                    className={cx('button__content')}
                                                    text={t<string>('common.edit')}
                                                    search
                                                />

                                                <Button
                                                    className={cx('button__delete', 'ms-4')}
                                                    text={t<string>('common.remove')}
                                                    onClick={() => handleOpenModalDelete('showModalDeleteAsset')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="col">
                                    <div className="row mt-3">
                                        <div className="col d-flex justify-content-between align-items-center">
                                            <span className={cx('section-title')}>{t('asset.roomList')}</span>
                                            <Button
                                                onClick={handleOpenRoomAddModal}
                                                className={cx('button__add')}
                                                text={t<string>('common.addRoom')}
                                                add
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        className="mt-3"
                                        isStickyColumn
                                        heads={table.heads}
                                        body={table.body}
                                        pageData={pageState}
                                        total={pageState.totalRecords}
                                        onChangePage={(page) => handleChangePage(page)}
                                    />
                                </div>
                            </div>

                            <div className={cx('col-5')}>
                                {roomDetail ? (
                                    <section className={cx('side-content')}>
                                        <div className="row">
                                            <div className="col">
                                                <section className={cx('content')}>
                                                    {roomDetail && (
                                                        <div className="d-flex mb-3 justify-content-center">
                                                            <Carousel
                                                                containerClassName={cx('content__carousel')}
                                                                items={
                                                                    roomDetail?.images?.map((image: any, index: number) => (
                                                                        <img key={index} src={image.url} />
                                                                    )) ?? []
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="d-flex w-100">
                                                        <div className="col-12">
                                                            <div className={'d-flex ' + cx('title')}>
                                                                <div className="h-auto me-2">{t('asset.detail_asset.room_number')}:</div>
                                                                <div className="pe-0 col">
                                                                    {roomDetail?.room_number ? roomDetail?.room_number : '-'}
                                                                </div>
                                                            </div>

                                                            <div className={'d-flex mt-3'}>
                                                                <div className="h-auto me-2"> {t('asset.keyboxSerial')}:</div>
                                                                <div className="pe-0 col fw-bold">
                                                                    {roomDetail?.box_number ? roomDetail?.box_number : '-'}
                                                                </div>
                                                            </div>

                                                            <div className="row mt-3">
                                                                <div className="col">
                                                                    {t('asset.batteryLevel')}:{' '}
                                                                    <span
                                                                        className={`fw-bold ${roomDetail?.box_battery && roomDetail?.box_battery <= 5
                                                                            ? 'bases__text--red'
                                                                            : ''
                                                                            }`}>
                                                                        {roomDetail?.box_battery ? `${roomDetail.box_battery}%` : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div
                                                                    className={`col ${roomDetail?.key_storage || roomDetail?.store_key_image
                                                                        ? 'bases__p--pointer'
                                                                        : ''
                                                                        }`}
                                                                    onClick={() => {
                                                                        roomDetail?.key_storage || roomDetail?.store_key_image
                                                                            ? handleOpenKeyStorageModal()
                                                                            : {};
                                                                    }}>
                                                                    {t('asset.detail_asset.box_image')}:{' '}
                                                                    <span
                                                                        className={cxCommon(
                                                                            `${roomDetail?.key_storage || roomDetail?.store_key_image
                                                                                ? 'text_color_blue_bold'
                                                                                : ''
                                                                            }`,
                                                                            'col',
                                                                        )}>
                                                                        {roomDetail?.key_storage || roomDetail?.store_key_image
                                                                            ? t('asset.keybox_image')
                                                                            : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {roomDetail && (
                                                                <>
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <span
                                                                                className={
                                                                                    !roomDetail?.status
                                                                                        ? cxCommon('text_color_green', 'fw-bold')
                                                                                        : cxCommon('text_color_red', 'fw-bold')
                                                                                }>
                                                                                {roomDetail.status === 1
                                                                                    ? t('asset.rent')
                                                                                    : t('asset.vacant')}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-3">
                                                                        <div className="col">
                                                                            <span
                                                                                className={
                                                                                    roomDetail.publication
                                                                                        ? cxCommon('text_color_green', 'fw-bold')
                                                                                        : cxCommon('text_color_red', 'fw-bold')
                                                                                }>
                                                                                {roomDetail.publication
                                                                                    ? t('asset.public')
                                                                                    : t('asset.private')}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="row mt-3">
                                                                <div className="col">
                                                                    {t('asset.detail_asset.view_number')}:{' '}
                                                                    <span className={'fw-bold'}>
                                                                        {roomDetail?.room_view_amount
                                                                            ? `${roomDetail?.room_view_amount}${t('asset.subject')}`
                                                                            : '-'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className={'d-flex '}>
                                                                    <div className="h-auto me-2">{t('asset.detail_asset.view_room')}:</div>
                                                                    <div className={cxCommon('text_color_blue', 'fw-bold', 'pe-0 col')}>  {roomDetail?.room_most_viewer ? roomDetail?.room_most_viewer : '-'}</div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-end mt-3">
                                                                <Button
                                                                    className={cx('button__content')}
                                                                    onClick={handleOpenRoomEditModal}
                                                                    text={t<string>('common.edit')}
                                                                    search
                                                                />
                                                                <Button
                                                                    onClick={() => handleOpenModalDelete('showModalDeleteRoom')}
                                                                    className={cx('button__delete', 'ms-4')}
                                                                    text={t<string>('common.remove')}
                                                                    del
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                        <div className="row mt-3 ms-1">
                                            <div className={'col' + cx('section-title')}>{t('asset.unlockHistory')}</div>
                                            <div className={'d-block'}>
                                                <div className="mt-2">
                                                    <NavLink
                                                        className={cxCommon('text_color_blue')}
                                                        to={`${Configs.routes.brokerHistory}?asset_name=${data?.name}&room_name=${roomDetail?.room_number}`}>
                                                        {t('asset.detail_asset.brokerage_company_history')}
                                                    </NavLink>
                                                </div>
                                                <div className="mt-2">
                                                    <NavLink
                                                        className={cxCommon('text_color_blue')}
                                                        to={`${Configs.routes.employeeHistory}?asset_name=${data?.name}&room_name=${roomDetail?.room_number}`}>
                                                        {t('asset.detail_asset.company_employee_history')}
                                                    </NavLink>
                                                </div>
                                                <div className="mt-2">
                                                    <NavLink
                                                        className={cxCommon('text_color_blue')}
                                                        to={`${Configs.routes.workerHistory}?asset_name=${data?.name}&room_name=${roomDetail?.room_number}`}>
                                                        {t('asset.detail_asset.contractor_history')}
                                                    </NavLink>
                                                </div>
                                                <div className="mt-2">
                                                    <NavLink
                                                        className={cxCommon('text_color_blue')}
                                                        to={`${Configs.routes.oneTimeHistory}?asset_name=${data?.name}&room_name=${roomDetail?.room_number}`}>
                                                        {t('asset.detail_asset.one-time_pass_history')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ) : (
                                    <div className="text-center">{t('common.no_data')}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    {modal}
                    {roomEditModal}
                    {roomAddModal}
                    {confirmModal}
                    {imageKeyboxModal}
                </BodyContainer>
            )}
        </>
    );
};

export default AssetDetails;
