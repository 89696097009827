import classNames from 'classnames/bind';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { Common } from '~/assets/styles';
import styles from './Form.module.scss';
import { Tooltip } from '@components/commons';
const cxCommon = classNames.bind(Common);
const cx = classNames.bind(styles);

interface IFormDropdownProps {
    className?: string;
    styleBlock?: boolean;
    required?: boolean;
    label?: string;
    value?: string | number;
    defaultValue?: string | number;
    placeholder?: string;
    options?: FormOptionType[];
    disabled?: boolean;
    onChangeOption?: (option: FormOptionType) => void;
    name?: string;
    classNameOption?: string;
    error?: string;
    hiddenLabel?: boolean;
    classNameLabel?: string;
    classNameStyle?: string;
    classNameDropdown?: string;
    classNameInput?: string;
    tooltip?: boolean;
    classNameMenu?: string;
    classNameItemOption?: string;
    fontSize?: string;
}

const FormDropdown: IInputComponent<IFormDropdownProps> = ({
    className = '',
    styleBlock,
    required,
    error,
    label = '',
    value: initValue,
    defaultValue,
    placeholder = '',
    options = [],
    disabled,
    classNameOption,
    hiddenLabel,
    classNameLabel,
    classNameStyle,
    classNameItemOption,
    classNameDropdown,
    onChangeOption = () => undefined,
    name,
    tooltip,
    classNameMenu,
    classNameInput,
    fontSize = '12'
}) => {
    const [value, setValue] = useState(defaultValue || initValue);
    const [isShow, setIsShow] = useState(false);

    const valueOption = useMemo(() => {
        return options.find((option) => option.value == value);
    }, [value]);


    useEffect(() => {
        if (initValue !== undefined) {
            const value = options.find((option) => option.value == initValue);
            if (value) {
                setValue(value.value);
            }
        }
    }, [initValue]);

    const labelClassName = useMemo(() => (styleBlock ? 'form-label' : 'col-md-4 col-form-label'), [styleBlock]);

    const handleSelectItem = (eventKey: string | null) => {
        const idx = Number(eventKey);
        setValue(options[idx].value);
        onChangeOption(options[idx]);
    };

    return (
        <div className={className}>
            <div className={styleBlock ? '' : cx(classNameStyle, 'row col')}>
                {hiddenLabel ?? (
                    <label className={classNameLabel ? classNameLabel : cxCommon(labelClassName, 'label-color')}>
                        {t(label)}
                        {required && <span className={cxCommon('text-danger')}>*</span>}
                    </label>
                )}

                <div className={classNameDropdown ? classNameDropdown : 'col'}>
                    <Dropdown
                        onToggle={(value) => {
                            setIsShow(value);
                        }}
                        className={cx(
                            'form-control',
                            'input-group-gl',
                            'p-0',
                            disabled ? 'gl-disable' : '',
                            isShow ? 'gl-input-active' : '',
                            error ? 'input_error' : '',
                        )}
                        onSelect={handleSelectItem}>
                        <Dropdown.Toggle
                            variant="default"
                            className={cx(
                                'w-100',
                                'd-flex',
                                'justify-content-between',
                                'align-items-center',
                                'gl-border-unset',
                                'input-group-gl',
                            )}
                            disabled={disabled}>
                            {valueOption?.imageSrc && <img src={valueOption?.imageSrc} />}
                            <input
                                disabled={disabled}
                                className={`${classNameInput} bases__text--font${fontSize ?? 12}  ${cx('pe-none', 'w-100', 'me-2', 'fz-14', disabled ? 'gl-disable' : '')}`}
                                value={t(valueOption?.label || '') || ''}
                                defaultValue={defaultValue}
                                placeholder={t<string>(placeholder)}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={cx('dropdown-menu-max', classNameMenu)}>
                            {options.map((option, idx) => (
                                <Dropdown.Item key={option.value} eventKey={idx} className={`bases__text--font${fontSize ?? 12} ${cx(classNameOption)}`}>
                                    {option.imageSrc && <img src={option.imageSrc} className={'me-2'} />}
                                    {tooltip ? (
                                        <Tooltip
                                            className={cx(classNameItemOption, 'w-100 d-block overflow-hidden')}
                                            content={option.label}>
                                            {t(option.label)}
                                        </Tooltip>
                                    ) : (
                                        t(option.label)
                                    )}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {error && (
                        <div className={cx('error-msg')}>
                            <div className={cx('text-overflow')}>{t(error)}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormDropdown;
