import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@components/commons';

import classNames from 'classnames/bind';
import styles from './EmployeeWorkerDetails.module.scss';
import { ButtonStyle, Common } from '@assets/styles';
import { images } from '@utils/constants';
import Configs from '@utils/configs';
import { BodyContainer } from '~/components/layouts';
import { deleteStaffs, getDetailStaffs, resetPasswordStaffs } from '@utils/helpers/api';
import { getAssetAsync } from '@redux/actions/AssetAction';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import moment from 'moment';
import DataTable from '@components/commons/Table/Table';
import { handleGetFullAddress } from '@utils/helpers/utilities';
import { setToast, showAlert } from '@redux/actions';
import { setShowDeleted } from '@redux/actions/CommonAction';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);
const cxButton = classNames.bind(ButtonStyle);

const EmployeeWorkerDetails: IHistoryPage<IHistoryPageProps> = (props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [dataDetail, setDataDetail] = useState<any>();
    const [dataAssets, setDataAssets] = useState<any>([]);
    const [statusListAsset, setStatusListAsset] = useState(0);
    const { sidebar, asset, showDeleted } = useSelector((states: ReduxStates) => states);
    const [height, setHeight] = useState(0);
    const [checkHeight, setCheckHeight] = useState(false);

    useEffect(() => {
        getDetailStaffs(id?.toString() ?? '')
            .then(({ data }) => {
                dispatch(getAssetAsync({ orderBy: JSON.stringify({ order: 'ASC', by: 'asset.name' }) }));
                setDataDetail(data.data);
            })
            .catch((err) => {
                console.log('🚀 ~ file: EmployeeWorkerRegister.tsx:79 ~ useEffect ~ err:', err);
            });
    }, []);
    useEffect(() => {
        setCheckHeight(true);
        setHeight(ref?.current?.offsetHeight as any);
    }, [sidebar]);

    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            navigate(Configs.routes.employeeWorker);
        }
    }, [showDeleted]);


    useEffect(() => {
        if (asset) {
            setDataAssets(
                (asset.listAsset.data ?? []).filter((asset: any) => {
                    const exIds = (dataDetail?.exclude_assets ?? [])?.map((item: any) => item.id);
                    return !exIds.includes(asset.id);
                }),
            );
        }
    }, [asset]);

    const handleTableDatas = () => {
        return dataAssets?.map((item: any) => {
            const addressParams: IEnterAddressForm = {
                postalCode: item?.postal_code,
                prefecture: item?.prefecture,
                city: item?.city,
                town: item?.town,
                building: item?.building,
            };
            return {
                ...item,
                name: item.name,
                address: handleGetFullAddress(addressParams),
            };
        });
    };

    const table = {
        heads: [
            {
                title: t('staffworker.detail.asset_name'),
            },
            {
                title: t('staffworker.detail.address'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'name',
                },
                {
                    field: 'address',
                },
            ],
            rows: handleTableDatas(),
        },
    };
    const handleChangeList = (number: number) => {
        if (number) {
            setDataAssets(
                (asset.listAsset.data ?? []).filter((ass: any) => (dataDetail.exclude_assets ?? []).map((a: any) => a.id).includes(ass.id)),
            );
            setStatusListAsset(1);
        } else {
            setStatusListAsset(0);
            setDataAssets(
                (asset.listAsset.data ?? []).filter((asset: any) => {
                    const exIds = (dataDetail.exclude_assets ?? [])?.map((item: any) => item.id);
                    return !exIds.includes(asset.id);
                }),
            );
        }
    };
    const handleDelete = () => {
        dispatch(
            showAlert({
                header: 'common.remove',
                content: 'staffworker.list.confirm_delete',
                handleOK: () => {
                    deleteStaffs(id?.toString() ?? '')
                        .then(({ data }) => {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: t<string>('common.delete_success'),
                                }),
                            );
                            navigate(Configs.routes.employeeWorker);
                        })
                        .catch((err) => {
                            console.log('🚀 ~ file: EmployeeWorkerDetails.tsx:145 ~ handleDelete ~ err:', err);
                        });
                },
            }),
        );
    };
    const handleResetPwd = () => {
        const lastDay = localStorage.getItem('checkResetPasswordOne');
        if (moment(lastDay).add(1, 'minutes').isAfter(moment())) {
            dispatch(
                showAlert({
                    hideNegativeButtonClose: true,
                    header: 'common.confirm',
                    content: 'staffworker.list.noti_reset_password',
                    titleCancel: 'OK',
                    classNameCancel: cx('btnCancel'),
                    hiddenOK: true,
                }),
            );
        } else {
            dispatch(
                showAlert({
                    header: 'staffworker.list.reset_password_one',
                    content: 'staffworker.list.noti_reset_password_one',
                    handleOK: () => {
                        const body = { userIds: [id] };
                        resetPasswordStaffs(body)
                            .then(({ data }) => {
                                const today = moment().format('YYYY-MM-DD HH:mm:ss');
                                localStorage.setItem('checkResetPasswordOne', today);
                                dispatch(
                                    setToast({
                                        isShow: true,
                                        message: `${t('staffworker.list.reset_completed')}`,
                                    }),
                                );
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    },
                }),
            );
        }
    };

    return (
        <BodyContainer title={t('staffworker.detail.title')}>
            <div className={'position-relative w-100'}>
                <div className="row w-100">
                    <div className={'col-6 ' + cx('text-title')}>{t('staffworker.detail.personal_info')}</div>
                    <div className={'col-6 ' + cx('text-title')}>{t('staffworker.detail.management_intelligence')}</div>
                </div>
                <div className="row w-100 mt-3">
                    <div className="col-6 h-auto">
                        <div
                            className={
                                cxCommon('bases__height-min--681') +
                                ' ' +
                                cx('content', 'height-content h-100 ', 'd-flex justify-content-between flex-column')
                            }>
                            <div>
                                <div ref={ref} className={cxCommon('d-flex')}>
                                    <div className={cx('responsive_img_detail')}>
                                        <img
                                            src={dataDetail?.staff_avatar ? dataDetail?.staff_avatar : images.IMAGES.NO_IMAGE_ONE}
                                            className={cx('image-account')}
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2">{t('staffworker.detail.user_name')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.user_username ? dataDetail?.user_username : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2"> {t('staffworker.list.user')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.staff_name ? dataDetail?.staff_name : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2">{t('staffworker.detail.kinds')}:</span>
                                                <span className={'pe-0 col fw-bold'}> {dataDetail?.user_role_id == 5
                                                    ? t('staffworker.list.contractor')
                                                    : t('staffworker.list.company_employee')}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2"> {t('staffworker.detail.affiliation')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.staff_store_name ? dataDetail?.staff_store_name : '-'}</span>
                                            </div>
                                        </div>

                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2">   {t('staffworker.list.phone_number')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.staff_phone ? dataDetail?.staff_phone : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2"> {t('staffworker.detail.email')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.user_email ? dataDetail?.user_email : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', 'col')}>
                                                {t('staffworker.detail.usage_status')}:{' '}
                                                <span className={'fw-bold ' + cxCommon('text-positive')}>
                                                    {dataDetail?.user_account_status === 2 ? (
                                                        <span className={cxCommon('text-red')}>{t('staffworker.list.stop')}</span>
                                                    ) : (
                                                        <span className={cxCommon('text-green')}>{t('staffworker.list.using')}</span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2">{t('staffworker.detail.created_date')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.created_at
                                                    ? moment.utc(dataDetail?.created_at).format('YYYY/MM/DD HH:mm')
                                                    : '-'}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 pt-1">
                                            <div className={cxCommon('bases__text--label', ' d-flex ')}>
                                                <span className="h-auto me-2">{t('staffworker.list.usage_start_date')}:</span>
                                                <span className={'pe-0 col fw-bold'}>{dataDetail?.user_first_login
                                                    ? moment.utc(dataDetail?.start_used_at).format('YYYY/MM/DD HH:mm')
                                                    : '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-column d-flex justify-content-end">
                                <Button
                                    onClick={() => handleResetPwd()}
                                    className={cx('btn_reset')}
                                    text="staffworker.list.reset_password_one"
                                />

                                <span className={cxCommon('text-red', 'font-size-12', 'bases__margin--top10')}>
                                    {t('staffworker.list.noti')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 h-auto">
                        <div className={cx('content', 'height-content h-100 ')}>
                            <div className="row">
                                <div className={cxCommon('bases__text--label', 'col')}>
                                    {t('staffworker.detail.property_limit')}:{' '}
                                    <span className={'fw-bold'}>
                                        {dataDetail?.exclude_assets.length > 0
                                            ? t('staffworker.register.restrict')
                                            : t('staffworker.register.no_limit')}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-3 pt-1">
                                <div className={cxCommon('bases__text--label', 'col')}>
                                    {t('staffworker.detail.usage_right')}:{' '}
                                    <span className={'fw-bold'}>
                                        {dataDetail?.permission === 4 ? (
                                            <span className={cxCommon('text-blue')}>{t('staffworker.register.use_only')}</span>
                                        ) : (
                                            <span className={cxCommon('text-purple')}>{t('staffworker.register.can_be_installed')}</span>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="row mt-3 pt-1">
                                <div className={cxCommon('bases__text--label', 'col')}>
                                    {t('staffworker.detail.number_of_uses')}:{' '}
                                    <span className={'fw-bold'}>{dataDetail?.usage_keybox_amount}</span>
                                </div>
                            </div>
                            <div className="row mt-3 pt-1">
                                <div className="col-12">
                                    <div className={'d-flex ' + cx('boxBtn')}>
                                        <div
                                            onClick={() => handleChangeList(0)}
                                            className={cx('btnUser', 'text-center', statusListAsset === 0 ? cx('btnActive') : '')}>
                                            {t('staffworker.detail.include_asset')}
                                        </div>
                                        <div
                                            onClick={() => handleChangeList(1)}
                                            className={cx('btnUser', 'text-center', 'ms-1', statusListAsset === 1 ? cx('btnActive') : '')}>
                                            {t('staffworker.detail.exclude_asset')}
                                        </div>
                                    </div>
                                </div>
                                <div className={cxCommon('bases__height-min--421')}>
                                    {dataAssets.length ? (
                                        <DataTable
                                            styleTable={{ maxHeight: checkHeight ? `${ref?.current?.offsetHeight}px` : `${height}px` }}
                                            classNameTable={'overflow-auto'}
                                            isStickyColumn
                                            heads={table.heads}
                                            body={table.body}
                                            isHidePagination={true}
                                            total={asset.listAsset?.data?.length}
                                            pageData={{
                                                totalRecords: asset.listAsset?.data?.length,
                                            }}
                                        />
                                    ) : (
                                        <div
                                            className={`d-flex justify-content-center ${cxCommon(
                                                'font-size-16',
                                            )} components__table-no-data`}>
                                            {t('common.no_data')}
                                        </div>
                                    )}

                                    {dataAssets.length > 0 && (
                                        <div className="mt-2">
                                            <span className={cxCommon('text-color-blue-custom', 'bases__padding0', 'font-size-12')}>
                                                {dataAssets && dataAssets.length}
                                                {t('staffworker.detail.subject')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row w-100 mt-3">
                    <div className="col-6 d-flex justify-content-end">
                        <Button
                            onClick={() => navigate(`${Configs.routes.employeeWorker}/${id}/edit`)}
                            text={'common.edit'}
                            className={cxCommon('bases__width--170')}
                            search></Button>
                    </div>
                    <div className="col-6 d-flex-justify-content-end">
                        <Button
                            onClick={() => handleDelete()}
                            text={'common.remove'}
                            className={cxCommon('bases__width--170', 'bases__text--red')}
                            del></Button>
                    </div>
                </div>
            </div>
        </BodyContainer>
    );
};

export default EmployeeWorkerDetails;
