import commonStyles from '@assets/styles/base/Common.module.scss';
import ModalInterface from '@components/commons/Modal';
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import configs from '~/utils/configs';
import { images } from '~/utils/constants';
import { fetchAddBrokerToCompany } from '~/utils/helpers/brokerApi';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

type IModalBrokerDetailProps = Pick<IModalComponentProps, 'isShow' | 'handleClose' | 'handleOK' | 'hidePositiveButton' | 'buttonGreen'> & {
    dataInit: IDataApiIntermediariesRes | null;
    typeBroker?: 'brokerRegistered' | 'brokerAll';
    isSuperAdmin?: boolean
};

const BrokerDetailModal: IModalComponent<IModalBrokerDetailProps> = (props) => {
    const { isShow, handleClose, handleOK, dataInit, hidePositiveButton, buttonGreen, typeBroker, isSuperAdmin } = props;
    const [isShowConfirm, setIsShowConfirm] = useState<boolean>(false);
    const [responseAddBroker, setResponseAddBroker] = useState<IBaseAPIRes>();

    const isRegistered = useMemo(() => !!(dataInit?.is_broker_registered), [dataInit]);
    const isAutoApprove = useMemo(() => !!(Number(dataInit?.auto_approve_status)), [dataInit]);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOKConfirm = async () => {
        if (dataInit?.id) {
            const response = await fetchAddBrokerToCompany(dataInit?.id);
            setResponseAddBroker(response);
        }
    };

    useEffect(() => {
        if (responseAddBroker?.status === 'SUCCESS') {
            setIsShowConfirm(false);
            handleOK();
            navigate(configs.routes.broker);
        }
    }, [responseAddBroker]);

    return (
        <ModalInterface
            dialogClassname={cx('content-width', 'modal-fixed')}
            disableBtnOk={isRegistered}
            modalHeader={typeBroker === 'brokerRegistered' ? t('staffworker.detail.title_broker') : t('modal.broker.detail.header')}
            isShow={isShow}
            buttonGreen={buttonGreen}
            handleOK={() => setIsShowConfirm(true)}
            handleClose={handleClose}
            textBtn={'modal.broker.detail.btnContent'}
            hidePositiveButton={hidePositiveButton}
            negativeButtonText="common.close"
            classNameHeader="bases__margin--top24"
            classNameBody="pt-0">
            <div className={cxCommon('label-color')}>
                <div className="row mt-3 ms-2">
                    <img
                        style={{ width: '250px', margin: 'auto', objectFit: 'contain', maxHeight: '250px' }}
                        src={dataInit?.avatar ? dataInit.avatar : images.IMAGES.NO_IMAGE_THREE}></img>
                </div>
                <div className="row mt-3 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.user')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.name || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.email')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.email || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.phone_number')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.phone || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.brokerage_company_name')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.broker_name || '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.storename')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.store_name || '-'}</div>
                </div>
                {typeBroker === 'brokerRegistered' && <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('staffworker.list.usage_start_date')}</div>
                    <div className="col fw-bold pe-4">
                        {dataInit?.usage_time || '-'}
                    </div>
                </div>}
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('intermediaries.created_date')}</div>
                    <div className="col fw-bold pe-4">
                        {dataInit?.created_time || '-'}
                    </div>
                </div>
                {
                    !isSuperAdmin &&
                    <div className="row mt-2 ms-2">
                        <div className="bases__width-percent--30">{typeBroker === 'brokerRegistered' ? t('staffworker.list.auto_approve_status') : t('intermediaries.registration_status')}</div>
                        {typeBroker === 'brokerAll' &&
                            <div className={cxCommon(isRegistered ? 'text-green' : 'text-red', 'col fw-bold')}>
                                {isRegistered ? t('common.registered') : t('common.non_registered')}
                            </div>
                        }
                        {typeBroker === 'brokerRegistered' &&
                            <div className={cxCommon(isAutoApprove ? 'text-green' : 'text-red', 'col fw-bold')}>
                                {isAutoApprove ? t('staffworker.list.auto_approve') : t('staffworker.list.not_auto_approve')}
                            </div>
                        }
                    </div>
                }
            </div>
            {isShowConfirm && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        dialogClassname={cx('content-width')}
                        isShow={isShowConfirm}
                        handleClose={() => setIsShowConfirm(false)}
                        handleOK={handleOKConfirm}
                        positiveButtonText="common.ok"
                        header={t<string>('common.confirm')}
                        content={t<string>('intermediaries.modal_confirm_content')}></ModalConfirm>
                </>
            )}
        </ModalInterface>
    );
};

BrokerDetailModal.defaultProps = {
    isShow: false,
};

export default BrokerDetailModal;
