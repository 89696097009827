import { Common } from '@assets/styles';
import { images } from '@utils/constants';
import { formatDate } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewType } from '../entrance/oneTimePassword/OneTimePassword';
import styles from './Dashboard.module.scss';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

export enum TYPE_NOTIFICATION {
    REQUEST_DETAIL = 8,
    APPROVE_DETAIL = 9,
    REJECT_DETAIL = 10,
    OTP_CREATE = 11,
    OTP_DELETED = 12,
    OTP_EDITED = 13,
    SYSTEM = 0,
    USER = 1,
}

const NotificationDetail: IDashboardNotificationDetailPage<IDashboardNotificationDetailProps> = (props) => {
    const { data } = props;
    const { t } = useTranslation();

    const roomNumber: string | undefined = useMemo(() => {
        if (isEmpty(data?.roomNumber)) return '-';
        if (data?.roomNumber) return data.roomNumber.join('・');
    }, [data?.roomNumber]);

    if (!data) return <></>;

    return (
        <div className={cx('d-flex flex-column gap-2 bases__text--label', 'notification__detail')}>
            <div className="bases__text--font16 fw-bold">
                {(data.type === TYPE_NOTIFICATION.SYSTEM || data.type === TYPE_NOTIFICATION.USER) && data.subject}
                {data.type === TYPE_NOTIFICATION.REQUEST_DETAIL && (
                    <>
                        {t('dashboard.notification.detail_title.request_app_title')}
                        {/*<span style={{ color: '#51a60e' }}>{t('dashboard.notification.detail_title.request_2')}</span>*/}
                        {/*{t('dashboard.notification.detail_title.request_app_detail_2')}*/}
                    </>
                )}
                {data.type === TYPE_NOTIFICATION.APPROVE_DETAIL && (
                    <>
                        {t('dashboard.notification.detail_title.request_1')}
                        <span style={{ color: '#51a60e' }}>{t('dashboard.notification.detail_title.request_2')}</span>
                        {t('dashboard.notification.detail_title.request_3')}
                    </>
                )}
                {( data.type === TYPE_NOTIFICATION.REJECT_DETAIL) && (
                    <span>
                        {t('dashboard.notification.detail_title.request_status_1')}
                        {/*{data.type === TYPE_NOTIFICATION.APPROVE_DETAIL && <span className={cxCommon('text-green')}>「承認」</span>}*/}
                        {data.type === TYPE_NOTIFICATION.REJECT_DETAIL && <span className={cxCommon('text-red')}>「拒否」</span>}
                        {t('dashboard.notification.detail_title.request_status_2')}
                    </span>
                )}
                {data.type === TYPE_NOTIFICATION.OTP_CREATE && t('dashboard.notification.detail_title.create_opt')}
                {data.type === TYPE_NOTIFICATION.OTP_DELETED && t('dashboard.notification.detail_title.delete_otp')}
                {data.type === TYPE_NOTIFICATION.OTP_EDITED && t('dashboard.notification.detail_title.edit_otp')}
            </div>

            {data.type === TYPE_NOTIFICATION.SYSTEM || data.type === TYPE_NOTIFICATION.USER ? (
                <Fragment>
                    <div className="d-flex align-items-center my-2">
                        <img className="me-2" src={images.IMAGES.ICON_CALENDERCLOCK} />
                        {`${data.startDate || ''}${data.endDate ? ' ~ ' + data.endDate : ''}`}
                    </div>
                    <div className="text-pre-wrap">{data.content || '-'}</div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="d-flex align-items-center my-2">
                        <img className="me-2" src={images.IMAGES.ICON_CALENDERCLOCK} />
                        {data.createdAt || ''}
                    </div>
                    {data.type === TYPE_NOTIFICATION.REQUEST_DETAIL && (
                        <Fragment>
                            <div>{t('dashboard.notification.detail_des.request_1')}</div>
                            <div>{t('dashboard.notification.detail_des.request_2')}</div>
                        </Fragment>
                    )}
                    {data.type === TYPE_NOTIFICATION.APPROVE_DETAIL && (
                        <Fragment>
                            <div>{t('dashboard.notification.detail_des.auto_request_1')}</div>
                            <div>{t('dashboard.notification.detail_des.auto_request_2')}</div>
                        </Fragment>
                    )}
                    {(data.type === TYPE_NOTIFICATION.OTP_CREATE ||
                        data.type === TYPE_NOTIFICATION.OTP_DELETED ||
                        data.type === TYPE_NOTIFICATION.OTP_EDITED) && (
                        <Fragment>
                            {data.type === TYPE_NOTIFICATION.OTP_CREATE && <div>{t('dashboard.notification.detail_des.otp_create')}</div>}
                            {data.type === TYPE_NOTIFICATION.OTP_DELETED && <div>{t('dashboard.notification.detail_des.otp_delete')}</div>}
                            {data.type === TYPE_NOTIFICATION.OTP_EDITED && <div>{t('dashboard.notification.detail_des.otp_edit')}</div>}
                            <div>{t('dashboard.notification.detail_des.otp_2')}</div>
                        </Fragment>
                    )}
                    <div>
                        {t('dashboard.notification.label.broker_name')}
                        <span className="fw-bold">{data.brokerName || '-'}</span>
                    </div>
                    <div>
                        {t('dashboard.notification.label.broker_phone')}
                        <span className="fw-bold">{data.brokerPhone || '-'}</span>
                    </div>
                    <div>
                        {t('dashboard.notification.label.asset_name')}
                        <span className="fw-bold">{data.assetName || '-'}</span>
                    </div>
                    <div>
                        {t('dashboard.notification.label.address')}
                        <span className="fw-bold">{data.address || '-'}</span>
                    </div>
                    <div>
                        {t('dashboard.notification.label.room_number')}
                        <span className="fw-bold">{roomNumber || '-'}</span>
                    </div>
                    {(data.type === TYPE_NOTIFICATION.REQUEST_DETAIL ||
                        data.type === TYPE_NOTIFICATION.APPROVE_DETAIL ||
                        data.type === TYPE_NOTIFICATION.REJECT_DETAIL) && (
                        <div>
                            {t('dashboard.notification.label.date_noti')}
                            <span className="fw-bold">{`${data.startDate || ''} ~ ${formatDate(data.endDate || '', 'HH:mm')}`}</span>
                        </div>
                    )}
                    {data.type === TYPE_NOTIFICATION.REJECT_DETAIL && (
                        <Fragment>
                            <div className={cxCommon('text-red')}>拒否</div>
                            <div className={cx('reject__reason')}>{data.message || '-'}</div>
                        </Fragment>
                    )}
                    {(data.type === TYPE_NOTIFICATION.OTP_CREATE ||
                        data.type === TYPE_NOTIFICATION.OTP_DELETED ||
                        data.type === TYPE_NOTIFICATION.OTP_EDITED) && (
                        <Fragment>
                            <div>
                                {t('dashboard.notification.label.start_date')}
                                <span className="fw-bold">{data.startDate || '-'}</span>
                            </div>
                            <div>
                                {t('dashboard.notification.label.end_date')}
                                <span className="fw-bold">{data.endDate || '-'}</span>
                            </div>
                            <div>
                                {t('dashboard.notification.label.store_name')}
                                <span className="fw-bold">{data.storeName || '-'}</span>
                            </div>
                            <div>
                                {t('dashboard.notification.label.receptionist')}
                                <span className="fw-bold">{data.receptionist || '-'}</span>
                            </div>
                            <div>
                                {t('dashboard.notification.label.person_in_charge')}
                                <span className="fw-bold">{data.personInCharge || '-'}</span>
                            </div>
                            <div>
                                {t('dashboard.notification.label.preview_type')}
                                <span className="fw-bold">
                                    {t(
                                        data?.previewType === PreviewType.SEE_BY_YOURSELF
                                            ? 'oneTimePassword.self_preview'
                                            : data?.previewType === PreviewType.WITH_STAFF
                                            ? 'oneTimePassword.accompanied_by_staff'
                                            : '-',
                                    )}
                                </span>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default NotificationDetail;
