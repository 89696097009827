import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, FormCheckbox, FormRadio, Tooltip } from '@components/commons';
import classNames from 'classnames/bind';
import styles from './SurveyQuestion.module.scss';
import { ButtonStyle, TableStyle, Common } from '@assets/styles';
import { COMMON_STATUS, SURVEY_TYPE, images, pageStateInit } from '@utils/constants';
import { setToast } from '@redux/actions';
import { BodyContainer } from '~/components/layouts';
import { apiHelper } from '@utils/helpers';
import { FormItemType } from '@components/commons/Form/FormRadio';
import { useDispatch } from 'react-redux';
import TablePagination from '@components/commons/Table/TablePagination';
import SurveyQuestionDetailModal from '@components/modals/SurveyQuestionDetailModal';
import SurveyQuestionCreateModal from '@components/modals/SurveyQuestionCreateModal';
import { getSurveyQuestionsDetail } from '@utils/helpers/api';
const cx = classNames.bind(styles);
const cxTable = classNames.bind(TableStyle);
const cxButton = classNames.bind(ButtonStyle);

const cxCommon = classNames.bind(Common);

let surveyPublicClone = '';
const SurveyQuestion: IHistoryPage<IHistoryPageProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [surveyPublicStr, setSurveyPublicStr] = useState<string>('');
    const [surveySelected, setSurveySelected] = useState<ISurveyQuestionDataAPI>();
    const [searchParams, setSearchParams] = useSearchParams({ limit: '20', offset: '0' });
    const [state, setState] = useState<ISurveyQuestionState>({
        dataTable: [],
        surveyPublic: [],
        metadata: undefined,
    });

    const { dataTable, metadata, surveyPublic } = state;
    const [showDetail, setShowDetail] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const refViewTable = useRef(null);
    const refViewSurveyPublic = useRef(null);
    const [surveyPrivated, setSurveyPrivated] = useState<{ [k: number]: 1 }>({});

    useEffect(() => {
        if (showDetail || showCreate) {
            window.addEventListener('popstate', (event) => {
                setShowDetail(false);
                setShowCreate(false);
            });
        }
    }, [showDetail, showCreate]);

    const limitPage = '20';

    const surveyQuestionMemo = useMemo<ISurveyQuestionDataAPI[]>(() => {
        return dataTable as ISurveyQuestionDataAPI[];
    }, [dataTable]);

    const surveyPublicMemo = useMemo<ISurveyQuestionDataAPI[]>(() => {
        return surveyPublic as ISurveyQuestionDataAPI[];
    }, [surveyPublic]);

    useEffect(() => {
        const init = async () => {
            const paramSurveyPublic = await fetchSurveyQuestionsPublic();
            fetchSurveyQuestions(paramSurveyPublic);
        };
        init();
    }, []);

    useEffect(() => {
        fetchSurveyQuestions();
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.has('question_id')) {
            handleFetchDetailQuestion(Number(searchParams.get('question_id')));
        }
    }, []);

    useEffect(() => {
        if (!metadata) {
            return;
        }
        const limit = metadata?.limit;
        const offset = metadata?.offset;
        const count = metadata?.count;
        const total = metadata?.total;

        setPageState({
            firstRecords: offset * limit + 1,
            currentPage: offset + 1,
            totalPage: Math.ceil(total / limit),
            pageRecords: count + offset * limit,
            totalRecords: total,
        });
    }, [metadata]);

    const fetchSurveyQuestionsPublic = async () => {
        try {
            const res = await apiHelper.getSurveyQuestionsPublic();
            if (res?.data) {
                const data = res?.data as IRes;

                if (data.status === 'SUCCESS') {
                    setState((prevState) => ({
                        ...prevState,
                        surveyPublic: data.data ?? [],
                    }));

                    surveyPublicClone = data.data
                        .map((e: ISurveyQuestionDataAPI) => e.id)
                        .sort()
                        .join('_');

                    setSurveyPublicStr(surveyPublicClone);

                    return data.data;
                }
            }
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const fetchSurveyQuestions = async (paramSurveyPublic?: ISurveyQuestionDataAPI[]) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        try {
            const res = await apiHelper.getSurveyQuestions(searchParamsObject ?? {});
            if (res?.data) {
                const data = res?.data as IRes;
                if (data.status === 'SUCCESS') {
                    const d = (data.data ?? []) as ISurveyQuestionDataAPI[];
                    const survey = paramSurveyPublic ?? surveyPublic;
                    for (const iterator of d) {
                        if (survey && Array.isArray(survey)) {
                            const obj = survey.find((e) => e.id === iterator.id);
                            if (obj) {
                                iterator.isPublic = COMMON_STATUS.PUBLIC;
                            } else {
                                iterator.isPublic = COMMON_STATUS.PRIVATE;
                            }
                        } else {
                            iterator.isPublic = COMMON_STATUS.PRIVATE;
                        }

                        if (surveyPrivated[Number(iterator.id)]) {
                            iterator.isPublic = COMMON_STATUS.PRIVATE;
                        }
                    }

                    setState((prevState) => ({
                        ...prevState,
                        dataTable: d,
                        metadata: data.metadata ?? [],
                    }));
                }

                if (refViewTable.current) {
                    const dom: HTMLElement = refViewTable.current;
                    dom?.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleFetchDetailQuestion = async (id: number) => {
        try {
            const response = await getSurveyQuestionsDetail(id);
            if (response.data.status === 'SUCCESS') {
                setSurveySelected(response.data.data);
                setShowDetail(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: limitPage,
        });
    };

    const onChangeApprove = (index: number, isPublic: number) => {
        if (isPublic === COMMON_STATUS.PUBLIC && surveyPublicMemo.length >= 5) {
            return;
        }

        if (dataTable && dataTable[index] && dataTable[index].isPublic !== isPublic) {
            dataTable[index].isPublic = isPublic;

            const surveys = surveyPublic;
            if (isPublic === COMMON_STATUS.PRIVATE) {
                const idx = surveyPublic?.findIndex((e) => e.id === dataTable[index].id);
                if (Number(idx) > -1) {
                    surveys?.splice(Number(idx), 1);
                    setSurveyPrivated((oldState) => ({ ...oldState, [Number(dataTable[index].id)]: 1 }));
                }
            } else {
                surveys?.push(dataTable[index]);
                delete surveyPrivated[Number(dataTable[index].id)];
                setSurveyPrivated((oldState) => {
                    delete oldState[Number(dataTable[index].id)];
                    return oldState;
                });

                setTimeout(() => {
                    if (refViewSurveyPublic.current) {
                        const dom: HTMLElement = refViewSurveyPublic.current;
                        dom?.scrollTo({ top: 10000, behavior: 'smooth' });
                    }
                }, 200);
            }

            setSurveyPublicStr(
                surveys
                    ?.map((e) => e.id)
                    .sort()
                    .join('_') || '',
            );

            setState((prevState) => ({
                ...prevState,
                dataTable: dataTable,
                surveyPublic: surveys,
            }));
        }
    };

    const onRemoveSurveyPublic = (item: ISurveyQuestionDataAPI, index: number) => {
        const idx = dataTable?.findIndex((e) => e.id === item.id);
        if (idx != null && idx > -1 && dataTable) {
            dataTable[idx].isPublic = COMMON_STATUS.PRIVATE;
        }

        surveyPublic?.splice(index, 1);
        setSurveyPrivated((oldState) => ({ ...oldState, [Number(item.id)]: 1 }));

        setSurveyPublicStr(
            surveyPublic
                ?.map((e) => e.id)
                .sort()
                .join('_') || '',
        );

        setState((prevState) => ({
            ...prevState,
            dataTable: dataTable,
            surveyPublic: surveyPublic,
        }));
    };

    const onSubmit = async () => {
        if (surveyPublicStr == surveyPublicClone || surveyPublicMemo.length > 5 || surveyPublicMemo.length == 0) {
            return;
        }

        try {
            const id = surveyPublicMemo?.map((e) => e.id) as number[];
            const res = await apiHelper.putSurveyQuestionsPublic(id);
            if (res?.data) {
                const data = res?.data as IRes;
                if (data.status === 'SUCCESS') {
                    setSurveyPublicStr(surveyPublicClone),
                        dispatch(
                            setToast({
                                isShow: true,
                                message: `${t('common.save_data')}`,
                            }),
                        );
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const _renderLeftModule = (): JSX.Element => (
        <div className={`d-flex flex-column h-auto ${cx('f-1')} me-2 w-100`}>
            <div className={cx('text-title')}>{t('survey_question.question_list')}</div>
            <div className={`${cx('content')} h-100 ${cx('mx-height-29')} ${cx('f-1')}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <Button
                            className={cx('btn-add-survey')}
                            onClick={() => {
                                setShowCreate(true);
                                const searchParamsObject = Object.fromEntries(searchParams.entries());
                                setSearchParams({
                                    ...searchParamsObject,
                                    action: 'create',
                                });
                            }}>
                            {t('survey_question.button.save_questionnaire')}
                        </Button>
                    </div>
                </div>
                <div className="col-12">
                    {!surveyQuestionMemo || surveyQuestionMemo.length === 0 ? (
                        <div
                            className={`mt-3 d-flex justify-content-center ${cxCommon('font-size-16')} ${cxTable(
                                'custom-table',
                            )} components__table-no-data`}>
                            {t('common.no_data')}
                        </div>
                    ) : (
                        <div className={cxTable('custom-table')}>
                            <div className={`${cx('view__table')} mt-3`} ref={refViewTable}>
                                <table className={`${cx('table')} table table-bordered`}>
                                    <thead>
                                        <tr className="align-middle">
                                            <th className={cx('w__75')}>{t('survey_question.table.question_number')}</th>
                                            <th className={cx('w__125')}>{t('survey_question.table.creation_date')}</th>
                                            <th className={cx('mw__300')}>{t('survey_question.table.contents_of_question')}</th>
                                            <th className={cx('w__198')}>{t('survey_question.table.status')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {surveyQuestionMemo?.map((item, index) => {
                                            return (
                                                <tr className={'align-middle'} key={item.id}>
                                                    <td className={cx('w__75')}>
                                                        <Tooltip className="gl-tooltip" content={item.id ? `#${item.order}` : '-'}>
                                                            <div className={cx('txt-overflow__ellipsis')}>
                                                                {item.id ? `#${item.id}` : '-'}
                                                            </div>
                                                        </Tooltip>
                                                    </td>
                                                    <td className={cx('w__125')}>
                                                        <div className={cx('txt-overflow__ellipsis')}>
                                                            <Tooltip className="gl-tooltip" content={item.createdAt || '-'}>
                                                                <div className={cx('txt-overflow__ellipsis')}>{item.createdAt || '-'}</div>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className={cx('mw__300')}>
                                                        <div className={cx('txt-overflow__ellipsis')}>
                                                            <Tooltip className="gl-tooltip" content={item.content || '-'}>
                                                                <div className={cx('txt-overflow__ellipsis')}>{item.content || '-'}</div>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                    <td className={cx('w__198')}>
                                                        <div className="d-flex">
                                                            <Button
                                                                text={t<string>('survey_question.button.public')}
                                                                disabled={
                                                                    surveyPublicMemo.length >= 5 && item.isPublic === COMMON_STATUS.PRIVATE
                                                                }
                                                                del
                                                                className={`me-1 ${cx('btn-table')} ${
                                                                    item.isPublic ? cxButton('positive-button') : ''
                                                                }`}
                                                                onClick={() => onChangeApprove(index, COMMON_STATUS.PUBLIC)}
                                                            />
                                                            <Button
                                                                text={t<string>('survey_question.button.private')}
                                                                del
                                                                disabled={
                                                                    surveyPublicMemo.length >= 5 && item.isPublic === COMMON_STATUS.PRIVATE
                                                                }
                                                                className={`ms-1 me-1 ${cx('btn-table')} ${
                                                                    !item.isPublic ? cxButton('negative-button') : ''
                                                                }`}
                                                                onClick={() => onChangeApprove(index, COMMON_STATUS.PRIVATE)}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    handleFetchDetailQuestion(item?.id);
                                                                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                                                                    setSearchParams({
                                                                        ...searchParamsObject,
                                                                        question_id: item?.id.toString(),
                                                                    });
                                                                }}
                                                                className={`ms-1 ${cx('btn-table')} ${cx('btn-table-detail')}`}
                                                                text={t<string>('survey_question.button.details')}>
                                                                <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3 pt-1">
                                <TablePagination
                                    current={pageState?.currentPage}
                                    onPageChange={(page: number) => handleChangePage(page)}
                                    totalPage={pageState.totalPage}
                                    totalRecords={pageState?.totalRecords}
                                    pageRecords={pageState?.pageRecords}
                                    firstRecords={pageState?.firstRecords}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const _renderRightModule = (): JSX.Element => (
        <div className={`d-flex flex-column h-auto ${cx('f-1')} ms-2`}>
            <div className={cx('text-title')}>{t('survey_question.questionnaire_list')}</div>
            <div className={`${cx('content')} ${cx('mx-height-29')} h-100 d-flex flex-column pt-2`}>
                <div className={`d-flex justify-content-end  ${cx('title-survey-public')}`}>{`${t('survey_question.survey_questions')} ${
                    surveyPublicMemo.length
                }/5`}</div>
                {!surveyPublicMemo || surveyPublicMemo.length === 0 ? (
                    <div
                        className={`mt-3 d-flex justify-content-center ${cxCommon('font-size-16')} ${cxTable(
                            'custom-table',
                        )} components__table-no-data`}>
                        {t('common.no_data')}
                    </div>
                ) : (
                    <>
                        <div className={`${cx('f-1')} ${cx('ol-y')}`} ref={refViewSurveyPublic}>
                            {surveyPublicMemo?.map((item, index) => (
                                <div className={`d-flex flex-column ${index > 0 ? 'mt-2' : ''}`} key={`${index}`}>
                                    <span className={'fw-bold mb-1'}>{`${t('survey_question.question_number')}: #${item.id}`}</span>
                                    <div className={cx('survey-public-content')}>
                                        <div className={'d-flex justify-content-between'}>
                                            <span className="fw-bold">{`${index + 1}. ${item.content}`}</span>
                                            <img
                                                src={images.IMAGES.ICON_CLOSE_NEW}
                                                className={cx('btn-delete')}
                                                onClick={() => onRemoveSurveyPublic(item, index)}
                                            />
                                        </div>
                                        <div className={`${cxCommon('bases__margin--left10')}`}>
                                            {item.type == SURVEY_TYPE.RADIO ? (
                                                <FormRadio
                                                    disabledOnlyIcon
                                                    styleBlock
                                                    defaultCheckedIndex={0}
                                                    classNameInput="size_radio--20"
                                                    classNameContent="w-100"
                                                    items={
                                                        item.options?.map((e, index) => {
                                                            return {
                                                                value: index + 1,
                                                                label: e.content,
                                                                key: `${index}`,
                                                            } as FormItemType;
                                                        }) ?? []
                                                    }
                                                />
                                            ) : (
                                                <FormCheckbox
                                                    classNameContent="d-flex flex-column"
                                                    classNameInput="size_checkbox--18"
                                                    classNameGroupItem="d-flex align-items-center"
                                                    readOnly
                                                    items={
                                                        item.options?.map((e, index) => {
                                                            return {
                                                                value: index + 1,
                                                                label: e.content,
                                                                key: `${index}`,
                                                            } as FormItemType;
                                                        }) ?? []
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={cxCommon('bases__margin--top20')}>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <Button
                                    onClick={() => onSubmit()}
                                    disabled={surveyPublicStr == surveyPublicClone || surveyPublicMemo.length <= 0}
                                    text={t<string>('survey_question.button.save_questionnaire_1')}
                                    className={`${cxButton('button-non-min-width')} ${cx('btn-submit')}`}
                                    search></Button>
                                <span className={cx('txt-note')}>{t('survey_question.note')}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <BodyContainer title={t('breadcrumb.survey_question.title')} classNameBody={cx('body')}>
            <div className={`position-relative w-100 d-flex ${cx('container')}`}>
                <div className="d-flex w-100">
                    {_renderLeftModule()}
                    {_renderRightModule()}
                </div>
            </div>
            {showDetail && (
                <SurveyQuestionDetailModal
                    isShow={showDetail}
                    handleClose={() => {
                        setShowDetail(false);
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        delete searchParamsObject.question_id;
                        setSearchParams(searchParamsObject);
                    }}
                    data={surveySelected}
                />
            )}
            {showCreate && (
                <SurveyQuestionCreateModal
                    isShow={showCreate}
                    handleClose={() => {
                        setShowCreate(false);
                        searchParams.delete('action');
                        setSearchParams(searchParams);
                    }}
                    handleOK={() => {
                        setSearchParams({
                            ...searchParams,
                            offset: '0',
                            limit: limitPage,
                        });
                        fetchSurveyQuestions();
                    }}
                />
            )}
        </BodyContainer>
    );
};

export default SurveyQuestion;
