import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReduxStates } from '@redux/reducers';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { Breadcrumb, Header, Sidebar } from '@components/layouts';
import Button from '@components/commons/Button/Button';
import { images } from '@utils/constants';
import { useEffect, useState } from 'react';
import { useAuth } from '~/utils/hook/useAuth';
import Configs from '~/utils/configs';
import {
    setShowPopupAccessTokenExpired,
    setCheckAccessTokenExpired,
    setShowNotFound,
    setCheckAccountHasBeenDeleted,
    setActionPopupAccountDeleted,
    setSuccessCallAPI,
} from '~/redux/actions/CommonAction';
import { t } from 'i18next';
import { fetchUserInfo } from '@utils/helpers/userApi';

const SHOW_TOP_OFFSET = 125;
const cx = classNames.bind(styles);

const Home: IHomePage<IHomePageProps> = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { loader, sidebar, checkToken, showNotFound, checkActionAccountExist, successCallAPI } = useSelector(
        (states: ReduxStates) => states,
    );
    const [isShowBtnTop, setIsShowBtnTop] = useState(false);
    const [isBack, setIsBack] = useState(false);
    const { id, adminId } = useParams();
    const { alert } = useSelector((states: ReduxStates) => states);

    useEffect(() => {
        if (isBack) {
            if (successCallAPI) {
                setTimeout(() => {
                    document.body.style.height = 'auto';
                    setIsBack(false);
                }, 1000);
            } else if (!successCallAPI) {
                setTimeout(() => {
                    document.body.style.height = 'auto';
                    setIsBack(false);
                }, 2000);
            }
        }
    }, [isBack, successCallAPI]);

    useEffect(() => {
        auth.signInWithToken((response, err) => {
            if (response == null || err) {
                navigate(Configs.routes.login);
            }
        });
        fetchUserInfo();
        handleOnScroll();
    }, []);

    useEffect(() => {
        if (showNotFound) {
            navigate(Configs.routes.notFound);
            dispatch(setShowNotFound(false));
        }
    }, [showNotFound]);

    useEffect(() => {
        if (!isBack) {
            handleScrollToTop();
        }
    }, [location.pathname]);

    useEffect(() => {
        setIsShowBtnTop(false);
        dispatch(setSuccessCallAPI(false));
    }, [location.pathname]);

    useEffect(() => {
        if (checkToken) {
            auth.signOut(() => {
                dispatch(setCheckAccessTokenExpired(false));
                localStorage.clear();
                navigate(Configs.routes.login);
                dispatch(setShowPopupAccessTokenExpired(true));
            });
        }
    }, [checkToken]);

    useEffect(() => {
        if (checkActionAccountExist) {
            auth.signOut(() => {
                dispatch(setCheckAccountHasBeenDeleted(false));
                localStorage.clear();
                navigate(Configs.routes.login);
                dispatch(setActionPopupAccountDeleted(false));
            });
        }
    }, [checkActionAccountExist]);

    const handleOnScroll = () => {
        window.onscroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop >= SHOW_TOP_OFFSET) {
                setIsShowBtnTop(true);
            } else {
                setIsShowBtnTop(false);
            }
        };
    };

    const handleScrollToTop = () => {
        window?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //FormRoutes
    const publicRoutes = [
        { path: Configs.routes.employeeWorkerRegister },
        { path: Configs.routes.employeeWorkerEdit.replace(':id', `${id}`) },
        { path: Configs.routes.companyRegister },
        { path: Configs.routes.companyEdit.replace(':id', `${id}`).replace(':adminId', `${adminId}`) },
        { path: Configs.routes.notificationRegister },
        { path: Configs.routes.newQuestion },
        // { path: Configs.routes.oneTimePasswordResgister }
    ];

    useEffect(() => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = () => {
            if (!alert.isShow) {
                window.onpopstate = null;
                navigate(-1);
                setIsBack(true);
                document.body.style.height = '4000px';
            } else {
                window.history.pushState(null, '', window.location.href);
            }
        };
    }, [alert.isShow]);

    useEffect(() => {
        window.addEventListener('popstate', () => {
            setIsBack(true);
            document.body.style.height = '4000px';
        });
    }, []);

    useEffect(() => {
        publicRoutes.map((item) => {
            if (location?.pathname === item.path) {
                window.addEventListener('beforeunload', (ev) => {
                    ev.preventDefault();
                    return (ev.returnValue = 'Are you sure you want to close?');
                });
            }
        });
    }, []);

    return (
        <>
            <div className={cx('container-home', loader ? 'loader' : '')}>
                <Sidebar />
                <div className={cx('wrapper', sidebar ? '' : 'sidebar--close')}>
                    <Header />
                    <div className={cx('wrapper__body')}>
                        <div className={cx('content')}>
                            <Breadcrumb />
                            <Outlet />
                        </div>
                        <div className={cx('footer')}>Copyright © 2022 GUARD LOCK. All Rights Reserved.</div>
                    </div>
                </div>
            </div>
            {isShowBtnTop && (
                <Button
                    classNameImg="bases__margin--right0"
                    image={images.IMAGES.ICON_TOP}
                    text={t<string>('common.toTop')}
                    top
                    onClick={handleScrollToTop}
                />
            )}
        </>
    );
};

export default Home;
