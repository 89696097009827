import { apiHelper } from '~/utils/helpers';
import { AxiosError, AxiosResponse } from 'axios';


export const getListAccountCompany = async (params?: ICompanyAccountSearchParams, callback: (result: IListCompanyRes | IErrorAPIRes | null) => void = () => undefined) => {
    try {
        const res = await apiHelper.getListAccountCompany(params ??{});
        if (res?.data) {
            callback(res?.data)
        }
    } catch (err) {
        if (!(err instanceof Error)) {
            const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
            if (res.data) {
                callback(res.data);
            }
        }
    }
};