import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput } from '@components/commons';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import DataTable from '@components/commons/Table/Table';
import { SearchForm } from '@components/forms';
import { BodyContainer } from '@components/layouts';
import NotificationDetailModal from '@components/modals/NotificationDetailModal';
import { setToast, showAlert } from '@redux/actions';
import Configs from '@utils/configs';
import { images, pageStateInit } from '@utils/constants';
import { dateHelper } from '@utils/helpers';
import { deleteNotification, getDetailNotification } from '@utils/helpers/api';
import { fetchGetListNotification } from '@utils/helpers/notificationApi';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import styles from './NotificationList.module.scss';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const initialValueSearch: SearchParamsNotification = {
    created_from: '',
    created_to: '',
    start_date_from: '',
    start_date_to: '',
    creator: '',
    subject: '',
    type: '',
    offset: 0,
    limit: 30,
};

const NotificationList: IAppointmentPage<IAppointmentPageProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { listAll } = useSelector((states: ReduxStates) => states);

    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [data, setData] = useState<INotificationList[]>([]);
    const [searchParams, setSearchParams] = useSearchParams(initialValueSearch as URLSearchParamsInit);
    const tableRef = createRef<ITableComponentHandle>();
    const [isClearTable, setIsClearTable] = useState(false);
    const [listCheckbox, setListCheckbox] = useState<string[]>([]);
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [dataInitDetail, setDataInitDetail] = useState<IDataNotificationDetail | null>(null);

    const dataTable = useMemo(() => {
        const dataRow: any = data.map((item) => {
            return {
                ...item,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${item.id}`,
                                value: item.id.toString(),
                            },
                        ],
                    },
                ],
                created_at: item?.createdAt ?? '',
                creator: item.creator,
                start_date: item?.startDate ?? '',
                end_date: item?.endDate ?? '',
                type: (
                    <span>
                        {t<string>(item.type ? 'notification.notificationTypeValue.user' : 'notification.notificationTypeValue.system')}
                    </span>
                ),
                detail: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <Button
                            onClick={() => handleClickDetail(item.id)}
                            className={cxCommon('button-detail')}
                            text={t<string>('common.detail')}>
                            <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                        </Button>
                    </div>
                ),
            };
        });

        const heads = [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
                // isDisable: !isAutoApprove,
            },
            {
                title: t('intermediaries.created_date'),
            },
            {
                title: t('notification.user_registered'),
            },
            {
                title: t('notification.start_date'),
            },
            {
                title: t('notification.end_date'),
            },
            {
                title: t('notification.notificationType'),
            },
            {
                title: t('notification.subject'),
            },
            {
                title: t('notification.content_title'),
            },
            {
                title: t('common.detail'),
            },
        ];

        const body = {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                    // isDisable: !isAutoApprove,
                },
                {
                    field: 'created_at',
                },
                {
                    field: 'creator',
                },
                {
                    field: 'start_date',
                },
                {
                    field: 'end_date',
                },
                {
                    field: 'type',
                },
                {
                    field: 'subject',
                },
                {
                    field: 'content',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: dataRow,
        };

        return {
            heads,
            body,
        };
    }, [data]);

    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);

    useEffect(() => {
        if (isShowDetail) {
            window.addEventListener('popstate', () => {
                setIsShowDetail(false);
            });
        }
    }, [isShowDetail]);

    useEffect(() => {
        const response = listAll.notificationList;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.notificationList]);

    const fetchGetListNotificationAsync = async (searchParams: SearchParamsNotification) => {
        const response = await fetchGetListNotification(searchParams);
        dispatch(setListDataAll({ ...listAll, notificationList: response }));
    };

    useEffect(() => {
        if (searchParams.get('id')) {
            handleClickDetail(Number(searchParams.get('id')));
        }
    }, []);
    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        fetchGetListNotificationAsync(searchParamsObject);
        if (!searchParamsObject.limit) {
            setSearchParams({
                ...searchParamsObject,
                limit: '30',
            });
            fetchGetListNotificationAsync(searchParams as any);
        } else {
            fetchGetListNotificationAsync(searchParamsObject);
        }
    }, [searchParams]);

    const handleClickDetail = (id: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            id: id.toString(),
        });
        getDetailNotification(id).then((res) => {
            setDataInitDetail(res.data.data);
            setIsShowDetail(true);
        });
    };

    const showConfirmDeletePopup = () => {
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: 'common.delete_all',
                handleOK: () => {
                    const listIdDelete: number[] = listCheckbox.map((item) => Number(item));

                    const params: IParamsDeleteNotification = {
                        ids: JSON.stringify(listIdDelete),
                    };
                    deleteNotification(params).then((res) => {
                        if (res.data.status === 'SUCCESS') {
                            dispatch(
                                setToast({
                                    isShow: true,
                                    message: `${t('common.delete_success')}`,
                                }),
                            );
                            fetchGetListNotificationAsync(searchParams as SearchParamsNotification);
                        }
                    });
                    setIsClearTable(true);
                },
            }),
        );
    };

    return (
        <BodyContainer title={t('notification.title')}>
            <NavLink style={{ textDecoration: 'none' }} to={Configs.routes.notificationRegister}>
                <Button text={t<string>('login.signup')} add className="bases__width--170 bases__margin--bottom20" />
            </NavLink>
            <Formik
                initialValues={initialValueSearch}
                enableReinitialize={true}
                onSubmit={(values) => {
                    const newSearchParam = {
                        ...searchParams,
                        ...values,
                    };
                    setSearchParams(newSearchParam);
                    setIsClearTable(true);
                    fetchGetListNotificationAsync(newSearchParam);
                }}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, resetForm } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={resetForm}
                            isDisableBtnDelete={isEqual(initialValueSearch, values)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row  align-items-center bases__height-percent--100">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('staffworker.list.date_time_picker')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.created_to ? new Date(values.created_to) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.created_from ? new Date(values.created_from) : null}
                                                onChange={(value) => setFieldValue('created_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.created_from ? new Date(values.created_from) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.created_to ? new Date(values.created_to) : null}
                                                onChange={(value) => setFieldValue('created_to', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row align-items-center bases__height-percent--100">
                                        <FormInput
                                            maxLength={255}
                                            name="creator"
                                            value={values.creator}
                                            className="col-md"
                                            label="notification.user_registered"
                                            placeholder="notification.user_registered"
                                            onChangeText={(value) => setFieldValue('creator', value)}
                                        />
                                        <FormDropdown
                                            classNameLabel={cx('bases__text--label', 'col-md-4 col-form-label text-end pe-0')}
                                            label="notification.notificationType"
                                            className={cx('pr-22', 'col-6')}
                                            classNameStyle="align-items-xxl-start align-items-center"
                                            name="type"
                                            options={[
                                                { value: '', label: t('notification.notificationTypeValue.all') },
                                                { value: '0', label: t('notification.notificationTypeValue.system') },
                                                { value: '1', label: t('notification.notificationTypeValue.user') },
                                            ]}
                                            value={values.type}
                                            onChangeOption={(option) => setFieldValue('type', option.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row align-items-center bases__height-percent--100">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('notification.start_date_1')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.start_date_to ? new Date(values.start_date_to) : null}
                                                placeholder={t<string>('common.start')}
                                                value={values.start_date_from ? new Date(values.start_date_from) : null}
                                                onChange={(value) => setFieldValue('start_date_from', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.start_date_from ? new Date(values.start_date_from) : null}
                                                placeholder={t<string>('common.end')}
                                                value={values.start_date_to ? new Date(values.start_date_to) : null}
                                                onChange={(value) => setFieldValue('start_date_to', moment(value).format('YYYY/MM/DD'))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row align-items-center bases__height-percent--100">
                                        <FormInput
                                            maxLength={255}
                                            value={values.subject}
                                            className="col-md"
                                            classNameLabel="ps-0"
                                            label="notification.subject"
                                            placeholder="notification.subject"
                                            onChangeText={(value) => setFieldValue('subject', value)}
                                        />
                                        <div className="col-md"></div>
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>
            {!isEmpty(data) && (
                <Button
                    del
                    onClick={showConfirmDeletePopup}
                    text={t<string>('common.remove')}
                    className="mt-4 bases__width--125 text-danger bases__border-red bases__text--red"
                    disabled={isEmpty(listCheckbox)}
                />
            )}
            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={dataTable.heads}
                    body={dataTable.body}
                    pageData={pageState}
                    onChangePage={(page) => {
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        const newSearchParam: SearchParamsNotification = {
                            ...searchParamsObject,
                            offset: page - 1,
                        };
                        setSearchParams(newSearchParam as URLSearchParamsInit);
                        fetchGetListNotificationAsync(newSearchParam);
                    }}
                    onChangeCheckList={(value: string[]) => {
                        setListCheckbox(value);
                    }}
                />
            </div>
            {isShowDetail && (
                <NotificationDetailModal
                    dataInit={dataInitDetail}
                    isShow={isShowDetail}
                    handleClose={() => {
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        delete searchParamsObject.id;
                        setSearchParams(searchParamsObject);
                        setIsShowDetail(false);
                    }}
                    handleCancel={() => {
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        delete searchParamsObject.id;
                        setSearchParams(searchParamsObject);
                        setIsShowDetail(false);
                        setIsClearTable(true);
                        fetchGetListNotificationAsync(searchParamsObject);
                    }}
                />
            )}
        </BodyContainer>
    );
};

export default NotificationList;
