import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormInput } from '@components/commons';
import ModalInterface from '@components/commons/Modal';
import classNames from 'classnames/bind';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RESPONSE_STATUS } from '~/utils/helpers/geocodingApi';
import { enterAddressInitialValues, enterAddressSchema, fetchGetAddressByPostalCode } from '~/utils/schema/enterAddress.form';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

type IModalAddressProps = Pick<IModalComponentProps, 'isShow' | 'handleClose' | 'handleOK'> & {
    dataInit: IEnterAddressForm;
};

const EnterAddressModal: IModalComponent<IModalAddressProps> = (props) => {
    const { isShow, handleClose, handleOK, dataInit } = props;
    const [isShowConfirmCancel, setIsShowConfirmCancel] = useState<boolean>(false);
    const [isShowConfirmClose, setIsShowConfirmClose] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (dataInit) {
            formik.setValues(dataInit);
        }
    }, [dataInit && isShow]);

    const handleSearchAddress = async () => {
        formik.setTouched({ town: true, postalCode: true });
        if (formik.values.postalCode) {
            fetchGetAddressByPostalCode(formik.values.postalCode).then((res) => {
                if (res?.data.status === RESPONSE_STATUS.SUCCESS) {
                    formik.setFieldError('postalCode', undefined);
                    handleChange('prefecture', res.data.data.prefectureKanji);
                    handleChange('city', res.data.data.cityKanji);
                    handleChange('town', res.data.data.townKanji);
                } else if (res?.data.status === RESPONSE_STATUS.FAILURE) {
                    formik.setFieldError(
                        'postalCode',
                        t<string>('message.invalid_format', {
                            0: t<string>('company_info.company_edit.modal.postal_code_label'),
                        }),
                    );
                }
            });
        } else {
            formik.setFieldError(
                'postalCode',
                t<string>('message.required', { 0: t<string>('company_info.company_edit.modal.postal_code_label') }),
            );
        }
    };

    const handleSubmitForm = (values: IEnterAddressForm) => {
        formik.setSubmitting(false);
        handleOK(values);
    };

    const handleChange = (type: string, value: string | number) => {
        formik.setFieldValue(`${type}`, value);

        if (type === 'postalCode') handleClearValueOnChangePostalCode();
    };

    const handleClearValueOnChangePostalCode = () => {
        formik.setFieldValue('prefecture', '');
        formik.setFieldValue('city', '');
    };

    const handleClickSubmit = () => {
        formik.validateForm();
        formik.isValid && formik.handleSubmit();
    };

    const formik = useFormik({
        initialValues: enterAddressInitialValues,
        enableReinitialize: true,
        validationSchema: enterAddressSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => {
            handleSubmitForm(values);
        },
    });

    return (
        <ModalInterface
            modalHeader={t<string>('住所記入')}
            isShow={isShow}
            handleClose={() => {
                setIsShowConfirmCancel(true);
            }}
            handleCancel={() => {
                setIsShowConfirmClose(true);
            }}
            handleOK={handleClickSubmit}
            positiveButtonText="common.completion"
            classNameBody={cx('center-body')}
        >
            <div className={cx('modal__body--border')}>
                <div className="row mt-3">
                    <div className={cxCommon('col-4', 'label_asterisk', 'label-color')}>{t<string>('郵便番号')}</div>
                    <div className="col-5">
                        <FormInput
                            styleBlock
                            name="postalCode"
                            required
                            maxLength={7}
                            placeholder={'郵便番号'}
                            value={formik.values.postalCode}
                            onChangeText={(text) => {
                                return handleChange('postalCode', text);
                            }}
                            error={formik.touched.postalCode && formik.errors.postalCode ? formik.errors.postalCode : undefined}

                        />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-2">
                        <Button onClick={handleSearchAddress} text={t<string>('common.search_address')} del danger></Button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={cxCommon('col-4', 'label-color')}>{t<string>('都道府県')}</div>
                    <div className="col-5">
                        <FormInput
                            styleBlock
                            placeholder={'部道府県'}
                            disabled
                            value={formik.values.prefecture}
                            onChangeText={(text) => {
                                return handleChange('prefecture', text);
                            }}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <span className={cxCommon('label-color')}>{t<string>('市区町村')}</span>
                        <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>{t<string>('(例: 川崎市川崎区)')}</span>
                    </div>
                    <div className="col-8">
                        <FormInput
                            styleBlock
                            placeholder={'市区町村'}
                            disabled
                            value={formik.values.city}
                            onChangeText={(text) => {
                                return handleChange('city', text);
                            }}
                            error={formik.touched.city && formik.errors.city ? formik.errors.city : undefined}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <span className={cxCommon('label_asterisk', 'label-color')}>{t<string>('町域・番地')}</span>
                        <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>{t<string>('(例: 旭町1-1)')}</span>
                    </div>
                    <div className="col-8">
                        <FormInput
                            styleBlock
                            required
                            maxLength={255}
                            placeholder={'町域・番地'}
                            value={formik.values.town}
                            onChangeText={(text) => {
                                return handleChange('town', text);
                            }}
                            error={formik.touched.town && formik.errors.town ? formik.errors.town : undefined}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-4">
                        <span className={cxCommon('label-color')}>{t<string>('建物名など')}</span>
                        <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>
                            {t<string>('(例: ○○マンション101号)')}
                        </span>
                    </div>
                    <div className="col-8">
                        <FormInput
                            styleBlock
                            required
                            maxLength={255}
                            placeholder={'建物名など'}
                            value={formik.values.building}
                            onChangeText={(text) => {
                                return handleChange('building', text);
                            }}
                        />
                    </div>
                </div>
            </div>
            {isShowConfirmCancel && (
                <>
                    <div className={cx('opacity-modal')}></div>
                    <ModalConfirm
                        isShow={isShowConfirmCancel}
                        handleOK={() => {
                            setIsShowConfirmCancel(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmCancel(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_close')} />
                </>
            )}
            {isShowConfirmClose && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowConfirmClose}
                        handleOK={() => {
                            setIsShowConfirmClose(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmClose(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>('common.confirm_cancel')} />
                </>
            )}
        </ModalInterface>
    );
};

EnterAddressModal.defaultProps = {
    isShow: false,
};

export default EnterAddressModal;
