import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput } from '@components/commons';
import DataTable from '@components/commons/Table/Table';
import { setToast } from '@redux/actions';
import Configs from '@utils/configs';
import { COMMON_STATUS, USER_ROLE_ENUM, images, pageStateInit } from '@utils/constants';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import moment from 'moment';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {  useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import ModalInterface from '~/components/commons/Modal';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import { getAssetAsync, getDetailAssetAsync } from '~/redux/actions/AssetAction';
import { ReduxStates } from '~/redux/reducers';
import { changeStatusAsset, deleteAsset } from '~/utils/helpers/api';
import styles from './AssetManagement.module.scss';
import { setShowDeleted } from '@redux/actions/CommonAction';
import useRole from '@utils/hook/useRole';
import { IMAGES } from '@utils/constants/images';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const AssetManagement: IAppointmentPage<IAppointmentPageProps> = (props) => {
    const [popupConfirmPublic, setPopupConfirmPublic] = useState(false);
    const [popupConfirmPrivate, setPopupConfirmPrivate] = useState(false);
    const [popupConfirmDelete, setPopupConfirmDelete] = useState(false);
    const [isDisableBtnDelete, setIsDisableBtnDelete] = useState(true);
    const { t } = useTranslation();
    const [data, setData] = useState<any[]>([]);
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const dispatch = useDispatch();
    const tableRef = createRef<ITableComponentHandle>();
    const [listCheckbox, setListCheckbox] = useState<any[]>([]);
    const [isClearTable, setIsClearTable] = useState(false);
    const { asset, showDeleted, userInfo } = useSelector((states: ReduxStates) => states);
    const isAdminPermission = useRole(userInfo, USER_ROLE_ENUM.admin);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams({ limit: '30', name: '', publication: '2' });
    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        limit: searchParams.get('limit') ?? 30,
        name: searchParams.get('name') ?? '',
        offset: searchParams.get('offset') ?? '',
        publication: searchParams.get('publication') ?? 2,
    });

    const handleSearch = (values: any) => {
        setIsClearTable(true);
        setSearchParams((params) => {
            return {
                ...params,
                ...values,
                offset: 0,
            };
        });
    }

    useEffect(() => {
        window.addEventListener('popstate', () => {
            if (popupConfirmPublic || popupConfirmPrivate || popupConfirmDelete) {
                setPopupConfirmPublic(false);
                setPopupConfirmPrivate(false);
                setPopupConfirmDelete(false);
            }
        });

    }, [popupConfirmPublic, popupConfirmPrivate, popupConfirmDelete]);

    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);

    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            dispatch(getAssetAsync(searchParams as IParamsAsset));
        }
    }, [showDeleted]);

    useEffect(() => {
        const limit = asset.listAsset.metadata?.limit;
        const offset = asset.listAsset.metadata?.offset;
        const count = asset.listAsset.metadata?.count;
        const total = asset.listAsset.metadata?.total;
        setPageState({
            firstRecords: offset * limit + 1,
            currentPage: offset + 1,
            totalPage: Math.ceil(total / limit),
            pageRecords: count + offset * limit,
            totalRecords: total,
        });
        setData(asset.listAsset?.data);
    }, [asset]);

    const close = () => {
        setPopupConfirmPublic(false);
        setPopupConfirmPrivate(false);
        setPopupConfirmDelete(false);
        searchParams.delete('action');
        setSearchParams(searchParams);
    };

    const handleChangeStatus = (status: COMMON_STATUS) => {
        let listSelected = listCheckbox.map((item) => Number(item));
        if (status === COMMON_STATUS.PUBLIC) {
            const listFilter = data.filter(
                (item) => listSelected.includes(Number(item.id)) && item.room_amount != 0 && item.total_public_room != 0,
            );
            listSelected = listFilter.map((item) => item.id);
        }
        if (status === COMMON_STATUS.PRIVATE) {
            const listFilter = data.filter(
                (item) =>
                    listSelected.includes(Number(item.id)) &&
                    item.unexpired_appointments === '0' &&
                    item.unexpired_otp === '0' &&
                    item.unexpired_submit_appointments === '0',
            );
            listSelected = listFilter.map((item) => item.id);
        }

        const body = {
            assets: listSelected,
            publication: status,
        };
        changeStatusAsset(body)
            .then(({ data }) => {
                dispatch(getAssetAsync(searchParams as IParamsAsset));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPopupConfirmPublic(false);
                setPopupConfirmPrivate(false);
                setIsClearTable(true);
            });
    };

    const handleDelete = () => {
        let listSelected = listCheckbox.map((item) => Number(item));
        const listFilter = data.filter(
            (item) =>
                listSelected.includes(Number(item.id)) &&
                item.unexpired_appointments === '0' &&
                item.unexpired_otp === '0' &&
                item.unexpired_submit_appointments === '0',
        );
        listSelected = listFilter.map((item) => item.id);
        const body = {
            params: { assets: JSON.stringify(listSelected) },
        };

        deleteAsset(body)
            .then(({ data }) => {
                dispatch(getAssetAsync(searchParams as IParamsAsset));
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.delete_success')}`,
                    }),
                );
            })
            .catch((err) => {
                console.log(data);
            })
            .finally(() => {
                setPopupConfirmDelete(false);
                setIsClearTable(true);
            });
    };

    const confirmPopupDelete = () => {
        setPopupConfirmDelete(true);
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'delete',
        });
    };

    useEffect(() => {
        const checkDefault = searchParams.get('name') === '' && searchParams.get('publication') === '2';
        setIsDisableBtnDelete(checkDefault);
    }, [searchParams]);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        dispatch(getAssetAsync(searchParamsObject));
    }, [searchParams]);

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        });
    };

    const clear = () => {
        const newValue = {
            name: '',
            publication: '2',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    };

    const handleGoToDetail = async (id: string) => {
        dispatch(
            await getDetailAssetAsync(id, (result: any) => {
                if (result.status === 'SUCCESS') {
                    navigate(`${Configs.routes.asset}/${id}`);
                }
            }),
        );
    };

    const handleTableDatas = () => {
        return data?.map((item) => {
            return {
                ...item,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${item.id}`,
                                value: item.id.toString(),
                            },
                        ],
                    },
                ],
                updated_time: item.updated_time ? moment.utc(item.updated_time).format('YYYY/MM/DD HH:mm') : '-',
                avatar: <div className="components__table-avatar">
                    {
                        item.images ?
                            <img className="bases__table--image" src={item.images} /> :
                            <images.IMAGES.NO_IMAGE_SVG className='bases__table--image' />
                    }
                </div>,
                name: item.name,
                address: item.address,
                usage_type: item.usage_type,
                room_type: item.room_type,
                box_amount: item.box_amount,
                room_amount: item.room_amount,
                publication: (
                    <span className={cxCommon(item.publication ? 'text-green' : 'text-red')}>
                        {item.publication === 1 ? t('asset.public') : t('asset.private')}
                    </span>
                ),
                raw_publication: item.publication === 1 ? t('asset.public') : t('asset.private'),
                detail: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <NavLink to={`${Configs.routes.asset}/${item.id}`} style={{ textDecoration: 'none' }}>
                            <Button
                                classNameLabel={cx('label-btn-detail')}
                                className={cx('button-detail')}
                                text={t<string>('common.detail')}>
                                <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                            </Button>
                        </NavLink>
                    </div>
                ),
            };
        });
    };

    const table = {
        heads: [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
            },
            {
                title: t('asset.update_date'),
            },
            {
                title: t('asset.photo'),
            },
            {
                title: t('asset.assetName'),
            },
            {
                title: t('asset.address'),
            },
            {
                title: t('asset.type_of_use'),
            },
            {
                title: t('asset.room_type'),
            },
            {
                title: t('asset.numberOfKeybox'),
            },
            {
                title: t('asset.numberOfRoom'),
            },
            {
                title: t('asset.publicPrivate'),
            },
            {
                title: t('common.detail'),
            },
        ],
        body: {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                },
                {
                    field: 'updated_time',
                },
                {
                    field: 'avatar',
                    isNotTooltip: true,
                },
                {
                    field: 'name',
                },
                {
                    field: 'address',
                },
                {
                    field: 'usage_type',
                },
                {
                    field: 'room_type',
                },
                {
                    field: 'box_amount',
                },
                {
                    field: 'room_amount',
                },
                {
                    field: 'publication',
                    rawField: 'raw_publication',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: handleTableDatas(),
        },
    };

    const confirmModalPublic = (
        <ModalInterface
            dialogClassname={cx('modal')}
            isShow={popupConfirmPublic}
            handleOK={() => handleChangeStatus(COMMON_STATUS.PUBLIC)}
            handleClose={() => close()}
            modalHeader={t<string>('common.confirm')}
            positiveButtonText="common.ok"
            classNameBody={cx('content-popup')}>
            <div className="text-center">
                <span>{t('asset.all_selected')}</span>
                <span className={cx('text_public', 'text-600')}>{t('asset.Release')}</span>
                <span>{t('asset.is_set')}</span>
                <br />
                <span>{t('asset.really_sure')}</span>
                <br />
            </div>
            <div className="ms-1">
                <span className={cx('text_private')}>{t('common.note')}</span>
                <br />
                <span className={cx('text_public')}>{t('asset.Release')}</span>
                <span>{t('asset.cannot_change_to')}</span>
                <br />
                <span>{t('asset.property_without_room')}</span>
                <br />
                <span>{t('asset.all_room')}</span>
            </div>
        </ModalInterface>
    );

    const confirmModalPrivate = (
        <ModalInterface
            dialogClassname={cx('modal')}
            isShow={popupConfirmPrivate}
            handleOK={() => handleChangeStatus(COMMON_STATUS.PRIVATE)}
            handleClose={() => close()}
            modalHeader={t<string>('common.confirm')}
            positiveButtonText="common.ok"
            classNameBody={cx('content-popup')}>
            <div>
                <div className="text-center">
                    <span>{t('asset.all_selected')}</span>
                    <span className={cx('text_private', 'text-600')}>{t('asset.private_popup')}</span>
                    <span>{t('asset.is_set')}</span>
                    <br />
                    <span>{t('asset.really_sure')}</span>
                </div>
                <br />
                <div className="ps-3">
                    <span className={cx('text_private')}>{t('common.note')}</span>
                    <br />
                    <span className={cx('text_private')}>{t('asset.private_popup')}</span>
                    <span>{t('asset.private_popup_content.1')}</span>
                    <br />
                    <span>{t('asset.private_popup_content.2')}</span>
                    <br />
                    <span>{t('asset.private_popup_content.3')}</span>
                </div>
            </div>
        </ModalInterface>
    );

    const confirmModalDelete = (
        <ModalInterface
            dialogClassname={cx('modal')}
            isShow={popupConfirmDelete}
            handleOK={() => handleDelete()}
            handleClose={() => close()}
            modalHeader={t<string>('common.remove')}
            positiveButtonText="common.ok"
            classNameBody={cx('content-popup')}>
            <div>
                <div className="text-center">
                    <span className="text-pre-wrap">{t('common.delete_all')}</span>
                </div>
                <br />
                <div className="ps-3">
                    <span className={cx('text_private')}>{t('common.note')}</span>
                    <br />
                    <span>{t('asset.delete_popup_content.1')}</span>
                    <br />
                    <span className="text-pre-wrap">{t('asset.delete_popup_content.2')}</span>
                    <br />
                    <span className="text-pre-wrap">{t('asset.delete_popup_content.3')}</span>
                </div>
            </div>
        </ModalInterface>
    );

    return (
        <>{isAdminPermission &&
            <BodyContainer title={'sidebar.asset.main'}>
                {confirmModalPublic}
                {confirmModalPrivate}
                {confirmModalDelete}

                <NavLink style={{ textDecoration: 'none' }} to={Configs.routes.assetRegister}>
                    <Button classNameLabel={cx('label-btn-add')} className={cx('btn-add')} text={t<string>('asset.register')} add />
                </NavLink>
                <Formik initialValues={initialValueSearch} enableReinitialize={true} onSubmit={(values) => handleSearch(values)}>
                    {(props) => {
                        const { handleSubmit, values, setFieldValue, handleReset } = props;
                        return (
                            <SearchForm
                                onDelete={() => {
                                    clear();
                                    handleReset();
                                }}
                                onSubmit={handleSubmit}
                                className="mt-4"
                                btnDelete
                                classNameBtnSearch={cx('btn-search')}
                                classNameBtnDelete={cx('btn-delete')}
                                isDisableBtnDelete={isDisableBtnDelete}>
                                <div className="row">
                                    <div className={cx('col-4')}>
                                        <FormInput
                                            classNameInput={cx('input')}
                                            label="asset.assetName"
                                            placeholder="asset.assetName"
                                            maxLength={255}
                                            value={values.name || ''}
                                            onChangeText={(text) => {
                                                setFieldValue('name', text);
                                            }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <FormDropdown
                                            className="text-end"
                                            label="公開/非公開"
                                            options={[
                                                { value: 2, label: 'すべて' },
                                                { value: 1, label: '公開' },
                                                { value: 0, label: '非公開' },
                                            ]}
                                            value={values.publication}
                                            onChangeOption={(option) => setFieldValue('publication', option.value)}
                                        />
                                    </div>
                                </div>
                            </SearchForm>
                        );
                    }}
                </Formik>

                <div className={'row mt-4 col-3'}>
                    {data?.length ? (
                        <div className="d-flex">
                            <Button
                                disabled={!listCheckbox.length}
                                text={'公開'}
                                del
                                className={cx('public')}
                                onClick={() => {
                                    setPopupConfirmPublic(true);
                                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                                    setSearchParams({
                                        ...searchParamsObject,
                                        action: 'public',
                                    });
                                }}
                            />
                            <Button
                                disabled={!listCheckbox.length}
                                text={'非公開'}
                                del
                                className={cx('private', 'ms-2')}
                                onClick={() => {
                                    setPopupConfirmPrivate(true);
                                    const searchParamsObject = Object.fromEntries(searchParams.entries());
                                    setSearchParams({
                                        ...searchParamsObject,
                                        action: 'private',
                                    });
                                }}
                            />
                            <Button
                                disabled={!listCheckbox.length}
                                onClick={() => confirmPopupDelete()}
                                text={'削除'}
                                del
                                className={cx('delete-item', 'ms-2')}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className={cx('asset-description')}>
                    <div><IMAGES.CHECKED_BLUE /></div>
                    <div className={cx('asset-description_text')} >{t('asset.description_checkbox')}</div>
                </div>
                <div className="mt-3">
                    <DataTable
                        isStickyColumn
                        ref={tableRef}
                        heads={table.heads}
                        body={table.body}
                        pageData={pageState}
                        total={pageState.totalRecords}
                        onChangePage={(page) => handleChangePage(page)}
                        onChangeCheckList={(value: string[]) => {
                            setListCheckbox(value);
                        }}
                    />
                </div>
            </BodyContainer>
        }</>
    );
};

export default AssetManagement;
