import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormInput } from '@components/commons';
import classNames from 'classnames/bind';
import moment from 'moment';
import { createRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useSearchParams } from 'react-router-dom';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import BrokerDetailModal from '~/components/modals/BrokerDeatailModal';
import { USER_ROLE_ENUM, images, pageStateInit } from '~/utils/constants';
import { fetchGetListBroker, fetchDetailBroker } from '~/utils/helpers/brokerApi';
import { convertToHalfSize, removeSpecialCharacter } from '~/utils/helpers/utilities';
import { Formik, useFormik } from 'formik';
import styles from './BrokerSystem.module.scss';
import DataTable from '@components/commons/Table/Table';
import { dateHelper } from '@utils/helpers';
import ModalConfirm from '@components/modals/ModalConfirm';
import { showAlert } from '@redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import useRole from '@utils/hook/useRole';
import { ReduxStates } from '@redux/reducers';
import { setListDataAll } from '@redux/actions/CommonAction';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

const BrokerSystem: IIntermediariesPage<IIntermediariesPageProps> = (props) => {
    const { t } = useTranslation();
    const { userInfo, listAll } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);
    const [isShow, setIsShow] = useState(false);
    const tableRef = createRef<ITableComponentHandle>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<IDataApiIntermediariesRes[]>([]);
    const [dataInitDetail, setDataInitDetail] = useState<IDataApiIntermediariesRes | null>(null);
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [isDisableBtnDelete, setIsDisableBtnDelete] = useState(true);
    const [modalState, setModalState] = useState<IModalState>({});
    const { showFirstModal } = modalState;
    const [isNotExisted, setIsNotExisted] = useState(false);
    const [initialValueSearch, setInitialValueSearch] = useState<any>({
        register_from_time: searchParams.get('register_from_time') ?? '',
        register_to_time: searchParams.get('register_to_time') ?? '',
        user_name: searchParams?.get('user_name') ?? '',
        phone: searchParams.get('phone') ?? '',
        broker_name: searchParams.get('broker_name') ?? '',
        store_name: searchParams.get('store_name') ?? '',
        offset: searchParams.get('offset') ?? 0,
        limit: searchParams.get('limit') ?? 30,
    });

    const handleSearch = (values: any) => {
        const newValue = { ...values, phone: convertToHalfSize(removeSpecialCharacter(values?.phone).trim()) };
        setSearchParams((params) => {
            return {
                ...params,
                ...newValue,
            };
        });
        fetchGetListBrokerAsync(newValue as IIntermediariesSearchParams);
    };

    useEffect(() => {
        if (isNotExisted) {
            showNotExistedPopup();
        }
    }, [isNotExisted]);

    const dispatch = useDispatch();

    const showNotExistedPopup = useCallback(() => {
        dispatch(
            showAlert({
                header: 'common.confirm',
                content: 'common.existed_popup_content',
                hiddenCancel: true,
                handleOK: () => {
                    fetchGetListBrokerAsync(searchParams as IIntermediariesSearchParams);
                    setIsNotExisted(false);
                },
                handleCancel: () => {
                    fetchGetListBrokerAsync(searchParams as IIntermediariesSearchParams);
                    setIsNotExisted(false);
                },
            }),
        );
    }, []);
    useEffect(() => {
        const response: any = listAll.brokerSystem;
        if (response && response.status === 'SUCCESS') {
            setData(response.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.brokerSystem]);
    const fetchGetListBrokerAsync = async (searchParams: IIntermediariesSearchParams) => {
        fetchGetListBroker(searchParams)
            .then((res) => {
                if (res?.data) {
                    dispatch(setListDataAll({ ...listAll, brokerSystem: res as any }));
                }
            })
            .catch();
    };

    const handleChangePage = (page: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        const newParams = {
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        };
        setSearchParams(newParams);
        fetchGetListBrokerAsync(newParams as IIntermediariesSearchParams);
    };

    const handleClickDetail = async (id: string | number) => {
        try {
            const params: IDetailBrokerParam = {
                page_source: '',
            };
            const response = await fetchDetailBroker(id, params);
            if (response.status === 'SUCCESS') {
                const searchParamsObject = Object.fromEntries(searchParams.entries());
                setSearchParams({
                    ...searchParamsObject,
                    broker_id: id.toString(),
                });
                setDataInitDetail(response.data);
                setIsShow(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const clear = () => {
        const newValue = {
            register_to_time: '',
            register_from_time: '',
            user_name: '',
            phone: '',
            broker_name: '',
            store_name: '',
            limit: '30',
            offset: '0',
        };
        setInitialValueSearch(newValue);
    };

    const dataTable = useMemo(() => {
        const dataRow: any = data.map((item) => {
            return {
                ...item,
                created_time: item?.created_time || '-',
                avatar: (
                    <div className="components__table-avatar">
                        {item.avatar ? (
                            <img className="bases__table--image" src={item.avatar} />
                        ) : (
                            <images.IMAGES.NO_IMAGE_SVG className="bases__table--image" />
                        )}
                    </div>
                ),
                name: item.name,
                broker_name: item.broker_name,
                raw_room_register_time: item.is_broker_registered ? t('common.registered') : t('common.non_registered'),
                room_register_time: (
                    <span className={cxCommon(item.is_broker_registered ? 'text-green' : 'text-red')}>
                        {item.is_broker_registered ? t('common.registered') : t('common.non_registered')}
                    </span>
                ),
                detail: (
                    <div className="td-last d-flex justify-content-cneter align-items-center">
                        <NavLink to={''} style={{ textDecoration: 'none' }}>
                            <Button
                                onClick={() => handleClickDetail(item.id)}
                                className={cx('button-detail')}
                                text={t<string>('common.detail')}>
                                <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                            </Button>
                        </NavLink>
                    </div>
                ),
            };
        });

        const heads = [
            {
                title: t('intermediaries.created_date'),
            },
            {
                title: t('intermediaries.image'),
            },
            {
                title: t('intermediaries.user'),
            },
            {
                title: t('intermediaries.email'),
            },
            {
                title: t('intermediaries.phone_number'),
            },
            {
                title: t('intermediaries.brokerage_company_name'),
            },

            {
                title: t('intermediaries.storename'),
            },
            {
                title: t('intermediaries.registration_status'),
            },
            {
                title: t('common.detail'),
            },
        ];

        const body = {
            columns: [
                {
                    field: 'created_time',
                },
                {
                    field: 'avatar',
                    isNotTooltip: true,
                },
                {
                    field: 'name',
                },
                {
                    field: 'email',
                },
                {
                    field: 'phone',
                },
                {
                    field: 'broker_name',
                },
                {
                    field: 'store_name',
                },
                {
                    field: 'room_register_time',
                    rawField: 'raw_room_register_time',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: dataRow,
        };

        if (isSuperAdminPermission) {
            heads.splice(-2, 1);
            body.columns.splice(-2, 1);
            body.rows = body.rows.map((item: any) => {
                delete item.room_register_time;
                return { ...item };
            });
        }

        return {
            heads,
            body,
        };
    }, [data]);

    const showPopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: true,
        }));
    };

    const hidePopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: false,
        }));
    };

    const warningModal = (
        <ModalConfirm
            isShow={showFirstModal}
            handleClose={() => {
                hidePopup('showFirstModal');
                fetchGetListBrokerAsync(searchParams as IIntermediariesSearchParams);
            }}
            hideNegativeButton
            header={t<string>('common.confirm')}
            content={t<string>('common.existed_popup_content')}></ModalConfirm>
    );

    useEffect(() => {
        const checkDefault =
            !searchParams.get('register_from_time') &&
            !searchParams.get('register_to_time') &&
            searchParams.get('user_name') === '' &&
            searchParams.get('phone') === '' &&
            searchParams.get('broker_name') === '' &&
            searchParams.get('store_name') === '';
        setIsDisableBtnDelete(checkDefault);
    }, [searchParams]);

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        fetchGetListBrokerAsync(searchParamsObject);
    }, [searchParams]);

    useEffect(() => {
        if (searchParams.get('broker_id')) {
            handleClickDetail(Number(searchParams.get('broker_id')));
        }
    }, []);

    useEffect(() => {
        if (isShow) {
            window.addEventListener('popstate', (event) => {
                setIsShow(false);
            });
        }
    }, [isShow]);

    return (
        <BodyContainer title={t('title.list_intermediaries')}>
            <Formik initialValues={initialValueSearch} enableReinitialize={true} onSubmit={(values) => handleSearch(values)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                clear();
                                handleReset();
                            }}
                            isDisableBtnDelete={isDisableBtnDelete}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('intermediaries.label.date_time_picker')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.register_to_time !== '' ? new Date(values.register_to_time) : null}
                                                value={values.register_from_time !== '' ? new Date(values.register_from_time) : null}
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) =>
                                                    setFieldValue('register_from_time', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.register_from_time !== '' ? new Date(values.register_from_time) : null}
                                                value={values.register_to_time !== '' ? new Date(values.register_to_time) : null}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('register_to_time', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label htmlFor="user_name" className={cxCommon('label-color')}>
                                                {t('intermediaries.user')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.user_name || ''}
                                            name="user_name"
                                            maxLength={255}
                                            className="col"
                                            styleBlock
                                            placeholder="intermediaries.user"
                                            onChangeText={(value) => {
                                                setFieldValue('user_name', value);
                                            }}
                                        />
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('intermediaries.phone_number')}</label>
                                        </div>
                                        <FormInput
                                            value={values.phone || ''}
                                            name="phone"
                                            maxLength={255}
                                            onChangeText={(value) => {
                                                setFieldValue('phone', value);
                                            }}
                                            className="col"
                                            styleBlock
                                            placeholder="intermediaries.phone_number"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('intermediaries.brokerage_company_name')}</label>
                                        </div>
                                        <FormInput
                                            value={values.broker_name || ''}
                                            name="broker_name"
                                            maxLength={255}
                                            onChangeText={(value) => {
                                                setFieldValue('broker_name', value);
                                            }}
                                            formContainer
                                            className="col"
                                            styleBlock
                                            placeholder="intermediaries.brokerage_company_name"
                                        />
                                        <div className="col-2"></div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('intermediaries.storename')}</label>
                                        </div>
                                        <FormInput
                                            value={values.store_name || ''}
                                            name="store_name"
                                            maxLength={255}
                                            onChangeText={(value) => {
                                                setFieldValue('store_name', value);
                                            }}
                                            className="col"
                                            styleBlock
                                            placeholder="intermediaries.storename"
                                        />
                                        <div className="col-2"></div>
                                        <div className="col"></div>
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>

            <div className="mt-4">
                <DataTable
                    total={pageState.totalRecords}
                    isStickyColumn
                    ref={tableRef}
                    heads={dataTable.heads}
                    body={dataTable.body}
                    pageData={pageState}
                    onChangePage={(page) => handleChangePage(page)}
                />
            </div>
            {isShow && (
                <BrokerDetailModal
                    typeBroker="brokerAll"
                    hidePositiveButton={true}
                    buttonGreen={!isSuperAdminPermission}
                    handleOK={() => {
                        setIsShow(false);
                    }}
                    dataInit={dataInitDetail}
                    isShow={isShow}
                    handleClose={() => {
                        setIsShow(false);
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        delete searchParamsObject.broker_id;
                        setSearchParams(searchParamsObject);
                        fetchGetListBrokerAsync(searchParamsObject as IIntermediariesSearchParams);
                    }}
                    isSuperAdmin={isSuperAdminPermission}
                />
            )}
            {warningModal}
        </BodyContainer>
    );
};

export default memo(BrokerSystem);
