import { useEffect } from 'react';
import { ToastContainer, toast as toastEmit } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import { setToast } from '@redux/actions';

import { images } from '@utils/constants';
import './_toast.scss';

const Toast: IToastComponent<IToastComponentProps> = () => {
    const dispatch = useDispatch();
    const { toast } = useSelector((states: ReduxStates) => states);

    useEffect(() => {
        if (toast?.isShow) {
            toastEmit.dismiss();
            toastEmit(toast?.message, {
                type: toast?.type ?? 'success'
            });
            dispatch(
                setToast({
                    isShow: false,
                }),
            );
        }
    }, [toast]);

    return (
        <div className="components__toast">
            <ToastContainer
                icon={() =>
                    toast?.icon ? (
                        toast?.icon
                    ) : (
                        <images.IMAGES.ICON_SUCCESS />
                    )
                }
                position="top-right"
                theme="light"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                closeButton={false}
                draggable={false}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
            />
        </div>
    );
};

export default Toast;
