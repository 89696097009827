import buttonStyles from '@assets/styles/base/ButtonStyle.module.scss';
import commonStyles from '@assets/styles/base/Common.module.scss';
import { Button, FormDropdown, FormInput, FormRadio } from '@components/commons';
import { CompanyForm } from '@components/forms';
import { dayOffCompany, images, TYPE_ACTION_SLIDE, USER_ROLE_ENUM } from '@utils/constants';
import classNames from 'classnames/bind';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMulti } from '~/components/commons/Dropdown/DropdownMulti';
import styles from './Company.module.scss';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Timepicker from '@components/commons/Dropdown/Timepicker';
import EnterAddressModal from '~/components/modals/EnterAddressModal';
import ModalConfirm from '~/components/modals/ModalConfirm';
import configs from '~/utils/configs';
import { IMAGES } from '~/utils/constants/images';
import { timeDropDown } from '~/utils/constants/input';
import { editCompanyInfoNew, getCompanyInfo } from '~/utils/helpers/api';
import { formatPostalCode, handleGetFullAddress, stringToListNumber } from '~/utils/helpers/utilities';
import { companyInfoEditInitialValues, companyInfoEditSchema } from '~/utils/schema/companyInfoEdit.form';
import moment from 'moment';
import { fetchUserInfo } from '@utils/helpers/userApi';
import { useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import useRole from '@utils/hook/useRole';
import { usePrompt } from '@utils/hook/usePormpt';

interface IState {
    checked1: boolean;
    checked2: boolean;
    checked3: boolean;
    checked4: boolean;
}
interface FileCustom extends File {
    preview?: string;
}

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);
const cxButton = classNames.bind(buttonStyles);

const CompanyInfoEdit: IModalComponent<IModalComponentProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfoDataAPI>(companyInfoEditInitialValues);
    const [responseEditInfo, setResponseEditInfo] = useState<IBaseAPIRes>();
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [currentPositionScroll, setCurrentPositionScroll] = useState<number>(0);
    const [maxPositionScroll, setMaxPositionScroll] = useState<number>(0);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const [imageSlide, setImageSlide] = useState<string>('');
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const fileInputRef2 = useRef<HTMLInputElement | null>(null);
    const dragItem = useRef<HTMLDivElement | null>(null);
    const dragIndex = useRef(0);
    const dropItem = useRef<HTMLDivElement | null>(null);
    const dropIndex = useRef(0);
    const [state, setState] = useState<IState>({
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
    });
    const { checked1, checked2, checked3, checked4 } = state;
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
        showSecondModal: false,
        showThirdModal: false,
        showFourModal: false,
    });
    const { showFirstModal, showSecondModal, showThirdModal, showFourModal } = modalState;
    const [listFile, setListFile] = useState<FileCustom[]>([]);
    const [logo, setLogo] = useState<string>('');
    const [fileLogo, setFileLogo] = useState<FileCustom | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [defaultListHoliday, setDefaultListHoliday] = useState<number[]>([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const isAdminPermission = useRole(userInfo, USER_ROLE_ENUM.admin);
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);

    const handleChecked = (idx: number, field: string, fieldForm: string) => {
        setState((prevState) => ({
            ...prevState,
            [field]: idx === 0,
        }));
        handleChange(fieldForm, idx);
    };

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave)

    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };

    const fetchEditCompanyInfo = async (formData: FormData) => {
        try {
            const response = await editCompanyInfoNew(formData);
            setResponseEditInfo(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: companyInfo,
        enableReinitialize: true,
        onSubmit: () => {
            setIsShowPopupLeave(false)
            setIsSubmit(false);
            showOrHideModal('showFirstModal');
        },
        validationSchema: companyInfoEditSchema,
        validateOnBlur: false,
        validateOnChange: false,
    });

    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        // handle list image
        const listImage = formik.values.images;
        const listOnlyFile = listImage && listImage.filter((image) => image.name);
        if (listOnlyFile?.length) {
            listOnlyFile.map((file) => {
                formData.append('images', file.file as Blob);
            });
        }
        // handle logo
        if (fileLogo) formData.append('logo', fileLogo);
        
        const imageMappingActive = [listImage[activeIndex],...listImage.filter((_,index) => index !== activeIndex)].map((item, index) => ({...item, order: index}));

        formData.append('data', JSON.stringify({...formik.values, images: imageMappingActive}));
        fetchEditCompanyInfo(formData);
    };

    const handleValidateTime = (values: ICompanyInfoDataAPI) => {
        Object.keys(values).forEach(key => {
            formik.setFieldTouched(key);
        });
        if (values.reservationTimeZoneEnabled && (values.reservationTimeUnitEnabled || values.reservationEntryOffsetEnabled)) {
            const minMinutes = (values.reservationTimeUnitEnabled ? values.reservationTimeUnitUnit : 0) + (values.reservationEntryOffsetEnabled ? values.reservationEntryOffsetStart + values.reservationEntryOffsetEnd : 0);

            const startTime = moment(values.reservationTimeZoneStartHour, 'HH:mm');
            const endTime = moment(values.reservationTimeZoneEndHour, 'HH:mm');

            const totalMinutes = moment.duration(endTime.diff(startTime)).asMinutes();
            if (totalMinutes < minMinutes) {
                formik.errors.reservationTimeZoneEnabled = t<string>('company_info.company_edit.error_time');
            } else {
                delete formik.errors.reservationTimeZoneEnabled;
            }
        } else {
            delete formik.errors.reservationTimeZoneEnabled;
        }
    }

    const handlePreSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleValidateTime(formik.values);
        formik.validateForm();
        setIsSubmit(true);
    };

    useEffect(() => {
        if (isSubmit && formik.isValid) {
            formik.handleSubmit();
        }
    }, [formik.isValid, isSubmit])

    useEffect(() => {
        if (responseEditInfo?.status === 'SUCCESS') {
            showOrHideModal('showFirstModal');
            fetchUserInfo();
            navigate(configs.routes.companyInfo);
        }
    }, [responseEditInfo]);

    useEffect(() => {
        slideRef?.current?.addEventListener('scroll', handleScroll);

        const fetchCompanyInfo = async () => {
            try {
                const response = await getCompanyInfo();
                setCompanyInfo(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCompanyInfo();
        return () => {
            slideRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (slideRef?.current) {
            setMaxPositionScroll(slideRef?.current?.scrollWidth - slideRef?.current?.offsetWidth);
        }
        handleUpdateImageSlide();
        handleChange('images', listImageSlide);
    }, [listImageSlide]);

    useEffect(() => {
        handleUpdateImageSlide();
    }, [activeIndex]);

    useEffect(() => {
        if (listFile.length > 0) {
            const listImageSlideCopy = [...listImageSlide];
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const totalImage = listImageSlideCopy.length + listFile.length;

            if (totalImage > 5) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_5_number');
                showOrHideModal('showFourModal');
                return;
            }
            listFile.forEach((file) => {
                const fileSize = Number((file.size / 1024 / 1024).toFixed(2));
                if (!validImageTypes.includes(file.type)) {
                    setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                    setModalState((prev: any) => {
                        return { ...prev, showFourModal: true };
                    });

                    return;
                } else if (fileSize > 8) {
                    setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                    setModalState((prev: any) => {
                        return { ...prev, showFourModal: true };
                    });
                    return;
                }
                const orders = listImageSlideCopy.map((imageSlide) => imageSlide.order);
                const maxOrder = orders.length > 0 ? Math.max(...orders) : -1;

                const newImage: IImageSlide = {
                    order: maxOrder + 1,
                    url: file.preview || '',
                    file: file,
                    name: file.name,
                };
                listImageSlideCopy.push(newImage);
            });
            setListImageSlide(listImageSlideCopy);
        }
    }, [listFile]);

    useEffect(() => {
        if (fileLogo) {
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const fileSize = Number((fileLogo.size / 1024 / 1024).toFixed(2));
            if (!validImageTypes.includes(fileLogo.type)) {
                setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                showOrHideModal('showFourModal');
                return;
            } else if (fileSize > 8) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                showOrHideModal('showFourModal');
                return;
            }

            setLogo(fileLogo.preview || '');
        }
    }, [fileLogo]);

    const handleUpdateImageSlide = () => {
        if (listImageSlide?.length) {
            setImageSlide(listImageSlide[activeIndex].url);
        } else {
            setImageSlide('');
            setActiveIndex(0);
        }
    };

    useEffect(() => {
        // handle get holiday
        if (companyInfo) {
            const listHoliday = stringToListNumber(companyInfo.holidays);
            setDefaultListHoliday(listHoliday);
            if (companyInfo?.images) {
                // handle list image
                setListImageSlide(companyInfo?.images);
                setImageSlide(companyInfo?.images[0]?.url);
            }
            setLogo(companyInfo?.logo || '');
            // handle get address
            const addressParams: IEnterAddressForm = {
                postalCode: companyInfo.postalCode,
                prefecture: companyInfo.prefecture,
                city: companyInfo.city,
                town: companyInfo.town,
                building: companyInfo.building,
            };
            handleChange('address', handleGetFullAddress(addressParams));
        }
    }, [companyInfo]);

    const handleScroll = () => {
        if (slideRef?.current) {
            setCurrentPositionScroll(slideRef?.current?.scrollLeft);
        }
    };

    const handleNextOrPrev = (typeAction: number) => {
        const addPosition = 150 * 3;

        const nextPosition =
            typeAction === TYPE_ACTION_SLIDE.NEXT ? currentPositionScroll + addPosition : currentPositionScroll - addPosition;

        slideRef?.current?.scrollTo(nextPosition, 0);
    };

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    const handleRemoveImage = (imageId: number, index: number) => {
        let listImageCopy = [...listImageSlide];
        const maxIndexOfList = listImageCopy.length - 1;
        listImageCopy = listImageCopy.filter((image) => image.order !== imageId);
        const newListImage = listImageCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
        if (index < activeIndex || activeIndex === maxIndexOfList) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleRemoveLogo = () => {
        formik.setFieldValue('logo', null);
        setLogo('');
        setFileLogo(null);
    };

    // handle drag and drop
    const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
        dragIndex.current = index;
        dragItem.current = e.target as HTMLDivElement;
        dragItem.current.style.opacity = '0.4';
    };

    const handleDragOver = (index: number, e: React.DragEvent) => {
        dropIndex.current = index;
        dropItem.current = e.target as HTMLDivElement;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        if (dragItem.current) {
            dragItem.current.style.opacity = '1';
        }
        if (dropItem.current === dragItem.current) return;

        const imagesCopy = [...listImageSlide];

        const draggedImage = imagesCopy.splice(dragIndex.current, 1)[0];
        imagesCopy.splice(dropIndex.current, 0, draggedImage);
        const newListImage = imagesCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
    };

    // handle upload multiple image
    const handleUploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listFile: FileCustom[] = Array.from(e.target?.files || []);
        listFile.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        setListFile(listFile);
        e.target.value = '';
    };

    const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = e.target?.files ? e.target?.files[0] : null;
        file.preview = URL.createObjectURL(file);
        setFileLogo(file);
        e.target.value = '';
    };

    const handleChangeMultiDayOff = (listDayOff: number[]) => {
        handleChange('holidays', listDayOff.join(', '));
    };

    const handleSubmitAddress = (values: IEnterAddressForm) => {
        const address = handleGetFullAddress({ ...values, postalCode: formatPostalCode(values.postalCode) });
        handleChange('postalCode', formatPostalCode(values.postalCode));
        handleChange('address', address);
        handleChange('prefecture', values.prefecture);
        handleChange('city', values.city);
        handleChange('town', values.town);
        handleChange('building', values.building);
        showOrHideModal('showSecondModal');
    };

    const handleOkUpdated = () => {
        showOrHideModal('showThirdModal');
        navigate(configs.routes.companyInfo);
        setIsShowPopupLeave(true)
    };

    return (
        <>
            {
                isAdminPermission &&
                <CompanyForm>
                    <div className="row">
                        <div className="col">
                            <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                                <img
                                    className={'w-100 ' + cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                    src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={'d-flex justify-content-center align-items-center ' + cx('slide')}>
                                <div className={cx('slide_container')}>
                                    {/* <div
                                onClick={() => handleNextOrPrev(TYPE_ACTION_SLIDE.PREV)}
                                className={cx('slide_button') + ' ' + cx(currentPositionScroll === 0 ? 'slide_button--disable' : '')}>
                                <ICON_PREV stroke={currentPositionScroll === 0 ? '#999999' : '#000000'} />
                            </div> */}
                                    <div ref={slideRef} className={cx('slide_list')}>
                                        {listImageSlide.map((image, index) => {
                                            return (
                                                <div
                                                    key={image.order}
                                                    draggable
                                                    onDragStart={(e) => handleDragStart(index, e)}
                                                    onDragOver={(e) => handleDragOver(index, e)}
                                                    onDragEnd={() => handleDragEnd()}
                                                    className={
                                                        cx('slide_img_edit') + ' ' + cx(activeIndex === index ? 'slide_img_edit--active' : '')
                                                    }>
                                                    <img
                                                        onClick={() => handleRemoveImage(image.order, index)}
                                                        src={IMAGES.ICON_CLOSE_BG}
                                                        alt=""
                                                        className={cx('img_close')}
                                                    />
                                                    <img onClick={() => handleClick(index)} src={image.url} alt="" className={cx('img_edit')} />
                                                </div>
                                            );
                                        })}
                                        <div>
                                            <Button
                                                disabled={listImageSlide.length >= 5}
                                                type="button"
                                                text={'写真追加'}
                                                className={cx('button-change-photo')}
                                                del
                                                onClick={() => fileInputRef1.current?.click()}
                                            />

                                            <input
                                                style={{ display: 'none' }}
                                                ref={fileInputRef1}
                                                type="file"
                                                accept="image/*"
                                                className="input-upload"
                                                onChange={(e) => handleUploadMultipleFiles(e)}
                                                multiple
                                            />
                                        </div>
                                    </div>
                                    {/* <div
                                onClick={() => handleNextOrPrev(TYPE_ACTION_SLIDE.NEXT)}
                                className={
                                    cx('slide_button') +
                                    ' ' +
                                    cx(currentPositionScroll === maxPositionScroll ? 'slide_button--disable' : '')
                                }>
                                <ICON_NEXT stroke={currentPositionScroll === maxPositionScroll ? '#999999' : '#000000'} />
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4"></div>
                    <form className={cx('form') + ' ' + cxCommon('font-size-14')} onSubmit={(e) => handlePreSubmit(e)}>
                        <div className="d-flex flex-xxl-row flex-column mt-1">
                            <div className="col-xxl-6 pe-xxl-3 col-12">
                                <label className={cxCommon('col-md-6 form-label fw-bold', 'label-color', 'font-size-16')}>
                                    {t('company_info.company_edit.basic_information')}
                                </label>
                                <div className="border rounded p-3 h-100">
                                    <div className="row">
                                        <div className="col-xxl-4 col-3">
                                            <div className={cx('logo-container', 'mb-2')}>
                                                <img className={cx('side-image')} src={logo ? logo : images.IMAGES.NO_IMAGE_ONE} />
                                                {logo && (
                                                    <img onClick={handleRemoveLogo} src={IMAGES.ICON_CLOSE_BG} alt="" className={cx('img_close')} />
                                                )}
                                            </div>
                                            <div className={cx('change-logo')}>
                                                <Button
                                                    type="button"
                                                    onClick={() => fileInputRef2.current?.click()}
                                                    text={'ロゴ編集'}
                                                    className={cx('button-change-photo')}
                                                    del
                                                />
                                                <input
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef2}
                                                    type="file"
                                                    accept="image/*"
                                                    className="input-upload"
                                                    onChange={(e) => handleUploadFiles(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xxl-8 col-9 just-content-center">
                                            <FormInput
                                                maxLength={255}
                                                styleBlock
                                                required
                                                label="company_info.company_edit.company_name"
                                                value={formik.values.name}
                                                onChangeText={(text) => {
                                                    return handleChange('name', text);
                                                }}
                                                error={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
                                            />
                                            <FormInput
                                                maxLength={255}
                                                styleBlock
                                                required
                                                className="mt-3"
                                                label="company_info.company_edit.manager"
                                                value={formik.values.admin.manager}
                                                onChangeText={(text) => {
                                                    return handleChange('admin.manager', text);
                                                }}
                                                error={formik.touched.admin?.manager && formik.errors.admin?.manager ? formik.errors.admin.manager : undefined}
                                            />
                                            <FormInput
                                                maxLength={255}
                                                styleBlock
                                                required
                                                className="mt-3"
                                                label="company_info.company_edit.affiliation"
                                                value={formik.values.admin?.affiliation}
                                                onChangeText={(text) => {
                                                    return handleChange('admin.affiliation', text);
                                                }}
                                                error={
                                                    formik.touched.admin?.affiliation && formik.errors.admin?.affiliation ? formik.errors.admin?.affiliation : undefined
                                                }
                                            />
                                            <FormInput
                                                maxLength={11}
                                                styleBlock
                                                required
                                                className="mt-3"
                                                label="company_info.company_edit.phone_number"
                                                value={formik.values.phoneNumber}
                                                onChangeText={(text) => {
                                                    return handleChange('admin.phone', text);
                                                }}
                                                error={formik.touched.admin?.phone && formik.errors.admin?.phone ? formik.errors.admin?.phone : undefined}
                                            />
                                            <FormInput
                                                disabled
                                                styleBlock
                                                className="mt-3"
                                                label="company_info.company_edit.email_address_of_person_in_charge"
                                                value={formik.values.admin.emailAdmin}
                                                onChangeText={(text) => {
                                                    return handleChange('admin.emailAdmin', text);
                                                }}
                                            />
                                            <div className="mt-3">
                                                <label className={cxCommon('form-label', 'label-color')}>
                                                    {t('company_info.company_edit.closed_date')}
                                                </label>
                                                <DropdownMulti
                                                    isSort
                                                    allValues={dayOffCompany}
                                                    defaultValue={defaultListHoliday}
                                                    onChange={handleChangeMultiDayOff}
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className={cxCommon('label_asterisk', 'form-label', 'label-color')}>
                                                    {t('company_info.company_edit.company_address')}
                                                </label>
                                                <div className="row mb-3">
                                                    <div className="col-8">
                                                        <FormInput
                                                            required
                                                            disabled={true}
                                                            styleBlock
                                                            value={formik.values.address}
                                                            placeholder={t<string>('company_info.company_edit.company_address')}
                                                            onChangeText={(text) => handleChange('address', text)}
                                                            error={
                                                                formik.touched.address && formik.errors.address ? formik.errors.address : undefined
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-4 ps-0">
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                showOrHideModal('showSecondModal');
                                                            }}
                                                            text={t<string>('common.enter')}
                                                            className={cxButton('button-non-min-width')}
                                                            search></Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className={cxCommon('form-label', 'label-color')}>
                                                    {t('company_info.company_edit.working_hour')}
                                                </label>
                                                <div className="row justify-content-center align-items-center">
                                                    <div className="col">
                                                        <Timepicker
                                                            value={new Date(`01/01/1970 ${formik.values.businessStartHour}`)}
                                                            onChange={(value) => handleChange('businessStartHour', moment(value).format('HH:mm'))}
                                                            maxTime={new Date(`01/01/1970 ${formik.values.businessEndHour}`)}
                                                        />
                                                    </div>
                                                    <div className="col-1 d-flex justify-content-center align-items-center">
                                                        <span>~</span>
                                                    </div>
                                                    <div className="col">
                                                        <Timepicker
                                                            minTime={new Date(`01/01/1970 ${formik.values.businessStartHour}`)}
                                                            value={new Date(`01/01/1970 ${formik.values.businessEndHour}`)}
                                                            onChange={(value) => handleChange('businessEndHour', moment(value).format('HH:mm'))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <FormInput
                                                disabled
                                                styleBlock
                                                className="mt-3"
                                                label="company_info.company_edit.number_of_properties"
                                                value={String(formik.values.assetAmount || '')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-12 mt-xxl-0 mt-3">
                                <label className={cxCommon('col-md-6 form-label fw-bold', 'label-color', 'font-size-16')}>
                                    {t('company_info.company_edit.setting_info')}
                                </label>
                                <div className="border rounded p-3 h-100">
                                    <FormRadio
                                        label="company_info.company_edit.unlock_application"
                                        items={[
                                            { label: 'company_info.company_edit.auto_approve', value: 1 },
                                            { label: 'company_info.company_edit.not_auto_approve', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.unlockApplication}
                                        onCheckItem={(value) => handleChange('unlockApplication', value)}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company_edit.unlock_application_detail"
                                    />
                                    {/* <FormRadio
                                        className="mt-1"
                                        label="company_info.company_edit.public_setting"
                                        items={[
                                            { label: 'company_info.company_edit.public', value: 1 },
                                            { label: 'company_info.company_edit.private', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.assetPublication}
                                        onCheckItem={(value) => handleChange('assetPublication', value)}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                    /> */}
                                    <FormRadio
                                        className="mt-1"
                                        label="company_info.company_edit.setting_viewing_time_zone"
                                        items={[
                                            { label: 'company_info.company_edit.use', value: 1 },
                                            { label: 'company_info.company_edit.not_use', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.reservationTimeZoneEnabled}
                                        onCheckItem={(idx) => handleChecked(idx, 'checked1', 'reservationTimeZoneEnabled')}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company_edit.setting_viewing_time_zone_detail"
                                    >
                                        <div className="mt-3">
                                            <label className={cxCommon('form-label', 'label-color')}>
                                                {t('company_info.company_edit.viewing_time_zone')}
                                            </label>
                                            <div className="row justify-content-center align-items-center">
                                                <div className="col">
                                                    <Timepicker
                                                        value={new Date(`01/01/1970 ${formik.values.reservationTimeZoneStartHour}`)}
                                                        disabled={checked1 || !formik.values.reservationTimeZoneEnabled}
                                                        onChange={(value) =>
                                                            handleChange('reservationTimeZoneStartHour', moment(value).format('HH:mm'))
                                                        }
                                                        maxTime={new Date(`01/01/1970 ${formik.values.reservationTimeZoneEndHour}`)}
                                                        isError={Boolean(formik.errors.reservationTimeZoneEnabled)}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-center align-items-center">
                                                    <span>~</span>
                                                </div>
                                                <div className="col">
                                                    <Timepicker
                                                        value={new Date(`01/01/1970 ${formik.values.reservationTimeZoneEndHour}`)}
                                                        disabled={checked1 || !formik.values.reservationTimeZoneEnabled}
                                                        onChange={(value) =>
                                                            handleChange('reservationTimeZoneEndHour', moment(value).format('HH:mm'))
                                                        }
                                                        minTime={new Date(`01/01/1970 ${formik.values.reservationTimeZoneStartHour}`)}
                                                        isError={Boolean(formik.errors.reservationTimeZoneEnabled)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </FormRadio>
                                    {formik.errors.reservationTimeZoneEnabled &&
                                        <div className="row mt-1">
                                            <div className="col-xxl-5 col-3"></div>
                                            <div className="col-xxl-7 col-9 bases__text--red">
                                                {formik.errors.reservationTimeZoneEnabled}
                                            </div>
                                        </div>
                                    }
                                    <FormRadio
                                        className="mt-1"
                                        label="company_info.company_edit.setting_viewing_time_unit"
                                        items={[
                                            { label: 'company_info.company_edit.use', value: 1 },
                                            { label: 'company_info.company_edit.not_use', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.reservationTimeUnitEnabled}
                                        onCheckItem={(idx) => handleChecked(idx, 'checked2', 'reservationTimeUnitEnabled')}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company_edit.setting_viewing_time_unit_detail"
                                    >
                                        <FormDropdown
                                            styleBlock
                                            className="col-md-12"
                                            label="company_info.company_edit.viewing_time_unit"
                                            value={formik.values.reservationTimeUnitUnit}
                                            defaultValue={formik.values.reservationTimeUnitUnit}
                                            placeholder="30分"
                                            options={timeDropDown}
                                            disabled={checked2 || !formik.values.reservationTimeUnitEnabled}
                                            onChangeOption={(option) => handleChange('reservationTimeUnitUnit', option.value)}
                                        />
                                    </FormRadio>
                                    <FormRadio
                                        className="mt-1"
                                        label="company_info.company_edit.setting_entry_exit_times"
                                        items={[
                                            { label: 'company_info.company_edit.use', value: 1 },
                                            { label: 'company_info.company_edit.not_use', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.reservationEntryOffsetEnabled}
                                        onCheckItem={(idx) => handleChecked(idx, 'checked3', 'reservationEntryOffsetEnabled')}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company_edit.setting_entry_exit_times_detail"
                                    >
                                        <div className="row">
                                            <FormDropdown
                                                styleBlock
                                                className="col-md-6"
                                                label="company_info.company_edit.move_in_out"
                                                value={formik.values.reservationEntryOffsetStart}
                                                defaultValue={formik.values.reservationEntryOffsetStart}
                                                placeholder="15分"
                                                options={[
                                                    { value: 10, label: '10分' },
                                                    { value: 15, label: '15分' },
                                                    { value: 20, label: '20分' },
                                                    { value: 25, label: '25分' },
                                                    { value: 30, label: '30分' },
                                                ]}
                                                disabled={checked3 || !formik.values.reservationEntryOffsetEnabled}
                                                onChangeOption={(option) => handleChange('reservationEntryOffsetStart', option.value)}
                                            />
                                            <FormDropdown
                                                styleBlock
                                                className="col-md-6"
                                                label="company_info.company_edit.moving_out"
                                                value={formik.values.reservationEntryOffsetEnd}
                                                defaultValue={formik.values.reservationEntryOffsetEnd}
                                                placeholder="15分"
                                                options={[
                                                    { value: 10, label: '10分' },
                                                    { value: 15, label: '15分' },
                                                    { value: 20, label: '20分' },
                                                    { value: 25, label: '25分' },
                                                    { value: 30, label: '30分' },
                                                ]}
                                                disabled={checked3 || !formik.values.reservationEntryOffsetEnabled}
                                                onChangeOption={(option) => handleChange('reservationEntryOffsetEnd', option.value)}
                                            />
                                        </div>
                                    </FormRadio>
                                    <FormRadio
                                        className="mt-1"
                                        label="company_info.company_edit.setting_a_limit_on_the_number_of_viewing_reservations"
                                        items={[
                                            { label: 'company_info.company_edit.use', value: 1 },
                                            { label: 'company_info.company_edit.not_use', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.reservationRequestLimitEnabled}
                                        onCheckItem={(idx) => handleChecked(idx, 'checked4', 'reservationRequestLimitEnabled')}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company_edit.setting_a_limit_on_the_number_of_viewing_reservations_detail"
                                    >
                                        <FormDropdown
                                            styleBlock
                                            className="col-md-12"
                                            label="company_info.company_edit.number_of_times_limit"
                                            value={formik.values.reservationRequestLimitNumber}
                                            defaultValue={formik.values.reservationRequestLimitNumber}
                                            placeholder="10回"
                                            options={[
                                                { value: 10, label: '10回' },
                                                { value: 15, label: '15回' },
                                                { value: 20, label: '20回' },
                                            ]}
                                            disabled={checked4 || !formik.values.reservationRequestLimitEnabled}
                                            onChangeOption={(option) => handleChange('reservationRequestLimitNumber', option.value)}
                                        />
                                    </FormRadio>
                                    <FormRadio
                                        className="mt-1"
                                        label="company_info.company.survey_require"
                                        items={[
                                            { label: 'company_info.company_edit.require', value: 1 },
                                            { label: 'company_info.company_edit.not_require', value: 0 },
                                        ]}
                                        defaultCheckedIndex={formik.values.reservationSurveyRequired}
                                        onCheckItem={(value) => handleChange('reservationSurveyRequired', value)}
                                        classNameLabel="col-xxl-5 col-3"
                                        classNameContent="col-xxl-7 col-9"
                                        helpText="company_info.company.survey_require_detail"
                                    ></FormRadio>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center gap-3 mt-xxl-5 mt-4">
                            <div className={cxCommon('width-170')}>
                                <Button type="submit" text={t<string>('common.save')} search></Button>
                            </div>
                            <div className={cxCommon('width-170')}>
                                <Button
                                    type="button"
                                    onClick={() => { showOrHideModal('showThirdModal'); setIsShowPopupLeave(false) }}
                                    text={t<string>('common.cancel')}
                                    del></Button>
                            </div>
                        </div>
                    </form>
                    {showSecondModal && (
                        <EnterAddressModal
                            dataInit={{
                                postalCode: formik.values.postalCode ? formik.values.postalCode.replace('-', '') : '',
                                prefecture: formik.values.prefecture,
                                city: formik.values.city,
                                town: formik.values.town,
                                building: formik.values.building,
                            }}
                            isShow={showSecondModal}
                            handleOK={handleSubmitAddress}
                            handleClose={() => showOrHideModal('showSecondModal')}
                        />
                    )}
                    {showFirstModal && (
                        <ModalConfirm
                            isShow={showFirstModal}
                            handleOK={handleSubmit}
                            handleClose={() => showOrHideModal('showFirstModal')}
                            header={t<string>('common.confirm')}
                            content={t<string>('company_info.company_edit.modal.confirm_content')}></ModalConfirm>
                    )}
                    {showThirdModal && (
                        <ModalConfirm
                            isShow={showThirdModal}
                            handleOK={() => handleOkUpdated()}
                            handleClose={() => {
                                showOrHideModal('showThirdModal');
                            }}
                            header={t<string>('common.confirm')}
                            content={t<string>('company_info.company_edit.modal.confirm_cancel_content')}></ModalConfirm>
                    )}
                    {showFourModal && (
                        <ModalConfirm
                            hideNegativeButton
                            isShow={showFourModal}
                            handleOK={() => showOrHideModal('showFourModal')}
                            handleClose={() => showOrHideModal('showFourModal')}
                            header={t<string>('company_info.company_edit.modal.error_title')}
                            content={t<string>(errorMessage)}></ModalConfirm>
                    )}
                </CompanyForm>
            }
        </>

    );
};

export default CompanyInfoEdit;
