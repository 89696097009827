import { ButtonStyle, Common, TableStyle } from '@assets/styles';
import { Button } from '@components/commons';
import ModalInterface from '@components/commons/Modal';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Modal.module.scss';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common)
const cxTable = classNames.bind(TableStyle)
const cxButton = classNames.bind(ButtonStyle)

const demoData = [
    {
        id: 1,
        name: 'A物件',
        selected: false,
    },
    {
        id: 2,
        name: 'B物件',
        selected: false,
    },
    {
        id: 3,
        name: 'C物件',
        selected: false,
    },
    {
        id: 4,
        name: 'D物件',
        selected: false,
    },
    {
        id: 5,
        name: 'E物件',
        selected: false,
    },
]

const PropertyApprovalSetting: IModalComponent<IModalComponentProps> = (props) => {
    const { isShow, handleClose } = props;
    const { t } = useTranslation();

    const [data, setData] = useState<any[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);


    // Component didmount
    useEffect(() => {
        setData(demoData);
        setIsCheckAll(demoData.every(item => item.selected))
    }, []);

    const onChangeCheckAll = (event: any) => {
        const { checked } = event.target
        data.forEach(item => {
            item.selected = checked
        })
        setIsCheckAll(checked);
        setData(JSON.parse(JSON.stringify(data)));
    }

    const onChangeCheckItem = (event: any) => {
        const { id, checked } = event.target;
        const index = id.replace('cb-', '');

        data[index].selected = checked;
        if (checked && data.every(item => item.selected)) {
            setIsCheckAll(true)
        }
        if (!checked && isCheckAll) {
            setIsCheckAll(false);
        }

        setData(JSON.parse(JSON.stringify(data)));
    }


    return <ModalInterface dialogClassname={cx('content-width')} modalHeader={'物件自動承認設定'} isShow={isShow} handleClose={handleClose} positiveButtonText='common.ok'>
        <div className="row">
            <div className={'col ' + cx('text-bold')}>
            利用者:  高橋
            </div>
            <div className="row mt-3 pt-2">
                <div className="col-10">
                    <input type="text" className={'form-control'} placeholder={'物件名'} />
                </div>
                <div className="col-2">
                    <Button className={cxButton('button-non-min-width')} text={t<string>('common.search')} search />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <div className={'row' + cxTable('custom-table', 'custom-table__appointment')}>
                        <div className={'col pe-0'}>
                            <table className="col table table-bordered mt-1">
                                <thead className={cxTable('header-background')}>
                                    <tr className="align-middle">
                                        <th className={'p-2 ' + cxCommon('width_2_pc', 'width-40')}>
                                            <div className="form-check">
                                                <input
                                                    id="check_unlocking"
                                                    className={'form-check-input ' + cx('custom-checkbox')}
                                                    type="checkbox"
                                                    checked={isCheckAll}
                                                    onChange={onChangeCheckAll}
                                                />
                                            </div>
                                        </th>
                                        <th className={'col-11'}>{t('物件名')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.map((item, index) => {
                                        return <tr key={item.id}>
                                            <td className={'p-2 ' + cxCommon('width_2_pc', 'width-40')}>
                                                <div className="form-check">
                                                    <input
                                                        id={`cb-${index}`}
                                                        className={'form-check-input ' + cx('custom-checkbox')}
                                                        type="checkbox"
                                                        checked={item.selected}
                                                        onChange={onChangeCheckItem}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {item.name}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ModalInterface>

};

PropertyApprovalSetting.defaultProps = {
    isShow: false
};

export default PropertyApprovalSetting;
