import { Tooltip } from '@components/commons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blockEmojiText } from '@utils/helpers/utilities';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Common } from '~/assets/styles';
import styles from './Form.module.scss';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

interface IFormInputProps extends IBaseCompProps {
    className?: string;
    formContainer?: boolean;
    styleBlock?: boolean;
    required?: boolean;
    label?: string;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    type?: 'text' | 'number' | 'password';
    maxLength?: number;
    onChangeText?: (value: string) => void;
    disabled?: boolean;
    textArea?: boolean;
    rows?: number;
    id?: string;
    autoComplete?: string;
    error?: string;
    isError?: boolean;
    name?: string;
    style?: any;
    classNameLabel?: string;
    checkError?: boolean;
    classNameInput?: string;
    isPassword?: boolean;
    inputRef?: any;
    textAreaRef?: any;
    textStart?: boolean;
    containerInput?: string;
    classNameContainerInput?: string;
    classNameTextArea?: string;
    readOnly?: boolean;
    tooltip?: boolean;
    onKeyUp?: any;
    styleArea?: any;
    pattern?: string;
    isOnlyNumber?: boolean;
    isOnlyText?: boolean;
}

const FormInput: IInputComponent<IFormInputProps> = ({
    styleArea,
    children,
    rows,
    onKeyUp,
    className = '',
    formContainer,
    styleBlock,
    required,
    label = '',
    value,
    disabled,
    defaultValue,
    placeholder = '',
    type = 'text',
    maxLength,
    onChangeText,
    textArea = false,
    autoComplete,
    id,
    error,
    isError,
    name,
    style,
    classNameInput,
    classNameLabel,
    checkError = false,
    inputRef,
    textAreaRef,
    textStart,
    containerInput,
    classNameContainerInput,
    classNameTextArea,
    readOnly = false,
    tooltip = false,
    isOnlyNumber = false,
    isOnlyText = false,
    pattern,
}) => {
    const [message, setMessage] = useState<string>('');
    const [inputType, setInputType] = useState(type);

    const handleDataChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let text = event?.target.value;
        if (isOnlyNumber) text = text.replace(/\D/g, '');
        if (isOnlyText) text = text.replace(/^[^A-Za-z]*$/gi, '');
        if (text) {
            text = blockEmojiText(text);
        }

        if (onChangeText) {
            onChangeText(text);
        }
    };

    useImperativeHandle(inputRef, () => ({
        checkJapanese: (mess: string) => {
            setMessage(mess);
        },
    }));

    const labelClassName = useMemo(() => {
        if (styleBlock) return 'form-label';
        return `col-md-${formContainer ? 3 : 4} col-form-label ${textStart ? 'text-start' : 'text-end'} pe-0`;
    }, [styleBlock, formContainer]);

    const handleTrimValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        value = event.target.value;
        if (onChangeText && type !== 'password') {
            onChangeText(value.trim());
        }
    };

    const handlePasswordType = useCallback(() => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    }, [inputType]);

    return (
        <div className={className}>
            <div className={styleBlock ? '' : 'row align-items-start'}>
                {label && (
                    <label className={cx('label-input', labelClassName, classNameLabel || '')}>
                        {t(label)}
                        {required && <span className={cxCommon('text-danger')}>*</span>}
                    </label>
                )}
                <div className={`col${formContainer ? ' pe-0' : ''}`}>
                    {textArea ? (
                        <textarea
                            style={styleArea}
                            onKeyUp={onKeyUp}
                            className={`${cx(disabled ? 'input--disable' : '', 'form-area', {
                                input_error: error || isError,
                            })} ${classNameTextArea}`}
                            name={name}
                            readOnly={readOnly}
                            disabled={disabled}
                            rows={rows}
                            value={value}
                            defaultValue={defaultValue}
                            placeholder={t<string>(placeholder)}
                            maxLength={maxLength}
                            onChange={handleDataChange}
                            ref={textAreaRef}
                            onBlur={handleTrimValue}></textarea>
                    ) : (
                        <div className={classNameContainerInput}>
                            <div
                                className={cx(
                                    'form-control input-group',
                                    containerInput,
                                    'input-group-gl',
                                    { input_error: error || isError },
                                    { input_error: checkError },
                                    { input_error: message },
                                    disabled ? 'input--disable' : '',
                                )}
                                style={style}>
                                {tooltip ? (
                                    <Tooltip className="w-100" content={value}>
                                        <input
                                            name={name}
                                            readOnly={readOnly}
                                            autoComplete={autoComplete}
                                            id={id}
                                            disabled={disabled}
                                            className={cx('flex-grow-1', 'gl-input', classNameInput)}
                                            value={value}
                                            defaultValue={defaultValue}
                                            placeholder={t<string>(placeholder)}
                                            type={inputType}
                                            maxLength={maxLength}
                                            onChange={handleDataChange}
                                            onBlur={handleTrimValue}
                                            ref={inputRef}
                                            pattern={pattern}
                                        />
                                    </Tooltip>
                                ) : (
                                    <input
                                        name={name}
                                        readOnly={readOnly}
                                        autoComplete={autoComplete}
                                        id={id}
                                        disabled={disabled}
                                        className={cx('flex-grow-1', 'gl-input', classNameInput)}
                                        value={value}
                                        defaultValue={defaultValue}
                                        placeholder={t<string>(placeholder)}
                                        type={inputType}
                                        maxLength={maxLength}
                                        onChange={handleDataChange}
                                        onBlur={handleTrimValue}
                                        ref={inputRef}
                                        pattern={pattern}
                                    />
                                )}

                                {type === 'password' && (
                                    <button className={cx('icon-eye')} type="button" onClick={handlePasswordType} disabled={disabled}>
                                        <FontAwesomeIcon icon={inputType === 'password' ? faEye : faEyeSlash} />
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {error && (
                        <div className={cx('error-msg')}>
                            <div className={cx('text-overflow')}>{t(error)}</div>
                        </div>
                    )}
                    {message && (
                        <div className={cx('error-msg')}>
                            <div className={cx('text-overflow')}>{t(message)}</div>
                        </div>
                    )}
                </div>
                {formContainer && (children || <div className="col-md-5" />)}
            </div>
        </div>
    );
};

export default FormInput;
