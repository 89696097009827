import commonStyles from '@assets/styles/base/Common.module.scss';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { stringHelper } from '~/utils/helpers';
import styles from '@components/commons/Form/Form.module.scss';
import { IMAGES } from '@utils/constants/images';
import Tooltip from '../Tooltip/Tooltip';
export type FormItemType = {
    label: string;
    value: number;
    key?: string;
    textColorCustom?: 'text_color_green' | 'text_color_red' | 'text-blue' | 'text-purple';
};

interface IFormRadioProps extends IBaseCompProps {
    className?: string;
    label?: string;
    items?: FormItemType[];
    defaultCheckedIndex?: number;
    onCheckItem?: (idx: number) => void;
    styleBlock?: boolean;
    classNameInput?: string;
    disabled?: boolean;
    classNameLabel?: string;
    readOnly?: boolean;
    classNameContent?: string;
    disabledOnlyIcon?: boolean;
    helpText?: string;
}

const cxCommon = classNames.bind(commonStyles);
const cx = classNames.bind(styles);
const FormRadio: IBaseComp<IFormRadioProps> = ({
    children,
    styleBlock,
    className = '',
    classNameInput,
    label,
    items = [],
    defaultCheckedIndex,
    disabled,
    readOnly,
    onCheckItem = () => undefined,
    classNameLabel,
    classNameContent,
    disabledOnlyIcon,
    helpText
}) => {
    const [data, setData] = useState<FormItemType[]>([]);
    const inputName = useMemo(() => `rd-${stringHelper.randomUid()}`, []);

    useEffect(() => {
        const data: FormItemType[] = [];
        items.forEach((item, idx) => {
            data.push({
                label: item.label,
                textColorCustom: item.textColorCustom,
                value: item.value,
                key: `${inputName}-${idx}`,
            });
        });
        setData(data);
    }, [items]);

    const handleDataChange = (value: number) => {
        onCheckItem(value);
    };
    return (
        <div className={className}>
            <div className="row">
                    {label && (
                        <label className={cxCommon('col-form-label pe-0', 'label-color', classNameLabel ? classNameLabel : 'col-md-5')}>
                            {t(label)}
                            {helpText && <Tooltip className="m-2" content={t(helpText) || '-'}>
                                <IMAGES.ICON_HELP  />
                            </Tooltip>}
                        </label>
                    )}
                <div className={classNameContent ? classNameContent : 'col-md-7'}>
                    <div className={!styleBlock ? 'row' : ''}>
                        {data.map((item) => (
                            <div key={item.value} className="col">
                                <div className="form-check mt-1 d-flex align-items-center">
                                    <input
                                        readOnly={readOnly}
                                        disabled={disabled}
                                        id={item.key}
                                        name={inputName}
                                        type="radio"
                                        value={item.value}
                                        className={
                                            cxCommon('form-check-input', 'gl-radio', classNameInput) +
                                            ' ' +
                                            cx(disabledOnlyIcon ? 'radio-disable' : ' ')
                                        }
                                        defaultChecked={item.value === defaultCheckedIndex}
                                        checked={item.value === defaultCheckedIndex}
                                        onChange={() => handleDataChange(item.value)}
                                    />
                                    <label
                                        className={cxCommon(
                                            //'bases__text--label',
                                            'form-check-label',
                                            'black-color',
                                            `${item.textColorCustom}`,
                                            'ms-2',
                                            'width-fill-available',
                                        )}
                                        htmlFor={readOnly ? '' : item.key}>
                                        {t(item.label)}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                    {children && children}
                </div>
            </div>
        </div>
    );
};

export default FormRadio;
