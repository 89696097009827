import { FC, ReactElement, useEffect, useState } from 'react';
import { BodyContainer } from '~/components/layouts';

import TablePagination from '@components/commons/Table/TablePagination';
import { formatDate, formatDateUTC } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { NOTIFICATION_TYPE, images, pageStateInit } from '~/utils/constants';
import { getDetailNotification, getNotification, readListNotification } from '~/utils/helpers/api';
import styles from './Dashboard.module.scss';
import NotificationDetail, { TYPE_NOTIFICATION } from './NotificationDetail';

const cx = classNames.bind(styles);

interface ISearchParamsNotification {
    limit: number;
    offset: number;
    type: number;
    isNotification?: boolean;
}

interface ITotalUnread {
    unReadSystem: number;
    unReadUser: number;
}

const NotificationDashboard = () => {
    const { t } = useTranslation('translation', { useSuspense: true });
    const [selectedId, setSelectedId] = useState<number>();
    const [selectedItem, setSelectedItem] = useState<IDataNotificationDetail | null>(null);
    const [listNotification, setListNotification] = useState<IDataNotification[]>([]);
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterParams, setFilterParams] = useState<ISearchParamsNotification>({
        limit: 30,
        offset: 0,
        type: Number(searchParams.get('type')) || NOTIFICATION_TYPE.SYSTEM,
        isNotification: true,
    });
    const [notificationType, setNotificationType] = useState(Number(searchParams.get('type')) || NOTIFICATION_TYPE.SYSTEM);
    const [totalUnRead, setTotalUnread] = useState<ITotalUnread>({
        unReadSystem: 0,
        unReadUser: 0,
    });

    useEffect(() => {
        if (searchParams.get('id')) {
            fetchGetListNotification(filterParams);
            setSelectedId(Number(searchParams.get('id')));
            const searchParamsObject = Object.fromEntries(searchParams.entries());
            delete searchParamsObject.id;
            setSearchParams(searchParamsObject);
        } else {
            fetchGetListNotification(filterParams, true);
        }
    }, []);

    useEffect(() => {
        if (selectedId) {
            const params: IParamNotificationDetail = { isNotification: true };
            fetchGetListNotificationDetail(selectedId, params);
        }
    }, [selectedId]);

    const fetchGetListNotificationDetail = (id: number, params: IParamNotificationDetail) => {
        getDetailNotification(id, params)
            .then((res) => {
                const data = res.data.data;
                setSelectedItem(data);
                setTotalUnread({
                    unReadSystem: data?.unReadSystem,
                    unReadUser: data?.unReadUser,
                });
                fetchGetListNotification(filterParams);
            })
            .catch((res) => console.log(res));
    };

    const fetchGetListNotification = async (params: ISearchParamsNotification, isResetSelectedId = false) => {
        await getNotification(params)
            .then((res) => {
                if (res.data.status === 'SUCCESS') {
                    const data = res.data;
                    setListNotification(data.data);
                    if (isResetSelectedId && !isEmpty(data.data)) setSelectedId(data.data[0].id);
                    if (isEmpty(data.data)) {
                        setSelectedItem(null);
                        setSelectedId(undefined);
                    }
                    setPageState({
                        firstRecords: data?.metadata?.offset * data?.metadata?.limit + 1,
                        currentPage: data?.metadata?.offset + 1,
                        totalPage: Math.ceil(data?.metadata?.total / data?.metadata?.limit),
                        pageRecords: data?.metadata?.count + data?.metadata?.offset * data?.metadata?.limit,
                        totalRecords: data?.metadata?.total,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const handleChangePage = (page: number) => {
        const newSearchParams: ISearchParamsNotification = {
            ...filterParams,
            offset: page - 1,
        };
        setFilterParams(newSearchParams);
        fetchGetListNotification(newSearchParams, true);
    };

    const handleChangeTypeNotification = (type: number) => {
        setNotificationType(type);
        const newSearchParams: ISearchParamsNotification = {
            limit: 30,
            offset: 0,
            type,
            isNotification: true,
        };
        setFilterParams(newSearchParams);
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            type: type.toString(),
        });
        fetchGetListNotification(newSearchParams, true);
    };

    const getTitleByType = (type: number): string | ReactElement => {
        let title = '';
        switch (type) {
            case TYPE_NOTIFICATION.SYSTEM:
                title = t('dashboard.notification.detail_title.system');
                break;
            case TYPE_NOTIFICATION.REQUEST_DETAIL:
                title = t('dashboard.notification.detail_title.request_app_title');
                break;
            case TYPE_NOTIFICATION.OTP_CREATE:
                title = t('dashboard.notification.detail_title.create_opt');
                break;
            case TYPE_NOTIFICATION.OTP_DELETED:
                title = t('dashboard.notification.detail_title.delete_otp');
                break;
            case TYPE_NOTIFICATION.OTP_EDITED:
                title = t('dashboard.notification.detail_title.edit_otp');
                break;
            case TYPE_NOTIFICATION.APPROVE_DETAIL:
                // title = t('dashboard.notification.detail_title.request_approved');
                // break;
                return (
                    <>
                        {t('dashboard.notification.detail_title.request_1')}
                        <span style={{ color: '#51a60e' }}>{t('dashboard.notification.detail_title.request_2')}</span>
                        {t('dashboard.notification.detail_title.request_3')}
                    </>
                );
            case TYPE_NOTIFICATION.REJECT_DETAIL:
                title = t('dashboard.notification.detail_title.request_rejected');
                break;
            default:
                break;
        }
        return title;

    };

    const handleReadAll = () => {
        const body: IParamReadNotification = { isAll: true, type: notificationType };
        const params: IParamNotificationDetail = { isNotification: true };
        readListNotification(body)
            .then(() => {
                if (selectedId) {
                    fetchGetListNotificationDetail(selectedId, params);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <BodyContainer title={t('notification.notice_list')}>
            <div className="d-flex">
                <div className="col-6 mb-3 card-header d-flex align-items-center justify-content-between">
                    <span className={cx('content', 'fw-bold')}>{t('common.list')}</span>
                    <div
                        onClick={handleReadAll}
                        className={cx(
                            'link',
                            (notificationType === NOTIFICATION_TYPE.SYSTEM && !totalUnRead.unReadSystem) ||
                                (notificationType === NOTIFICATION_TYPE.USER && !totalUnRead.unReadUser)
                                ? 'pe-none'
                                : '',
                        )}>
                        {t('notification.clear_read')}
                    </div>
                </div>
            </div>
            <div className="d-flex">
                <div className={cx('col-6', 'notification__container')}>
                    <div className={'d-flex ' + cx('boxBtn')}>
                        <div
                            onClick={() => handleChangeTypeNotification(NOTIFICATION_TYPE.SYSTEM)}
                            className={`${cx('btnUser', 'text-center')} ${
                                notificationType === NOTIFICATION_TYPE.SYSTEM ? cx('btnActive') : ''
                            }`}>
                            {t('title.system_notice')} ({totalUnRead.unReadSystem || 0})
                        </div>
                        <div
                            onClick={() => handleChangeTypeNotification(NOTIFICATION_TYPE.USER)}
                            className={`ms-1 ${cx('btnUser', 'text-center')} ${
                                notificationType === NOTIFICATION_TYPE.USER ? cx('btnActive') : ''
                            }`}>
                            {t('title.user_notice')} ({totalUnRead.unReadUser || 0})
                        </div>
                    </div>
                    <div className={cx('list__notification')}>
                        {!isEmpty(listNotification) ? (
                            listNotification?.map((item: IDataNotification) => (
                                <div
                                    key={item.id}
                                    className={cx(
                                        'notification__item',
                                        selectedId == Number(item.id) ? 'notification--active' : '',
                                        item.isRead ? '' : 'fw-bold text-black',
                                    )}
                                    onClick={() => {
                                        setSelectedId(Number(item.id));
                                    }}>
                                    <div
                                        className="col-12 "
                                        style={{
                                            marginRight: '30px',
                                        }}>
                                        <div style={{ padding: '8px' }}>
                                            <div className={cx('text_notification')}>{item.subject || getTitleByType(item.type)}</div>
                                            <div className="d-flex align-items-center">
                                                <img className={cx('img-calendar', 'me-1')} src={images.IMAGES.ICON_CALENDERCLOCK} />
                                                {formatDate(
                                                    item.type === TYPE_NOTIFICATION.SYSTEM || item.type === TYPE_NOTIFICATION.USER
                                                        ? item.startDate
                                                        : item.createdAt,
                                                    'YYYY/MM/DD',
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-center bases__text--font16 mt-3">{t('common.no_data')}</div>
                        )}
                    </div>

                    {!isEmpty(listNotification) && (
                        <div className="pt-3 mt-auto">
                            <TablePagination
                                current={pageState.currentPage}
                                onPageChange={handleChangePage}
                                className="bases__margin--top24"
                                totalPage={pageState.totalPage}
                                totalRecords={pageState.totalRecords}
                                pageRecords={pageState.pageRecords}
                                firstRecords={pageState.firstRecords}
                            />
                        </div>
                    )}
                </div>
                <div className={cx('col-6 ms-3', 'notification__container')}>
                    <NotificationDetail data={selectedItem} />
                </div>
            </div>
        </BodyContainer>
    );
};

export default NotificationDashboard;
