import { useCallback, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { images } from '@utils/constants';

import styles from './Sidebar.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface IMenuItemsProps {
    items: any;
    className?: any;
}

const MenuItems: IBaseComp<IMenuItemsProps> = (props) => {
    const { items, className } = props;
    const [isExpand, setIsExpand] = useState(false);
    const { t } = useTranslation();

    const isActiveGroup = useMemo(() => {
        return (
            items.submenu &&
            !isExpand &&
            items.submenu.some((item: any) =>
                !item.submenu ? item.url === location.pathname : item.submenu.some((sub: any) => sub.url === location.pathname),
            )
        );
    }, [isExpand, items.submenu, location.pathname]);

    const handleClickMain = useCallback(() => {
        setIsExpand(!isExpand);
    }, [isExpand]);

    return (
        <div className={cx('list-item')}>
            {items.submenu ? (
                <>
                    <div className={cx('wrapper', className, isActiveGroup ? 'active' : 'no-active')} onClick={handleClickMain}>
                        {items.image ? items.image : <images.IMAGES.ICON_NEXT_NAV_SVG />}
                        <span className={cx('text-item')}>{t(items.title)}</span>
                    </div>
                    {isExpand && (
                        <div className={cx('dropdown')}>
                            <div className={cx('dropdown-list-item')}>
                                {items.submenu.map((submenu: any) => (
                                    <MenuItems key={submenu.url} items={submenu} />
                                ))}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <NavLink
                    className={cx('wrapper', location.pathname === items.url ? 'active' : 'no-active')}
                    to={location.pathname !== items.url ? items.url : `${items.url}${location.search}`}>
                    {items.image ? items.image : <images.IMAGES.ICON_NEXT_NAV_SVG />}
                    <span className={cx('text-item')}>{t(items.title)}</span>
                </NavLink>
            )}
        </div>
    );
};

export default MenuItems;
