import { Button } from '@components/commons';
import { setShowNotFound, setToast, showAlert } from '@redux/actions/CommonAction';
import { ReduxStates } from '@redux/reducers';
import { deleteOneTimePass, getDetailOneTimePass } from '@utils/helpers/api';
import { compareDateBefore, formatDateUTC } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BodyContainer } from '~/components/layouts';
import { PreviewType } from '../OneTimePassword';
import styles from './OneTimePasswordDetails.module.scss';
import configs from '@utils/configs';

const cx = classNames.bind(styles);

export enum TYPE_ACTION_ONETIME {
    EDIT,
    REMOVE,
}

const OneTimePasswordDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const [isReRender, setIsReRender] = useState(false);

    const [data, setData] = useState<IOneTimePassDetailRes | null>(null);

    const previewType: string = useMemo(() => {
        if (data?.preview_type === PreviewType.SEE_BY_YOURSELF) return 'oneTimePassword.self_preview';
        if (data?.preview_type === PreviewType.WITH_STAFF) return 'oneTimePassword.accompanied_by_staff';
        return '-';
    }, [data]);

    const handleCompareDate = (startTime: string): boolean => {
        const currentTime = moment().tz('Asia/Tokyo').format('YYYY/MM/DD HH:mm:ss');
        const startTimeNew = moment(startTime).utc();

        const animusTime = moment(startTimeNew)
            .clone()
            .subtract(2, 'hours')
            .subtract(userInfo.company_reservation_entry_offset_enabled ? userInfo.company_reservation_entry_offset_start : 0, 'minutes')
            .format('YYYY/MM/DD HH:mm:ss');

        return !compareDateBefore(currentTime.toString(), animusTime.toString(), 'YYYY/MM/DD HH:mm:ss');
    };

    const isDisableButton: boolean = useMemo(() => {
        return data?.start_time ? handleCompareDate(data?.start_time) : true;
    }, [data, isReRender]);

    const handleShowPopupWarning = (typeAction: number) => {
        if (data?.start_time && handleCompareDate(data?.start_time)) {
            dispatch(
                showAlert({
                    header: 'common.error_title',
                    content: 'appointment.warning_schedule',
                    hiddenCancel: true,
                    handleOK: () => setIsReRender(!isReRender),
                }),
            );
        } else {
            if (typeAction === TYPE_ACTION_ONETIME.EDIT) {
                navigate(`/oneTime/edit/${id}`);
            } else {
                dispatch(
                    showAlert({
                        header: 'common.confirm',
                        content: 'common.remove_data',
                        handleOK: () => {
                            deleteOneTimePass(Number(id))
                                .then((res) => {
                                    if (res.data.status === 'SUCCESS') {
                                        dispatch(
                                            setToast({
                                                isShow: true,
                                                message: `${t('common.delete_success')}`,
                                            }),
                                        );
                                        navigate(configs.routes.oneTimePassword);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        },
                    }),
                );
            }
        }
    };

    const fetchDetailOneTimePass = (id: number) => {
        getDetailOneTimePass(id)
            .then((res) => {
                setData(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (!id) {
            dispatch(setShowNotFound(true));
        } else {
            fetchDetailOneTimePass(Number(id));
        }
    }, [id]);

    return (
        <BodyContainer title={t('detailOneTimePassword.title')}>
            <div className={cx('content-wrapper')}>
                <section className={cx('content') + ' d-flex flex-column align-items-center'}>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.username')}</div>
                        <div className="col fw-bold pe-4">{data?.user_info || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.user_phone_number')}</div>
                        <div className="col fw-bold pe-4">{data?.phone_number || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.asset_name')}</div>
                        <div className="col fw-bold pe-4">{data?.asset_name || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.propertyaddress')}</div>
                        <div className="col fw-bold pe-4">{data?.address || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.room_number')}</div>
                        <div className="col fw-bold pe-4">{data?.room_number || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('addOneTimePassword.preview_start_date_and_time')}</div>
                        <div className="col fw-bold pe-4">
                            {data?.start_time ? formatDateUTC(data?.start_time, 'YYYY/MM/DD HH:mm') : '-'}
                        </div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('addOneTimePassword.private_viewing_end_date_and_time')}</div>
                        <div className="col fw-bold pe-4">{data?.end_time ? formatDateUTC(data?.end_time, 'YYYY/MM/DD HH:mm') : '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.store_name')}</div>
                        <div className="col fw-bold pe-4">{data?.store_name || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.receptionist')}</div>
                        <div className="col fw-bold pe-4">{data?.receptionist || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.person_in_charge')}</div>
                        <div className="col fw-bold pe-4">{data?.person_in_charge || '-'}</div>
                    </div>
                    <div className="row bases__width-percent--60">
                        <div className="bases__width-percent--30">{t('oneTimePassword.preview_support')}</div>
                        <div className="col fw-bold pe-4">{t(previewType) || '-'}</div>
                    </div>
                </section>
                <div className="w-100">
                    <div className="row col-12 gap-4 justify-content-center">
                        <Button
                            disabled={isDisableButton}
                            type="submit"
                            className="bases__width--170"
                            text={t<string>('common.edit')}
                            edit
                            onClick={() => handleShowPopupWarning(TYPE_ACTION_ONETIME.EDIT)}
                        />
                        <Button
                            disabled={isDisableButton}
                            onClick={() => handleShowPopupWarning(TYPE_ACTION_ONETIME.REMOVE)}
                            className="bases__width--170 bases__text--red"
                            text={t<string>('common.remove')}
                            del
                        />
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-center bases__text--red bases__margin--top10 bases__text--font12">
                    <p>{t<string>('oneTimePassword.warning')}</p>
                </div>
            </div>
        </BodyContainer>
    );
};

export default OneTimePasswordDetails;
