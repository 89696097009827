import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/commons';

import classNames from 'classnames/bind';
import styles from './BrokerUserDetails.module.scss';
import { Common, Layout } from '@assets/styles';
import { images } from '~/utils/constants';
import { BodyContainer } from '~/components/layouts';

const cx = classNames.bind(styles);
const cxLayout = classNames.bind(Layout);
const common = classNames.bind(Common);

const BrokerUserDetails: IStaffWorkerDetailPage<IStaffWorkerDetailPageProps> = (props) => {
    const { t } = useTranslation();

    const [state, setState] = useState<IStaffWorkerDetailPageState>({});

    return (
        <BodyContainer title={t('title.employee_detail')}>
            <div className={cxLayout('container')}>
                <div className={cx('content-wrapper')}>
                    <section className={cx('content')}>
                        <div className="w-100 card border-0">
                            <div className="card-body py-0">
                                <div className="row">
                                    <div className="col-4">
                                        <div className={cx('no-img')}>
                                            <div className="form-group">
                                                <img src={images.IMAGES.NO_IMAGE} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-horizontal">
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.name')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2 fw-bold">ABC</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.permission')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2"></span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.responsible_property')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2"></span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.email')}
                                                    {':'}
                                                </span>
                                                <a className="mx-2">abc.com</a>
                                            </div>
                                            <div>
                                                <span className="px-0">
                                                    {t('staffworker.detail.usage_status')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2 fw-bold">active</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-horizontal">
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.position')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2 fw-bold">Employee</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.asset_limit')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2">1</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.number_uses')}
                                                    {':'}
                                                </span>
                                                <span className="mx-2">1</span>
                                            </div>
                                            <div className="mb-3">
                                                <span className="px-0">
                                                    {t('staffworker.detail.phone_number')}
                                                    {':'}
                                                </span>
                                                <a className="mx-2">112312123</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="w-100 content border-0 mt-3">
                        <div className="row">
                            <div className="col d-flex justify-content-between">
                                <Button className={common('w-49pc')} del text={t<string>('common.delete')} />
                                <Button className={common('w-49pc')} edit text={t<string>('common.edit')} />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </BodyContainer>
    );
};

export default BrokerUserDetails;
