import classNames from 'classnames/bind';
import styles from './Dashboard.module.scss';

const cx = classNames.bind(styles);

const ItemDashboard: IDashboardItem<IDashboardItemProps> = (props) => {
    const { image, info1, info2, index, row } = props
    return (
        <>
            {!info2 && <div key={index} className={'d-flex ' + (row === 1 ? 'col-3' : 'col-4')}>
                <div className={cx('item-info')}>
                    {image}
                    <span className={cx('count-number')}>{info1.count}</span>
                    <span
                        className={cx('type-info')}
                        dangerouslySetInnerHTML={{ __html: info1.text }}></span>
                </div>
            </div>}
            {info2 && <div key={index} className={'d-flex ' + (row === 1 ? 'col-3' : 'col-4')}>
                <div className={cx('item-info')}>
                    {image}
                    <div className="row w-100">
                        <div className="col-6">
                            <div className={cx('row', 'item-col', 'item-col-border')}>
                                <span className={'text-center ' + cx('count-number')}>{info1.count}</span>
                                <span
                                    className={'text-center ' + cx('type-info')}
                                    dangerouslySetInnerHTML={{ __html: info1.text }} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={cx('row', 'item-col')}>
                                <span className={'text-center ' + cx('count-number')}>{info2.count}</span>
                                <span
                                    className={'text-center ' + cx('type-info')}
                                    dangerouslySetInnerHTML={{ __html: info2.text }}></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ItemDashboard;
