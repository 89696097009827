import * as http from './http';
import * as regex from './regex';
import * as themes from './themes';
import * as images from './images';
import * as api from './api';

export const pageStateInit: ICommonPageState = {
    firstRecords: 1,
    currentPage: 1,
    totalPage: 1,
    totalRecords: 0,
    pageRecords: 0,
};

export enum USER_ROLE_ENUM {
    superadmin = '1',
    admin = '2',
}

export enum UNLOCK_APPLICATION_ENUM {
    自動承認しない,
    自動承認する,
}

export enum ASSET_PUBLICATION_ENUM {
    非公開,
    公開,
}

export enum RESERVATION_TIME_ENUM {
    利用しない,
    利用する,
}

export enum SURVEY_REQUIRE_ENUM {
    必須しない,
    必須する,
}

export enum TYPE_ACTION_SLIDE {
    PREV,
    NEXT,
}

export enum COMMON_STATUS {
    PRIVATE,
    PUBLIC,
}

export enum APPROVE_STATUS {
    NON_AUTO,
    AUTO,
}

export enum SURVEY_TYPE {
    RADIO = 1,
    CHECKBOX = 0,
}

export const dayOffCompany = [
    {
        value: 0,
        name: '月曜日',
    },
    {
        value: 1,
        name: '火曜日',
    },
    {
        value: 2,
        name: '水曜日',
    },
    {
        value: 3,
        name: '木曜日',
    },
    {
        value: 4,
        name: '金曜日',
    },
    {
        value: 5,
        name: '土曜日',
    },
    {
        value: 6,
        name: '日曜日',
    },
    {
        value: 7,
        name: '祝祭日',
    },
];

export enum APPOINTMENT_STATUS {
    SUBMITTED,
    APPROVED,
    REJECTED,
}

export enum NOTIFICATION_TYPE {
    SYSTEM,
    USER
}
export { http, regex, themes, images, api };
