import commonStyles from '@assets/styles/base/Common.module.scss';
import { FormInput } from '@components/commons';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import ModalInterface from '@components/commons/Modal';
import { setToast } from '@redux/actions';
import { IMAGES } from '@utils/constants/images';
import { createPayment } from '@utils/helpers/api';
import { formatDate } from '@utils/helpers/date';
import { paymentInitialValues, paymentSchema } from '@utils/schema/payment.form';
import classNames from 'classnames/bind';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

export declare type TypeCloseButton = 'close' | 'cancel';

const PaymentModal: IModalComponent<IModalComponentProps> = (props) => {
    const { isShow, handleClose, handleOK } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const btnSubmitRef = useRef<HTMLButtonElement | null>(null);
    const [isShowConfirmCancel, setIsShowConfirmCancel] = useState(false);
    const [isShowError, setIsShowError] = useState(false);
    const [errorCard, setErrorCard] = useState('');
    const [typeCloseButton, setTypeCloseButton] = useState<TypeCloseButton>('close');

    const handleSubmitForm = () => {
        btnSubmitRef.current && btnSubmitRef.current.click();
    };

    const handleSubmit = (values: IPaymentForm) => {
        const params: IPaymentForm = {
            ...values,
            expires: formatDate(values.expires, 'YYYY/MM'),
            isNew: true,
        };

        createPayment(params)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data);
                    dispatch(
                        setToast({
                            isShow: true,
                            message: `${t('common.transaction_success')}`,
                        }),
                    );
                    handleClose();
                    handleOK();
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    setIsShowError(true);
                    setErrorCard(err.data.message);
                }
            });
    };

    return (
        <ModalInterface
            dialogClassname={cx('modal-fixed')}
            modalHeader={'クレジットカード決済申し込み'}
            isShow={isShow}
            positiveButtonText="決済"
            handleClose={() => {
                setTypeCloseButton('close');
                setIsShowConfirmCancel(true);
            }}
            handleCancel={() => {
                setTypeCloseButton('cancel');
                setIsShowConfirmCancel(true);
            }}
            handleOK={handleSubmitForm}
            classNamePositive="btn-custom-1"
            classNameHeader="bases__margin--top24"
            classNameBody={cx('center-body')}>
            <Formik
                initialValues={paymentInitialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={paymentSchema}>
                {(props) => {
                    const { values, setFieldValue, errors } = props;
                    return (
                        <Form className={cx('text-break', 'row justify-content-center', 'modal__body--border')}>
                            <div className="col-6 d-flex justify-content-center align-items-center">
                                <IMAGES.VISA />
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <span className={cxCommon('label-color', 'label_asterisk')}>{t<string>('カード番号')}</span>
                                    <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>
                                        {t<string>('(例: 1111 1111 1111 1111)')}
                                    </span>
                                </div>
                                <div className="row mt-2">
                                    <FormInput
                                        maxLength={16}
                                        isOnlyNumber
                                        styleBlock
                                        placeholder={'カード番号'}
                                        value={values.number}
                                        onChangeText={(text) => setFieldValue('number', text)}
                                        error={errors.number ? errors.number : undefined}
                                        pattern="[0-9]*"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row mt-3">
                                            <span className={cxCommon('label-color', 'label_asterisk')}>{t<string>('カード有効期限')}</span>
                                            <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>
                                                {t<string>('(例: 22/01)')}
                                            </span>
                                        </div>
                                        <div className="row mt-2">
                                            <Datepicker
                                                classNameIcon='bases__right-22'
                                                minDate={new Date()}
                                                value={values.expires ? new Date(values.expires) : undefined}
                                                onChange={(value) => setFieldValue('expires', value)}
                                                placeholder="YY/MM"
                                                dateFormat="yy/MM"
                                                showMonthYearPicker
                                                showYearDropDown
                                                popperPlacement="right"
                                                isError={errors.expires ? true : undefined}
                                            />
                                            <div className="mt-2">
                                                <div className="bases__text--red">{errors.expires}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row mt-3">
                                            <span className={cxCommon('label-color', 'label_asterisk')}>
                                                {t<string>('セキュリティコード')}
                                            </span>
                                            <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>
                                                {t<string>('(例: 000)')}
                                            </span>
                                        </div>
                                        <div className="row mt-2">
                                            <FormInput
                                                maxLength={4}
                                                isOnlyNumber
                                                styleBlock
                                                placeholder={'セキュリティコード'}
                                                value={values.cvv}
                                                onChangeText={(text) => setFieldValue('cvv', text)}
                                                error={errors.cvv ? errors.cvv : undefined}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <span className={cxCommon('label-color', 'label_asterisk')}>{t<string>('カード名義')}</span>
                                    <span className={cxCommon('block', 'text-gray', 'font-size-12', 'lh-1')}>
                                        {t<string>('(例: TAKAHASHI)')}
                                    </span>
                                </div>
                                <div className="row mt-2">
                                    <FormInput
                                        maxLength={100}
                                        isOnlyText
                                        styleBlock
                                        placeholder={'カード名義'}
                                        value={values.name}
                                        onChangeText={(text) => setFieldValue('name', text.toUpperCase())}
                                        error={errors.name ? errors.name : undefined}
                                    />
                                </div>
                            </div>
                            <button className="d-none" ref={btnSubmitRef} type="submit"></button>
                        </Form>
                    );
                }}
            </Formik>
            {isShowConfirmCancel && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowConfirmCancel}
                        handleOK={() => {
                            setIsShowConfirmCancel(false);
                            handleClose();
                        }}
                        handleClose={() => {
                            setIsShowConfirmCancel(false);
                        }}
                        header={t<string>('common.confirm')}
                        content={t<string>(typeCloseButton === 'cancel' ? 'common.confirm_cancel' : 'common.confirm_close')}
                    />
                </>
            )}
            {isShowError && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isShowError}
                        handleOK={() => setIsShowError(false)}
                        handleClose={() => setIsShowError(false)}
                        hideNegativeButton
                        hideNegativeButtonClose
                        header={t<string>('common.error_title')}
                        content={errorCard}
                    />
                </>
            )}
        </ModalInterface>
    );
};

PaymentModal.defaultProps = {
    isShow: false,
};

export default PaymentModal;
