import moment from 'moment';
import * as yup from 'yup';
import i18n from '../lang/i18next';
import { isValid } from '@utils/helpers/date';

export const addOneTimePassSchema = yup.object().shape({
    user_info: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('oneTimePassword.user') })),
    phone_number: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('oneTimePassword.user_phone_number') }))
        .min(10, i18n.t<string>('message.invalid_phone_number'))
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            i18n.t<string>('message.invalid_phone_number'),
        ),
    asset_id: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.not_choose', { 0: i18n.t<string>('oneTimePassword.asset_name') })),
    room_id: yup.number().required(i18n.t<string>('message.not_choose', { 0: i18n.t<string>('oneTimePassword.room_number') })),
    start_time: yup
        .string()
        .trim()
        .test(
            'start_time',
            i18n.t<string>('message.not_choose', { 0: i18n.t<string>('oneTimePassword.privateViewingStartDatetime') }),
            (value) => {
                return isValid(value || '', 'YYYY-MM-DD HH:mm:ss');
            },
        )
        .test('start_time', i18n.t<string>('message.invalid_end_date'), (value) => {
            const currentDate = moment(new Date()).utc();
            const selectedDate = moment(value).utc();
            return !moment(selectedDate).isBefore(currentDate);
        }),
    end_time: yup
        .string()
        .trim()
        .test(
            'end_time',
            i18n.t<string>('message.not_choose', { 0: i18n.t<string>('oneTimePassword.privateViewingEndDatetime') }),
            (value) => {
                return isValid(value || '', 'YYYY-MM-DD HH:mm:ss');
            },
        )
        .test('end_time', i18n.t<string>('message.invalid_end_date'), (value) => {
            const currentDate = moment(new Date()).utc();
            const selectedDate = moment(value).utc();
            return !moment(selectedDate).isBefore(currentDate);
        }),
});

export const addOneTimePassFormInitialValues: IAddOneTimePassForm = {
    preview_type: '',
    start_time: '',
    end_time: '',
    asset_id: '',
    user_info: '',
    room_id: '',
    phone_number: '',
    person_in_charge: '',
    receptionist: '',
    store_name: '',
    address: '',
};
