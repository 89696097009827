import { apiHelper } from '~/utils/helpers';
import { AxiosError, AxiosResponse } from 'axios';

interface IChangePasswordRes {
    status: string;
    message: string;
}

interface IErrorAPIRes {
    status: string;
    message: string;
}
interface IChangePasswordAPI {
    password: string;
    newPassword: string;
}

export const changePasswordAsync = async (formData: IChangePasswordAPI, callback: (result: IChangePasswordRes | IErrorAPIRes | null) => void = () => undefined) => {
    try {
        const res = await apiHelper.changePassword(formData);
        if (res?.data) {
            callback(res?.data)
        }
    } catch (err) {
        if (!(err instanceof Error)) {
            const res = err as AxiosResponse<IErrorAPIRes, AxiosError>;
            if (res.data) {
                callback(res.data);
            }

        }
    }
};
