import axios from 'axios';
import { api } from '../constants';

export interface IParamsAddress {
    components: string;
    language: string;
    key: string;
}

interface IResponseGetAddress {
    results: IAddressComponent[];
    status: string;
}

interface IAddressComponent {
    address_components: IAddressComponentDetail[];
}
export interface IAddressComponentDetail {
    long_name: string;
    short_name: string;
    types: string[];
}

export enum RESPONSE_STATUS {
    OK = 'OK',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ZERO_RESULTS = 'ZERO_RESULTS',
    OVER_DAILY_LIMIT = 'OVER_DAILY_LIMIT',
    OVER_QUERY_LIMIT = 'OVER_QUERY_LIMIT',
    REQUEST_DENIED = 'REQUEST_DENIED',
    INVALID_REQUEST = 'INVALID_REQUEST',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export const getAddressByPostalCode = async (params: IParamsAddress) => {
    return await axios.get<IResponseGetAddress>(api.API.GEOCODING, {
        params,
    });
};
