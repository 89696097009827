import { setSidebar } from '@redux/actions';
import { ReduxStates } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';

import { USER_ROLE_ENUM, images } from '@utils/constants';

import styles from './Header.module.scss';
import classNames from 'classnames/bind';
import { NavLink } from 'react-router-dom';
import configs from '~/utils/configs';
import useRole from '@utils/hook/useRole';

const cx = classNames.bind(styles);

const Header: IBaseComp = () => {
    const dispatch = useDispatch();
    const { sidebar, userInfo } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);

    const handleSidebar = () => {
        dispatch(setSidebar(!sidebar));
    };

    return (
        <div className={cx('header', sidebar ? '' : 'sidebar--close')}>
            <button className={cx('header__btn-sidebar')} onClick={handleSidebar}>
                <images.IMAGES.ICON_SIDEBAR_SVG />
            </button>
            <div className={cx('header__user-info')}>
                {!isSuperAdminPermission && <span>{`こんにちは、${userInfo.company_name}`}</span>}
                <NavLink to={isSuperAdminPermission ? configs.routes.changePassword : configs.routes.companyInfo}>
                    <images.IMAGES.ICON_USER_SVG />
                </NavLink>
            </div>
        </div>
    );
};

export default Header;
