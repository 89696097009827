import ModalInterface from '@components/commons/Modal';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '~/redux/reducers';
import styles from './Modal.module.scss';
import { hideConfirmDelete } from '@redux/actions';

const cx = classNames.bind(styles);

const ConfirmDelete: IBaseComp = () => {
    const dispatch = useDispatch();
    const { confirmDelete } = useSelector((states: ReduxStates) => states);

    const handleOK = useCallback(() => {
        confirmDelete.handleOK && confirmDelete.handleOK();
        dispatch(hideConfirmDelete());
    }, [confirmDelete.handleOK]);

    const handleClose = useCallback(() => {
        confirmDelete.handleCancel && confirmDelete.handleCancel();
        dispatch(hideConfirmDelete());
    }, [confirmDelete.handleCancel]);

    return (
        <ModalInterface
            dialogClassname={cx('modal-delete-width')}
            isShow={confirmDelete.isShow}
            modalHeader={confirmDelete.header ?? 'common.confirm'}
            handleClose={handleClose}
            negativeButtonText={confirmDelete.titleCancel ?? 'common.cancel'}
            hideNegativeButton={confirmDelete.hiddenCancel}
            handleOK={handleOK}
            positiveButtonText={confirmDelete.titleOK ?? 'common.ok'}
            hidePositiveButton={confirmDelete.hiddenOK}>
            <div className={'col text-center'}>
                {confirmDelete.delete_many ? (
                    <span className={cx('text-content')}>
                         {t('common.delete_select')} <br /> {t('common.confirm_delete')}
                    </span>
                ) : (
                    <span className={cx('text-content')}>
                        {t('common.delete_data')} <br /> {t('common.confirm_delete')}
                    </span>
                )}
            </div>
        </ModalInterface>
    );
};

export default ConfirmDelete;
