import { Common } from '@assets/styles';
import { Button, FormInput, FormDropdown } from '@components/commons';
import classNames from 'classnames/bind';
import { Formik } from 'formik';
import { createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchForm } from '~/components/forms';
import { BodyContainer } from '~/components/layouts';
import { http, images, pageStateInit } from '~/utils/constants';
import { fetchListKeybox, fetchDeleteKeybox, fetchDetailKeybox } from '../../../../utils/helpers/keyboxApi';
import styles from './KeyboxManagement.module.scss';
import Datepicker from '@components/commons/Dropdown/Datepicker';
import moment from 'moment';
import DataTable from '@components/commons/Table/Table';
import { dateHelper } from '@utils/helpers';
import { NavLink, useSearchParams } from 'react-router-dom';
import ModalConfirm from '@components/modals/ModalConfirm';
import { setToast } from '@redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import useRole from '@utils/hook/useRole';
import { USER_ROLE_ENUM } from '@utils/constants';
import { setListDataAll } from '@redux/actions/CommonAction';
import ModalInterface from '@components/commons/Modal/Modal';
import KeyboxDetailModal from '@components/modals/KeyboxDetailModal';
import Alert from '@components/modals/Alert';
const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);

const KeyboxManagement: IKeyboxPage<IKeyboxPageProps> = (props) => {
    const dispatch = useDispatch();
    const { userInfo, listAll } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);
    const [listKeybox, setListKeybox] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams({
        offset: '0',
        limit: '30',
        register_from_time: '',
        register_to_time: '',
        box_number: '',
        status: '2',
        room_name: '',
        asset_name: '',
        contract: '',
    });
    const [listCheckbox, setListCheckbox] = useState<any[]>([]);
    const [modalState, setModalState] = useState<IModalState>({
        showFirstModal: false,
        showSecondModal: false,
        errorRoomNumber: '',
    });
    const tableRef = createRef<ITableComponentHandle>();
    const [pageState, setPageState] = useState<ICommonPageState>(pageStateInit);
    const [initialValuesForm, setInitialValuesForm] = useState({
        offset: searchParams.get('offset') ?? '0',
        limit: searchParams.get('limit') ?? '30',
        register_from_time: searchParams.get('register_from_time') ?? '',
        register_to_time: searchParams.get('register_to_time') ?? '',
        box_number: searchParams.get('box_number') ?? '',
        status: searchParams.get('status') ?? '2',
        asset_name: searchParams.get('asset_name') ?? '',
        room_name: searchParams.get('room_name') ?? '',
        company_name: searchParams.get('company_name') ?? '',
        contract: searchParams.get('contract') ?? '',
    });

    const { t } = useTranslation();
    const { showSecondModal, showFirstModal } = modalState;
    const [isClearTable, setIsClearTable] = useState(false);
    const [isShowDetailPayment, setIsShowDetailPayment] = useState(false);
    const [isShowDetailKeybox, setIsShowDetailKeybox] = useState(false);
    const [dataDetail, setDataDetail] = useState<IDataDetailKeyBox>();

    useEffect(() => {
        if (isClearTable) {
            tableRef?.current?.onClearCheckedList();
            setIsClearTable(false);
        }
    }, [isClearTable]);

    useEffect(() => {
        if (showSecondModal || showFirstModal) {
            window.addEventListener('popstate', () => {
                setModalState((prevState) => ({
                    ...prevState,
                    showSecondModal: false,
                    showFirstModal: false,
                }));
            });
        }
    }, [showSecondModal, showFirstModal]);

    useEffect(() => {
        const response = listAll.keyBox;
        if (response) {
            setListKeybox(response?.data);
            setPageState({
                firstRecords: response?.metadata?.offset * response?.metadata?.limit + 1,
                currentPage: response?.metadata?.offset + 1,
                totalPage: Math.ceil(response?.metadata?.total / response?.metadata?.limit),
                pageRecords: response?.metadata?.count + response?.metadata?.offset * response?.metadata?.limit,
                totalRecords: response?.metadata?.total,
            });
        }
    }, [listAll.keyBox]);

    const fetchGetListKeybox = async (searchParams: IKeyboxSearchParams) => {
        fetchListKeybox(searchParams)
            .then((res) => {
                if (res?.data) {
                    dispatch(setListDataAll({ ...listAll, keyBox: res }));
                }
            })
            .catch();
    };

    const showPopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: true,
        }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            action: 'delete',
        });
    };

    const hidePopup = (field: string) => {
        setModalState((prevState) => ({
            ...prevState,
            [field]: false,
        }));
        searchParams.delete('action');
        setSearchParams(searchParams);
    };

    const handleSearch = (values: IKeyboxSearchParams) => {
        setIsClearTable(true);
        setSearchParams((params) => {
            return {
                ...params,
                ...values,
            };
        });

        fetchGetListKeybox(values as IKeyboxSearchParams);
    };

    const handleChangePage = (page: number) => {
        setPageState((prevState) => ({
            ...prevState,
            currentPage: page,
        }));
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        });

        fetchGetListKeybox({
            ...searchParamsObject,
            offset: (page - 1)?.toString(),
            limit: '30',
        } as IKeyboxSearchParams);
    };

    const dataTable = useMemo(() => {
        const dataRow = listKeybox.map((data) => {
            return {
                ...data,
                checkbox: [
                    {
                        type: 'checkbox',
                        data: [
                            {
                                id: `table_data_${data.id}`,
                                value: data.id.toString(),
                            },
                        ],
                    },
                ],
                register_time: dateHelper.formatDateUTC(data.register_time, 'YYYY/MM/DD HH:mm'),
                box_battery:
                    Number(data.box_battery) <= 5 ? (
                        <span className={cxCommon('text-red')}>{Number(data.box_battery) + '%'}</span>
                    ) : (
                        Number(data.box_battery) + '%'
                    ),
                raw_box_battery: Number(data.box_battery) + '%',
                raw_status: data.company_register_time || data.status ? t('keybox.install') : t('keybox.remove'),
                status: (
                    <span className={data.status ? cxCommon('text-green') : cxCommon('text-red')}>
                        {data.status ? t('keybox.install') : t('keybox.remove')}
                    </span>
                ),
                rooms: data?.rooms ? data.rooms.join('・') : null,
                status_contract: (
                    <span
                        className={
                            data.status_contract === 2
                                ? cxCommon('text-purple')
                                : data.status_contract === 1
                                ? cxCommon('text-green')
                                : data.status_contract === 0
                                ? cxCommon('text-red')
                                : ''
                        }>
                        {data.status_contract === 2
                            ? '未契約'
                            : data.status_contract === 1
                            ? '契約中'
                            : data.status_contract === 0
                            ? '今月まで契約'
                            : ''}
                    </span>
                ),
                raw_status_contract: (
                    <span>
                        {data.status_contract === 2
                            ? '未契約'
                            : data.status_contract === 1
                            ? '契約中'
                            : data.status_contract === 0
                            ? '今月まで契約'
                            : ''}
                    </span>
                ),
                detail: (
                    <div className="td-last d-flex justify-content-center align-items-center">
                        <Button
                            onClick={() => handleClickDetail(data.id)}
                            className={cxCommon('button-detail')}
                            text={t<string>('common.detail')}>
                            <img style={{ marginLeft: '3px' }} src={images.IMAGES.ICON_DETAIL}></img>
                        </Button>
                    </div>
                ),
            };
        });

        const heads = [
            {
                isCheckbox: true,
                dataCheckbox: [
                    {
                        data: [
                            {
                                id: 'checkAll',
                                value: 'all',
                            },
                        ],
                    },
                ],
            },
            {
                title: t('keybox.update_date'),
            },
            {
                title: t('asset.keyboxSerial'),
            },
            {
                title: t('keybox.batteryPercentage'),
            },
            {
                title: t('keybox.installRemove'),
            },
            {
                title: t('intermediaries.company_name'),
            },
            {
                title: t('keybox.asset_name'),
            },
            {
                title: t('keybox.roomNumber'),
            },
            {
                title: t('契約'),
            },
            {
                title: t('common.detail'),
            },
        ];

        const bodys = {
            columns: [
                {
                    field: 'checkbox',
                    isCheckbox: true,
                },
                {
                    field: 'register_time',
                },
                {
                    field: 'box_number',
                },
                {
                    field: 'box_battery',
                    rawField: 'raw_box_battery',
                },
                {
                    field: 'status',
                    rawField: 'raw_status',
                },
                {
                    field: 'company_name',
                },
                {
                    field: 'asset_name',
                },
                {
                    field: 'rooms',
                },
                {
                    field: 'status_contract',
                    rawField: 'raw_status_contract',
                },
                {
                    field: 'detail',
                    isNotTooltip: true,
                },
            ],
            rows: dataRow,
        };

        if (isSuperAdminPermission) {
            heads.shift();
            bodys.columns.shift();
            heads.splice(-2, 2);
            bodys.columns.splice(-2, 2);
            bodys.rows = bodys.rows.map((item) => {
                delete item.checkbox;
                delete item.status_contract;
                delete item.detail;
                return { ...item };
            });
        } else {
            heads.splice(-5, 1);
            bodys.columns.splice(-5, 1);
            bodys.rows = bodys.rows.map((item) => {
                delete item.company_name;
                return { ...item };
            });
        }

        return {
            heads,
            bodys,
        };
    }, [listKeybox]);

    const resetParams = () => {
        const newValue = {
            limit: '30',
            offset: '0',
            register_from_time: '',
            register_to_time: '',
            box_number: '',
            status: '2',
            room_name: '',
            asset_name: '',
            company_name: '',
            contract: '',
        };
        setInitialValuesForm(newValue);
    };

    const checkShowModal = () => {
        const listNew = listCheckbox.map((item) => {
            return Number(item);
        });
        setListCheckbox(listNew);
        const found = listKeybox.filter((r) => r.status === 1);
        const check = found.filter((r) => listNew.includes(r.id));
        if (check.length > 0) {
            showPopup('showFirstModal');
        } else {
            showPopup('showSecondModal');
        }
    };
    const alertModal = (
        <ModalConfirm
            isShow={!!modalState.errorRoomNumber}
            handleOK={() => setModalState({ ...modalState, errorRoomNumber: '' })}
            handleClose={() => setModalState({ ...modalState, errorRoomNumber: '' })}
            header={'削除'}
            hideNegativeButton={true}
            content={modalState.errorRoomNumber}></ModalConfirm>
    );
    const confirmModal = (
        <ModalConfirm
            isShow={showSecondModal}
            handleOK={() => handleDelete()}
            handleClose={() => hidePopup('showSecondModal')}
            header={t<string>('common.confirm')}
            content={t<string>('common.delete_all')}></ModalConfirm>
    );

    const warningModal = (
        <ModalConfirm
            isShow={showFirstModal}
            handleClose={() => hidePopup('showFirstModal')}
            hideNegativeButton
            header={t<string>('common.delete_confirm')}
            content={t<string>('common.it_can_not')}></ModalConfirm>
    );

    const handleDelete = () => {
        const params = {
            keyboxies: JSON.stringify(listCheckbox),
        };
        fetchDeleteKeybox(params).then((res) => {
            if (res?.status === 'FAILURE') {
                // hidePopup('showSecondModal');
                // hidePopup('showFirstModal');
                const messageError = res.message;
                setModalState({
                    ...modalState,
                    showFirstModal: false,
                    showSecondModal: false,
                    errorRoomNumber: messageError,
                });
            }
            if (res?.data) {
                setIsClearTable(true);
                fetchGetListKeybox(searchParams as IKeyboxSearchParams);
                hidePopup('showSecondModal');
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.delete_success')}`,
                    }),
                );
            }
        });
    };

    const handleChangeList = (value: string[]) => {
        setListCheckbox([]);
        setListCheckbox(value);
    };

    const handleClickDetail = async (id: number) => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...searchParamsObject,
            keybox_id: id.toString(),
        });
        const response = await fetchDetailKeybox(id);
        if (response?.status === 'SUCCESS') {
            setDataDetail(response.data);
            setIsShowDetailKeybox(true);
        }
    };

    useEffect(() => {
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        fetchGetListKeybox(searchParamsObject as IKeyboxSearchParams);
        if (searchParamsObject.keybox_id) {
            handleClickDetail(Number(searchParamsObject.keybox_id));
        }
    }, [searchParams]);

    return (
        <BodyContainer title={t('title.keybox')}>
            <Formik enableReinitialize={true} initialValues={initialValuesForm} onSubmit={(values) => handleSearch(values)}>
                {(props) => {
                    const { handleSubmit, values, setFieldValue, handleReset, getFieldProps } = props;
                    return (
                        <SearchForm
                            btnDelete
                            onSubmit={handleSubmit}
                            onDelete={() => {
                                handleReset();
                                resetParams();
                                setIsClearTable(true);
                            }}
                            isDisableBtnDelete={
                                getFieldProps('register_from_time').value == '' &&
                                getFieldProps('register_to_time').value == '' &&
                                getFieldProps('box_number').value == '' &&
                                getFieldProps('status').value == '2' &&
                                getFieldProps('room_name').value == '' &&
                                getFieldProps('asset_name').value == ''
                            }>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('common.update_date')}</label>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                maxDate={values.register_to_time !== '' ? new Date(values.register_to_time) : undefined}
                                                value={values.register_from_time !== '' ? new Date(values.register_from_time) : undefined}
                                                placeholder={t<string>('common.start')}
                                                onChange={(value: Date) =>
                                                    setFieldValue('register_from_time', moment(value).format('YYYY/MM/DD'))
                                                }
                                            />
                                        </div>
                                        <div className="col-1 d-flex justify-content-center align-items-center">
                                            <span>~</span>
                                        </div>
                                        <div className="col-4">
                                            <Datepicker
                                                minDate={values.register_from_time !== '' ? new Date(values.register_from_time) : undefined}
                                                value={values.register_to_time !== '' ? new Date(values.register_to_time) : undefined}
                                                placeholder={t<string>('common.end')}
                                                onChange={(value: Date) => {
                                                    setFieldValue('register_to_time', moment(value).format('YYYY/MM/DD'));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label
                                                htmlFor="box_number"
                                                className={'text-end ' + cxCommon('label-color')}
                                                style={{ whiteSpace: 'pre' }}>
                                                {t('keybox.serial')}
                                            </label>
                                        </div>
                                        <FormInput
                                            value={values.box_number}
                                            name="box_number"
                                            maxLength={255}
                                            className="col-4"
                                            styleBlock
                                            placeholder="keybox.serial"
                                            onChangeText={(value) => setFieldValue('box_number', value)}
                                        />
                                        <FormDropdown
                                            className={cx('pr-22', 'col-6 text-end')}
                                            label="keybox.installRemove"
                                            name="status"
                                            options={[
                                                { value: '2', label: 'すべて' },
                                                { value: '1', label: '設置' },
                                                { value: '0', label: '予備' },
                                            ]}
                                            value={values.status}
                                            onChangeOption={(option) => setFieldValue('status', option.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        {isSuperAdminPermission ? (
                                            <FormInput
                                                className={'col-6 '}
                                                label="会社名"
                                                name="status"
                                                placeholder="会社名"
                                                value={values.company_name}
                                                onChangeText={(value) => setFieldValue('company_name', value)}
                                            />
                                        ) : (
                                            <>
                                                <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                                    <label className={cxCommon('label-color')}>{t('keybox.asset_name')}</label>
                                                </div>
                                                <FormInput
                                                    value={values.asset_name}
                                                    name="asset_name"
                                                    maxLength={255}
                                                    onChangeText={(value) => setFieldValue('asset_name', value)}
                                                    formContainer
                                                    className="col-4"
                                                    styleBlock
                                                    placeholder="keybox.asset_name"
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        {isSuperAdminPermission && (
                                            <>
                                                <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                                    <label className={cxCommon('label-color')}>{t('keybox.asset_name')}</label>
                                                </div>
                                                <FormInput
                                                    value={values.asset_name}
                                                    name="asset_name"
                                                    maxLength={255}
                                                    onChangeText={(value) => setFieldValue('asset_name', value)}
                                                    formContainer
                                                    className="col-4"
                                                    styleBlock
                                                    placeholder="keybox.asset_name"
                                                />
                                            </>
                                        )}

                                        <div className="col-2 d-flex justify-content-end align-items-center px-0">
                                            <label className={cxCommon('label-color')}>{t('keybox.roomNumber')}</label>
                                        </div>
                                        <FormInput
                                            value={values.room_name}
                                            name="room_name"
                                            maxLength={255}
                                            onChangeText={(value) => setFieldValue('room_name', value)}
                                            className={`col-4 ${isSuperAdminPermission ? ' bases__padding--right22' : ''}`}
                                            styleBlock
                                            placeholder="keybox.roomNumber"
                                        />
                                        {!isSuperAdminPermission && (
                                            <FormDropdown
                                                className={cx('pr-22', 'col-6 text-end')}
                                                label="keybox.status"
                                                name="contract"
                                                options={[
                                                    { value: '', label: 'すべて' },
                                                    { value: '1', label: '契約中' },
                                                    { value: '2', label: '未契約' },
                                                    { value: '0', label: '今月まで契約' },
                                                ]}
                                                value={values.contract}
                                                onChangeOption={(option) => setFieldValue('contract', option.value)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </SearchForm>
                    );
                }}
            </Formik>
            {listKeybox.length > 0 && !isSuperAdminPermission && (
                <div className="mt-4 d-flex align-items-end w-100">
                    <Button
                        del
                        onClick={checkShowModal}
                        text={t<string>('common.remove')}
                        className={cxCommon('btn-remove')}
                        disabled={listCheckbox.length === 0}
                    />
                    <span className="bases__text--red bases__text--font12 ms-3">※ キーボックスは「未契約」のみが削除できる。</span>
                    <span
                        className="bases__text--link bases__text--font12 bases__p--pointer ms-auto"
                        onClick={() => setIsShowDetailPayment(true)}>
                        キーボックス利用料のご説明
                    </span>
                </div>
            )}

            <div className="mt-4">
                {pageState && (
                    <DataTable
                        isStickyColumn
                        total={pageState.totalRecords}
                        ref={tableRef}
                        heads={dataTable.heads}
                        body={dataTable.bodys}
                        pageData={pageState}
                        onChangePage={(page) => handleChangePage(page)}
                        onChangeCheckList={(value: string[]) => {
                            handleChangeList(value);
                        }}
                        isKeyBox
                        isSuperAdmin={isSuperAdminPermission}
                    />
                )}
            </div>
            {confirmModal}
            {warningModal}
            {alertModal}
            {isShowDetailKeybox && (
                <KeyboxDetailModal
                    hidePositiveButton={true}
                    typeBroker="brokerRegistered"
                    dataInit={dataDetail}
                    isShow={isShowDetailKeybox}
                    handleClose={() => {
                        const searchParamsObject = Object.fromEntries(searchParams.entries());
                        delete searchParamsObject.keybox_id;
                        setSearchParams(searchParamsObject);
                        setIsShowDetailKeybox(false);
                        // fetchGetListKeybox(searchParamsObject as IKeyboxSearchParams);
                    }}
                />
            )}
            {isShowDetailPayment && (
                <ModalInterface
                    dialogClassname={cx('content-width', 'modal-fixed')}
                    modalHeader={t('キーボックス利用料のご説明')}
                    isShow={isShowDetailPayment}
                    handleClose={() => setIsShowDetailPayment(false)}
                    textBtn={'modal.broker.detail.btnContent'}
                    hidePositiveButton={true}
                    negativeButtonText="common.close"
                    classNameHeader="bases__margin--top24 bases__margin--bottom20"
                    classNameBody="pt-0 px-4">
                    <div className={cxCommon('label-color', 'text-break', 'd-flex flex-column align-items-start gap-1')}>
                        <span>
                            ・初めて内覧Benriアプリ(スマホサービス)でキーボックスと物件を紐づけますと、キー
                            ボックスは内覧Benri（WEBサービス）に登録(契約)されることとなりその時から課金 が始まります。
                            部屋への設置の有無にかかわらず、登録されますと費用が発生します。
                        </span>
                        <span>
                            ・キーボックスはWEBサービスから、いつでも登録の解除ができます。登録の解除月は、当月の利用料金がかかります。また解除月の当月に、再度登録した場合は１度課金しているので当月の追加料金はかかりません。キーボックスの登録及び解除は、何度でも行えます。
                        </span>
                        <span className="fw-bold ms-2">システム使用料</span>
                        <span className="ms-4">10台以下 : 基本料金1000円/月額</span>
                        <span className="ms-4">11台以上 : １台追加毎に100円/月額 （基本料金1000円（10台）+100円×1台）</span>

                        <span className="fw-bold ms-2">例</span>
                        <span className="ms-4">〇 １台１000円/月額（10台以下・基本料金のみ）</span>
                        <span className="ms-4">〇 ３台１000円/月額（10台以下・基本料金のみ）</span>
                        <span className="ms-4">〇 １３台 1,300円/月額 （基本料金1000円（10台）+100円×3台） </span>
                        <span className="ms-4">〇 36台 3,600円/月額 （基本料金1000円（10台）+100円×26台）</span>
                        <span className="fw-bold">※別途消費税がかかります。</span>
                    </div>
                </ModalInterface>
            )}
        </BodyContainer>
    );
};

export default KeyboxManagement;
