import commonStyles from '@assets/styles/base/Common.module.scss';
import ModalInterface from '@components/commons/Modal';
import { formatDateUTC } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './Modal.module.scss';
import ModalConfirm from './ModalConfirm';
import { fetchCancelContractKeybox } from '@utils/helpers/keyboxApi';
import { setToast } from '@redux/actions';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

type IMModalKeyboxDetailProps = Pick<IModalComponentProps, 'isShow' | 'handleClose' | 'handleOK' | 'hidePositiveButton' | 'buttonGreen'> & {
    dataInit?: IDataDetailKeyBox;
    typeBroker?: 'brokerRegistered' | 'brokerAll';
};

const KeyboxDetailModal: IModalComponent<IMModalKeyboxDetailProps> = (props) => {
    const { isShow, handleClose, dataInit, hidePositiveButton } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);

    const roomName: string = useMemo(() => {
        return dataInit?.rooms && dataInit?.rooms.join('・') || '-';
    }, [dataInit]);

    const handleCancelContractKeybox = async () => {
        if (dataInit?.id) {
            const response = await fetchCancelContractKeybox(Number(dataInit.id));

            if (response?.status === 'SUCCESS') {
                dispatch(
                    setToast({
                        isShow: true,
                        message: `${t('common.cancel_contract_success')}`,
                    }),
                );
            }
            setIsConfirmDeleteModal(false);
            handleClose();
        }
    }

    return (
        <ModalInterface
            dialogClassname={cx('content-width', 'modal-fixed')}
            modalHeader={'キーボックス詳細'}
            isShow={isShow}
            positiveButtonText="common.close"
            negativeButtonText="契約解除"
            handleClose={handleClose}
            handleCancel={() => setIsConfirmDeleteModal(true)}
            // handleOK={() => showConfirmPopup(1)}
            classNameHeader="bases__margin--top24"
            classNameBody="pt-0"
            classNamePositive="btn-custom"
            classNameCancel="bases__text--red"
            disableBtnCancel={dataInit?.status_contract !== 1}
            >
            <div className={cxCommon('label-color', 'text-break')}>
                <div className="row mt-3 ms-2">
                    <div className="bases__width-percent--30">{t('更新日時')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.register_time ? formatDateUTC(dataInit?.register_time, 'YYYY/MM/DD HH:mm') : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('キーボックス番号')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.box_number ? dataInit?.box_number : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('電池残量')}</div>
                    <div className="col fw-bold pe-4">{Number(dataInit?.box_battery) <= 5 ? (
                        <span className={cxCommon('text-red')}>{Number(dataInit?.box_battery) + '%'}</span>
                    ) : (
                        Number(dataInit?.box_battery) + '%'
                    )}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('設置/予備')}</div>
                    <div className="col fw-bold pe-4">
                    <span className={dataInit?.status ? cxCommon('text-green') : cxCommon('text-red')}>
                        {dataInit?.status ? t('keybox.install') : t('keybox.remove')}
                    </span>
                    </div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('物件名')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.asset_name? dataInit?.asset_name: '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('部屋番号')}</div>
                    <div className="col fw-bold pe-4">{roomName}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('契約')}</div>
                    <div className="col fw-bold pe-4">{<span
                        className={
                            dataInit?.status_contract === 2
                                ? cxCommon('text-purple')
                                : dataInit?.status_contract === 1
                                ? cxCommon('text-green')
                                : dataInit?.status_contract === 0
                                ? cxCommon('text-red')
                                : ''
                        }>
                        {dataInit?.status_contract === 2 ? '未契約' : dataInit?.status_contract === 1 ? '契約中' : dataInit?.status_contract === 0 ? '今月まで契約' : ''}
                    </span>}</div>
                </div>
            </div>

            {isConfirmDeleteModal && (
                <>
                    <div className={cx('opacity-modal')} />
                    <ModalConfirm
                        isShow={isConfirmDeleteModal}
                        handleOK={handleCancelContractKeybox}
                        handleClose={() => setIsConfirmDeleteModal(false)}
                        header={t<string>('契約解除確認')}
                        content={`契約解除してよろしいでしょうか。\nなお、キーボックスは今月末まで利用できます。`}
                    />
                </>
            )}
        </ModalInterface>
    );
};

KeyboxDetailModal.defaultProps = {
    isShow: false,
};

export default KeyboxDetailModal;
