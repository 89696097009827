import { Button, FormDropdown, FormInput, FormRadio } from '@components/commons';
import Configs from '@utils/configs';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { ButtonStyle, Common } from '@assets/styles';
import { USER_ROLE_ENUM, http, images } from '@utils/constants';
import classNames from 'classnames/bind';
import { useState, useEffect, useRef, FormEvent } from 'react';
import { BodyContainer } from '~/components/layouts';
import styles from './CompanyAccountRegister.module.scss';
import EnterAddressModal from '@components/modals/EnterAddressModal';
import { useFormik } from 'formik';
import { formatPostalCode, handleGetFullAddress } from '@utils/helpers/utilities';
import { IMAGES } from '@utils/constants/images';
import ModalConfirm from '@components/modals/ModalConfirm';
import { createAccountCompany, getDetailAccountCompany, getListCompany, updateAccountCompany } from '@utils/helpers/api';
import { companyAccountUpdateSchema, companyInfoEditInitialValues } from '@utils/schema/companyInfoEdit.form';
import i18n from '@utils/lang/i18next';
import configs from '@utils/configs';
import { getUTCStringNow } from '@utils/helpers/date';
import { setToast } from '@redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setShowDeleted } from '@redux/actions/CommonAction';
import { ReduxStates } from '@redux/reducers';
import useRole from '@utils/hook/useRole';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(Common);
const cxButton = classNames.bind(ButtonStyle);

interface FileCustom extends File {
    preview?: string;
}

const companyAccountUpdateInitialValues: ICompanyAccountDataAPI = {
    name: '',
    images: [],
    company_logo: '',
    manager: '',
    phone: '',
    affiliation: '',
    email_admin: '',
    postalCode: '',
    prefecture: '',
    city: '',
    town: '',
    building: '',
    memo: '',
    registerTime: getUTCStringNow(),
    email: '',
};

const CompanyAccountRegister: IBaseComp<IBaseCompProps> = (props) => {
    const routeParams = useParams();
    const { t } = useTranslation();
    const [isShow, setIsShow] = useState(false);
    const [companyDetail, setCompanyDetail] = useState<ICompanyInfoDataAPI>(companyInfoEditInitialValues);
    const slideRef = useRef<HTMLDivElement | null>(null);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [imageSlide, setImageSlide] = useState<string>('');
    const [address, setAddress] = useState<string>();
    const dropIndex = useRef(0);
    const dragItem = useRef<HTMLDivElement | null>(null);
    const dragIndex = useRef(0);
    const dropItem = useRef<HTMLDivElement | null>(null);
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const fileInputRef2 = useRef<HTMLInputElement | null>(null);
    const [listFile, setListFile] = useState<FileCustom[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [logo, setLogo] = useState<string>('');
    const [fileLogo, setFileLogo] = useState<FileCustom | null>(null);
    const [modalState, setModalState] = useState<IModalState>({
        showFourModal: false,
        showSecondModal: false,
    });
    const { showFourModal, showSecondModal } = modalState;
    const [isSubmit, setIsSubmit] = useState(false);
    const [errorAddress, setErrorAddress] = useState<string>();
    const [errorEmail, setErrorEmail] = useState<string>();
    const [errorCompanyName, setErrorCompanyName] = useState<string>();
    const navigate = useNavigate();
    const [listCompany, setListCompany] = useState<FormOptionType[]>();
    const [idActive, setIDactive] = useState<number | string>();
    const [registMethod, setRegistMethod] = useState<number>(1);
    const [dataCompany, setDataCompany] = useState<any>();
    const dispatch = useDispatch();
    const { showDeleted } = useSelector((states: ReduxStates) => states);
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const isSuperAdminPermission = useRole(userInfo, USER_ROLE_ENUM.superadmin);
    const [isShowPopupLeave, setIsShowPopupLeave] = useState(true);

    useEffect(() => {
        if (routeParams.id && routeParams.adminId) {
            handleGetAccountCompanyDetail();
        }
    }, [routeParams.id, routeParams.adminId]);

    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。', isShowPopupLeave)

    useEffect(() => {
        if (idActive) {
            dataCompany.map((data: any) => {
                if (idActive === data.id) {
                    setLogo(data.logo)
                    setListImageSlide(data.images ?? [])
                    const addressParams: IEnterAddressForm = {
                        postalCode: data.postalCode,
                        prefecture: data.prefecture,
                        city: data.city,
                        town: data.town,
                        building: data.building,
                    };
                    setAddress(handleGetFullAddress(addressParams));
                    handleChange('name', data.name);
                    handleChange('companyId', data.id.toString());
                    formik.setFieldValue('postalCode', data.postalCode)
                    formik.setFieldValue('prefecture', data.prefecture)
                    formik.setFieldValue('city', data.city)
                    formik.setFieldValue('town', data.town)
                    formik.setFieldValue('building', data.building)
                    formik.setFieldValue('registerTime', data.registerTime)
                }
            })
        }
    }, [idActive]);

    useEffect(() => {
        handleChange('images', listImageSlide);
        if (listImageSlide.length === 0) {
            setImageSlide('')
            setActiveIndex(0)
        }
    }, [listImageSlide]);

    useEffect(() => {
        if (showDeleted) {
            dispatch(setShowDeleted(false));
            navigate(Configs.routes.company);
        }
    }, [showDeleted]);


    useEffect(() => {
        if (companyDetail?.images?.length) {
            setListImageSlide(companyDetail?.images);
            setImageSlide(companyDetail.images[0]?.url);
        }
        setLogo(companyDetail?.company_logo ?? '')

        if (companyDetail) {
            const addressParams: IEnterAddressForm = {
                postalCode: companyDetail.postalCode,
                prefecture: companyDetail.prefecture,
                city: companyDetail.city,
                town: companyDetail.town,
                building: companyDetail.building,
            };
            setAddress(handleGetFullAddress(addressParams));
            formik.setFieldValue('name', companyDetail.company_name)
            formik.setFieldValue('email_admin', companyDetail.email_admin)
            formik.setFieldValue('email', companyDetail.email_admin)
            formik.setFieldValue('phone', companyDetail.phone)
            formik.setFieldValue('affiliation', companyDetail.affiliation)
            formik.setFieldValue('manager', companyDetail.manager)
            formik.setFieldValue('memo', companyDetail.memo)
            formik.setFieldValue('postalCode', companyDetail.postalCode)
            formik.setFieldValue('prefecture', companyDetail.prefecture)
            formik.setFieldValue('city', companyDetail.city)
            formik.setFieldValue('town', companyDetail.town)
            formik.setFieldValue('building', companyDetail.building)
            formik.setFieldValue('registerTime', companyDetail.registerTime)
            formik.setFieldValue('company_logo', companyDetail.company_logo)
        }
    }, [companyDetail]);

    // useEffect(() => {
    //     if (listCompany) {
    //         setListCompany(listCompany?.filter((data, index, arr) => removeRepeatedElement(arr, data) === index))
    //     }

    // }, [listCompany]);

    // const removeRepeatedElement = (listCompanyArr: FormOptionType[], company: FormOptionType) => {
    //     return listCompanyArr.findIndex(data => data.value === company.value && data.label === company.label)
    // }

    const handleGetAccountCompanyDetail = async () => {
        try {
            const { data } = await getDetailAccountCompany(routeParams.adminId ?? '', routeParams.adminId ?? '');
            setCompanyDetail(data.data);
        } catch (err) {
            throw new Error('Error res');
        }
    }

    const handleFetchListCompany = async () => {
        const response = await getListCompany();
        if (response.data.status === "SUCCESS") {
            setDataCompany(response.data.data)
            const optionSelect = response.data.data.map((company: any) => {
                const { id, name } = company;
                return {
                    value: id ?? '',
                    label: name ?? '',
                };
            });
            setListCompany(optionSelect)
        }
    };

    useEffect(() => {
        if (fileLogo) {
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const fileSize = Number((fileLogo.size / 1024 / 1024).toFixed(2));
            if (!validImageTypes.includes(fileLogo.type)) {
                setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                showOrHideModal('showFourModal');
                return;
            } else if (fileSize > 8) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                showOrHideModal('showFourModal');
                return;
            }

            setLogo(fileLogo.preview || '');
        }
    }, [fileLogo]);

    useEffect(() => {
        if (listImageSlide?.length > 0) {
            const imageSlide: IImageSlide[] = listImageSlide.filter((image) => image.order === activeIndex);
            setImageSlide(imageSlide[0]?.url);
            formik.setFieldValue('images', listImageSlide)
        }
    }, [activeIndex, listImageSlide?.length]);

    useEffect(() => {
        if (listFile.length > 0) {
            const listImageSlideCopy = [...listImageSlide];
            const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const totalImage = listImageSlideCopy.length + listFile.length;

            if (totalImage > 5) {
                setErrorMessage('company_info.company_edit.modal.error_image_over_5_number');
                setModalState((prev: any) => {
                    return { ...prev, showFourModal: true };
                });
                return;
            }
            listFile.forEach((file) => {
                const fileSize = Number((file.size / 1024 / 1024).toFixed(2));
                if (!validImageTypes.includes(file.type)) {
                    setErrorMessage('company_info.company_edit.modal.error_image_invalid_format');
                    setModalState((prev: any) => {
                        return { ...prev, showFourModal: true };
                    });

                    return;
                } else if (fileSize > 8) {
                    setErrorMessage('company_info.company_edit.modal.error_image_over_8mb');
                    setModalState((prev: any) => {
                        return { ...prev, showFourModal: true };
                    });
                    return;
                }
                const orders = listImageSlideCopy.map((imageSlide) => imageSlide.order);
                const maxOrder = orders.length > 0 ? Math.max(...orders) : -1;

                const newImage: IImageSlide = {
                    order: maxOrder + 1,
                    url: file.preview || '',
                    file: file,
                    name: file.name,
                };
                listImageSlideCopy.push(newImage);
            });
            setListImageSlide(listImageSlideCopy);
        }
    }, [listFile]);

    const formik = useFormik({
        initialValues: companyAccountUpdateInitialValues,
        enableReinitialize: true,
        onSubmit: () => {
            setIsSubmit(false);
            setIsShowPopupLeave(false)
            if (routeParams.id && routeParams.adminId) {
                handleSubmit();
            } else {
                const formData = new FormData();
                // handle list image
                const listImage = formik.values.images;
                const listOnlyFile = listImage && listImage.filter((image) => image.name);
                if (listOnlyFile?.length) {
                    listOnlyFile.map((file) => {
                        formData.append('images', file.file as Blob);
                    });
                }

                // handle logo
                if (fileLogo) formData.append('logo', fileLogo);
                if (registMethod) formData.append('type', registMethod.toString());
                formData.append('data', JSON.stringify(formik.values));
                fetchCreateCompanyAccount(formData)
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: companyAccountUpdateSchema,
    });

    useEffect(() => {
        formik.resetForm();
        setAddress('');
        setLogo('');
        setListImageSlide([]);
        setErrorCompanyName('')
        setErrorEmail('')
        setErrorAddress('')
        setIDactive('')
    }, [registMethod]);

    useEffect(() => {
        handleFetchListCompany();
    }, []);

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    const showOrHideModal = (field: string) => {
        setModalState((prev: any) => {
            const value = !prev[field];
            return { ...prev, [field]: value };
        });
    };

    const openmModalAndress = () => {
        setIsShow(true);
    };

    const closeModalAndress = () => {
        setIsShow(false);
    };

    const handleChange = (type: string, value: any) => {
        formik.setFieldValue(`${type}`, value);
    };

    const handleSubmitAddress = (values: IEnterAddressForm) => {
        const address = handleGetFullAddress({ ...values, postalCode: formatPostalCode(values.postalCode) });
        setAddress(address)
        handleChange('postalCode', formatPostalCode(values.postalCode));
        handleChange('prefecture', values.prefecture);
        handleChange('city', values.city);
        handleChange('town', values.town);
        handleChange('building', values.building);
        closeModalAndress();
    };

    useEffect(() => {
        if (isSubmit && formik.isValid) {
            formik.handleSubmit();
        }
    }, [formik.isValid, isSubmit])

    const modalAndress = () => {
        return (
            <>
                <div className={cx('opacity-modal')} />
                <EnterAddressModal
                    dataInit={{
                        postalCode: formik.values.postalCode ? formik.values.postalCode.replace('-', '') : '',
                        prefecture: formik.values.prefecture,
                        city: formik.values.city,
                        town: formik.values.town,
                        building: formik.values.building,
                    }}
                    isShow={isShow}
                    handleOK={handleSubmitAddress}
                    handleClose={() => closeModalAndress()}
                />
            </>
        );
    };

    // handle drag and drop
    const handleDragStart = (index: number, e: React.DragEvent<HTMLDivElement>) => {
        dragIndex.current = index;
        dragItem.current = e.target as HTMLDivElement;
        dragItem.current.style.opacity = '0.4';
    };

    const handleDragOver = (index: number, e: React.DragEvent) => {
        dropIndex.current = index;
        dropItem.current = e.target as HTMLDivElement;
        e.preventDefault();
    };

    const handleDragEnd = () => {
        if (dragItem.current) {
            dragItem.current.style.opacity = '1';

        }
        if (dropItem.current === dragItem.current) return;

        const imagesCopy = [...listImageSlide];

        const draggedImage = imagesCopy.splice(dragIndex.current, 1)[0];
        imagesCopy.splice(dropIndex.current, 0, draggedImage);
        setActiveIndex(dropIndex.current);
        const newListImage = imagesCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
    };

    const handleRemoveImage = (imageId: number, index: number) => {
        let listImageCopy = [...listImageSlide];
        const maxIndexOfList = listImageCopy.length - 1;
        listImageCopy = listImageCopy.filter((image) => image.order !== imageId);
        const newListImage = listImageCopy.map((image, index) => {
            return { ...image, order: index };
        });
        setListImageSlide(newListImage);
        if (index < activeIndex || activeIndex === maxIndexOfList) {
            setActiveIndex(activeIndex - 1);
        }
    };


    const handleUploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const listFile: FileCustom[] = Array.from(e.target?.files || []);
        listFile.map((file) => {
            file.preview = URL.createObjectURL(file);
            return file;
        });
        setListFile(listFile);
        e.target.value = '';
    };

    const handleUploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = e.target?.files ? e.target?.files[0] : null;
        file.preview = URL.createObjectURL(file);
        setFileLogo(file);
        e.target.value = '';
    };

    const handleRemoveLogo = () => {
        formik.setFieldValue('logo', null);
        setLogo('');
        setFileLogo(null);
    };

    const handlePreSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        formik.validateForm();

        if (!address) {
            setErrorAddress(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company.company_address') }))
            setIsSubmit(false);
        } else {
            setErrorAddress('')
        }
        if (address) {
            setIsSubmit(true);
        }

        if (!routeParams.id) {
            formik.setFieldValue('registerTime', getUTCStringNow())
        }
    };

    const fetchEditCompanyAccount = async (formData: FormData) => {
        try {
            const response = await updateAccountCompany(routeParams.id ?? 0, routeParams.adminId ?? 0, formData);
            if (response.data.message === "UPDATE_COMPANY_DETAILS_SUCCESS") {
                navigate(`${configs.routes.company}/${routeParams.id}/${routeParams.adminId}`);
                dispatch(setToast({
                    isShow: true,
                    message: t<string>('common.save_data')
                }));
                setIsShowPopupLeave(true)
            }

        } catch (error) {
            console.log(error);
        }
    };

    const fetchCreateCompanyAccount = async (formData: FormData) => {
        try {
            const response = await createAccountCompany(formData);
            if (response.data.message === "REGISTER_COMPANY_SUCCESS") {
                // const id = response.data.data.raw.insertId
                navigate(`${configs.routes.company}`);
                dispatch(setToast({
                    isShow: true,
                    message: t<string>('common.add_success')
                }));
                setIsShowPopupLeave(true)
            }

        } catch (error: any) {
            if (error.data.message === "EMAIL_EXISTED") {
                setErrorEmail(t<string>('message.duplicate_email'))
            }
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        // handle list image
        const listImage = formik.values.images;
        const listOnlyFile = listImage && listImage.filter((image) => image.name);
        if (listOnlyFile?.length) {
            listOnlyFile.map((file) => {
                formData.append('images', file.file as Blob);
            });
        }

        // handle logo
        if (fileLogo) formData.append('logo', fileLogo);

        formData.append('data', JSON.stringify(formik.values));
        fetchEditCompanyAccount(formData);

    };

    const handleCancel = () => {
        if (routeParams.id && routeParams.adminId) {
            navigate(`${configs.routes.company}/${routeParams.id}/${routeParams.adminId}`);
            setIsShowPopupLeave(true)
        } else {
            navigate(`${configs.routes.company}`);
            setIsShowPopupLeave(true)
        }
    }

    return (
        <>
            {isSuperAdminPermission &&
                <form className={cx('form') + cxCommon('font-size-14')} onSubmit={(e) => handlePreSubmit(e)}>
                    <BodyContainer
                        title={
                            routeParams.id
                                ? t<string>('title.management_company_account_edit')
                                : t<string>('title.management_company_account_registration')
                        }>

                        <div className={cxCommon('container-asset')}>
                            <div className="row mt-4 d-flex justify-content-center">
                                <div className="col-10">
                                    <section className={'mt-0 d-block ' + cx('content')}>
                                        <div className="row">
                                            <div className="col">
                                                <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                                                    <img
                                                        className={'w-100 ' + cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                                        src={(imageSlide && imageSlide !== '') ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className={cx('slide', 'd-flex')}>
                                                    <div className={cx('slide_container') + (' justify-content-center')}>
                                                        <div ref={slideRef} className={cx('slide_list')}>
                                                            {listImageSlide.map((image, index) => {
                                                                return (
                                                                    <div
                                                                        key={image.order}
                                                                        draggable
                                                                        onDragStart={registMethod !== 2 ? (e) => { handleDragStart(index, e) } : undefined}
                                                                        onDragOver={registMethod !== 2 ? (e) => handleDragOver(index, e) : undefined}
                                                                        onDragEnd={registMethod !== 2 ? () => handleDragEnd() : undefined}
                                                                        className={
                                                                            cx('slide_img_edit') + ' ' + cx(activeIndex === index ? 'slide_img_edit--active' : '')
                                                                        }>
                                                                        {registMethod === 1 &&
                                                                            <img
                                                                                onClick={() => handleRemoveImage(image.order, index)}
                                                                                src={IMAGES.ICON_CLOSE_BG}
                                                                                alt=""
                                                                                className={cx('img_close')}
                                                                            />}
                                                                        <img onClick={() => handleClick(index)} src={image.url} alt="" className={cx('img_edit')} />
                                                                    </div>
                                                                );
                                                            })}
                                                            {registMethod === 1 &&
                                                                <div className={cx('btn-upload')}>
                                                                    <Button
                                                                        disabled={listImageSlide.length >= 5}
                                                                        type="button"
                                                                        text={'写真追加'}
                                                                        className={cx('button-change-photo')}
                                                                        del
                                                                        onClick={() => fileInputRef1.current?.click()}
                                                                    />

                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        ref={fileInputRef1}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        className="input-upload"
                                                                        onChange={(e) => handleUploadMultipleFiles(e)}
                                                                        multiple
                                                                    />
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex mt-3 mb-4 flex-xxl-row flex-column mt-1">
                                            <div className=" pe-xxl-3 col">
                                                <label className={cxCommon('col-md-6 form-label fw-bold', 'label-color', 'font-size-16')}>
                                                    {t('company_info.company_edit.basic_information')}
                                                </label>
                                                <div className="border rounded p-3 h-100">
                                                    <div className="d-flex">
                                                        <div className="">
                                                            <div className={cx('logo-container', 'mb-2')}>
                                                                <img className={cx('side-image')} src={logo ? logo : images.IMAGES.NO_IMAGE_ONE} />
                                                                {logo && registMethod === 1 && (
                                                                    <img onClick={handleRemoveLogo} src={IMAGES.ICON_CLOSE_BG} alt="" className={cx('img_close')} />
                                                                )}
                                                            </div>
                                                            {registMethod === 1 &&
                                                                <div className={cx('change-logo')}>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => fileInputRef2.current?.click()}
                                                                        text={routeParams.id ? '写真編集' : '写真追加'}
                                                                        className={cx('button-change-photo')}
                                                                        del
                                                                    />
                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        ref={fileInputRef2}
                                                                        type="file"
                                                                        accept="image/*"
                                                                        className="input-upload"
                                                                        onChange={(e) => handleUploadFiles(e)}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="w-100 bases__margin--left30 just-content-center">

                                                            {!routeParams.id && <>
                                                                <div className="col-12 col-xxl-8">
                                                                    <FormRadio
                                                                        classNameInput="size_checkbox"
                                                                        className={cxCommon('bases__margin--bottom10')}
                                                                        classNameLabel="col"
                                                                        classNameContent="col"
                                                                        items={[
                                                                            { label: '新規会社を入力する', value: 1, },
                                                                            { label: '登録された会社を選択する', value: 2 },
                                                                        ]}
                                                                        defaultCheckedIndex={registMethod}
                                                                        onCheckItem={(value) => setRegistMethod(value)}
                                                                    />
                                                                </div>
                                                            </>}

                                                            {registMethod === 2 ? <>
                                                                <FormDropdown
                                                                    styleBlock
                                                                    classNameInput='bases__text--font12'
                                                                    required
                                                                    label="company_info.company_edit.company_name"
                                                                    value={idActive}
                                                                    classNameOption={cxCommon('bases__height--32 bases__text--ellipsis')}
                                                                    options={listCompany}
                                                                    placeholder='会社選択'
                                                                    onChangeOption={(option) => setIDactive(option.value)}
                                                                    error={formik.errors.name ? formik.errors.name : undefined}
                                                                />
                                                            </> : <>
                                                                <FormInput
                                                                    maxLength={255}
                                                                    styleBlock
                                                                    required
                                                                    label="company_info.company_edit.company_name"
                                                                    placeholder={t<string>('management_company_account.company_name')}
                                                                    value={formik.values.name}
                                                                    onChangeText={(text) => {
                                                                        return handleChange('name', text);
                                                                    }}
                                                                    error={formik.errors.name ? formik.errors.name : errorCompanyName ? errorCompanyName : undefined}
                                                                />
                                                            </>}

                                                            <FormInput
                                                                maxLength={255}
                                                                styleBlock
                                                                required
                                                                className="mt-3"
                                                                label="company_info.company_edit.manager"
                                                                value={formik.values.manager}
                                                                onChangeText={(text) => {
                                                                    return handleChange('manager', text);
                                                                }}
                                                                placeholder={t<string>('management_company_account.manager')}
                                                                error={formik.errors.manager ? formik.errors.manager : undefined}
                                                            />
                                                            <FormInput
                                                                maxLength={255}
                                                                styleBlock
                                                                required
                                                                className="mt-3"
                                                                label="company_info.company_edit.affiliation"
                                                                placeholder={t<string>('management_company_account.affiliation')}
                                                                value={formik.values.affiliation}
                                                                onChangeText={(text) => {
                                                                    return handleChange('affiliation', text);
                                                                }}
                                                                error={
                                                                    formik.errors.affiliation ? formik.errors.affiliation : undefined
                                                                }
                                                            />
                                                            <div className="mt-3">
                                                                <label className={cxCommon('label_asterisk', 'form-label', 'label-color')}>
                                                                    {t('company_info.company_edit.company_address')}
                                                                </label>
                                                                <div className="d-flex mb-3">
                                                                    <div className={`w-100 ${registMethod === 1 ? 'bases__padding--right20' : ''}`}>
                                                                        <FormInput
                                                                            required
                                                                            tooltip={true}
                                                                            disabled={true}
                                                                            styleBlock
                                                                            value={address}
                                                                            placeholder={t<string>('company_info.company_edit.company_address')}
                                                                            error={
                                                                                !address ? errorAddress : undefined
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {
                                                                        registMethod === 1 && <div className="col ps-0 d-flex justify-content-end">
                                                                            <Button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    openmModalAndress()
                                                                                }}
                                                                                text={t<string>('common.enter')}
                                                                                className={cxButton('address-button')}
                                                                                search></Button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>

                                                            <FormInput
                                                                styleBlock
                                                                required
                                                                className="mt-3"
                                                                label="company_info.company.phone_number"
                                                                value={formik.values.phone}
                                                                maxLength={11}
                                                                placeholder={t<string>('management_company_account.phoneNumber')}
                                                                onChangeText={(text) => {
                                                                    return handleChange('phone', text);
                                                                }}
                                                                error={
                                                                    formik.errors.phone ? formik.errors.phone : undefined
                                                                }
                                                            />
                                                            <FormInput
                                                                styleBlock
                                                                maxLength={255}
                                                                required
                                                                disabled={routeParams.adminId ? true : false}
                                                                className="mt-3 mb-3"
                                                                label="company_info.company.email_address_of_person_in_charge"
                                                                value={routeParams.adminId ? formik.values.email_admin : formik.values.email}
                                                                placeholder={t<string>('management_company_account.company_email_address')}
                                                                onChangeText={(text) => {
                                                                    return handleChange('email', text);
                                                                }}
                                                                error={
                                                                    formik.errors.email ? formik.errors.email : errorEmail ? errorEmail : undefined
                                                                }
                                                            />

                                                            <FormInput
                                                                classNameTextArea={cx('text-area', 'bases__padding--top10')}
                                                                textArea
                                                                rows={6}
                                                                styleBlock
                                                                maxLength={3000}
                                                                className={cx('mr-input')}
                                                                label="management_company_account.memo"
                                                                onChangeText={(text) => {
                                                                    return handleChange('memo', text);
                                                                }}
                                                                placeholder='メモ...'
                                                                value={formik.values.memo}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>

                            <div className="mt-4 mb-2 d-flex justify-content-center">
                                <div className={cxCommon('width-170')}>
                                    <Button
                                        type="submit"
                                        text={
                                            routeParams.id
                                                ? t<string>('common.save')
                                                : t<string>('common.register')
                                        }
                                        search></Button>
                                </div>
                                <NavLink style={{ marginLeft: '10px' }} className={'px-0 text-decoration-none bases__width--170'} to={''}>
                                    <Button
                                        onClick={() => {
                                            setIsShowPopupLeave(false);
                                            setModalState((prev: any) => {
                                                return { ...prev, showSecondModal: true };
                                            })
                                        }}
                                        text={t<string>('common.cancel')} del></Button>
                                </NavLink>
                            </div>

                        </div>
                        {modalAndress()}
                        {
                            showSecondModal && (
                                <ModalConfirm
                                    isShow={showSecondModal}
                                    handleOK={handleCancel}
                                    handleClose={() => showOrHideModal('showSecondModal')}
                                    header={t<string>('common.confirm')}
                                    content={t<string>('common.confirm_cancle')}></ModalConfirm>
                            )
                        }
                        {
                            showFourModal && (
                                <ModalConfirm
                                    hideNegativeButton
                                    isShow={showFourModal}
                                    handleOK={() => showOrHideModal('showFourModal')}
                                    handleClose={() => showOrHideModal('showFourModal')}
                                    header={t<string>('company_info.company_edit.modal.error_title')}
                                    content={t<string>(errorMessage)}></ModalConfirm>
                            )
                        }
                    </BodyContainer >
                </form >
            }
        </>

    );
};

export default CompanyAccountRegister;
