import commonStyles from '@assets/styles/base/Common.module.scss';
import ModalInterface from '@components/commons/Modal';
import { formatDateUTC } from '@utils/helpers/date';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import styles from './Modal.module.scss';
import { useMemo } from 'react';
import { handleGetFullAddress } from '@utils/helpers/utilities';
import { isEmpty } from 'lodash';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(commonStyles);

type IMModalAppointmentDetailProps = Pick<
    IModalComponentProps,
    'isShow' | 'handleClose' | 'handleOK' | 'hidePositiveButton' | 'buttonGreen'
> & {
    dataInit: any;
    typeBroker?: 'brokerRegistered' | 'brokerAll';
};

const AppointmentDetailModal: IModalComponent<IMModalAppointmentDetailProps> = (props) => {
    const { isShow, handleClose, dataInit, hidePositiveButton } = props;
    const { t } = useTranslation();

    const assetAddress: string = useMemo(() => {
        if (!dataInit?.asset?.postalCode) return '-';
        const address: IEnterAddressForm = {
            postalCode: dataInit?.asset?.postalCode,
            prefecture: dataInit?.asset?.prefecture,
            city: dataInit?.asset?.city,
            town: dataInit?.asset?.town,
            building: dataInit?.asset?.building,
        };
        return handleGetFullAddress(address);
    }, [dataInit]);

    const roomName: string = useMemo(() => {
        if (isEmpty(dataInit?.infoRooms)) return '-';
        const roomArray = dataInit?.infoRooms.map((item: any) => item.roomNumber);

        return roomArray.join('・');
    }, [dataInit]);

    return (
        <ModalInterface
            dialogClassname={cx('content-width', 'modal-fixed')}
            modalHeader={t('appointment.detail_title')}
            isShow={isShow}
            handleClose={handleClose}
            textBtn={'modal.broker.detail.btnContent'}
            hidePositiveButton={hidePositiveButton}
            negativeButtonText="common.close"
            classNameHeader="bases__margin--top24"
            classNameBody="pt-0">
            <div className={cxCommon('label-color', 'text-break')}>
                <div className="row mt-3 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.application_datetime')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.createdAt ? dataInit?.createdAt : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.user')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.broker?.name ? dataInit?.broker?.name : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.phone_number')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.broker?.phone ? dataInit?.broker?.phone : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.brokerage_company_name')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.broker_company?.name ? dataInit?.broker_company?.name : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.store_name')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.broker?.storeName ? dataInit?.broker?.storeName : '-'}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.asset_name')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.asset?.name ? dataInit?.asset?.name : '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.asset_address')}</div>
                    <div className="col fw-bold pe-4">{assetAddress}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('asset.detail_asset.room_number')}</div>
                    <div className="col fw-bold pe-4">{roomName}</div>
                </div>

                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.start_date')}</div>
                    <div className="col fw-bold pe-4">
                        {dataInit?.startDate ? formatDateUTC(dataInit?.startDate, 'YYYY/MM/DD HH:mm') : '-'}
                    </div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.end_date')}</div>
                    <div className="col fw-bold pe-4">{dataInit?.endDate ? formatDateUTC(dataInit?.endDate, 'YYYY/MM/DD HH:mm') : '-'}</div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.status')}</div>
                    <div className="col fw-bold pe-4">
                        {dataInit?.requestStatus == 0 ? (
                            <span style={{ color: '#001AFF', fontSize: '12px' }}>確認中</span>
                        ) : dataInit?.requestStatus == 1 ? (
                            <span style={{ color: '#4FA50C', fontSize: '12px' }}>承認</span>
                        ) : (
                            <span style={{ color: '#EF5050', fontSize: '12px' }}>拒否</span>
                        )}
                    </div>
                </div>
                <div className="row mt-2 ms-2">
                    <div className="bases__width-percent--30">{t('appointment.noted')}</div>
                    <div className="col fw-bold pe-4 text-pre-wrap">{(dataInit?.message && dataInit?.requestStatus === 2) ? dataInit?.message : '-'}</div>
                </div>
            </div>
        </ModalInterface>
    );
};

AppointmentDetailModal.defaultProps = {
    isShow: false,
};

export default AppointmentDetailModal;
