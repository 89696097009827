import { useTranslation } from 'react-i18next';

import styles from './Pagination.module.scss';
import classNames from 'classnames/bind';
import { images } from '~/utils/constants';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);

const Pagination: IPaginationComponent<IPaginationComponentProps> = ({
    currentPage: initCurrentPage,
    totalPage,
    pageRecords,
    totalRecords,
    firstRecords,
    onChangePage = () => undefined
}) => {
    const { t } = useTranslation();

    let pageNumber: Array<number> = new Array<number>();

    const [currentPage, setCurrentPage] = useState(initCurrentPage);
    const [state, setState] = useState<IPaginationState>({
        listPageToShow: [],
    });
    const { listPageToShow } = state;

    useEffect(() => {
        setState((prevState) => ({ ...prevState, listPageToShow1: [] }));
        pageNumber = [];
        for (let page = 1; page <= totalPage; page++) {
            if (
                page === 1 ||
                page === 2 ||
                page === totalPage - 1 ||
                page === totalPage ||
                page === currentPage ||
                page === currentPage - 1 ||
                page === currentPage + 1
            ) {
                pageNumber.push(page);
            }
        }
        setState((prevState) => ({ ...prevState, listPageToShow: pageNumber }));
    }, [totalPage, currentPage]);

    const renderPageNumber = (index: number) => {
        const indents = [];
        for (let page = 1; page <= index; page++) {
            if (listPageToShow.includes(page)) {
                indents.push(
                    <span key={page}
                        className={cx({ 'current': page === currentPage })}
                        onClick={() => handleClickPage(page)}
                    >
                        {page}
                    </span>
                );
            } else {
                if (page === currentPage - 2 || page === currentPage + 2) {
                    indents.push(<span key={page}>...</span>);
                }
            }
        }
        return indents;
    };

    const handleClickPage = (page: number) => {
        setCurrentPage(page);
        onChangePage(page);
    }

    return (
        <div className={cx('pagination')}>
            {pageRecords && totalRecords && (
                <span className={cx('pagination_total')}>{t('common.paginationShowing', { firstRecords, pageRecords, totalRecords })}</span>
            )}
            {totalPage > 1 && (
                <div className={cx('pagination_content')}>
                    <span
                        className={cx(currentPage === 1 && 'disabled')}
                        onClick={() => currentPage !== 1 && handleClickPage(currentPage - 1)}>
                        <img src={images.IMAGES.ICON_ARROW_LEFT} />
                    </span>
                    {renderPageNumber(totalPage)}
                    <span
                        className={cx(currentPage === totalPage && 'disabled')}
                        onClick={() => currentPage !== totalPage && handleClickPage(currentPage + 1)}>
                        <img src={images.IMAGES.ICON_ARROW_RIGHT} />
                    </span>
                </div>
            )}
        </div>
    );
};

export default Pagination;
