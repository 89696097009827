export const SET_LOADER = 'set_loader';
export const SET_SIDEBAR = 'set_sidebar';
export const SET_SHOW_ALERT = 'set_show_alert';
export const SET_HIDE_ALERT = 'set_hide_alert';
export const SET_USERS = 'set_users';
export const SET_ASSET = 'set_asset';
export const CHANGE_PASSWORD_ACTION = 'CHANGE_PASSWORD_ACTION';
export const CHANGE_PASSWORD_SUCCESS_ACTION = 'CHANGE_PASSWORD_SUCCESS_ACTION';
export const CHECK_ERROR = 'CHECK_ERROR';
export const SET_MODAL = 'set_modal';
export const ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED';
export const ACCOUNT_HAS_BEEN_DELETED = 'ACCOUNT_HAS_BEEN_DELETED';
export const ACCTION_HAS_BEEN_DELETED = 'ACCTION_HAS_BEEN_DELETED';
export const SHOW_POPUP_ACCESS_TOKEN_EXPIRED = 'SHOW_POPUP_ACCESS_TOKEN_EXPIRED';
export const SET_SHOW_CONFIRM_DELETE = 'SET_SHOW_CONFIRM_DELETE';
export const ADD_ROOM_ASSET = 'ADD_ROOM_ASSET';
export const EDIT_ROOM_ASSET = 'EDIT_ROOM_ASSET';
export const SET_HIDE_CONFIRM_DELETE = 'SET_HIDE_CONFIRM_DELETE';
export const SHOW_NOT_FOUND = 'show_not_found';
export const SET_SUCCESS_CALL_API = 'show_success_api';
export const SET_USER_INFO = 'set_user_info';
export const SET_TOAST = 'set_toast';
export const SHOW_DELETED = 'show_deleted';
export const GET_LIST_STAFFS = 'GET_LIST_STAFFS';
export const SET_LIST_DATA_ALL = 'SET_LIST_DATA_ALL';
export const SET_ACCUONT_NOT_PERMISSION = 'SET_ACCUONT_NOT_PERMISSION';

