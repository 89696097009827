const API = {
    USER: '/users',
    USER_INFO: '/user/info',
    COMPANY_INFO: '/user/company',
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    ASSET: '/assets',
    CHANGE_PASSWORD: '/user/password',
    NOTIFICATION: '/notification',
    GEOCODING: 'https://maps.googleapis.com/maps/api/geocode/json',
    REFRESH_TOKEN: '/auth/new-token',
    GET_ADDRESS_BY_POSTAL_CODE: '/master/postal-code/',
    CHANGE_STATUS: '/assets/privacy',
    DELETE_ASSET: '/assets/delete',
    GET_LIST_BROKER: '/brokers',
    GET_LIST_BROKER_REGISTERED: '/brokers/registered',
    GET_LIST_BROKER_COMPANY: '/broker-company/auto-approve',
    AUTO_APPROVE_BROKER_COMPANY: '/auto-approve/broker-company',
    AUTO_APPROVE_BROKER_COMPANY_BDS: '/auto-approve/broker',
    ADD_BROKER_TO_COMPANY: '/companies/brokers/',
    GET_LIST_KEYBOX: '/keyboxies',
    DELETE_KEYBOX: '/keyboxies',
    ADD_ROOM_ASSET: '/rooms/',
    ROOMS: '/rooms',
    REGISTER_ASSET: '/assets/',
    LIST: '/list',
    ASSET_ROOM: '/rooms/list/',
    ROOM_DETAIL: '/rooms/',
    EXPORT_ROOM: '/assets/rooms/export',
    IMPORT_ROOM: '/assets/rooms/import',
    REGISTER_STAFFS: '/staffs/',
    ACCOUNT_COMPANY: '/companies',
    GET_LIST_STAFFS: '/staffs/',
    RESETPASSWORD_STAFFS: '/staffs/reset-password',
    GET_LIST_HISTORY_SETUP_KEYBOX: '/history/historyOtpSetupKeybox',
    GET_LIST_NOTIFICATION: '/notification',
    READ_LIST_NOTIFICATION: '/notification/read',
    GET_DETAIL_HISTORY_SETUP_KEYBOX: '/keyboxies/history-setup/',
    GET_DETAIL_HISTORY: '/keybox-open-history/',
    GET_SURVEY_QUESTIONS: '/surveys/questions/',
    GET_SURVEY_QUESTIONS_PUBLIC: '/surveys/questions/public',
    GET_LIST_HISTORY: '/history/',
    GET_SURVEY_ANSWERS: '/surveys/answers/list',
    GET_SURVEY_ANSWER_DETAIL: '/surveys/answers/detail',
    GET_SURVEY_ANSWER_STATISTICAL: '/surveys/answers/statistical',
    // Appointment
    APPOINTMENT_LIST: '/appointments',
    APPOINTMENT_UPDATE: '/appointments/update',
    // One Time Pass
    ONE_TIME_PASS_LIST: '/otp',
    ONE_TIME_PASS_SCHEDULE: '/appointments/room',
    PAYMENT: '/payment',
};

export { API };
