import classNames from 'classnames/bind';
import { t } from 'i18next';
import { Button } from '~/components/commons';
import styles from './SearchForm.module.scss';

const cx = classNames.bind(styles);

interface IFormInputProps extends IBaseCompProps {
    disabledDelete?: boolean;
    classNameBtnSearch?: string;
    classNameBtnDelete?: string;
    btnDelete?: boolean;
    className?: string;
    isDisableBtnDelete?: boolean;
    isDisableBtnSearch?: boolean;
    onSubmit?: () => void;
    onDelete?: () => void;
}

const SearchForm: IBaseComp<IFormInputProps> = ({
    btnDelete,
    children,
    className,
    onSubmit = () => undefined,
    onDelete = () => undefined,
    classNameBtnSearch,
    classNameBtnDelete,
    isDisableBtnDelete,
    isDisableBtnSearch
}) => {
    return (
        <form className={cx('form_container') + ' ' + className} onSubmit={onSubmit}>
            {children}
            <div className={cx('justify-content-end d-flex', 'gl-gap-10')}>
                <div>
                    <Button className={cx('btn-search', classNameBtnSearch)} filter text={t<string>('common.search')} type="submit" disabled={isDisableBtnSearch} />
                </div>
                {btnDelete && (
                    <div>
                        <Button
                            disabled={isDisableBtnDelete}
                            type="button"
                            className={cx('btn-delete', classNameBtnDelete)}
                            text={t<string>('common.condition_deletion')}
                            onClick={onDelete}
                        />
                    </div>
                )}
            </div>
        </form>
    );
};

export default SearchForm;
