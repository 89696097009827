import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';
import styles from './AccountManagement.module.scss';
import { ButtonStyle, TableStyle, Layout, Input, Common } from '@assets/styles';
import { images } from '@utils/constants';
import { BodyContainer } from '~/components/layouts';
import { usePrompt } from '@utils/hook/usePormpt';

const cx = classNames.bind(styles);
const cxTable = classNames.bind(TableStyle);
const cxButton = classNames.bind(ButtonStyle);
const cxLayout = classNames.bind(Layout);
const cxInput = classNames.bind(Input);
const cxCommon = classNames.bind(Common);

const AccountManagement: IBaseComp<IBaseCompProps> = (props) => {
    const { t } = useTranslation();
    const companyData = {
        logo: 'A',
        email: 'A',
        companyName: 'ABC会社',
        manager: '高橋',
        affilation: '部長',
        phoneNumber: '080-XXXX-XXXX',
        closedDate: '10',
        address: 'xxxxxxxxxxxxx',
        workingHour: '8:00 ~ 5:00',
        numberOfPropeties: 10,
    };
    usePrompt('サイトから移動しますか？\n変更内容が保存されない可能性があります。',true)

    return (
        <BodyContainer title={t('title.new_account')}>
            <div className={'position-relative w-100'}>
                <div className="row w-100 mt-4">
                    <div className={'col-6 ' + cx('text-title')}>{t('account_management.personal_info')}</div>
                    <div className={'col-6 ' + cx('text-title')}>{t('account_management.info_management')}</div>
                </div>
                <div className="row w-100 mt-3">
                    <div className="col-6 h-100">
                        <div className={'height-content h-100 ' + cx('content')}>
                            <div className="row">
                                <div className={'col-5 d-flex justify-content-center pt-2'}>
                                    <img src={images.IMAGES.TEMP_IMAGE} className={cx('image-account')} alt="" />
                                </div>
                                <div className="col-7">
                                    <div className="row pt-2">
                                        <div className="col">
                                            {t('account_management.company_logo')}: <span className={'fw-bold'}>{companyData.logo}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.email')}: <span className={'fw-bold'}>{companyData.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.company_name')}:{' '}
                                            <span className={'fw-bold'}>{companyData.companyName}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.manager')}: <span className={'fw-bold'}>{companyData.manager}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.affiliation')}:{' '}
                                            <span className={'fw-bold'}>{companyData.affilation}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.phone_number')}:{' '}
                                            <span className={'fw-bold'}>{companyData.phoneNumber}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.closed_date')}: {companyData.closedDate}
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.address')}: {companyData.address}
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.working_hour')}: {companyData.workingHour}
                                        </div>
                                    </div>
                                    <div className="row mt-3 pt-1">
                                        <div className="col">
                                            {t('account_management.number_of_properties')}: {String(companyData.numberOfPropeties)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={cxCommon('height-content') + ' ' + cx('content')}>
                            <div className="row">
                                <div className={'col-4'}>
                                    <div className="row pt-2">
                                        <div className="col pt-2">{t('account_management.unlock_application')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">{t('account_management.public_setting')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">{t('account_management.setting_viewing_time')}: </div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">{t('account_management.setting_viewing_time_unit')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">{t('account_management.setting_entry_exit_times')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">{t('account_management.move_in_out')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-3">
                                        <div className="col">{t('account_management.moving_out')}:</div>
                                    </div>
                                    <div className="row mt-3 pt-2">
                                        <div className="col">
                                            {t('account_management.account_id')}: <span className={'fw-bold'}>ABC</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.not_auto_approve')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.auto_approve')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.public')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.private')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.use')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.not_use')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.use')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.not_use')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.use')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-check pt-2">
                                                <input className="form-check-input" type="radio" name="name1" id="check_unlocking" />
                                                <label
                                                    className={'form-check-label ' + cxCommon('text-positive')}
                                                    htmlFor="check_unlocking">
                                                    {t('account_management.not_use')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col">
                                            <input type="text" className={'form-control'} />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col">
                                            <input type="text" className={'form-control'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BodyContainer>
    );
};

export default AccountManagement;
