import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { t } from 'i18next';
import styles from './Form.module.scss';
import commonStyles from '@assets/styles/base/Common.module.scss';

const cx = classNames.bind(styles);

export type FormItemType = {
    key?: string;
    label: string;
    defaultChecked?: boolean;
};

interface IFormCheckboxProps {
    className?: string;
    classNameContent?: string;
    classNameGroupItem?: string;
    label?: string;
    classNameInput?: string;
    items?: FormItemType[];
    readOnly?: boolean;
    onCheckItem?: (idx: number, checked: boolean) => void;
}


const cxCommon = classNames.bind(commonStyles);
const FormCheckbox: IBaseComp<IFormCheckboxProps> = ({
    className = '',
    classNameContent = '',
    classNameGroupItem = '',
    classNameInput = '',
    label = '',
    items = [],
    readOnly = false,
    onCheckItem = () => undefined,
}) => {
    const [data, setData] = useState<FormItemType[]>([]);

    useEffect(() => {
        const data: FormItemType[] = [];
        items.forEach((item, idx) => {
            data.push({
                key: `cb-${idx}-${item.label}`,
                label: item.label,
                defaultChecked: item.defaultChecked,
            });
        })
        setData(data);
    }, [items]);

    const handleDataChange = (idx: number, checked: boolean) => {
        onCheckItem(idx, checked);
    };

    return (
        <div className={className}>
            <div className={`row ${classNameContent}`}>
                {label && <label className="col-md-4 col-form-label text-end pe-0">{t(label)}</label>}
                {data.map((item, idx) =>
                    <div key={item.key} className="col">
                        <div className={`form-check mt-2 ${classNameGroupItem}`}>
                            <input
                                id={item.key}
                                type="checkbox"
                                className={`form-check-input ${cxCommon(classNameInput)}`}
                                defaultChecked={item.defaultChecked}
                                onChange={e => handleDataChange(idx, e.target.checked)}
                                readOnly={readOnly}
                            />
                            {item.label && <label className={`form-check-label ${cxCommon('width-fill-available','bases__text--label')}`} htmlFor={readOnly ? '' : item.key}>{t(item.label)}</label>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormCheckbox;
