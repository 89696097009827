import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxStates } from '~/redux/reducers';
import { images } from '@utils/constants';
import MenuItems from './MenuItems';
import Configs from '@utils/configs';

import classNames from 'classnames/bind';
import styles from './Sidebar.module.scss';

const cx = classNames.bind(styles);

const Sidebar: IBaseComp = () => {
    const { sidebar, userInfo } = useSelector((states: ReduxStates) => states);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const navigationData = useMemo(
        () => [
            {
                title: 'sidebar.dashboard',
                image: <images.IMAGES.ICON_DASHBOARD />,
                url: Configs.routes.dashboard,
            },
            {
                title: 'sidebar.inout.main',
                image: <images.IMAGES.ICON_IN_OUT />,
                submenu: [
                    {
                        title: 'sidebar.inout.appointment_management',
                        url: Configs.routes.appointment,
                    },
                    {
                        title: 'sidebar.inout.one_time_pass',
                        url: Configs.routes.oneTimePassword,
                    },
                    {
                        title: 'sidebar.inout.keybox_lock_unlock_history',
                        submenu: [
                            { title: 'appointment.intermediary', url: Configs.routes.brokerHistory },
                            { title: 'appointment.employee_name', url: Configs.routes.employeeHistory },
                            { title: 'appointment.worker_history', url: Configs.routes.workerHistory },
                            { title: 'appointment.one_time_history', url: Configs.routes.oneTimeHistory },
                        ],
                    },
                    {
                        title: 'sidebar.inout.keybox_setup_history',
                        url: Configs.routes.keyboxHistory,
                    },
                ],
            },
            {
                title: 'sidebar.asset.main',
                image: <images.IMAGES.ICON_ASSETS />,
                url: Configs.routes.asset,
            },
            {
                title: 'sidebar.asset.keybox_list',
                url: Configs.routes.keybox,
                image: <images.IMAGES.ICON_KEYBOX_SVG />,
            },
            {
                title: 'sidebar.user_info.main',
                image: <images.IMAGES.ICON_BROKER />,
                submenu: [
                    {
                        title: 'sidebar.user_info.broker_register',
                        url: Configs.routes.broker,
                    },
                    {
                        title: 'sidebar.user_info.intermediary_system_user',
                        url: Configs.routes.brokerSystem,
                    },
                ],
            },
            {
                title: 'sidebar.user_manager.main',
                image: <images.IMAGES.ICON_USER_INFO_SVG />,
                submenu: [
                    // {
                    //     title: 'sidebar.user_manager.new_account',
                    //     url: Configs.routes.accountDetail,
                    // },
                    {
                        title: 'sidebar.user_manager.employee_worker_account',
                        url: Configs.routes.employeeWorker,
                    },
                ],
            },
            {
                title: 'アンケート管理',
                image: <images.IMAGES.ICON_MANAGER />,
                submenu: [
                    {
                        title: 'アンケート結果集計',
                        url: Configs.routes.summaryQuestion,
                    },
                    {
                        title: 'アンケート回答一覧',
                        url: Configs.routes.questionnaireManagement,
                    },
                    {
                        title: 'アンケート質問管理',
                        url: Configs.routes.surveyQuestion,
                    },
                ],
            },
            {
                title: 'sidebar.payment_manager',
                image: <images.IMAGES.ICON_PAYMENT_SVG />,
                url: Configs.routes.payment,
            },
            {
                title: 'sidebar.manual',
                image: <images.IMAGES.ICON_PAPER_SVG />,
                url: Configs.routes.manual,
            },
            // {
            //     title: 'sidebar.team',
            //     image: <images.IMAGES.ICON_TERM />,
            //     url: Configs.routes.terms,
            // },
            {
                title: 'sidebar.notification',
                image: <images.IMAGES.ICON_NOTIFICATION_SVG />,
                url: Configs.routes.notification,
            },
        ],
        [],
    );

    const navigationSpuerAdminData = useMemo(
        () => [
            {
                title: 'sidebar.dashboard',
                image: <images.IMAGES.ICON_DASHBOARD />,
                url: Configs.routes.dashboard,
            },

            {
                title: 'sidebar.asset.keybox_list',
                url: Configs.routes.keybox,
                image: <images.IMAGES.ICON_KEYBOX_SVG />,
            },
            {
                title: 'sidebar.user_info.main',
                image: <images.IMAGES.ICON_BROKER />,
                submenu: [
                    {
                        title: 'sidebar.user_info.intermediary_system_user',
                        url: Configs.routes.brokerSystem,
                    },
                ],
            },
            {
                title: 'sidebar.user_manager.main',
                image: <images.IMAGES.ICON_USER_INFO_SVG />,
                submenu: [
                    {
                        title: 'sidebar.user_manager.management_company_account',
                        url: Configs.routes.company,
                    },
                ],
            },
            {
                title: 'sidebar.payment_manager',
                image: <images.IMAGES.ICON_PAYMENT_SVG />,
                url: Configs.routes.payment,
            },
            {
                title: 'sidebar.manual',
                image: <images.IMAGES.ICON_PAPER_SVG />,
                url: Configs.routes.manual,
            },
            {
                title: 'sidebar.notification',
                image: <images.IMAGES.ICON_NOTIFICATION_SVG />,
                url: Configs.routes.notification,
            },
            // {
            //     title: 'sidebar.team',
            //     image: <images.IMAGES.ICON_TERM />,
            //     url: Configs.routes.terms,
            // },
        ],
        [],
    );

    const handleClick = () => {
        navigate(Configs.routes.dashboard);
    };

    return (
        <div className={cx('container-drawer', sidebar ? 'open-sidebar' : 'd-none')}>
            <div className={cx('logo-navigation')} onClick={handleClick}>
                <images.IMAGES.LOGO_GUARDLOCK />
                <images.IMAGES.LOGO_BENRI />
            </div>
            {(userInfo.user_roleId === 1 ? navigationSpuerAdminData : navigationData).map((item, index) => (
                <MenuItems key={index} items={item} />
            ))}
        </div>
    );
};

export default Sidebar;
