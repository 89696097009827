import classNames from 'classnames/bind';
import styles from './Modal.module.scss';
import stylesCommon from '@assets/styles/base/Common.module.scss';
import { useTranslation } from 'react-i18next';
import ModalInterface from '@components/commons/Modal';

const cx = classNames.bind(styles);
const cxCommon = classNames.bind(stylesCommon);

const ModalConfirm: IModalComponent<IModalConfirmProps> = (props) => {
    const { header, isShow, handleOK, handleClose, content, hideNegativeButton, hidePositiveButton, negativeButtonText } = props;
    const { t } = useTranslation();

    return (
        <ModalInterface
            dialogClassname={cx('modal-delete-width', 'modal-fixed')}
            modalHeader={header}
            classNameBody={cxCommon('bases__height-max-percent--90','overflow-hidden')}
            isShow={isShow}
            handleOK={handleOK}
            handleClose={handleClose}
            positiveButtonText="common.ok"
            hideNegativeButton={hideNegativeButton}
            hidePositiveButton={hidePositiveButton}
            negativeButtonText={negativeButtonText}
            >
            <div className="row">
                <div className={'col text-center'}>
                    <span className={cx('text-content') + ' ' + cxCommon('label-color')}>{content}</span>
                </div>
            </div>
        </ModalInterface>
    );
};

ModalConfirm.defaultProps = {
    isShow: false,
};

export default ModalConfirm;
