import { API_URL, SOCKET_PATH, SOCKET_URL, WEB_NAME } from './env';

console.info('API Base Url: ', API_URL || '');
console.info('Web Name: ', WEB_NAME || 'GuardLock');

export default {
    API_URL: API_URL || '',
    WEB_NAME: WEB_NAME || 'GuardLock',
    SOCKET_URL: SOCKET_URL || '',
    SOCKET_PATH: SOCKET_PATH || '',
};
