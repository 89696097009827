import { useState, useCallback, useEffect } from 'react';
import styles from './Dropdown.module.scss';
import classNames from 'classnames/bind';
import { images } from '@utils/constants';
const cx = classNames.bind(styles);

interface IInitialProps {
    children?: React.ReactNode;
    placeholder?: string,
    styles?: any,
    disabled?: boolean
    defaultValue?: string
}

const arrlanguge = [
    {
        name: 'Vietnamese',
    },
    {
        name: 'English',
    },
    {
        name: 'Janpanese',
    },
    {
        name: 'Chinaese',
    },
    {
        name: 'Malaysiaese',
    },
];

export const Dropdown: React.FC<IInitialProps> = (props) => {
    const { placeholder, styles, disabled, defaultValue } = props
    const [isDrop, setIsDrop] = useState(false);
    const [selectDrop, setSelectDrop] = useState<string>();

    useEffect(()=> {
        setSelectDrop(defaultValue)
    })
    const handleSortByItem = useCallback((name: string) => {
        setSelectDrop(name);
        setIsDrop(false);
    }, []);

    return (
        <div className={styles ? 'w-100 position-relative ' + styles : 'w-100 position-relative '} style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
            <div
                className={cx('select_drop')}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsDrop(!isDrop);
                }}>
                <p
                    style={{
                        marginRight: '8px',
                        color: selectDrop ? '#333333' : '#999999'
                    }}>
                    {selectDrop ?? placeholder}
                </p>
                <div>
                    <img src={images.IMAGES.ICON_DROPDOWN} ></img>
                </div>
            </div>
            {isDrop && (
                <div>
                    <div className={'pt-3 ' + cx('select_list')}>
                        {arrlanguge.map((item, index) => (
                            <div
                                className={cx('select_listItem', {'item_selected': selectDrop === item.name})}
                                key={index}
                                onClick={() => handleSortByItem(item.name)}>
                                <p>{item.name}</p>
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            top: '0',
                            visibility: 'visible',
                            width: '100%',
                            height: '100vh',
                            position: 'fixed',
                            left: '0',
                            zIndex: 0,
                        }}
                        onClick={() => { setIsDrop(false) }}
                    />
                </div>
            )}
        </div>
    );
};
