import commonStyles from '@assets/styles/base/Common.module.scss';
import tableStyles from '@assets/styles/base/Table.module.scss';
import { Button } from '@components/commons';
import Configs from '@utils/configs';
import {
    ASSET_PUBLICATION_ENUM,
    images,
    RESERVATION_TIME_ENUM,
    SURVEY_REQUIRE_ENUM,
    TYPE_ACTION_SLIDE,
    UNLOCK_APPLICATION_ENUM,
    USER_ROLE_ENUM,
} from '@utils/constants';
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompanyForm } from '~/components/forms';
import { timeDropDown } from '~/utils/constants/input';
import { getCompanyInfo } from '~/utils/helpers/api';
import { dayOfWeekAsString } from '~/utils/helpers/date';
import { handleGetFullAddress, stringToListNumber } from '~/utils/helpers/utilities';
import styles from './Company.module.scss';
import { useSelector } from 'react-redux';
import { ReduxStates } from '@redux/reducers';
import useRole from '@utils/hook/useRole';

const cx = classNames.bind(styles);
const cxTable = classNames.bind(tableStyles);
const cxCommon = classNames.bind(commonStyles);

const CompanyInfo: IBaseComp<IBaseCompProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfoDataAPI | null>(null);
    const [dayHoliday, setDayHoliday] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const slideRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [currentPositionScroll, setCurrentPositionScroll] = useState<number>(0);
    const [maxPositionScroll, setMaxPositionScroll] = useState<number>(0);
    const [listImageSlide, setListImageSlide] = useState<IImageSlide[]>([]);
    const [imageSlide, setImageSlide] = useState<string>('');
    const { userInfo } = useSelector((states: ReduxStates) => states);
    const isAdminPermission = useRole(userInfo, USER_ROLE_ENUM.admin);

    const handleClick = (selectedIndex: number) => {
        setActiveIndex(selectedIndex);
    };

    useEffect(() => {
        slideRef?.current?.addEventListener('scroll', handleScroll);

        const fetchCompanyInfo = async () => {
            try {
                const response = await getCompanyInfo();
                setCompanyInfo(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchCompanyInfo();
        return () => {
            slideRef?.current?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (slideRef?.current) {
            setMaxPositionScroll(slideRef?.current?.scrollWidth - slideRef?.current?.offsetWidth);
        }
    }, [companyInfo]);

    useEffect(() => {
        if (listImageSlide?.length) {
            const imageSlide: IImageSlide[] = listImageSlide.filter((image) => image.order === activeIndex);
            setImageSlide(imageSlide[0].url);
        }
    }, [activeIndex]);

    const handleScroll = () => {
        if (slideRef?.current) {
            setCurrentPositionScroll(slideRef?.current?.scrollLeft);
        }
    };

    const handleNextOrPrev = (typeAction: number) => {
        const addPosition = imageRef?.current ? imageRef?.current?.offsetWidth * 3 : 0;

        const nextPosition =
            typeAction === TYPE_ACTION_SLIDE.NEXT ? currentPositionScroll + addPosition : currentPositionScroll - addPosition;

        slideRef?.current?.scrollTo(nextPosition, 0);
    };

    useEffect(() => {
        if (companyInfo && Object.keys(companyInfo).length !== 0) {
            // handle get holiday
            const getDayHoliday = (): string => {
                let dayHoliday = '';
                const listHoliday = stringToListNumber(companyInfo.holidays);
                listHoliday.forEach((holiday: number) => {
                    dayHoliday = dayHoliday.concat(dayHoliday ? ', ' : '', dayOfWeekAsString(holiday));
                });
                return dayHoliday;
            };

            // handle get address
            const addressParams: IEnterAddressForm = {
                postalCode: companyInfo.postalCode,
                prefecture: companyInfo.prefecture,
                city: companyInfo.city,
                town: companyInfo.town,
                building: companyInfo.building,
            };

            setAddress(handleGetFullAddress(addressParams));
            setDayHoliday(getDayHoliday());

            // handle list image
            if (companyInfo?.images?.length) {
                setListImageSlide(companyInfo.images);
                setImageSlide(companyInfo.images[0].url);
            }
        }
    }, [companyInfo]);

    return (
        <>{
            isAdminPermission &&
            <CompanyForm>
                <div className="row">
                    <div className="col">
                        <div className={'mt-0 d-flex justify-content-center align-items-center '}>
                            <img
                                className={'w-100 ' + cx('company-image', imageSlide ? 'gl-object-fit-contain' : 'gl-object-fit-cover')}
                                src={imageSlide ? imageSlide : images.IMAGES.DEFAULT_IMAGE}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className={cx('slide', 'd-flex justify-content-center align-items-center')}>
                            <div className={cx('slide_container')}>
                                {/* <div
                                onClick={() => handleNextOrPrev(TYPE_ACTION_SLIDE.PREV)}
                                className={cx('slide_button') + ' ' + cx(currentPositionScroll === 0 ? 'slide_button--disable' : '')}>
                                <ICON_PREV stroke={currentPositionScroll === 0 ? '#999999' : '#000000'} />
                            </div> */}
                                <div ref={slideRef} className={cx('slide_list')}>
                                    {listImageSlide.map((image, index) => {
                                        return (
                                            <div
                                                key={image.order}
                                                className={
                                                    cx('slide_img_edit') + ' ' + cx(activeIndex === image.order ? 'slide_img_edit--active' : '')
                                                }>
                                                <img
                                                    ref={imageRef}
                                                    src={image.url}
                                                    alt=""
                                                    onClick={() => handleClick(image.order)}
                                                    className={cx('img_edit')}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                {/* <div
                                onClick={() => handleNextOrPrev(TYPE_ACTION_SLIDE.NEXT)}
                                className={
                                    cx('slide_button') +
                                    ' ' +
                                    cx(currentPositionScroll === maxPositionScroll ? 'slide_button--disable' : '')
                                }>
                                <ICON_NEXT stroke={currentPositionScroll === maxPositionScroll ? '#999999' : '#000000'} />
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 col-12">
                    {/* <div className="d-flex">
                        <div className="col-6 mb-3 card-header d-flex align-items-center">
                            <span className={cxCommon('fw-bold', 'label-color', 'font-size-16')}>
                                {t('company_info.company.basic_information')}
                            </span>
                        </div>
                        <div className="col-6 ms-3 mb-3 card-header d-flex align-items-center">
                            <span className={cxCommon('fw-bold', 'label-color', 'font-size-16')}>{t('company_info.company.setting_info')}</span>
                        </div>
                    </div> */}

                    <div className={cxCommon('d-flex', 'font-size-14', 'label-color') + ' ' + cx('info-company-container')} >
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                            <div className="col-6 mb-3 card-header d-flex align-items-center">
                                <span className={cxCommon('fw-bold', 'label-color', 'font-size-16')}>
                                    {t('company_info.company.basic_information')}
                                </span>
                            </div>
                            <div className="col-6 d-flex" style={{ border: '1px solid #e2e2e2', borderRadius: '5px', width: '100%', flex: 1 }}>
                                <div className="col-xxl-3 col-5 ms-1 mt-3 d-flex justify-content-center">
                                    <div className={cx('logo-container')}>
                                        <img className={cx('side-image')} src={companyInfo?.logo ? companyInfo.logo : images.IMAGES.NO_IMAGE_ONE} />
                                    </div>
                                </div>
                                <div className={cxCommon('col-xxl-9 col-7 mt-3 ps-3 pe-3')}>
                                    <div className="row">
                                        <div className={'d-flex '}>
                                            <div className="h-auto me-2">{t('company_info.company.company_name')}:</div>
                                            <div className={'pe-0 col fw-bold'}>{companyInfo?.name || ''}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <div className="h-auto me-2">  {t('company_info.company.manager')}:</div>
                                            <div className={'pe-0 col fw-bold'}>{companyInfo?.admin.manager || ''}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <div className="h-auto me-2"> {t('company_info.company.affiliation')}:</div>
                                            <div className={'pe-0 col fw-bold'}>{companyInfo?.admin.affiliation || ''}</div>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <span className="h-auto me-2">   {t('company_info.company.phone_number')}:</span>
                                            <span className={'pe-0 col fw-bold'}>{companyInfo?.phoneNumber || ''}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className='d-flex flex-wrap'>
                                            <div className="h-auto w-auto me-2 flex-xxl-grow-0 flex-xxl-shrink-0 flex-basis-xxl-auto"> {t('company_info.company.email_address_of_person_in_charge')}:</div>
                                            <div className='fw-bold'>{companyInfo?.admin.emailAdmin || ''}</div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <span className="h-auto me-2">{t('company_info.company.regular_holiday')}:</span>
                                            <span className={'pe-0 col fw-bold'}>{dayHoliday}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex'}>
                                            <span className="h-auto me-2"> {t('company_info.company.company_address')}:</span>
                                            <span className={'pe-0 col fw-bold'}>{address}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <span className="h-auto me-2"> {t('company_info.company.business_hours')}:</span>
                                            <span className={'pe-0 col fw-bold'}>  {companyInfo?.businessStartHour || ''} ~ {companyInfo?.businessEndHour || ''}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className={'d-flex '}>
                                            <span className="h-auto me-2">{t('company_info.company.number_of_object_management')}:</span>
                                            <span className={'pe-0 col fw-bold'}>  {companyInfo?.assetAmount || ''} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={cx('setting-information')} style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
                            <div className="col-6 ms-3 mb-3 card-header d-flex align-items-center zxz">
                                <span className={cxCommon('fw-bold', 'label-color', 'font-size-16')}>{t('company_info.company.setting_info')}</span>
                            </div>
                            <div className="col-6 ms-3" style={{ border: '1px solid #e2e2e2', borderRadius: '5px', width: '100%', flex: 1 }}>
                                <div className="col-12">
                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.application_for_unlocking')}</div>
                                        <div className="col-6 d-block">
                                            <div>
                                                <span className={'fw-bold ' + cxCommon(companyInfo?.unlockApplication ? 'text-green' : 'text-red')}>
                                                    {companyInfo?.unlockApplication !== undefined
                                                        ? UNLOCK_APPLICATION_ENUM[companyInfo?.unlockApplication]
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.property_publication_settings')}</div>
                                        <div className="col-6 d-block">
                                            <div>
                                                <span className={'fw-bold ' + cxCommon(companyInfo?.assetPublication ? 'text-green' : 'text-red')}>
                                                    {companyInfo?.assetPublication !== undefined
                                                        ? ASSET_PUBLICATION_ENUM[companyInfo?.assetPublication]
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.setting_the_viewing_time_zone')}</div>
                                        <div className="col-6 d-block">
                                            <div>
                                                <span
                                                    className={
                                                        'fw-bold ' + cxCommon(companyInfo?.reservationTimeZoneEnabled ? 'text-green' : 'text-red')
                                                    }>
                                                    {companyInfo?.reservationTimeZoneEnabled !== undefined
                                                        ? RESERVATION_TIME_ENUM[companyInfo?.reservationTimeZoneEnabled]
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className={cxCommon('mt-2', companyInfo?.reservationTimeZoneEnabled === 0 ? 'display-none' : '')}>
                                                {t('company_info.company.viewing_time_zone')}:&nbsp;
                                                <span className={'fw-bold '}>
                                                    {companyInfo?.reservationTimeZoneEnabled !== (undefined || null)
                                                        ? companyInfo?.reservationTimeZoneStartHour +
                                                        ' ~ ' +
                                                        companyInfo?.reservationTimeZoneEndHour
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.setting_the_viewing_time_unit')}</div>{' '}
                                        <div className="col-6 d-block">
                                            <div>
                                                <span
                                                    className={
                                                        'fw-bold ' + cxCommon(companyInfo?.reservationTimeUnitEnabled ? 'text-green' : 'text-red')
                                                    }>
                                                    {companyInfo?.reservationTimeUnitEnabled !== undefined
                                                        ? RESERVATION_TIME_ENUM[companyInfo?.reservationTimeUnitEnabled]
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className={cxCommon('mt-2', companyInfo?.reservationTimeUnitEnabled === 0 ? 'display-none' : '')}>
                                                {t('company_info.company.viewing_time_unit')}:&nbsp;
                                                <span className={'fw-bold '}>
                                                    {companyInfo?.reservationTimeUnitUnit !== undefined
                                                        ? timeDropDown.map((item) => {
                                                            if (item.value === companyInfo?.reservationTimeUnitUnit) {
                                                                return item.label;
                                                            }
                                                        })
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.setentry/exit_times')}</div>{' '}
                                        <div className="col-6 d-block">
                                            <div>
                                                <span
                                                    className={
                                                        'fw-bold ' +
                                                        cxCommon(companyInfo?.reservationEntryOffsetEnabled ? 'text-green' : 'text-red')
                                                    }>
                                                    {companyInfo?.reservationEntryOffsetEnabled !== undefined
                                                        ? RESERVATION_TIME_ENUM[companyInfo?.reservationEntryOffsetEnabled]
                                                        : ''}
                                                </span>
                                            </div>
                                            <div
                                                className={cxCommon(
                                                    companyInfo?.reservationEntryOffsetEnabled === 0 ? 'display-none' : 'd-flex mt-2',
                                                )}>
                                                <div className="col-6">
                                                    {t('company_info.company.move_in_out')}:&nbsp;
                                                    <span className={'fw-bold '}>
                                                        {companyInfo?.reservationEntryOffsetStart !== undefined
                                                            ? companyInfo?.reservationEntryOffsetStart + '分'
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className="col-6">
                                                    {t('company_info.company.moving_out')}:
                                                    <span className={'fw-bold '}>
                                                        {' '}
                                                        {companyInfo?.reservationEntryOffsetEnd !== undefined
                                                            ? companyInfo?.reservationEntryOffsetEnd + '分'
                                                            : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.setting_the_number_of_viewing_reservation_deadlines')}</div>{' '}
                                        <div className="col-6 d-block">
                                            <div>
                                                <span
                                                    className={
                                                        'fw-bold ' +
                                                        cxCommon(companyInfo?.reservationRequestLimitEnabled ? 'text-green' : 'text-red')
                                                    }>
                                                    {companyInfo?.reservationRequestLimitEnabled !== undefined
                                                        ? RESERVATION_TIME_ENUM[companyInfo?.reservationRequestLimitEnabled]
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className={cxCommon(companyInfo?.reservationRequestLimitEnabled === 0 ? 'display-none' : 'mt-2')}>
                                                {t('company_info.company.Number_of_times_limit')}:&nbsp;
                                                <span className={'fw-bold '}>
                                                    {companyInfo?.reservationRequestLimitNumber !== undefined
                                                        ? companyInfo?.reservationRequestLimitNumber + '回'
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row ms-3 mt-3 d-flex">
                                        <div className="col-6">{t('company_info.company.survey_require')}</div>{' '}
                                        <div className="col-6 d-block">
                                            <div>
                                                <span
                                                    className={
                                                        'fw-bold ' + cxCommon(companyInfo?.reservationSurveyRequired ? 'text-green' : 'text-red')
                                                    }>
                                                    {companyInfo?.reservationSurveyRequired !== (undefined && null)
                                                        ? SURVEY_REQUIRE_ENUM[companyInfo?.reservationSurveyRequired]
                                                        : ''}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 ">
                        <div className="col d-flex justify-content-center">
                            <div className="col-2">
                                <Button text={'編集'} search onClick={() => navigate(Configs.routes.companyInfoEdit)}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CompanyForm>
        }</>

    );
};

export default CompanyInfo;
