import { deleteCard, getListPayment, unlockCard } from './api';
export const fetchGetListPayment = async (params: SearchParamsNotification): Promise<IBaseAPIResGeneric<IDataPayment>> => {
    try {
        const response = await getListPayment(params);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchDeleteCard = async (): Promise<IBaseAPIRes> => {
    try {
        const response = await deleteCard();
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};

export const fetchUnlockCard = async (body: { companyIdUnlock: number }): Promise<IBaseAPIRes> => {
    try {
        const response = await unlockCard(body);
        return response.data;
    } catch (error: any) {
        console.log(error);
        return error;
    }
};
