import { IAlertReduxData } from '~/interfaces/redux/alert';
import * as Types from './type';

export const setLoader = (data = false) => {
    return {
        type: Types.SET_LOADER,
        data,
    };
};
export const setModal = (data = false) => {
    return {
        type: Types.SET_MODAL,
        data,
    };
};
export const setSidebar = (data = false) => {
    return {
        type: Types.SET_SIDEBAR,
        data,
    };
};
export const showConfirmDelete = (data: IConfirmDeleteReduxData) => {
    return {
        type: Types.SET_SHOW_CONFIRM_DELETE,
        payload: data,
    }
}
export const hideConfirmDelete = () => {
    return {
        type: Types.SET_HIDE_CONFIRM_DELETE,
    }
}
export const showAlert = (data: IAlertReduxData) => {
    return {
        type: Types.SET_SHOW_ALERT,
        payload: data,
    }
}
export const hideAlert = () => {
    return{
        type: Types.SET_HIDE_ALERT
    }
}
export const setAsset = (data: any) => {
    return {
        type: Types.SET_ASSET,
        payload: data,
    };
};
export const setCheckError = (isError: boolean) => {
    return {
        type: Types.CHECK_ERROR,
        data: isError,
    }
}
export const setCheckAccessTokenExpired = (isError: boolean) => {
    return {
        type: Types.ACCESS_TOKEN_EXPIRED,
        data: isError,
    }
}
export const setShowPopupAccessTokenExpired = (isError: boolean) => {
    return {
        type: Types.SHOW_POPUP_ACCESS_TOKEN_EXPIRED,
        data: isError,
    }
}
export const setCheckAccountHasBeenDeleted = (isError: boolean) => {
    return {
        type: Types.ACCOUNT_HAS_BEEN_DELETED,
        data: isError,
    }
}
export const setAccountNotPermission = (isError: boolean) => {
    return {
        type: Types.SET_ACCUONT_NOT_PERMISSION,
        data: isError,
    }
}
export const setActionPopupAccountDeleted = (isAction: boolean) => {
    return {
        type: Types.ACCTION_HAS_BEEN_DELETED,
        data: isAction,
    }
}
export const setAddRoomAsset = (data: IAddRoomAssetAction) => {
    return {
        type: Types.SHOW_POPUP_ACCESS_TOKEN_EXPIRED,
        data: data,
    }
}

export const setShowNotFound = (data = false) => {
    return {
        type: Types.SHOW_NOT_FOUND,
        data,
    };
};

export const setSuccessCallAPI= (data = false) => {
    return {
        type: Types.SET_SUCCESS_CALL_API,
        data,
    };
};

export const setToast = (data: IToastReduxData = { isShow: false }) => {
    return {
        type: Types.SET_TOAST,
        data,
    };
};

export const setShowDeleted = (data = false) => {
    return {
        type: Types.SHOW_DELETED,
        data,
    };
};

export const getStaffs = (data: any) => {
    return {
        type: Types.GET_LIST_STAFFS,
        payload: data,
    };
};

export const setListDataAll = (data: IListDataAllAPI) => {
    return {
        type: Types.SET_LIST_DATA_ALL,
        payload: data,
    };
};