import * as yup from 'yup';
import { apiHelper } from '../helpers';
import i18n from '../lang/i18next';

export const enterAddressSchema = yup.object().shape({
    postalCode: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company_edit.modal.postal_code_label') }))
        .min(
            7,
            i18n.t<string>('message.invalid_format', {
                0: i18n.t<string>('company_info.company_edit.modal.postal_code_label'),
            }),
        )
        .matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            i18n.t<string>('message.invalid_format', {
                0: i18n.t<string>('company_info.company_edit.modal.postal_code_label'),
            }),
        )
        .test('check-prefecture', i18n.t<string>('company_info.company_edit.modal.not_search_address'), function (value, { parent }) {
            const { prefecture } = parent;
            if (!prefecture || prefecture.trim() === '') {
                return !value;
            }
            return true;
        }),
    prefecture: yup.string(),
    town: yup
        .string()
        .trim()
        .required(i18n.t<string>('message.required', { 0: i18n.t<string>('company_info.company_edit.modal.street_label') })),
});

export const enterAddressInitialValues: IEnterAddressForm = {
    postalCode: '',
    prefecture: '',
    city: '',
    town: '',
    building: '',
};

export const fetchGetAddressByPostalCode = async (postalCode: string) => {
    try {
        const response = await apiHelper.getAddressByPostalCode(postalCode);
        return response;
    } catch (error: any) {
        return error;
    }
};
